<template>
    <v-card class="date-picker-modal">
        <div class="date-picker-close-btn" >
            <img class="cursor-pointer" @click="$emit('close')" src="@/assets/img/cross-circle.svg" />
        </div>
        <div class="date-picker-content">
            <div class="date-picker-card">
                <DatePicker
                    v-if="isOpened"
                    class="inline-block h-full date-picker" 
                    v-model="range" 
                    :model-config="modelConfig" 
                    :masks="masks" 
                    color="purple"
                    is-range
                    :min-date='new Date()'
                    :disabled-dates='disabledDates'
                />
                <p class="date-picker-description text-size-1">Zakres czasu z dostępnymi dniami jest zaznaczony pogrubionym tekstem. Wybierz dowolny zakres spośród dostępnych danych.</p>
            </div>
            <div class="d-flex justify-center">
                <v-btn @click="selectDate" class="save-btn">Zapisz</v-btn>
            </div>
        </div>
    </v-card>
</template>
<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
    components: {  DatePicker },
    props: {
        isOpened: {},
        initialDate: {},
        futureEmissions: {},
    },
    data: () => ({
        range: {
            start: null,
            end: null
        },
        masks: {
            input: 'DD-MM-YYYY',
        },
        modelConfig: {
            type: 'string',
            mask: 'YYYY-MM-DD',
        },
        date: null
    }),
    computed: {
        dateRangeText () {
            return this.dates.join(' ~ ')
        },
        disabledDates() {
            let result = [];
            if(this.futureEmissions) {
                 this.futureEmissions.forEach((emission) => {
                    result.push({
                        start: emission.date_start,
                        end: emission.date_end
                    })
                })
            }
            return result;
        }
    },
    methods: {
        selectDate() {
            let dates = []
            if(!this.range.end) {
                dates = [this.range.start, this.range.start];
            } else {
                dates = [this.range.start, this.range.end];
            }
            this.$emit("datesSelected", dates);
        },
        clear() {
            this.range.start = null;
            this.range.end = null;
        },
        setInitialDate() {
            this.range.start = this.initialDate.start;
            this.range.end = this.initialDate.end;
        }
    },
    watch: {
        isOpened(val) {
            if(!val) {
                this.clear();
            } else {
                if(this.initialDate) {
                    this.setInitialDate();
                }
            }
        },
    },
    created() {
        if(this.initialDate.start) {
            this.setInitialDate();
        }
    }
}
</script>
<style src="vuelendar/scss/vuelendar.scss" lang="scss"></style>