var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.col.type !== 'row-title' &&
    _vm.selectedPersonas.length !== 0 &&
    _vm.arraysHasCommon(_vm.row.options, ['persona'])
  )?_c('div',{staticClass:"personas",on:{"click":function($event){return _vm.setPersonaDialog(true)}}},[_c('div',{staticClass:"personas-avatars-wrapper"},[_c('div',{staticClass:"personas-avatars"},[_vm._l((_vm.selectedPersonas),function(personaId,idx){return [_c('div',{key:("selected_" + idx),staticClass:"avatar-wrapper",style:({
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: _vm.getPersonaColor(personaId),
          })},[_c('img',{attrs:{"src":_vm.getPersonaAvatar(personaId)}})])]})],2),_c('span',[_vm._v(_vm._s(_vm.selectedPersonas.length)+" Persona(s)")])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }