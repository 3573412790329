<template>
  <div class="callback">
    <v-container class="text-xs-center" style="height: 100%">
      <v-layout row justify-center fill-height align-center>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { http } from "@/plugins/http";
import store from '@/store/index';

export default {
  name: 'GoogleCallback',
  mounted() {
    const code = this.$route.query.code;
    this.authorizeGoogle(code);
  },
  methods: {
    authorizeGoogle(code) {
      http.get('/google/authorize?code=' + code)
        .then(response => {
            store.dispatch('parseLogin', {
              result: response,
              redirect: '/'
            })
        })
        .catch(error => {
            if (error.response) {
              this.$toast('Niespodziewany błąd', { 
                type: 'error'
              })
              console.log('response error',error.response)
              this.$router.replace('/auth/login')
            } else {
              this.$toast('Niespodziewany błąd', { 
                type: 'error'
              })
              console.log('another error', error)
              this.$router.replace('/auth/login')
            }
        });
    }
  }
}
</script>

<style>

</style>