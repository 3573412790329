<template>
  <!-- Card dialog -->
  <v-dialog v-model="dialog" max-width="1014px">
    <v-card>
      <div class="new-item">
        <h3>Dodawanie / Edycja Kafelka</h3>
        <div class="new-item-content">
          <div class="inputs">
            <v-text-field
              :class="'item-title'"
              v-model="title"
              dense
              hide-details
              :placeholder="'Tytuł kafelka'"
              outlined
            ></v-text-field>
            <v-textarea
              v-model="desc"
              dense
              :placeholder="'Opis'"
              :counter="400"
              :counter-value="displayCounter"
              outlined
            ></v-textarea>
            <v-file-input
              v-model="picture"
              prepend-icon=""
              accept="image/png, image/jpeg"
              placeholder="Załaduj obrazek"
            ></v-file-input>
          </div>
          <div class="pickers">
            <div @click="openPickerDialog('shape')" class="picker shape">
              <span class="label">Kształt</span>
              <div class="output">
                <BaseIcon
                  v-if="shapeSelected !== null"
                  :key="'selected_shape'"
                  class="selected"
                  :path="`shapes/shape-${shapeSelected}.vue`"
                />
              </div>
            </div>
            <div @click="openPickerDialog('icon')" class="picker icon">
              <span class="label">Ikona</span>
              <div class="output">
                <BaseIcon
                  v-if="iconSelected !== null"
                  :key="'selected_icon'"
                  class="selected"
                  :path="`icons/shape-${iconSelected}.vue`"
                  :fill="'#2E1B75'"
                />
              </div>
            </div>
            <div @click="openPickerDialog('color')" class="picker color">
              <span class="label">Kolor</span>
              <div
                class="output"
                :style="{
                  backgroundColor:
                    colorSelected !== null
                      ? colors[colorSelected].backgroundColor
                      : null,
                  border: colorSelected !== null ? '2px solid #2E1B75' : null,
                }"
              ></div>
            </div>
            <div class="picker preview">
              <span class="label">Obrazek</span>
              <img class="picture" v-if="picture" :src="pictureSrc" />
            </div>
          </div>
        </div>
        <div class="add-item" @click="add">Zapisz</div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseIcon from "@/components/customerJourney/BaseIcon.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseIcon,
  },
  data() {
    return {
      pictureSrc: null,
    };
  },
  computed: {
    ...mapState("cjstore", [
      "cardDialog",
      "editCardTitle",
      "editCardDesc",
      "shapeSelected",
      "iconSelected",
      "colorSelected",
      "pictureUploaded",
      "colors",
    ]),
    dialog: {
      get() {
        return this.cardDialog;
      },
      set(value) {
        this.setCardDialog(value);
      },
    },
    title: {
      get() {
        return this.editCardTitle;
      },
      set(value) {
        this.setEditCardTitle(value);
      },
    },
    desc: {
      get() {
        return this.editCardDesc;
      },
      set(value) {
        this.setEditCardDesc(value);
      },
    },
    picture: {
      get() {
        return this.pictureUploaded;
      },
      set(value) {
        this.setPictureUploaded(value);
      },
    },
  },
  watch: {
    picture(newVal) {
      if (newVal) {
        this.pictureSrc = URL.createObjectURL(newVal);
      }
    },
    dialog(newVal) {
      if(!newVal) {
        this.clearAll();
      }
    }
  },
  methods: {
    ...mapActions("cjstore", [
      "setCardDialog",
      "setEditCardTitle",
      "setEditCardDesc",
      "setPictureUploaded",
      "setShapeSelected",
      "setColorSelected",
      "setIconSelected",
      "addCard",
      "saveCardPicture",
    ]),
    clearAll() {
      this.setEditCardTitle(null);
      this.setEditCardDesc(null);
      this.setEditCardDesc(null);
      this.setPictureUploaded(null);
      this.setShapeSelected(null);
      this.setColorSelected(null);
      this.setIconSelected(null);
    },
    displayCounter(item) {
      let returnText = item ? item.length : 0;
      return `${returnText} / 400`;
    },
    openPickerDialog(picker) {
      this.$store.dispatch("cjstore/setCurrentPicker", picker);
      this.$store.dispatch("cjstore/setPickersDialog", true);
    },
    async add() {
      let id = this.$route.params.id;
      await this.saveCardPicture(id);
      await this.addCard();
      this.setCardDialog(false);
    },
  },
};
</script>

<style lang="sass" scoped>
.new-item
  display: flex
  flex-direction: column
  align-items: center
  padding: 30px
  h3
    padding-bottom: 30px
    font-size: 18px
    color: $primary-color
.new-item-content
  width: 90%
  padding: 20px
  display: flex
.inputs
  width: 60%
.item-title
  margin-bottom: 25px
.pickers
  width: calc(40% - 100px)
  margin-left: 100px
  display: flex
  flex-direction: column
  .picker
    display: flex
    justify-content: space-between
    align-items: center
    margin: 10px 0
    cursor: pointer
  .picker.preview
    cursor: auto
    justify-content: normal
  .picker.preview .label
    margin-right: 28px
  .output
    width: 35px
    height: 35px
    background-color: #F5F4FB
    border-radius: 8px
    display: flex
    justify-content: center
    align-items: center
    margin-right: 115px
    > *
      max-height: 20px
      max-width: 20px
.add-item
  margin: 30px auto 0 auto
  font-weight: bold
  font-size: 12px
  background: $primary-color
  color: #fff
  cursor: pointer
  border-radius: 8px
  padding: 12px 150px
.picture
  max-width: 150px
  max-height: 150px
  border-radius: 8px
</style>
