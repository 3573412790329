var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"person-item"},[_c('v-card',[_c('img',{staticClass:"cursor-pointer",attrs:{"src":require(("@/assets/img/personas/person_" + (_vm.persona.basic_info.image_id || 1) + ".jpg"))},on:{"click":_vm.clicked}}),_c('div',{staticClass:"person-name",on:{"click":_vm.clicked}},[_c('h2',[_vm._v(_vm._s(_vm.persona.basic_info.name))]),_c('div',{staticClass:"separator"}),_c('span',[_vm._v(_vm._s(_vm.persona.job_title))])]),_c('br'),_c('div',{staticClass:"card-date"},[_vm._v(" Ostatnio otworzony: "+_vm._s(_vm.formatDate(_vm.persona.last_modification_ts))+" ")]),_c('div',{staticClass:"person-actions"},[_c('div',{staticClass:"edit",on:{"click":_vm.clicked}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('PencilIcon')],1)]}}])},[_c('div',[_vm._v("Edytuj personę")])])],1),_c('div',{staticClass:"duplicate",on:{"click":_vm.duplicate}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('DuplicateIcon')],1)]}}])},[_c('div',[_vm._v("Zduplikuj personę")])])],1),_c('div',{staticClass:"remove",on:{"click":_vm.removePersona}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({attrs:{"data-html2canvas-ignore":"true"}},'div',attrs,false),on),[_c('TrashIcon')],1)]}}])},[_c('div',[_vm._v("Usuń personę")])])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }