<template>
    <div id="source-files-table">
        <v-data-table
            :headers="headers"
            :items="items"
        >
            <template v-slot:item.name="{ item }">
                {{item.name.split(".")[0]}}
            </template>
            <template v-slot:item.type="{ item }">
                <div class="d-flex">
                    <img v-if="item.type === 'pdf'" src="@/assets/img/icon_sheet_1.svg" />
                    <img v-if="item.type === 'txt'" src="@/assets/img/icon_sheet_2.svg" />
                    <img v-if="item.type === 'jpeg' || item.type === 'jpg' || item.type === 'png' " src="@/assets/img/icon_image.svg" />
                    <span class="file-type">{{item.type}}</span>
                </div>
            </template>
            <template v-slot:item.actions="{ item }">
                <a download :href="baseURL+'/files?file_path='+item.path"><img class="cursor-pointer" src="@/assets/img/icon_download.svg" /></a>
                <img @click="removeFile(item)" class="ml-5 cursor-pointer" src="@/assets/img/icon_trash.svg" />
            </template>
        </v-data-table>
    </div>
</template>
\
<script>
import { http } from "@/plugins/http";
//import { saveAs } from 'file-saver';

export default {
    props: {
        items: {},
        personaId: {}
    },
    data() {
        return {
            baseURL: process.env.VUE_APP_API_URL,
            headers: [
                {
                    text: "NAZWA PLIKU",
                    align: 'start',
                    sortable: false,
                    value: 'name',
                }, {
                    text: "TYP PLIKU",
                    align: 'start',
                    sortable: false,
                    value: 'type',
                }, {
                    text: "AKCJE",
                    align: 'start',
                    sortable: false,
                    value: 'actions',
                }
            ]
        }
    },
    methods: {
        async downloadFile(file) {
            await http.post(`/download_file`,  { file_path: file.path })
            //saveAs(`${response}`, file.name);
        },
        removeFile(file) {
            http.delete(`/persona_source_files/${this.personaId}`, { params: { file_name: file.name }})
            this.$emit("fileDeleted")
        }
    }
}
</script>