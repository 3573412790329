<template>
  <div id="survey-analytics">
    <div class="d-flex align-top">
      <v-tabs v-model="tab" left>
        <v-tabs-slider color="#2E1B75"></v-tabs-slider>
        <v-tab href="#tab-1" class="pl-8"> Emisje </v-tab>

        <v-tab href="#tab-2"> Testy A/B </v-tab>
      </v-tabs>
      <div class="close-btn">
        <img @click="$emit('close')" src="@/assets/img/cross-circle.svg" />
      </div>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="i in 2" :key="i" :value="'tab-' + i">
        <v-card flat>
          <component
            :ref="`tab${i}`"
            :is="components[i - 1]"
            :surveyData="surveyData"
            :surveyRunData="surveyRunData"
            :subpages="subpages"
            :isOpened="isOpened"
            @getSurveyRunData="getSurveyRunData"
          >
            <SurveyDataContainer
              :surveyData="surveyData"
              :surveyRunData="surveyRunData"
              :subpages="subpages"
              :isOpened="isOpened"
              :activateSurveyBtnClicked="activateSurveyBtnClicked"
              @getSurveyRunData="getSurveyRunData"
              ref="surveyDataContainer"
            />
          </component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ABTestsTab from "@/components/surveys/analytics/ABTestsTab";
import EmissionsTab from "@/components/surveys/analytics/EmissionsTab";
import { http } from "@/plugins/http";
import SurveyDataContainer from "@/components/surveys/analytics/SurveyDataContainer";

export default {
  props: {
    surveyData: {},
    isOpened: {},
    addSurveyRun: {},
    activateSurveyBtnClicked: {
      default: false
    },
  },
  name: "paginated-tables",
  components: { ABTestsTab, EmissionsTab, SurveyDataContainer },
  data() {
    return {
      tab: null,
      components: ["EmissionsTab", "ABTestsTab"],
      surveyRunData: null,
      subpages: null,
    };
  },
  mounted() {
    this.getSurveyRunData();
    this.getSubpages();
  },
  methods: {
    async getSurveyRunData() {
      const response = await http.get(
        `/survey_runs_binded_survey/${this.surveyData.id}`
      );
      this.surveyRunData = response.data.data;
    },
    async getSubpages() {
      const result = await http.get("/domain");
      if(result.data.data[0].subpages) {
        this.subpages = result.data.data[0].subpages.data;
      }
    },
  },
  watch: {
    tab(newVal) {
      let isHintClosed = localStorage.getItem("hint_analytics");
      if (newVal == "tab-1" && !isHintClosed) {
        this.$store.dispatch("hints/setHint", {
          visible: true,
          category: "analytics",
        });
      }
    },
    isOpened(val) {
      if (val) {
        this.getSurveyRunData();
        this.getSubpages();
      } else {
        this.$refs.tab1[0].clear();
        this.$refs.tab2[0].clear();
        this.tab = null;
        this.$store.dispatch("hints/setHint", {
          visible: false,
        });
      }
    },
  },
};
</script>
