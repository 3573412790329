var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto customer-journey-card"},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1),_c('v-img',{staticClass:"card-image",attrs:{"height":"112","src":_vm.defaultImage}}),_c('div',{staticClass:"card-id my-1"},[_vm._v("Projekt #"+_vm._s(_vm.data.id))]),_c('v-card-title',{staticClass:"card-title pt-0 mb-1 px-0"},[_c('span',{staticClass:"card-title-text"},[_vm._v(_vm._s(_vm.data.name))]),_c('div',{staticClass:"card-actions"},[(!_vm.isArchive)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"icon-archive",on:{"click":function($event){return _vm.$emit('archive', _vm.data.id, true)}}},'span',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/img/icon_archive.svg")}})])]}}],null,false,1366058403)},[_c('span',[_vm._v("Archiwizuj")])])]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"icon-archive mr-2",on:{"click":function($event){return _vm.$emit('archive', _vm.data.id, false)}}},'span',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/img/icon_restore.svg")}})])]}}])},[_c('span',[_vm._v("Przywróć")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"icon-trash",on:{"click":function($event){_vm.showRemoveCardModal = true}}},'span',attrs,false),on),[_c('img',{attrs:{"src":require("@/assets/img/icon_trash.svg")}})])]}}])},[_c('span',[_vm._v("Usuń")])])]],2)]),_c('v-card-text',{staticClass:"px-0"},[_c('div',{staticClass:"card-description mb-1"},[_vm._v(_vm._s(_vm.data.description))]),_c('div',{staticClass:"card-date"},[_vm._v(" Ostatnio otworzony: "+_vm._s(_vm.formatDate(_vm.data.insert_ts))+" ")])]),(!_vm.isArchive)?[_c('v-card-actions',[_c('router-link',{staticClass:"card-button-link",attrs:{"to":("/customer_journey/" + (_vm.data.id))}},[_c('v-btn',{staticClass:"card-button",attrs:{"text":""}},[_vm._v(" OTWÓRZ PROJEKT ")])],1)],1)]:_vm._e(),_c('RemoveCardModal',{attrs:{"dialog":_vm.showRemoveCardModal,"data":_vm.data},on:{"close":function($event){_vm.showRemoveCardModal = false},"deleteCustomerJourney":_vm.deleteCustomerJourney}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }