const SET_NOTIFICATION_MENU = "SET_NOTIFICATION_MENU";

const baseState = function () {
  return {
    notificationMenu: false,
  };
};

export default {
  namespaced: true,
  state: baseState(),
  actions: {
    setNotificationMenu({ commit }, payload) {
      commit(SET_NOTIFICATION_MENU, payload);
    },
  },
  mutations: {
    [SET_NOTIFICATION_MENU](state, payload) {
      state.notificationMenu = payload;
    },
  },
};
