<template>
    <v-card id="plan-emission-modal">
        <div class="plan-emission-close-btn" >
            <img @click="$emit('close')" src="@/assets/img/cross-circle.svg" />
        </div>
        <div class="plan-emission-content">
            <p class="plan-emission-title">Edycja emisji ankiety</p>
            <p class="text-center text-color-primary font-weight-bold">Edytuj emisję ankiety</p>
            <p class="text-center text-color-gray text-size-1">Ankiety mogą być emitowane na wybranych przez Ciebie podstronach, lub wg Regex.</p>
            <label>Zakres czasu:</label>
            <div class="position-relative mb-3">
                <v-text-field
                    v-model="emissionDate"
                    :disabled="true"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    class="plan-emission-input mt-3"
                >
                </v-text-field>
                <img @click="showDatePicker=true" class="icon cursor-pointer" src="@/assets/img/icon_calendar.svg" />
                <div class="text-right text-color-red text-size-2" v-if="emissionDateErrorMessage">{{ emissionDateErrorMessage }}</div>
            </div>
            <label>emisja za pomocą:</label>
            <div class="position-relative">
                <v-radio-group
                    v-model="emissionData.trigger_location.type"
                >
                    <v-radio
                        class="trigger-radio-item"
                        label="Podstrona"
                        color="#2E1B75"
                        value="subpage"
                    ></v-radio>
                    <v-radio
                        class="trigger-radio-item"
                        label="Regex"
                        color="#2E1B75"
                        value="regex"
                    ></v-radio>
                </v-radio-group>
            </div>
            <div class="mb-3 mt-0" v-if="emissionData.trigger_location.type === 'subpage'" >
                <Dropdown 
                    :data="subpages"
                    :initialValue="emissionData.trigger_location.value"
                    @itemSelected="emissionData.trigger_location.value = $event"
                />
                <div class="text-right text-color-red text-size-2" v-if="emissionLocationValueErrorMessage">{{ emissionLocationValueErrorMessage }}</div>
            </div>
            <div class="mb-3" v-else>
                <v-text-field
                    hide-details
                    outlined
                    placeholder="Regex"
                    color="rgba(0,0,0,.6)"
                    light
                    class="plan-emission-input"
                    v-model="emissionData.trigger_location.value"
                >
                </v-text-field>
                <div class="text-right text-color-red text-size-2" v-if="emissionTypeErrorMessage">{{ emissionTypeErrorMessage }}</div>
            </div>
            <label>Trigger wg: </label>
            <div class="position-relative">
                <v-radio-group
                    v-model="emissionData.trigger.type"
                >
                    <v-radio
                        class="trigger-radio-item"
                        label="Czas na stronie"
                        color="#2E1B75"
                        value="time"
                    ></v-radio>
                    <v-radio
                        class="trigger-radio-item"
                        label="% Scrollowania"
                        color="#2E1B75"
                        value="scroll"
                    ></v-radio>
                    <v-radio
                        class="trigger-radio-item"
                        color="#2E1B75"
                        value="rage"
                    >
                        <template v-slot:label>
                            <div>Zdenerwowanie <br> (rage click)</div>
                        </template>
                    </v-radio>
                    <v-radio
                        class="trigger-radio-item"
                        color="#2E1B75"
                        value="rage_scroll"
                    >
                        <template v-slot:label>
                            <div>Nerwowy scroll</div>
                        </template>
                    </v-radio>
                    <v-radio
                        class="trigger-radio-item"
                        color="#2E1B75"
                        value="rage_browse"
                    >
                        <template v-slot:label>
                            <div>Nerwowe przekliki <br> (zmiany stron)</div>
                        </template>
                    </v-radio>
                    <v-radio
                        class="trigger-radio-item"
                        color="#2E1B75"
                        value="not_found"
                    >
                        <template v-slot:label>
                            <div>Nieznalezienie informacji</div>
                        </template>
                    </v-radio>
                    <v-radio
                        class="trigger-radio-item"
                        color="#2E1B75"
                        value="exit"
                    >
                        <template v-slot:label>
                            <div>Wyjście ze strony</div>
                        </template>
                    </v-radio>
                </v-radio-group>
            </div>
            <div class="position-relative mt-0" v-if="emissionData.trigger.type === 'time'">
                <v-text-field
                    v-model="emissionData.trigger.value"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    placeholder="Czas na stronie"
                    class="plan-emission-input"
                >
                </v-text-field>
                <span class="unit">[s]</span>
                <img class="icon" src="@/assets/img/icon_stopwatch.svg" />
                <div class="text-right text-color-red text-size-2" v-if="triggerValueErrorMessage">{{ triggerValueErrorMessage }}</div>
            </div>
            <div class="position-relative mt-0" v-if="emissionData.trigger.type === 'scroll'">
                <v-text-field
                    v-model="emissionData.trigger.value"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    placeholder="% Scrollowania"
                    class="plan-emission-input"
                >
                </v-text-field>
                <img class="icon" src="@/assets/img/icon_offer.svg" />
                <div class="text-right text-color-red text-size-2" v-if="triggerValueErrorMessage">{{ triggerValueErrorMessage }}</div>
            </div>
            <v-btn
                class="save-emission-btn"
                @click="save"
                :disabled="!isChanged"
            >
                Zapisz zmiany
            </v-btn>
        </div>
        <v-dialog
            v-model="showDatePicker"
            class="pr-0"
            max-width="fit-content"
        >
            <DatePickerModal
                max-width="fit-content"
                @close="showDatePicker=false"
                @datesSelected="setEmissionDate"
                :isOpened="showDatePicker"
                :initialDate="initialDataForDatePicker"
                ref="datepicker"
                :futureEmissions="futureEmissions"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import DatePickerModal from "@/components/surveys/DatePickerModal.vue"
import Dropdown from "@/components/surveys/Dropdown.vue"
import { http } from "@/plugins/http";

export default {
    components: { DatePickerModal, Dropdown },
    props: {
        editedEmission: {},
        isOpened: {},
        subpages: {},
        futureEmissions: {}
    },
    data() {
        return {
            emissionData: {
                trigger: {
                    type: null,
                    value: null,
                },
                trigger_location: {
                    type: null,
                    value: null
                }
            },
            showDatePicker: false,
            emissionType: "subpage",
            emissionDate: null,
            dateStart: null,
            dateEnd: null,
            emissionDateErrorMessage: null,
            triggerValueErrorMessage: null,
            emissionTypeErrorMessage: null,
            emissionLocationValueErrorMessage: null,
        }
    },
    computed: {
        triggerLocationType() {
            return this.emissionData.trigger_location.type;
        },
        triggerType() {
            return this.emissionData.trigger.type;
        },
        initialDataForDatePicker() {
            return { 
                start: this.dateStart, 
                end: this.dateEnd
            }
        },
        isChanged() {
            let result = false;

            if(this.emissionData) {
                const firstDay = this.formatDate(this.editedEmission.date_start)
                const lastDay = this.formatDate(this.editedEmission.date_end)
                const initialEmissionDate = `${firstDay} - ${lastDay}`;

                if(this.emissionData.trigger_location.type !== this.editedEmission.trigger_location.type) {
                    result = true;
                }
                if(this.emissionData.trigger_location.value !== this.editedEmission.trigger_location.value) {
                    result = true;
                }
                if(this.emissionData.trigger.type !== this.editedEmission.trigger.type) {
                    result = true;
                }
                if(this.emissionData.trigger.value !== this.editedEmission.trigger.value) {
                    result = true;
                }
                if(this.emissionDate !== initialEmissionDate) {
                    result = true;
                }
            }

            return result;
        }
    },
    methods: {
        setEmissionDate(dates) {
            this.emissionDate =  `${this.formatDate(dates[0])} - ${this.formatDate(dates[1])}`
            this.dateStart = dates[0];
            this.dateEnd = dates[1];
            this.showDatePicker = false;
        },
        isValid() {
            this.resetValidation();
            let result = true;
            if(!this.emissionDate) {
                result = false;
                this.emissionDateErrorMessage = "Czas emisji ankiety jest wymagany"
            }
            if(this.emissionData.trigger_location.type === "regex" && !this.emissionData.trigger_location.value) {
                result = false;
                this.emissionTypeErrorMessage = "To pole nie może być puste"
            }
            if((this.emissionData.trigger.type === 'scroll' || this.emissionData.trigger.type === 'time') && !this.emissionData.trigger.value) {
                result = false;
                this.triggerValueErrorMessage = "To pole nie może być puste"
            }
            if(this.emissionData.trigger_location.value === "Wybierz podstronę") {
                result = false;
                this.emissionLocationValueErrorMessage = "Podstrona jest wymagana"
            }
            return result;
        },
        resetValidation() {
            this.emissionDateErrorMessage = null;
            this.emissionTypeErrorMessage = null;
            this.triggerValueErrorMessage = null;
            this.emissionLocationValueErrorMessage = null;
        },
        getEditedFields() {
            const emission = {}
            const firstDay = this.formatDate(this.editedEmission.date_start)
            const lastDay = this.formatDate(this.editedEmission.date_end)
            const initialEmissionDate = `${firstDay} - ${lastDay}`;

            if((this.emissionData.trigger_location.type !== this.editedEmission.trigger_location.type) || (this.emissionData.trigger_location.value !== this.editedEmission.trigger_location.value)) {
                if(!emission.trigger_location) {
                    emission.trigger_location = {}
                }
                emission.trigger_location.type = this.emissionData.trigger_location.type;
                emission.trigger_location.value = this.emissionData.trigger_location.value;
            } 
            if((this.emissionData.trigger.type !== this.editedEmission.trigger.type) || (this.emissionData.trigger.value !== this.editedEmission.trigger.value)) {
                if(!emission.trigger) {
                    emission.trigger = {}
                }
                emission.trigger.type = this.emissionData.trigger.type;
                emission.trigger.value = this.emissionData.trigger.value;
            }
            if(this.emissionDate !== initialEmissionDate) {
                emission.date_start = this.dateStart
                emission.date_end = this.dateEnd
            }
            return emission;
        },
        async save() {
            if(this.isValid()) {
                const editedFields = this.getEditedFields();
                await http.patch(`/survey_run/${this.editedEmission.id}`,  {...editedFields} )
                this.$emit('close');
            }   
        },
        formatDate(data) {
            const date = new Date(data);
            return [
                this.padTo2Digits(date.getDate()),
                this.padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('.');
        },
        formatDateWithDash(data) {
            const date = new Date(data);
            return [
                date.getFullYear(),
                this.padTo2Digits(date.getMonth() + 1),
                this.padTo2Digits(date.getDate()),
                
            ].join('-');
        },
        setEmissionData() {
            this.emissionData = JSON.parse(JSON.stringify(this.editedEmission));
            const firstDay = this.formatDate(this.emissionData.date_start)
            const lastDay = this.formatDate(this.emissionData.date_end)
            this.dateStart = this.formatDateWithDash(this.emissionData.date_start);
            this.dateEnd = this.formatDateWithDash(this.emissionData.date_end);
            this.emissionDate = `${firstDay} - ${lastDay}`;
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
    },
    mounted() {
        this.setEmissionData();
    },
    watch: {
        isOpened(val) {
            if(val) {
                this.setEmissionData();
            }
        },
        triggerLocationType(val, oldVal) {
            if(oldVal) {
                if(val === "subpage") {
                    this.emissionData.trigger_location.value = "Wybierz podstronę"
                } else {
                    this.emissionData.trigger_location.value = ""
                }
            }
        },
        triggerType(val, oldVal) {
            if(oldVal) {
                this.emissionData.trigger.value = "";
            }
        }
    }
}
</script>