<template>
  <div :class="['column', col.type == 'row-title' ? 'row-title' : '']">
    <!-- Title label cell -->
    <TitleLabel :col="col" :row="row" :columnId="columnId" :rowId="rowId" />
    <!-- Add/remove column buttons -->
    <AddRemoveColumns :row="row" :columnId="columnId" />
    <!-- Add new item cell -->
    <NewCard :col="col" :row="row" :columnId="columnId" :rowId="rowId" />
    <!-- Stage cell -->
    <Stage :col="col" :row="row" :columnId="columnId" :rowId="rowId" />
    <!-- Substage cell -->
    <Substage :col="col" :row="row" :columnId="columnId" :rowId="rowId" />
    <!-- Add persona cell-->
    <AddPersona :col="col" :row="row" :columnId="columnId" :rowId="rowId" />
    <!-- Persona cell -->
    <Persona :col="col" :row="row" :columnId="columnId" :rowId="rowId" />
    <CommunicationChannel
      :col="col"
      :row="row"
      :columnId="columnId"
      :rowId="rowId"
    />
    <!-- Card cell -->
    <Card :col="col" :row="row" :columnId="columnId" :rowId="rowId" />
  </div>
</template>

<script>
import TitleLabel from "@/components/customerJourney/cells/TitleLabel.vue";
import AddRemoveColumns from "@/components/customerJourney/cells/AddRemoveColumns.vue";
import NewCard from "@/components/customerJourney/cells/NewCard.vue";
import Stage from "@/components/customerJourney/cells/Stage.vue";
import Substage from "@/components/customerJourney/cells/Substage.vue";
import AddPersona from "@/components/customerJourney/cells/AddPersona.vue";
import Persona from "@/components/customerJourney/cells/Persona.vue";
import CommunicationChannel from "@/components/customerJourney/cells/CommunicationChannel.vue";
import Card from "@/components/customerJourney/cells/Card.vue";

export default {
  components: {
    TitleLabel,
    AddRemoveColumns,
    NewCard,
    Stage,
    Substage,
    AddPersona,
    Persona,
    CommunicationChannel,
    Card,
  },
  props: {
    row: Object,
    col: Object,
    rowId: Number,
    columnId: Number,
  },
};
</script>

<style lang="sass" scoped>
.column
  border-right: 2px dotted rgba(131, 146, 171, .5)
  min-width: 275px
  position: relative
  display: flex
  flex-direction: column
  justify-content: center
  min-height: 40px
  pointer-events: auto
  &.full-width
    width: 100%
  &.row-title
    min-width: 240px
    width: 100%
    max-width: 240px
.column::v-deep:hover .add-new,
.column::v-deep:hover .add-row,
.column::v-deep:hover .remove-row,
.column::v-deep:hover .add-column,
.column::v-deep:hover .remove-column,
.column::v-deep:hover .add-persona
  opacity: 1
.row-title
  padding: 20px
  background: #fff
  position: sticky
  left: 0
  z-index: 100
</style>
