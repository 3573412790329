<template>
    <v-card class="px-6 py-10" id="payments-modal">
        <v-row>
            <v-col cols="6">
                <v-row>
                    <v-col cols="6" class="d-flex flex-column justify-space-between payments-box">
                        <div>
                            <div class="font-weight-bold text-color-primary">Obecny plan:</div>
                            <div class="text-color-gray">roczny PROFESJONALNY</div>
                            <div class="text-color-primary">
                                dostęp ważny do <span class="font-weight-bold text-color-primary">2022-12-01</span>
                            </div>
                        </div>
                        <div class="text-color-primary">
                            Rozszerz plan: <span class="text-color-secondary cursor-pointer ml-4">więcej</span>
                        </div>
                    </v-col>
                     <v-col cols="5" class="d-flex flex-column justify-space-between payments-box ml-2">
                        <div>
                            <div class="font-weight-bold text-color-primary">Dane do faktury:</div>
                            <div class="text-color-gray">Nazwa Sp. z o.o.</div>
                            <div class="text-color-primary">NIP: 0000001111</div>
                            <div class="text-color-primary">adres: ul. Najpiękniejsza 18 22-222 Warszawa</div>
                        </div>
                        <div>
                            <span class="text-color-secondary cursor-pointer" @click="$refs.billingDetailsModal.setDialog(true)">
                                Zmień
                            </span>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-list three-line>
                            <v-subheader id="payments-invoices-title">Faktury</v-subheader>
                            <template v-for="(item, index) in items">
                                <v-list-item
                                :key="item.index"
                                >
                                    <v-list-item-content class="payments-invoices">
                                        <v-row>
                                            <v-col class="text-start">
                                                <v-list-item-title class="font-weight-bold text-color-primary mb-1"> {{ item.title }}</v-list-item-title>
                                                <v-list-item-subtitle class="text-color-primary text-size-1"> {{ item.subtitle }} </v-list-item-subtitle>
                                            </v-col>
                                            <v-col class="d-flex align-center justify-end text-color-gray text-size-1 font-weight-bolder">
                                                <span class="mr-4">{{item.amount}}</span>
                                                <img class="cursor-pointer" src="@/assets/img/icon_pdf.svg" />
                                            </v-col>
                                        </v-row>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider
                                    :key="index"
                                    :inset="item.inset"
                                ></v-divider>
                            </template>
                        </v-list>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6">
                <v-row class="d-flex justify-end mr-6">
                    <v-col cols="4" class="py-0 px-0" 
                        @click="paymentsMethodsOpen = !paymentsMethodsOpen">
                        <div class="payment-method-box">
                            <div class="font-weight-bold text-color-primary">Metoda płatności:</div>
                            <div class="text-color-gray">karta: Master Card</div>
                        </div>
                    </v-col>
                    <v-col cols="7"  class="ml-2 py-0 px-0">
                        <div id="payments-card">
                            <v-row>
                                <v-col cols="12">7812 2139 0823 XXXX</v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pr-0 pt-0 card-data">
                                    <p class="text-size-1 mb-0">Właściciel karty</p>
                                    <p class="text-size-2 font-weight-bold mb-0">Jack Peterson</p>
                                </v-col>
                                <v-col class="px-0 pt-0 card-data">
                                    <p class="text-size-1 mb-0">Data ważności</p>
                                    <p class="text-size-2 font-weight-bold mb-0">05/2022</p>
                                </v-col>
                            </v-row>
                        </div>
                        <p class="text-center mt-2 text-color-secondary font-weight-bold " >
                            <span class="cursor-pointer" @click="$refs.cardModal.setDialog(true)">zmień dane karty</span>
                        </p>
                    </v-col>
                </v-row>
            </v-col>
            <CardModal ref="cardModal" />
            <BillingDetailsModal ref="billingDetailsModal" />
            <PaymentsMethodsModal :isOpen="paymentsMethodsOpen"
            @close="close" />
        </v-row>                                                                                                                                                                            
    </v-card>
</template>

<script>
import CardModal from "@/components/admin/accountManagement/payments/CardModal"
import BillingDetailsModal from "@/components/admin/accountManagement/payments/BillingDetailsModal"
import PaymentsMethodsModal from "@/components/admin/accountManagement/payments/PaymentsMethodsModal"

export default {
    components: { CardModal, BillingDetailsModal, PaymentsMethodsModal },
    data: () => ({
        paymentsMethodsOpen: false,
        items: [
            {
                title: 'March, 01, 2020',
                subtitle: `FV/01/01/2022`,
                amount: '125 PLN'
            },
            {
                title: 'March, 01, 2020',
                subtitle: `FV/01/01/2022`,
                amount: '125 PLN'
            },
            {
                title: 'March, 01, 2020',
                subtitle: `FV/01/01/2022`,
                amount: '125 PLN'
            },
            {
                title: 'March, 01, 2020',
                subtitle: `FV/01/01/2022`,
                amount: '125 PLN'
            },
            {
                title: 'March, 01, 2020',
                subtitle: `FV/01/01/2022`,
                amount: '125 PLN'
            },
        ],
    }),
    methods:{
        close(){
            this.paymentsMethodsOpen = false;
        }
    }
  }
</script>