<template>
  <v-data-table
    :headers="headers"
    :items="surveys"
    sort-by="name"
    show-select
    v-model="selected"
    id="surveys"
    :items-per-page="surveys.length"
  >
    <template v-slot:top>
        <v-toolbar
            flat
        >
            <v-dialog
                v-model="addSurveyModal"
                max-width="fit-content"
            >
             <template v-slot:activator="{ on, attrs }">
                    <div class="position-fixed d-flex button-wrapper">
                        <button
                            class="d-flex align-center justify-space-between add-survey-button"
                            @click="showModal = true"
                            v-bind="attrs"
                            v-on="on"
                        >
                            Stwórz nową ankietę
                            <img src="@/assets/img/icon_plus-circle.svg" />
                        </button>
                        <div class="info-container" @click="surveyConfigModal = true">
                            <img src="@/assets/img/icon_info.svg">
                            Pomoc
                        </div>
                    </div>
                </template>
                <AddSurveyModal 
                    @close="closeAddSurveyModal" 
                    :isOpened="addSurveyModal"
                />
            </v-dialog>
            <v-dialog
                v-model="surveyConfigModal"
                max-width="823px"
            >
                <SurveyConfigModal
                    @close="surveyConfigModal = false"
                    :isOpened="surveyConfigModal"
                />
            </v-dialog>
            <v-dialog
                v-model="editSurveyModal"
                max-width="fit-content"
            >
                <EditSurveyModal
                    @close="closeEditSurveyModal"
                    :isOpened="editSurveyModal"
                    :editedItemProp="editedItem"
                />
            </v-dialog>
            <v-dialog
                v-model="duplicateSurveyModal"
                max-width="fit-content"
            >
                <DuplicateSurveyModal
                    @close="closeDuplicateSurveyModal"
                    :isOpened="duplicateSurveyModal"
                    :editedItemProp="editedItem"
                />
            </v-dialog>
            <v-dialog
                v-model="showAnalytics"
                class="pr-0"
                max-width="fit-content"
            >
                <Analytics
                    :surveyData="editedItem"
                    :isOpened="showAnalytics"
                    :newSurveyRun="newSurveyRun"
                    :activateSurveyBtnClicked="activateSurveyBtnClicked"
                    @close="showAnalytics=false"
                />
            </v-dialog>
                <v-dialog
                v-model="showSurveyLinkModal"
                class="pr-0"
                max-width="fit-content"
            >
                <SurveyLinkModal
                    :isOpened="showSurveyLinkModal"
                    @close="showSurveyLinkModal=false"
                    :linkToSurvey="linkToSurvey"
                />
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card id="survey-delete-modal">
                    <div class="survey-modal-close-btn" >
                        <img @click="dialogDelete = false" src="@/assets/img/cross-circle.svg" />
                    </div>
                    <div v-if="editedItem.is_editable" class="survey-delete-modal-content">
                        <v-card-title class="delete-modal-title">Czy na pewno chcesz usunąć?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="delete-modal-cancel" @click="closeDelete">Anuluj</v-btn>
                            <v-btn class="delete-modal-confirm" @click="deleteItemConfirm">Usuń</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </div>
                    <div v-else class="survey-delete-modal-content">
                        <v-card-title class="delete-modal-title">Czy na pewno chcesz archiwizować?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="delete-modal-cancel" @click="closeDelete">Anuluj</v-btn>
                            <v-btn class="delete-modal-confirm" @click="deleteItemConfirm">Archiwizuj</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </div>
                </v-card>
            </v-dialog>
        </v-toolbar>
    </template>
    <template v-slot:item.insert_ts="{ item }">
        {{formatDate(item.insert_ts) }}
    </template>
    <template v-slot:item.activation="{ item }">
        <v-btn @click="activateSurvey(item)">Aktywuj</v-btn> 
    </template>
    <template v-slot:item.actions="{ item }">
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                 <svg  v-bind="attrs" v-on="on" @click="editItem(item)" class="mr-2 icon-pen" width="24" height="25" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 20.75H3.25C3.25 21.1642 3.58579 21.5 4 21.5V20.75ZM4 16.75L3.46967 16.2197C3.32902 16.3603 3.25 16.5511 3.25 16.75H4ZM17 3.75L17.5303 3.21967C17.2374 2.92678 16.7626 2.92678 16.4697 3.21967L17 3.75ZM21 7.75L21.5303 8.28033C21.8232 7.98744 21.8232 7.51256 21.5303 7.21967L21 7.75ZM8 20.75V21.5C8.19891 21.5 8.38968 21.421 8.53033 21.2803L8 20.75ZM4.75 20.75V16.75H3.25V20.75H4.75ZM16.4697 4.28033L20.4697 8.28033L21.5303 7.21967L17.5303 3.21967L16.4697 4.28033ZM8 20H4V21.5H8V20ZM20.4697 7.21967L17.4697 10.2197L18.5303 11.2803L21.5303 8.28033L20.4697 7.21967ZM17.4697 10.2197L7.46967 20.2197L8.53033 21.2803L18.5303 11.2803L17.4697 10.2197ZM4.53033 17.2803L14.5303 7.28033L13.4697 6.21967L3.46967 16.2197L4.53033 17.2803ZM14.5303 7.28033L17.5303 4.28033L16.4697 3.21967L13.4697 6.21967L14.5303 7.28033ZM18.5303 10.2197L14.5303 6.21967L13.4697 7.28033L17.4697 11.2803L18.5303 10.2197Z" :fill="item.is_editable ? '#2E1B75' : 'lightgray'"/>
                </svg>
            </template>
            <span>Edytuj ankietę</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <img
                    v-bind="attrs"
                    v-on="on"
                    src="@/assets/img/icon_chart-vertical.svg"
                    class="mr-2 icon-chart"
                    @click="showAnalyticsModal(item)"
                />
            </template>
            <span>Analityka</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <img
                    src="@/assets/img/icon_copy.svg"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2 icon-copy"
                    @click="duplicateItem(item)"
                />
            </template>
            <span>Duplikuj ankietę</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
               <img 
                    src="@/assets/img/icon_trash.svg"
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItem(item)"
                    class="icon-trash mr-2"
                />
            </template>
            <span>Usuń ankietę</span>
        </v-tooltip>
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <svg class="icon-share" v-bind="attrs" v-on="on" @click="shareSurvey(item)" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.25 4C18.25 5.24264 17.2426 6.25 16 6.25V7.75C18.0711 7.75 19.75 6.07107 19.75 4H18.25ZM16 6.25C14.7574 6.25 13.75 5.24264 13.75 4H12.25C12.25 6.07107 13.9289 7.75 16 7.75V6.25ZM13.75 4C13.75 2.75736 14.7574 1.75 16 1.75V0.25C13.9289 0.25 12.25 1.92893 12.25 4H13.75ZM16 1.75C17.2426 1.75 18.25 2.75736 18.25 4H19.75C19.75 1.92893 18.0711 0.25 16 0.25V1.75ZM7.0198 9.3286L13.6479 6.01457L12.977 4.67293L6.34898 7.98696L7.0198 9.3286ZM6.25 10C6.25 11.2426 5.24264 12.25 4 12.25V13.75C6.07107 13.75 7.75 12.0711 7.75 10H6.25ZM4 12.25C2.75736 12.25 1.75 11.2426 1.75 10H0.25C0.25 12.0711 1.92893 13.75 4 13.75V12.25ZM1.75 10C1.75 8.75736 2.75736 7.75 4 7.75V6.25C1.92893 6.25 0.25 7.92893 0.25 10H1.75ZM4 7.75C5.24264 7.75 6.25 8.75736 6.25 10H7.75C7.75 7.92893 6.07107 6.25 4 6.25V7.75ZM13.651 13.987L7.02921 10.6761L6.35839 12.0177L12.9802 15.3287L13.651 13.987ZM18.25 16C18.25 17.2426 17.2426 18.25 16 18.25V19.75C18.0711 19.75 19.75 18.0711 19.75 16H18.25ZM16 18.25C14.7574 18.25 13.75 17.2426 13.75 16H12.25C12.25 18.0711 13.9289 19.75 16 19.75V18.25ZM13.75 16C13.75 14.7574 14.7574 13.75 16 13.75V12.25C13.9289 12.25 12.25 13.9289 12.25 16H13.75ZM16 13.75C17.2426 13.75 18.25 14.7574 18.25 16H19.75C19.75 13.9289 18.0711 12.25 16 12.25V13.75Z" :fill="item.is_active ? '#2E1B75' : 'lightgray'"/>
                    </svg>
                </template>
            <span>Udostępnij ankietę</span>
        </v-tooltip>
    </template>
  </v-data-table>
</template>

<script>
import { http } from "@/plugins/http";
import AddSurveyModal from "@/components/surveys/AddSurveyModal"
import EditSurveyModal from "@/components/surveys/EditSurveyModal"
import DuplicateSurveyModal from "@/components/surveys/DuplicateSurveyModal"
import Analytics from '@/components/surveys/analytics/Analytics.vue';
import SurveyConfigModal from "@/components/surveys/SurveyConfigModal"
import SurveyLinkModal from "@/components/surveys/SurveyLinkModal"

export default {
    data: () => ({
        activateSurveyBtnClicked: false,
        linkToSurvey: null,
        showSurveyLinkModal: false,
        showAnalytics: false,
        newSurveyRun: false,
        surveyConfigModal: false,
        editedItemId: null,
        editSurveyModal: false,
        addSurveyModal: false,
        duplicateSurveyModal: false,
        selected: [],
        dialogDelete: false,
        headers: [
            {
                text: 'Nazwa ankiety',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: 'Data utworzenia', value: 'insert_ts' },
            { text: 'Cel ankiety', value: 'purpose' },
            { text: 'Aktywacja', value: 'activation' },
            { text: 'Akcje', value: 'actions' },
        ], 
        surveys: [],
        editedIndex: -1,   
        editedItem: { 
            name: "",
            creationDate: "",
            domain: "",
            purpose: "", 
            activation: 0,
            status: false,
            description: "",
            questions: [
                {question:"", type: "sentyment 3 gwiazdki", possibleAnswers: [1,2,3]},
            ]
        },
        defaultItem: {
            name: "",
            creationDate: "",
            domain: "",
            purpose: "",
            activation: 0,
            status: false,
            description: "",
            questions: [
                {question:"", type: "sentyment 3 gwiazdki", possibleAnswers: [1,2,3]},
            ]
        },
    }),
    components: { AddSurveyModal, EditSurveyModal, DuplicateSurveyModal, Analytics, SurveyConfigModal, SurveyLinkModal },

    watch: {
        addSurveyModal (val) {
            val || this.close()
        },
        dialogDelete (val) {
            val || this.closeDelete()
        },
        showAnalytics(val) {
            if(!val) {
                this.getSurveys();
                this.activateSurveyBtnClicked = false;
            }
        },
    },

    created () {
      this.getSurveys();
    },
    methods: {
        async activateSurvey(survey)  {
            this.newSurveyRun = true;
            this.showAnalyticsModal(survey);
            this.activateSurveyBtnClicked = true;
        },
        async isSurveyActive(survey) {
            const surveyRuns = await this.getSurveyRuns(survey)
            let result = false;
            surveyRuns.forEach(element => {
                if(this.isEmissionRunningNow(element) === true) {
                    result = true;
                }
            });
            return result;
        },
        async getSurveyRuns(survey) {
            const response = await http.get(`/survey_runs_binded_survey/${survey.id}`);
            return response.data.data;
        },
        isEmissionRunningNow(surveyRun) {
            const today = new Date();
            const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
            const emissionStartDate = new Date(surveyRun.date_start);
            const emissionEndDate = new Date(surveyRun.date_end);
            let result = false;
            if((todayWithoutTime.getTime() <= emissionEndDate.getTime() && todayWithoutTime.getTime() >= emissionStartDate.getTime()) && surveyRun.is_active) {
                result = true;
            }
            return result;
        },
        async shareSurvey(survey) {
            if(await this.isSurveyActive(survey)) {
                const containerId = prompt("Podaj identyfikator kontenera GTM (w formacie 'GTM-XXXXXXX'), do którego wysyłane mają być eventy z tej ankiety");
                if(containerId) {
                    this.linkToSurvey = `${process.env.VUE_APP_API_URL}/standalone_survey/${survey.subscription_id}?gtm_container_id=${containerId}&survey_id=${await this.activeEmissionId(survey)}`
                    this.showSurveyLinkModal = true;
                }
            }
        },
        async activeEmissionId(survey) {
            const surveyRuns = await this.getSurveyRuns(survey)
            let result = null;
            surveyRuns.forEach(element => {
                if(this.isEmissionRunningNow(element)) {
                    result = element.id;
                }
            });
            return result;
        },
        closeAddSurveyModal() {
            this.addSurveyModal = false;
            this.getSurveys();
        },
        closeEditSurveyModal() {
            this.editSurveyModal = false;
            this.getSurveys();
        },
        closeDuplicateSurveyModal() {
            this.duplicateSurveyModal = false;
            this.getSurveys();
        },
        async getSurveys() {
            const response = await http.get('/surveys');
            this.surveys = response.data.data
            this.surveys.map(async (element) => {
                element.is_active = await this.isSurveyActive(element);
            });
        },
        formatDate(date) {
            const formattedDate = new Date(date).toLocaleDateString()
            return formattedDate;
        },
        editItem (item) {
            if(item.is_editable) {
                this.editedIndex = this.surveys.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.editSurveyModal = true;
            }  
        },
        duplicateItem(item) {
            this.editedIndex = this.surveys.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.duplicateSurveyModal = true;
        },
        showAnalyticsModal(item) {
            this.editedIndex = this.surveys.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.showAnalytics = true;
        },

        deleteItem (item) {
            this.editedItemId = item.id
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        async deleteItemConfirm () {
            await http.delete(`/survey/${this.editedItemId}`)
            this.getSurveys();
            this.closeDelete()
        },

        close () {
            this.addSurveyModal = false
            this.editSurveyModal = false
            this.duplicateSurveyModal = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete () {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        removeEmptyQuestions() {
            const result = this.editedItem.questions.filter((question) => {
                return question.question
            })
            this.editedItem.questions = [...result]
        },

        async save () {
            const newSurveyQuestions = [...this.editedItem.questions]
            for(let i = 0; i < this.editedItem.questions.length; i++) {
                
                if(i===0) {
                    newSurveyQuestions[i] = {
                        question1: this.editedItem.questions[i].question,
                        question1_type: this.editedItem.questions[i].type,
                        question1_answer: this.editedItem.questions[i].possibleAnswers,
                    }
                }
                if(i===1) {
                    newSurveyQuestions[i] = {
                        question2: this.editedItem.questions[i].question,
                        question2_type: this.editedItem.questions[i].type,
                        question2_answer: this.editedItem.questions[i].possibleAnswers,
                    }
                }
                if(i===2) {
                    newSurveyQuestions[i] = {
                        question3: this.editedItem.questions[i].question,
                        question3_type: this.editedItem.questions[i].type,
                        question3_answer: this.editedItem.questions[i].possibleAnswers,
                    } 
                }
            }
            //let questionsObj = {}
            // questionsObj = newSurveyQuestions.map((question) => {
            //     return {...question}
            // })
            //await http.post('/surveys',{...questionsObj})
            await http.post('/surveys',{
                question1: "string",
                question1_type: "string",
                question1_answer: ["string"],
            })
            this.removeEmptyQuestions();
            if (this.editedIndex > -1) {
                Object.assign(this.surveys[this.editedIndex], this.editedItem)
            } else {
                this.surveys.push(this.editedItem)
            }
            this.close()
        },
    },
  }
</script>