<template>
  <v-card class="mx-auto customer-journey-card">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-img height="112" :src="defaultImage" class="card-image"></v-img>
    <div class="card-id my-1">Projekt #{{ data.id }}</div>
    <v-card-title class="card-title pt-0 mb-1 px-0">
      <span class="card-title-text">{{ data.name }}</span>
      <div class="card-actions">
        <template v-if="!isArchive">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                class="icon-archive"
                v-bind="attrs"
                v-on="on"
                @click="$emit('archive', data.id, true)"
              >
                <img src="@/assets/img/icon_archive.svg" />
              </span>
            </template>
            <span>Archiwizuj</span>
          </v-tooltip>
        </template>
        <template v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                class="icon-archive mr-2"
                v-bind="attrs"
                v-on="on"
                @click="$emit('archive', data.id, false)"
              >
                <img src="@/assets/img/icon_restore.svg" />
              </span>
            </template>
            <span>Przywróć</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                class="icon-trash"
                v-bind="attrs"
                v-on="on"
                @click="showRemoveCardModal = true"
              >
                <img src="@/assets/img/icon_trash.svg" />
              </span>
            </template>
            <span>Usuń</span>
          </v-tooltip>
        </template>
      </div>
    </v-card-title>
    <v-card-text class="px-0">
      <div class="card-description mb-1">{{ data.description }}</div>
      <div class="card-date">
        Ostatnio otworzony: {{ formatDate(data.insert_ts) }}
      </div>
    </v-card-text>
    <template v-if="!isArchive">
      <v-card-actions>
        <router-link
          class="card-button-link"
          :to="`/customer_journey/${data.id}`"
        >
          <v-btn class="card-button" text> OTWÓRZ PROJEKT </v-btn>
        </router-link>
      </v-card-actions>
    </template>
    <RemoveCardModal
      :dialog="showRemoveCardModal"
      @close="showRemoveCardModal = false"
      @deleteCustomerJourney="deleteCustomerJourney"
      :data="data"
    />
  </v-card>
</template>

<script>
import RemoveCardModal from "@/components/dashboard/RemoveCardModal";
import store from "@/store/index";

export default {
  data() {
    return {
      showRemoveCardModal: false,
      defaultImage: "https://cdn.vuetifyjs.com/images/cards/cooking.png",
      user: store.getters.getUser,
    };
  },
  components: { RemoveCardModal },
  props: {
    data: {},
    isArchive: Boolean,
  },
  mounted() {
    let userJourneys = JSON.parse(localStorage.getItem("selected_templates"));
    let templateAvatars = JSON.parse(
      localStorage.getItem("journey_template_avatars")
    );
    if (templateAvatars == null)
      templateAvatars = []
    let isAdminTemplate =
      templateAvatars &&
      templateAvatars.findIndex((item) => {
        return item.templateId == this.$props.data.id;
      }) !== -1;
    let isUserJourney =
      userJourneys &&
      userJourneys.findIndex((item) => {
        return item.customerJourneyId == this.$props.data.id;
      }) !== -1;

    if (this.user.super_admin && isAdminTemplate) {
      let template = templateAvatars.filter(
        (item) => item.templateId == this.$props.data.id
      )[0];
      this.defaultImage = require(`@/assets/img/customer-journey/avatars/avatar_${template.avatar}.jpg`);
    }
    if (isUserJourney) {
      let template = userJourneys.filter(
        (item) => item.customerJourneyId == this.$props.data.id
      )[0];
      let teplateAvatar = templateAvatars.filter(
        (item) => item.templateId == template.selectedTemplate
      )[0];
      this.defaultImage = require(`@/assets/img/customer-journey/avatars/avatar_${teplateAvatar.avatar}.jpg`);
    }
  },
  methods: {
    deleteCustomerJourney() {
      this.showRemoveCardModal = false;
      this.$emit("deleteCustomerJourney", this.data.id);
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    formatDate(data) {
      const date = new Date(data);
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join(".");
    },
  },
};
</script>
<style lang="sass" scoped>
.card-actions,
.card-actions span
	display: flex
	align-items: center
.icon-archive img
	height: 19px
</style>
