 <template>
    <v-dialog
        v-model="dialog"
        max-width="421px"
    >
        <v-card id="edit-user-modal" class="mx-auto" v-if="userData">
            <div class="close-btn" >
                <img class="cursor-pointer" @click="dialog = false" src="@/assets/img/cross-circle.svg" />
            </div>
            <div id="edit-user-content">
                <div class="edit-user-title">Tworzenie użytkownika </div>
                <div class="d-flex justify-center mb-11">
                    <img src="@/assets/img/avatar.png" id="edit-user-image" />
                </div>
    
                <div class="position-relative">
                    <label>Email</label>
                    <v-text-field
                        v-model="userData.email"
                        hide-details
                        outlined
                        color="rgba(0,0,0,.6)"
                        placeholder="mail@mail.com"
                        class="edit-user-input"
                        :class="emailMessage ? 'invalid' : ''"
                    >
                    </v-text-field>
                    <div class="error-message">{{emailMessage}}</div>
                </div>

                <label>Funkcja</label>
                <EditUserDropdown 
                    v-if="userData"
                    :currentPermission="userRole"
                    @roleChanged="roleChanged"
                />
                <v-btn id="confirm-button" @click="save">
                    ZAPISZ
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import EditUserDropdown from "@/components/admin/accountManagement/subscription/EditUserDropdown";

export default {
    components: { EditUserDropdown },
    data() {
        return {
            dialog: false,
            userData: {
                email: null,
                role: "ADMIN"
            },
            emailMessage: ''
        }
    },
     computed: {
        userRole() {
            let result = "Admin"
            if(this.userData.role) {
                result = this.userData.role.charAt(0) + this.userData.role.substring(1).toLowerCase();
            }
            return result;
        }
    },
    methods: {
        isValid() {
            let result = true;
            if(!this.userData.email) {
                result = false;
                this.emailMessage = "Email is required"
            }
            return result;
        },
        setDialog(value) {
            this.dialog = value;
        },
        roleChanged(role) {
            const newRole = role.toUpperCase();
            this.userData.role = newRole;
        },
        clear() {
            this.userData.email = null;
            this.userData.role = 'ADMIN';
            this.emailMessage = '';
        },
        async save() {
            if(this.isValid()) {
                await this.$emit('saveUser', {...this.userData})
                this.dialog = false;
            }
        }
    },
    watch: {
        dialog(val) {
            if(!val){
                this.clear();
            }
        }
    }
}
</script>