<template>
  <div
    :class="[
      'column',
      col.type == 'row-title' ? 'row-title' : null,
      columnId === row.cols.length - 1 ? 'full-width' : null,
    ]"
  >
    <div
      v-if="col.type && col.type == 'row-title'"
      :key="`row_${rowId}_col_${columnId}`"
      class="actions"
    >
      <!-- Title label cell -->
      <input
        @input="(e) => labelInput(e, rowId, columnId)"
        :value="col.title"
        :placeholder="col.title"
      />
      <div class="add add-row" @click="addRow(rowId)">
        <PlusCircleFillIcon />
      </div>
    </div>
    <div
      v-if="col.type !== 'row-title' && columnId === row.cols.length - 1"
      :key="`row_${rowId}_col_${columnId}`"
    >
      <template v-if="chartData.datasets">
        <LineChart :key="chartKey" :chartData="chartData" />
      </template>
    </div>
  </div>
</template>

<script>
import PlusCircleFillIcon from "@/components/icons/PlusCircleFillIcon.vue";
import LineChart from "@/components/customerJourney/LineChart.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    PlusCircleFillIcon,
    LineChart,
  },
  props: {
    col: Object,
    row: Object,
    columnId: Number,
    rowId: Number,
  },
  computed: {
    ...mapState("cjstore", [
      "rows",
      "chartData",
      "chartKey",
      "selectedPersonasObjs",
    ]),
  },
  watch: {
    selectedPersonasObjs(newVal) {
      this.parseChartValues(newVal);
    },
    rows() {
      this.parseChartValues(this.selectedPersonasObjs);
    },
  },
  mounted() {},
  methods: {
    ...mapActions("cjstore", [
      "setChartData",
      "setChartKey",
      "setChartColumnValues",
      "parseChartValues",
      // "renderChart",
    ]),
  },
};
</script>

<style lang="sass" scoped>
.full-width,
.row-title
  border-right: 2px dotted rgba(131, 146, 171, .5)
.row-title
  border-right: 2px dotted rgba(131, 146, 171, .5)
  max-width: 240px
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  padding: 20px
  background: #fff
  position: sticky
  left: 0
  z-index: 99
  input
    font-family: 'Roboto'
    font-style: normal
    font-weight: 600
    font-size: 16px
    color: $primary-color
    outline: 0
.full-width
  width: calc(100% - 2px)
.add-row
  position: absolute
  width: 20px
  height: 20px
  z-index: 999
  cursor: pointer
  opacity: 0
  transition: opacity .2s ease-out
.add-row
  bottom: -10px
  right: 30px
.column:hover .actions .add-row
  opacity: 1
</style>
