<template>
  <v-card id="analytics" class="px-6 py-5">
    <v-row>
      <v-col cols="6">
        <v-row>
          <v-col cols="12">
            <label>GCP Service Account Credentials</label>
            <div class="d-flex">
              <v-file-input
                class="ga-json-file"
                v-model="file"
                outlined
                prepend-icon=""
                hide-details
                accept="application/JSON"
                @change="saveCredentials"
                :placeholder="filePlaceholder"
              >
              </v-file-input>
              <button @click="saveCredentials" class="ml-3">
                <img src="@/assets/img/icon_refresh.svg" />
              </button>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="pairs.length > 0">
          <v-col cols="12">
            <label>Properties w GA i ich odpowiedniki w Big Query</label>
            <div class="d-flex">
              <v-select
                v-model="pair"
                :items="pairs"
                label="Wybierz..."
                dense
                class="ga-json-file"
                outlined
              >
                <template v-slot:item="slotProps">
                  {{ slotProps.item.text }}
                </template>
              </v-select>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="ga4 && bq">
          <v-col cols="12">
            <v-row>
              <v-col cols="12">
                <v-btn @click="showPanel = !showPanel" class="save-btn">
                  Nie ma tego, czego szukasz?
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="showPanel">
              <v-col cols="6">
                <label>GA Properties:</label>
                <v-row v-for="(item, index) in ga4" :key="`ga4-item-${index}`">
                  <v-col cols="12">
                    GA: {{ item.account_name }}, {{ item.property_name }}
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                <label>BQ Properties:</label>
                <v-row v-for="(item, index) in bq" :key="`bq-item-${index}`">
                  <v-col cols="12">
                    BQ: {{ item.project_id }}, {{ item.dataset_id }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6"> </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn
          :disabled="!isDataChanged"
          @click="uploadCredentials"
          class="save-btn"
        >
          Zapisz
        </v-btn>
      </v-col>
    </v-row>
    <SaveChangesModal
      ref="saveChangesModal"
      @saveChanges="saveCredentials"
      @clearChanges="clearChanges"
      @closedWithoutAction="closedWithoutAction"
    />
  </v-card>
</template>

<script>
import SaveChangesModal from "@/components/admin/accountManagement/profile/SaveChangesModal";
import { http } from "@/plugins/http";

export default {
  components: {
    SaveChangesModal,
  },
  data() {
    return {
      initGaId: null,
      initFilePlaceholder: "Załaduj plik",
      closeAccountManagement: null,
      showPanel: false,
      gaId: null,
      file: null,
      nextTab: null,
      pair: null,
      initPair: null,
      filePlaceholder: "Załaduj plik",
      pairs: [],
      ga4: null,
      bq: null,
    };
  },
  created() {
    this.getCredentials();
  },
  computed: {
    isDataChanged() {
      let gaId = this.gaId;
      if (this.gaId == "") {
        gaId = null;
      }
      return (
        this.initGaId !== gaId ||
        (this.initFilePlaceholder !== this.filePlaceholder &&
          this.initPair !== this.pair)
      );
    },
  },
  watch: {
    file(newVal) {
      if (newVal) {
        this.filePlaceholder = newVal.name;
      }
    },
  },
  methods: {
    async saveCredentials() {
      let formData = new FormData();
      formData.append("gcp_credentials", this.file);
      const response = await http.post("/gcp_config", formData);
      if (response.data) {
        this.pairs = response.data.pairs.map((item) => {
          return {
            text: `GA: ${item.ga4.account_name}, ${item.ga4.property_name} \r\n BQ: ${item.bq.project_id}, ${item.bq.dataset_id}`,
            value: item,
          };
        });
        this.ga4 = response.data.ga4;
        this.bq = response.data.bq;
      }
    },

    async getCredentials() {
      const response = await http.get(`/gcp_config`);
      if (response.data) {
        console.log(response.data);
      }
    },

    async uploadCredentials() {
      let formData = new FormData();
      formData.append("gcp_credentials", this.file);
      formData.append("ga4", JSON.stringify(this.pair.ga4));
      formData.append("bq", JSON.stringify(this.pair.bq));
      const response = await http.post("/gcp_credentials_upload", formData);
      if (response.data) {
        console.log(response.data);
      }
    },
    closedWithoutAction() {
      this.$emit("setDialog", true);
      this.$emit("setTab", "tab-5");
    },
    clearChanges() {
      this.gaId = this.initGaId;
      this.filePlaceholder = this.initFilePlaceholder;
    },
    checkChanges(nextTab, closeAccountManagement = false) {
      if (this.isDataChanged) {
        this.closeAccountManagement = closeAccountManagement;
        this.nextTab = nextTab;
        this.$refs.saveChangesModal.setDialog(true);
      }
    },
  },
};
</script>

<style></style>
