<template>
  <svg
    width="26.129879"
    height="34"
    viewBox="0 0 26.129879 34"
    class="arrow"
    version="1.1"
    id="svg4"
    preserveAspectRatio="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
    <defs
      id="defs8" />
    <path
      d="m 1.6e-4,0 h 11.620848 c 2.7402,0 4.491756,2.0887769 6.1151,4.29637 l 7.72115,10.50003 c 0.896827,1.219599 0.896828,3.187603 0,4.4072 l -7.72115,10.5 C 16.112772,31.911176 14.361058,34 11.620858,34 H 0 Z"
      stroke="#8392ab"
      stroke-width="3"
      id="path2"
      :style="{fill: fill}"
      style="fill-opacity:1;stroke:none;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none" />
  </svg>
</template>
<script>
  export default {
    props: {
      fill: {
        type: String,
        default: '#fff'
      }
    }
  }
</script>