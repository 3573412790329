<template>
    <v-dialog
        v-model="dialog"
        max-width="448px"
    >
        <v-card id="delete-account-modal" class="mx-auto">
            <div class="close-btn" >
                <img @click="dialog = false" src="@/assets/img/cross-circle.svg" />
            </div>
            <div id="delete-account-modal-content">
                <v-card-title class="delete-account-modal-title px-0 py-0">
                    Czy na pewno chcesz usunąć?
                </v-card-title>
                <v-card-text class="delete-account-modal-content mx-auto">Usunięcie konta spowoduje trwały brak dostępu do Canvax.</v-card-text>
                <v-card-actions class="buttons">
                    <v-btn
                        text
                        @click="dialog = false"
                        class="cancel-button"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        text
                        @click="deleteAccount"
                        class="confirm-button"
                    >
                        Usuń
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        deleteAccount() {
            this.$emit("deleteAccount");
        },
        setDialog(value) {
            this.dialog = value;
        },
    },
}
</script>