<template>
    <v-card class="ab-tests-tab">
        <div class="survey-analytics-content">
            <v-row>
                <v-col :cols="firstColumnCols" class="survey-analytics-column">
                    <slot></slot>
                </v-col>
                <v-col :cols="areBothDatesChoosen ? '4' : '6'" class="choose-dates-column survey-analytics-column" v-if="finishedEmissions.length >= 2">
                    <label>Zakres czasu:</label>
                    <div class="position-relative">
                        <div class="choose-dates-input">
                            <Dropdown 
                                :data="dropdownData"
                                @itemSelected="dateA = $event"
                                :initialValue="`Wybierz emisję`"
                                ref="dropdown"
                            />
                        </div>
                        <img class="choose-dates-image" src="@/assets/img/A.png" />
                    </div>
                    <label>Zakres czasu:</label>
                    <div class="position-relative">
                        <div class="choose-dates-input">
                            <Dropdown 
                                :data="dropdownData"
                                @itemSelected="dateB = $event"
                                :initialValue="`Wybierz emisję`"
                                ref="dropdown"
                            />
                        </div>
                        <img class="choose-dates-image" src="@/assets/img/B.png" />
                    </div>
                </v-col>
                <v-col cols="4" v-if="areBothDatesChoosen" class="survey-analytics-column">
                    <InfoContainer
                        v-if="areEmissionAResultsEmpty || areEmissionBResultsEmpty"
                        :message="infoContainerMessage"
                    />
                    <div v-for="(question, index) in surveyData.questions" :key="index" class="chart-card">
                        <p class="text-color-primary font-weight-bold mb-0">Pytanie {{index+1}}:</p>
                        <p class="text-color-primary font-size-1">{{question.question}}</p>
                        <Chart
                            :chartData="{
                                labels: question.answers,
                                datasets: [
                                    { 
                                        data: chartDataA[index],
                                        backgroundColor: '#2E1B75',
                                        borderRadius: 6,
                                        barPercentage: 1,
                                        categoryPercentage: 0.5
                                    }, 
                                    { 
                                        data: chartDataB[index],
                                        backgroundColor: '#FFB145',
                                        borderRadius: 6,
                                        barPercentage: 1,
                                        categoryPercentage: 0.5
                                    } 
                                ]
                            }"
                        />
                    </div>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
import Dropdown from "@/components/surveys/Dropdown.vue"
import Chart from "@/components/surveys/analytics/Chart.vue"
import { http } from "@/plugins/http";
import InfoContainer from "@/components/surveys/analytics/InfoContainer.vue"

export default {
    components: { Dropdown, Chart, InfoContainer },
    data() {
        return {
            dateA: null,
            dateB: null,
            chartDataA: null,
            chartDataB: null,
        }
    },
    props: {
        surveyData: {},
        isOpened: {},
        surveyRunData: {},
        subpages: {}
    },
    computed: {
        infoContainerMessage() {
            let result = ""
            if(this.areEmissionAResultsEmpty) {
                result = "Brak wyników dla emisji A."
            }
            if(this.areEmissionBResultsEmpty) {
                result = "Brak wyników dla emisji B."
            }
            if(this.areEmissionAResultsEmpty && this.areEmissionBResultsEmpty) {
                result = ""
            }
            result += " Zbieranie danych może trwać do dwóch dni po zakoczeniu emisji ankiety."
            return result;
        },
        areEmissionAResultsEmpty() {
            let result = true;
            if(this.chartDataA) {
                this.chartDataA.forEach((element) => {
                    element.forEach((item) => {
                        if(item !== 0) {
                            result = false;
                        }
                    })
                })
            }
            return result;
        },
        areEmissionBResultsEmpty() {
            let result = true;
            if(this.chartDataB) {
                this.chartDataB.forEach((element) => {
                    element.forEach((item) => {
                        if(item !== 0) {
                            result = false;
                        }
                    })
                })
            }
            return result;
        },
        firstColumnCols() {
            let result = 12
            if(this.finishedEmissions.length >= 2) {
                result = 6;
            }
            if(this.areBothDatesChoosen) {
                result = 4;
            }
            return result;
        },
        areBothDatesChoosen() {
            return this.dateA && this.dateB;
        },
        finishedEmissions() {
            const result = [];
            if(this.surveyRunData) {
                const today = new Date();
                const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
                this.surveyRunData.forEach(emission => {
                    const lastDay = new Date(emission.date_end);
                    if(todayWithoutTime.getTime() > lastDay.getTime()) {
                        result.push(emission)
                    }
                }); 
            }
            return result
        },
        dropdownData() {
            let result = []
            this.finishedEmissions.forEach(emission => {
                result.push(`${this.formatDate(emission.date_start) } - ${this.formatDate(emission.date_end)}`)
            });
            if(this.dateA) {
                result.splice(result.indexOf(this.dateA),1)
            }
            if(this.dateB) {
                result.splice(result.indexOf(this.dateB),1)
            }
            return result;
        },
    },
    methods: {
        formatDate(data) {
            const date = new Date(data);
            return [
                this.padTo2Digits(date.getDate()),
                this.padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('.');
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        clear() {
            this.dateA = null;
            this.dateB = null;
        },
        async getChartData(val) {
            const dateStart = val.split("-")[0].replaceAll(".", "-").trim().split("-").reverse().join("-");
            const dateEnd = val.split("-")[1].replaceAll(".", "-").trim().split("-").reverse().join("-");
            const response = await http.post(`/survey_results/${this.surveyData.id}`, {
                date_start: dateStart,
                date_end: dateEnd
            });
            const result = [];
            response.data.data.forEach((element) => {
                result.push(element.answer_count)
            });
            return result;
        }
    },
    watch: {
        async dateA(val) {
            if(val) {
                this.chartDataA = await this.getChartData(val);
            }
        },
        async dateB(val) {
            if(val) {
                this.chartDataB = await this.getChartData(val);
            }
        }
    }
}
</script>