<template>
  <v-card class="emissions-tab">
    <v-row>
      <v-col class="survey-analytics-column survey-data-column mr-3">
        <slot></slot>
      </v-col>
      <v-col
        class="choose-dates-column survey-analytics-column all-emissions-wrapper pl-0 pr-0 mr-3"
      >
        <h5 class="all-emissions-title">Wszystkie emisje ankiety</h5>
        <SurveyEmissionsTable
          :allSurveyRuns="surveyRunData"
          @emissionSelected="emissionSelected"
          @getSurveyRunData="$emit('getSurveyRunData')"
        />
      </v-col>
      <v-col v-if="chartData" class="survey-analytics-column charts-column">
        <InfoContainer
          v-if="areEmissionResultsEmpty"
          :message="infoContainerMessage"
        />
        <div
          v-for="(question, index) in surveyData.questions"
          :key="index"
          class="chart-card"
        >
          <p class="text-color-primary font-weight-bold mb-0">
            Pytanie {{ index + 1 }}:
          </p>
          <p class="text-color-primary font-size-1">{{ question.question }}</p>
          <Chart
            :chartData="{
              labels: question.answers,
              datasets: [
                {
                  data: chartData[index],
                  backgroundColor: '#2E1B75',
                  borderRadius: 6,
                  barPercentage: 1,
                  categoryPercentage: 0.5,
                },
              ],
            }"
          />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Chart from "@/components/surveys/analytics/Chart.vue";
import SurveyEmissionsTable from "@/components/surveys/analytics/SurveyEmissionsTable.vue";
import { http } from "@/plugins/http";
import InfoContainer from "@/components/surveys/analytics/InfoContainer.vue";

export default {
  components: { Chart, SurveyEmissionsTable, InfoContainer },
  data() {
    return {
      selectedEmission: null,
      chartData: null,
    };
  },
  props: {
    surveyData: {},
    isOpened: {},
    subpages: {},
    surveyRunData: {},
  },
  computed: {
    areEmissionResultsEmpty() {
      let result = true;
      if (this.chartData) {
        this.chartData.forEach((element) => {
          element.forEach((item) => {
            if (item !== 0) {
              result = false;
            }
          });
        });
      }
      return result;
    },
    infoContainerMessage() {
      let result =
        "Wygląda na to, że ankieta nie została wypełniona ani razu w wybranej emisji.";
      const today = new Date();
      const todayWithoutTime = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      );
      const selectedEmissionEndDay = new Date(this.selectedEmission.date_end);
      selectedEmissionEndDay.setDate(selectedEmissionEndDay.getDate() + 2);
      if (selectedEmissionEndDay.getTime() === todayWithoutTime.getTime()) {
        result =
          "Zbieranie danych może trwać do dwóch dni po zakoczeniu emisji ankiety.";
      }
      return result;
    },
    finishedEmissions() {
      const result = [];
      if (this.surveyRunData) {
        const today = new Date();
        const todayWithoutTime = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          0,
          0,
          0
        );
        this.surveyRunData.forEach((emission) => {
          const lastDay = new Date(emission.date_end);
          if (todayWithoutTime.getTime() > lastDay.getTime()) {
            result.push(emission);
          }
        });
      }
      return result;
    },
  },
  watch: {
    isOpened(newVal) {
      if (!newVal) {
        this.$store.dispatch("hints/setHint", {
          visible: false,
        });
      }
    },
  },
  destroyed() {
    this.$store.dispatch("hints/setHint", {
      visible: false,
    });
  },
  methods: {
    async emissionSelected(selectedEmission) {
      this.selectedEmission = selectedEmission;
      if (selectedEmission) {
        this.chartData = await this.getChartData(
          selectedEmission.date_start,
          selectedEmission.date_end
        );
      } else {
        this.chartData = null;
      }
    },
    async getChartData(dateStart, dateEnd) {
      const dateStartWithoutTime = dateStart.split("T")[0];
      const dateEndWithoutTime = dateEnd.split("T")[0];
      const response = await http.post(
        `/survey_results/${this.surveyData.id}`,
        {
          date_start: dateStartWithoutTime,
          date_end: dateEndWithoutTime,
        }
      );
      const result = [];
      response.data.data.forEach((element) => {
        result.push(element.answer_count);
      });
      return result;
    },
    formatDate(data) {
      const date = new Date(data);
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join(".");
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    clear() {
      this.selectedEmission = null;
      this.chartData = null;
    },
  },
};
</script>
