<template>
    <div class="survey-data-container">
        <p class="text-size-3 font-weight-bold mb-5">{{surveyData.name}}</p>
        <p class="survey-data-details mb-5">Szczegóły ankiety:</p>
        <p>Cel: {{surveyData.purpose}}</p>
        <p>Podstrona: </p>
        <div v-for="(question, index) in surveyData.questions" :key="index">
            <p>Pytanie {{index+1}}: {{question.question}}</p>
            <p>Forma odpowiedzi {{index+1}}: {{questionTypeForDisplay(question.type)}}</p>
            <div v-if="question.type === 'custom'">
                <p v-for="(answer, index) in question.answers" :key="index">
                    Odpowiedź {{index+1}}: {{answer}}
                </p>
            </div>
        </div>
        <p>Status:
            <span v-if="isActive"><span class="survey-active">aktywna</span>, koniec emisji {{endOfCurrentEmission}}</span>
            <span v-else class="survey-inactive">nieaktywna</span>
        </p>
        <p class="mt-11">Kolejne emisje: </p>
        <div class="d-flex justify-space-between" v-for="emission in emissionsToBeDisplayed" :key="emission.id">
            <p>{{ formatDate(emission.date_start) }} - {{formatDate(emission.date_end)}}</p>
            <div>
                <svg @click="showEditModal(emission.id)" width="24" height="25" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 20.75H3.25C3.25 21.1642 3.58579 21.5 4 21.5V20.75ZM4 16.75L3.46967 16.2197C3.32902 16.3603 3.25 16.5511 3.25 16.75H4ZM17 3.75L17.5303 3.21967C17.2374 2.92678 16.7626 2.92678 16.4697 3.21967L17 3.75ZM21 7.75L21.5303 8.28033C21.8232 7.98744 21.8232 7.51256 21.5303 7.21967L21 7.75ZM8 20.75V21.5C8.19891 21.5 8.38968 21.421 8.53033 21.2803L8 20.75ZM4.75 20.75V16.75H3.25V20.75H4.75ZM16.4697 4.28033L20.4697 8.28033L21.5303 7.21967L17.5303 3.21967L16.4697 4.28033ZM8 20H4V21.5H8V20ZM20.4697 7.21967L17.4697 10.2197L18.5303 11.2803L21.5303 8.28033L20.4697 7.21967ZM17.4697 10.2197L7.46967 20.2197L8.53033 21.2803L18.5303 11.2803L17.4697 10.2197ZM4.53033 17.2803L14.5303 7.28033L13.4697 6.21967L3.46967 16.2197L4.53033 17.2803ZM14.5303 7.28033L17.5303 4.28033L16.4697 3.21967L13.4697 6.21967L14.5303 7.28033ZM18.5303 10.2197L14.5303 6.21967L13.4697 7.28033L17.4697 11.2803L18.5303 10.2197Z" fill="#FFFFFF"/>
                </svg>
                <svg @click="showDeleteModal(emission.id)" class="ml-3" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.75 8C7.75 7.58579 7.41421 7.25 7 7.25C6.58579 7.25 6.25 7.58579 6.25 8H7.75ZM6.25 14C6.25 14.4142 6.58579 14.75 7 14.75C7.41421 14.75 7.75 14.4142 7.75 14H6.25ZM11.75 8C11.75 7.58579 11.4142 7.25 11 7.25C10.5858 7.25 10.25 7.58579 10.25 8H11.75ZM10.25 14C10.25 14.4142 10.5858 14.75 11 14.75C11.4142 14.75 11.75 14.4142 11.75 14H10.25ZM15.75 4C15.75 3.58579 15.4142 3.25 15 3.25C14.5858 3.25 14.25 3.58579 14.25 4H15.75ZM3.75 4C3.75 3.58579 3.41421 3.25 3 3.25C2.58579 3.25 2.25 3.58579 2.25 4H3.75ZM1 3.25C0.585786 3.25 0.25 3.58579 0.25 4C0.25 4.41421 0.585786 4.75 1 4.75V3.25ZM17 4.75C17.4142 4.75 17.75 4.41421 17.75 4C17.75 3.58579 17.4142 3.25 17 3.25V4.75ZM11.25 4C11.25 4.41421 11.5858 4.75 12 4.75C12.4142 4.75 12.75 4.41421 12.75 4H11.25ZM5.25 4C5.25 4.41421 5.58579 4.75 6 4.75C6.41421 4.75 6.75 4.41421 6.75 4H5.25ZM6.25 8V14H7.75V8H6.25ZM10.25 8V14H11.75V8H10.25ZM14.25 4V16H15.75V4H14.25ZM13 17.25H5V18.75H13V17.25ZM3.75 16V4H2.25V16H3.75ZM5 17.25C4.30964 17.25 3.75 16.6904 3.75 16H2.25C2.25 17.5188 3.48122 18.75 5 18.75V17.25ZM14.25 16C14.25 16.6904 13.6904 17.25 13 17.25V18.75C14.5188 18.75 15.75 17.5188 15.75 16H14.25ZM1 4.75H17V3.25H1V4.75ZM12.75 4V3H11.25V4H12.75ZM10 0.25H8V1.75H10V0.25ZM5.25 3V4H6.75V3H5.25ZM8 0.25C6.48122 0.25 5.25 1.48122 5.25 3H6.75C6.75 2.30964 7.30964 1.75 8 1.75V0.25ZM12.75 3C12.75 1.48122 11.5188 0.25 10 0.25V1.75C10.6904 1.75 11.25 2.30964 11.25 3H12.75Z" fill="#FFFFFF"/>
                </svg>
            </div>
        </div>
        <v-btn @click="showPlanEmissionModal = true" class="plan-emission-button">Zaplanuj emisję</v-btn>
        <v-dialog
            v-model="showPlanEmissionModal"
            class="pr-0"
            max-width="421px"
        >
            <PlanEmissionModal
                :isOpened="showPlanEmissionModal"
                :surveyId="surveyData.id"
                :subpages="subpages"
                @close="closePlanEmissionModal"
                :futureEmissions="emissionsToBeDisplayed"
            />
        </v-dialog>
        <v-dialog
            v-model="deleteEmissionModal"
            max-width="448px"
        >
            <DeleteEmissionModal
                @close="closeDeleteEmissionModal"
                :emissionId="deletedEmissionId"
                @emissionDeleted="$emit(`getSurveyRunData`)"
            />
        </v-dialog>
        <v-dialog
            v-model="showEditEmissionModal"
            class="pr-0"
            max-width="421px"
        >
            <EditEmissionModal 
                @close="closeEditEmissionModal"
                :subpages="subpages"
                :editedEmission="editedEmission"
                :isOpened="showEditEmissionModal" 
                :futureEmissions="emissionsToBeDisplayed"
            />
        </v-dialog>
    </div>
</template>

<script>
import PlanEmissionModal from "@/components/surveys/analytics/PlanEmissionModal.vue"
import DeleteEmissionModal from "@/components/surveys/analytics/DeleteEmissionModal.vue"
import EditEmissionModal from "@/components/surveys/analytics/EditEmissionModal.vue"

export default {
    components: { PlanEmissionModal, DeleteEmissionModal, EditEmissionModal },
    props: {
        surveyData: {},
        surveyRunData: {},
        subpages: {},
        isOpened: {},
        activateSurveyBtnClicked: {}
    },
    data() {
        return {
            showPlanEmissionModal: false,
            activeEmissionId: null,
            deleteEmissionModal: false,
            deletedEmissionId: null,
            showEditEmissionModal: false,
            editedEmission: null,
            showDatePicker: false,
            dropdownData: ["Wybierz emisję"],
        }
    },
    mounted() {
        if(this.activateSurveyBtnClicked === true) {
            this.showPlanEmissionModal = true;
        }
    },
    computed: {
        isActive() {
            let result = false;
            if(this.surveyRunData) {
                const today = new Date();
                const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
                this.surveyRunData.forEach((element)=> {
                    const firstDay = new Date(element.date_start);
                    const lastDay = new Date(element.date_end);
                    if((todayWithoutTime.getTime() >= firstDay.getTime() && todayWithoutTime.getTime() <= lastDay.getTime()) && element.is_active) {
                        result = true;
                        this.activeEmissionId = element.id;
                    }
                });
            }
            return result;
        },
        endOfCurrentEmission() {
            let result = ""
            if(this.activeEmissionId && this.surveyRunData) {
                const activeEmission = this.surveyRunData.find(element => element.id === this.activeEmissionId)
                result = this.formatDate(activeEmission.date_end);
            }
            return result
        },
        emissionsToBeDisplayed() {
            let result = [];
            if(this.surveyRunData) {
                result = JSON.parse(JSON.stringify(this.surveyRunData));
                const today = new Date();
                const idsOfPastEmissions = []

                if(this.activeEmissionId) {
                    idsOfPastEmissions.push(this.activeEmissionId);
                }
                result.forEach((element) => {
                    const lastDay = new Date(element.date_end);
                    if(today.getTime() > lastDay.getTime()) {
                        idsOfPastEmissions.push(element.id);
                    }
                });
                result = result.filter((emission) => {
                    return !idsOfPastEmissions.includes(emission.id)
                })
            }
            return result
        },
    },
    methods: {
        showEditModal(id) {
            const editedEmission = this.surveyRunData.find(element => element.id === id);
            this.editedEmission = JSON.parse(JSON.stringify(editedEmission));
            this.showEditEmissionModal = true;
        },
        showDeleteModal(id) {
            this.deleteEmissionModal = true;
            this.deletedEmissionId = id;
        },
        closeDeleteEmissionModal() {
            this.deleteEmissionModal = false;
        },
        formatDate(data) {
            const date = new Date(data);
            return [
                this.padTo2Digits(date.getDate()),
                this.padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('.');
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        // isSurveyRunning() {
        //     const today = new Date();
        //     const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
        //     this.surveyRunData.forEach((element)=> {
        //         const firstDay = new Date(element.date_start);
        //         const lastDay = new Date(element.date_end);
        //         if(todayWithoutTime.getTime() >= firstDay.getTime() && todayWithoutTime.getTime() <= lastDay.getTime()) {
        //             this.isActive = true;
        //             this.activeEmissionId = element.id;
        //         }
        //     });
        // },
        questionTypeForDisplay(type) {
            let result = "";
            if(type === "custom") {
                result = "customizowane odpowiedzi";
            }
            if(type === "sentiment_3_stars") {
                result = "sentyment 3 gwiazdki";
            }
            if(type === "sentiment_5_stars") {
                result = "sentyment 5 gwiazdek";
            }
            if(type === "likert_5_stars") {
                result = "sentyment Likert";
            }
            return result;
        },
        clear() {
            this.activeEmissionId = null;
            this.deletedEmissionId = null;
            this.editedEmission = null;
            this.dropdownData = ["Wybierz emisję"];
        },
        closePlanEmissionModal() {
            this.showPlanEmissionModal=false
            this.$emit(`getSurveyRunData`)
        },
        closeEditEmissionModal() {
            this.showEditEmissionModal = false;
            this.$emit(`getSurveyRunData`)
        }
    },
    watch: {
        deleteEmissionModal(val) {
            if(!val) {
                this.deletedEmissionId = null;
            }
        },
        isOpened(val) {
            if(this.activateSurveyBtnClicked === true && val) {
                this.showPlanEmissionModal = true;
            }
        }
        // surveyRunData() {
        //     this.isSurveyRunning();
        // },
    }
}
</script>