<template>
    <div class="source-files-top-container">
        <div>
            <p class="top-container-title">Pliki źródłowe</p>
            <button class="add-source-file-btn" @click="showAddSourceFileModal = true" data-html2canvas-ignore="true">
                <img class="mr-4" src="@/assets/img/icon_document_plus.svg" />
                Dodaj plik źródłowy
            </button>
        </div>
        <hr />
        <v-dialog
            v-model="showAddSourceFileModal"
            class="pr-0"
            max-width="421px"
        >
            <AddSourceFileModal
                :isOpened="showAddSourceFileModal"
                :personaId="personaId"
                :savedFiles="savedFiles"
                @close="showAddSourceFileModal = false"
                @sourceFilesAdded="$emit('sourceFilesAdded')"
                ref="addSourceFileModal"
            />
        </v-dialog>
    </div>
</template>

<script>
import AddSourceFileModal from "@/components/personas/sourceFiles/AddSourceFileModal";

export default {
    components: { AddSourceFileModal },
    props: {
        personaId: {},
        savedFiles: {}
    },
    data() {
        return {
            showAddSourceFileModal: false
        }
    },
    watch: {
        showAddSourceFileModal(val) {
            if(!val) {
                this.$refs.addSourceFileModal.clear();
            }
        }
    }
}
</script>