<template>
  <v-card>
    <TitleInput 
      :placeholder="'Tytuł'" 
      :title="card.title"
      :id="id"
      :events="true"
      :type="type"
      :inputType="inputType"
      :removeArrayName="removeArrayName"></TitleInput>
    <div class="separator"></div>
    <v-card-text>
      <template v-for="(item, itemId) in card.items">
        <component 
          :is="type" 
          :key="`component_${type}_${itemId}`" 
          :data="item" 
          :outlined="true" 
          :color="'grey'"
          :type="type"
          :id="id"
          :value="item.value"
          :itemId="itemId"
          :append="type == 'Input'" />
      </template>
      <div class='add-item' @click="addItem(type, id)" data-html2canvas-ignore="true">
        <PlusCircleIcon />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import Slider from '@/components/personas/Slider.vue';
import Input from '@/components/personas/Input.vue';
import TitleInput from '@/components/personas/TitleInput.vue';
import PlusCircleIcon from '@/components/icons/PlusCircleIcon.vue';
import bus from '@/components/personas/eventBus.js';

export default {
  components: {
    Slider,
    Input,
    TitleInput,
    PlusCircleIcon
  },
  props: {
    id: Number,
    type: String,
    inputType: String,
    card: Object
  },
  methods: {
    addItem(type, id){  
      bus.$emit('addCardItem', {type: type, id: id})
    }
  },
  computed: {
    removeArrayName() {
      return `${this.type.toLowerCase()}s`;
    }
  }
}
</script>

<style lang="sass" scoped>
  .v-card 
    border-radius: 16px
  .v-dialog .v-card .v-card__title
    font-weight: 600
    font-size: 16px
    display: inline-block
    text-align: left
    width: 100%
    padding: 20px 10px
    color: $primary-color
  .v-card__subtitle, .v-card__title
    padding: 0 10px 24px 10px
  .v-card__text
    padding: 22px 10px
  .separator
    width: 100%
    height: 2px
    background: linear-gradient(to right, #fff, #E0E1E2, #fff)
  .add-item 
    cursor: pointer
  ::v-deep .add-item svg path
    transition: fill .2s ease-out
  ::v-deep .add-item:hover svg path
    fill: #2E1B75
</style>