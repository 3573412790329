var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"390px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('div',{staticClass:"new-item"},[_c('h3',{staticClass:"pb-3"},[_vm._v(_vm._s(_vm.pickersLabels[_vm.currentPicker]))]),(_vm.currentPicker == 'shape')?[_c('div',{staticClass:"items"},[_c('div',{staticClass:"shapes"},[_vm._l((_vm.shapes),function(title,id){return [_c('div',{key:("shape_" + id),staticClass:"shape",on:{"click":function($event){_vm.shape = id}}},[_c('h5',{staticClass:"label"},[_vm._v(_vm._s(title))]),_c('BaseIcon',{class:_vm.shape == id ? 'selected' : null,attrs:{"path":("shapes/shape-" + id + ".vue")}})],1)]})],2)])]:_vm._e(),(_vm.currentPicker == 'icon')?[_c('div',{staticClass:"items"},[_c('div',{staticClass:"icons"},[_vm._l((43),function(id){return [_c('div',{key:("icon_" + id),class:['icon-wrapper', _vm.icon == id ? 'selected' : null],on:{"click":function($event){_vm.icon = id}}},[_c('BaseIcon',{attrs:{"path":("icons/shape-" + id + ".vue"),"fill":'#2E1B75'}})],1)]})],2)])]:_vm._e(),(_vm.currentPicker == 'color')?[_c('div',{staticClass:"preview",style:({
            backgroundColor:
              _vm.color !== null ? _vm.colors[_vm.color].backgroundColor : null,
          })},[_c('div',{staticClass:"text",style:({
              color: _vm.color !== null ? _vm.colors[_vm.color].textColor : null,
            })},[_vm._v(" Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis vestibulum donec dignissim amet purus pharetra facilisi. ")]),_c('div',{staticClass:"card-icon"},[_c('HeartIcon',{attrs:{"fill":_vm.color !== null ? _vm.colors[_vm.color].textColor : '#2E1B75'}})],1)]),_c('div',{staticClass:"items"},[_c('div',{staticClass:"colors"},[_vm._l((_vm.colors),function(item,id){return [_c('div',{key:("color_" + id),class:['color', _vm.color == id ? 'selected' : null],style:({
                  backgroundColor: item.backgroundColor,
                  border:
                    item.backgroundColor == '#fff'
                      ? '2px solid  #cacaca'
                      : null,
                }),on:{"click":function($event){_vm.color = id}}})]})],2)])]:_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }