<template>
  <div>
    <router-view />
    <Loader />
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

export default {
  name: "Canvax",
  components: {
    Loader,
  },
  beforeCreate() {
    this.$store.dispatch("checkLoggedIn");
  },
};
</script>
