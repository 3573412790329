<template>
  <div class="input-wrapper">
    <v-text-field
        hide-details
        v-bind:outlined="outlined"
        :background-color="background"
        :class="[
          `text-color-${color}`, 
          prepend ? 'align-right' : '', 
          append ? 'append-item' : ''
        ]"
        v-on:keypress="inputType == 'number' ? isNumber($event): {}"
        :placeholder="placeholder"
        @input="inputHandler"
        :value="value"
    >
      <template v-if="prepend" v-slot:prepend-inner>
        <span :class="`prepend-text text-color-${color}`">{{prepend}}</span>
      </template>
      <template v-if="append" v-slot:append>
        <div class="remove" @click="removeItem" data-html2canvas-ignore="true">
          <TrashIcon />
        </div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import TrashIcon from '@/components/icons/TrashIcon.vue';
import bus from '@/components/personas/eventBus.js';

export default {
  components: {
    TrashIcon
  },
  props: {
    value: [String, Number],
    inputType: String,
    details: Boolean,
    prepend: String,
    append: Boolean,
    id: [Number,String],
    itemId: Number,
    type: String,
    outlined: {
      type: Boolean,
      default(){
        return true
      }
    },
    placeholder: String,
    color: String,
    background: {
      type: String,
      default(){
        return 'inherit'
      }
    }
  },
  methods: {
    removeItem(){
      bus.$emit('removeCardItem', {
        type: this.type,
        id: this.id,
        itemId: this.itemId
      })
    },
    isNumber(evt){
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];
      const keyPressed = evt.key;
      
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault()
      }
    },
    inputHandler(event){
      bus.$emit('inputChange', {
        type: this.type,
        id: this.id,
        itemId: this.itemId,
        event: event
      })
    }
  }
}
</script>

<style lang="sass" scoped>
  .remove 
    cursor: pointer
  .input-wrapper 
    margin-bottom: 15px 
  ::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot
    height: 40px
    min-height: 40px
  ::v-deep .v-text-field--outlined
    border-radius: 8px
  ::v-deep .v-input 
    font-size: 14px
    font-weight: bold
  ::v-deep .theme--light.v-input input::placeholder
    opacity: 1
  ::v-deep .text-color-grey.theme--light.v-input input::placeholder
    color: #8392AB
  .text-color-white,
  ::v-deep .text-color-white.theme--light.v-input input::placeholder
    color: #ffffff
    font-weight: bold
  .prepend-text
    margin-top: -3px
  ::v-deep .align-right.theme--light.v-input input
    text-align: right
  ::v-deep .append-item.v-text-field--enclosed .v-input__append-inner 
    margin-top: 11px
</style>