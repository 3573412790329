 <template>
    <v-dialog
        v-model="dialog"
        max-width="421px"
    >
        <v-card id="edit-user-modal" class="mx-auto" v-if="userData">
            <div class="close-btn" >
                <img class="cursor-pointer" @click="dialog = false" src="@/assets/img/cross-circle.svg" />
            </div>
            <div id="edit-user-content">
                <div class="edit-user-title">Edycja użytkownika </div>
                <div class="d-flex justify-center mb-11">
                    <img :src="profilePicture(userData.image)" id="edit-user-image" />
                </div>
                <label>Funkcja</label>
                <EditUserDropdown 
                    v-if="userData"
                    :currentPermission="userRole"
                    @roleChanged="roleChanged"
                />
                <v-btn id="confirm-button" @click="save">
                    ZAPISZ
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import EditUserDropdown from "@/components/admin/accountManagement/subscription/EditUserDropdown";

export default {
    components: { EditUserDropdown },
    data() {
        return {
            dialog: false,
            userData: null,
        }
    },
    computed: {
        userRole() {
            let result = ""
            if(this.userData.role) {
                result = this.userData.role.charAt(0) + this.userData.role.substring(1).toLowerCase();
            }
            return result;
        }
    },
    methods: {
        profilePicture(src) {
            let result = src;
            if(!result) {
                result = require('@/assets/img/avatar.png');
            }
            return result
        },
        setDialog(value, user) {
            this.dialog = value;
            this.userData = {...user}; 
        },
        roleChanged(role) {
            const newRole = role.toUpperCase();
            this.userData.role = newRole;
        },
        clear() {
            this.userData = null;
        },
        save() {
            this.$emit('changeUserData', this.userData)
            this.dialog = false;
        }
    },
    watch: {
        dialog(val) {
            if(!val){
                this.clear();
            }
        }
    }
}
</script>