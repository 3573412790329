<template>
    <v-card class="survey-modal">
        <div class="survey-modal-close-btn" >
            <img @click="$emit('close')" src="@/assets/img/cross-circle.svg" />
        </div>
        <div class="survey-modal-content">
            <div class="survey-modal-form">
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="modal-title">
                                Duplikacja ankiety
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <input
                                    type="text"
                                    v-model="editedItem.name"
                                    placeholder="Nazwa ankiety"
                                    class="survey-modal-input"
                                    :class="editedItem.name ? 'filled' : ''"
                                />
                                <div v-if="editedItem.name === ''" class="text-right text-color-red text-size-2">{{nameErrorMessage}}</div>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <input
                                    v-model="editedItem.purpose"
                                    placeholder="Cel ankiety"
                                    class="survey-modal-input"
                                    :class="editedItem.purpose ? 'filled' : ''"
                                />
                                <div v-if="editedItem.purpose === ''" class="text-right text-color-red text-size-2">{{purposeErrorMessage}}</div>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <input
                                    type="text"
                                    v-model="editedItem.headline"
                                    placeholder="Nagłówek ankiety"
                                    class="survey-modal-input"
                                    :class="editedItem.headline ? 'filled' : ''"
                                />
                                <div v-if="!editedItem.headline" class="text-right text-color-red text-size-2">{{headlineErrorMessage}}</div>
                            </v-col>
                            <v-col
                                cols="12"
                            >
                                <textarea
                                    v-model="editedItem.description"
                                    placeholder="Opis ankiety"
                                    class="survey-modal-input description-input"
                                    :class="editedItem.description ? 'filled' : ''"
                                >
                                </textarea>
                            </v-col> 
                            <div class="question" v-for="(question, questionIndex) in editedItem.questions" :key="questionIndex">
                                <v-col cols="12" class="text-color-primary font-weight-bolder d-flex justify-space-between">
                                    Pytanie do ankiety {{editedItem.questions.indexOf(question) + 1}}
                                    <img v-if="editedItem.questions.length > 1" @click="deleteQuestion(questionIndex)" class="cursor-pointer" src="@/assets/img/icon_trash.svg" />
                                </v-col>
                                <v-col cols="12">
                                    <input
                                        v-model="question.question"
                                        placeholder="Treść pytania"
                                        class="survey-modal-input"
                                        :class="question.question ? 'filled' : ''"
                                    />
                                    <div v-if="question.question === ''" class="text-right text-color-red text-size-2">{{questionErrorMessage}}</div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="dropdown"
                                >
                                    <QuestionTypeDropdown 
                                        @typeSelected="setAnswers($event, questionIndex)"
                                        :editedItemQuestion="question"    
                                    />
                                </v-col>
                                <div v-if="question.type === 'custom'"> 
                                    <v-col cols="12" v-for="(item, answerIndex) in question.answers" :key="answerIndex" class="position-relative" >
                                        <input
                                            v-model="editedItem.questions[questionIndex].answers[answerIndex]"
                                            placeholder="Wpisz odpowiedź"
                                            class="survey-modal-input"
                                            :class="editedItem.questions[questionIndex].answers[answerIndex] ? 'filled' : ''"
                                        />
                                        <img v-if="question.answers.length > 2" @click="deleteAnswer(questionIndex, answerIndex)" class="cursor-pointer trash-icon-answers" src="@/assets/img/icon_trash.svg" />
                                        <div v-if="item === ''" class="text-right text-color-red text-size-2">{{answerErrorMessage}}</div>
                                    </v-col>
                                </div>
                                <v-col cols="12" class="bp-0">
                                    <v-btn icon v-if="editedItem.questions[questionIndex].type === 'custom'" :disabled="editedItem.questions[questionIndex].answers.length === 5" class="add-question-btn" :ripple="false">
                                        <svg @click="addAnswerInput(questionIndex)" class="add-question-btn-img cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7H12.75ZM11.25 17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17H11.25ZM7 11.25C6.58579 11.25 6.25 11.5858 6.25 12C6.25 12.4142 6.58579 12.75 7 12.75V11.25ZM17 12.75C17.4142 12.75 17.75 12.4142 17.75 12C17.75 11.5858 17.4142 11.25 17 11.25V12.75ZM11.25 7V17H12.75V7H11.25ZM7 12.75H17V11.25H7V12.75ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75Z" fill="#8392AB"/>
                                        </svg>
                                        <span class="cursor-pointer" @click="addAnswerInput(questionIndex)">dodaj kolejną odpowiedź do pytania</span>
                                    </v-btn>
                                </v-col>
                            </div>
                            <v-col
                                cols="12"
                            >
                                <v-btn icon :disabled="editedItem.questions.length === 3" class="add-question-btn mb-5" :ripple="false">
                                    <svg @click="addQuestionInput" class="add-question-btn-img cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.75 7C12.75 6.58579 12.4142 6.25 12 6.25C11.5858 6.25 11.25 6.58579 11.25 7H12.75ZM11.25 17C11.25 17.4142 11.5858 17.75 12 17.75C12.4142 17.75 12.75 17.4142 12.75 17H11.25ZM7 11.25C6.58579 11.25 6.25 11.5858 6.25 12C6.25 12.4142 6.58579 12.75 7 12.75V11.25ZM17 12.75C17.4142 12.75 17.75 12.4142 17.75 12C17.75 11.5858 17.4142 11.25 17 11.25V12.75ZM11.25 7V17H12.75V7H11.25ZM7 12.75H17V11.25H7V12.75ZM20.25 12C20.25 16.5563 16.5563 20.25 12 20.25V21.75C17.3848 21.75 21.75 17.3848 21.75 12H20.25ZM12 20.25C7.44365 20.25 3.75 16.5563 3.75 12H2.25C2.25 17.3848 6.61522 21.75 12 21.75V20.25ZM3.75 12C3.75 7.44365 7.44365 3.75 12 3.75V2.25C6.61522 2.25 2.25 6.61522 2.25 12H3.75ZM12 3.75C16.5563 3.75 20.25 7.44365 20.25 12H21.75C21.75 6.61522 17.3848 2.25 12 2.25V3.75Z" fill="#8392AB"/>
                                    </svg>
                                    <span class="cursor-pointer" @click="addQuestionInput">dodaj kolejne pytanie</span>
                                </v-btn>

                                <input
                                    v-model="editedItem.custom_content"
                                    placeholder="Treść przycisku zatwierdzającego"
                                    class="survey-modal-input"
                                    :class="editedItem.custom_content ? 'filled' : ''"
                                />
                                <div class="text-right text-color-red text-size-2">{{confirmButtonErrorMessage}}</div>

                                <v-btn
                                    color="create-survey-btn"
                                    @click="sendSurvey"
                                >
                                    ZAPISZ
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </div>
            <div id="survey-preview">
                 <v-card-text>
                    <v-container>
                        <p class="text-center text-color-primary text-size-2 mb-0">Podgląd ankiety</p>
                        <div class="survey-preview-content position-relative">
                            <p class="survey-title" v-if="editedItem.headline">{{ editedItem.headline }}</p> 
                            <p class="survey-preview-description"  v-if="editedItem.description">
                                {{ editedItem.description }}
                            </p>
                            <div v-for="(item, index) in editedItem.questions" :key="index">
                                <p class="survey-question">{{ item.question }}</p>
                                <div class="survey-answers" :class="editedItem.questions.length === index + 1 ? 'last-question' : ''">
                                    <div v-if="item.type === 'sentiment_3_stars' || item.type === 'sentiment_5_stars' || item.type === 'likert_5_stars'">
                                        <RatingStars
                                            :numberOfStars="item.answers.length"
                                        />
                                    </div>
                                    <div v-if="item.type === 'custom'">
                                        <div v-for="(answer, index) in item.answers" :key="index">
                                            <v-btn class="custom-answer">{{answer}}</v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <v-btn
                                color="send-opinion-btn"
                            >
                                {{editedItem.custom_content}}
                            </v-btn>
                            <p class="powered-by">Powered by Canvax</p>
                        </div>
                    </v-container>
                 </v-card-text>
            </div>
        </div>
    </v-card>
</template>

<script>
import { http } from "@/plugins/http";
import QuestionTypeDropdown from "@/components/surveys/QuestionTypeDropdown"
import RatingStars from "@/components/surveys/RatingStars"

export default {
    components: { QuestionTypeDropdown, RatingStars },
    props: {
        isOpened: {},
        editedItemProp: {},
    },
    data() {
        return {
            editedItem: { 
                headline: "Dziękujemy za Twoje zamówienie!",
                name: "Oceń swoje zamówienie",
                creationDate: "",
                domain: "",
                purpose: "", 
                result: 0,
                status: false,
                description: "Chcielibyśmy poznać Twoją opinię na temat jakości i dostawy zamówienia. Będzie to pomocne dla nas ale również innych osób chcących zamówić na naszej stronie! ",
                questions: [
                    {question: "", type: "sentiment_3_stars", answers: [1,2,3]},
                ],
                custom_content: "Podziel się swoją opinią"
            }, 
            headlineErrorMessage: "",
            nameErrorMessage: "",
            purposeErrorMessage: "",
            questionErrorMessage: "",
            answerErrorMessage: "",
            confirmButtonErrorMessage: "",
        }
    },
    mounted() {
        if(!this.editedItem.id && this.editedItemProp) {
            this.editedItem = JSON.parse(JSON.stringify(this.editedItemProp))
        }
    },
    methods: {
        setAnswers(data, questionIndex) {
            this.editedItem.questions[questionIndex].type = data.type;
            this.editedItem.questions[questionIndex].answers = data.answers;
        },
        async sendSurvey() {
            if(this.isValid()) {
                const newSurvey = this.createNewSurvey();
                await http.post(`/surveys`, newSurvey)
                this.$emit('close')
            }
        },
        createNewSurvey() {
            const survey = {
                headline: this.editedItem.headline,
                name: this.editedItem.name,
                purpose: this.editedItem.purpose, 
                description: this.editedItem.description,
                questions: this.editedItem.questions,
                custom_content: this.editedItem.custom_content
            }
            return survey;
        },
        clearErrorMessages() {
            this.headlineErrorMessage = "";
            this.nameErrorMessage = "";
            this.purposeErrorMessage = "";
            this.questionErrorMessage = "";
            this.answerErrorMessage = "";
            this.confirmButtonErrorMessage = "";
        },
        isValid() {
            let result = true;
            this.clearErrorMessages();

            if(!this.editedItem.name) {
                this.nameErrorMessage = "Tytuł jest wymagany";
                result = false;
            }
            if(!this.editedItem.custom_content) {
                this.editedItem.custom_content = "Przycisk zatwierdzający nie może być pusty"
                result = false;
            }
            this.editedItem.questions.forEach(question => {
                if(question.question === "") {
                    this.questionErrorMessage = "Pytanie nie może być puste";
                    result = false;
                }
                if(question.type === "custom") {
                    if(question.answers.includes("")) {
                        this.answerErrorMessage = "Odpowiedź nie może być pusta";
                        result = false;
                    }
                }
            });

            return result;
        },
        addQuestionInput() {
            this.editedItem.questions.push({question: "", type: "sentiment_3_stars", answers: [1,2,3]})
        },
        addAnswerInput(questionIndex) {
            this.editedItem.questions[questionIndex].answers.push("");
        },
        deleteQuestion(questionId) {
            this.editedItem.questions.splice(questionId, 1);
        },
        deleteAnswer(questionIndex, answerIndex) {
            this.editedItem.questions[questionIndex].answers.splice(answerIndex, 1);
        },
        clear() {
            this.editedItem = { 
                headline: "Dziękujemy za Twoje zamówienie!",
                name: "Oceń swoje zamówienie",
                creationDate: "",
                domain: "",
                purpose: "", 
                result: 0,
                status: false,
                description: "Chcielibyśmy poznać Twoją opinię na temat jakości i dostawy zamówienia. Będzie to pomocne dla nas ale również innych osób chcących zamówić na naszej stronie! ",
                questions: [
                    {question: "", type: "sentiment_3_stars", answers: [1,2,3]},
                ],
                custom_content: "Podziel się swoją opinią"
            }
            this.headlineErrorMessage = "";
            this.nameErrorMessage = "";
            this.purposeErrorMessage = "";
            this.questionErrorMessage = "";
            this.answerErrorMessage = "";
            this.confirmButtonErrorMessage = "";
        }
    },
    watch: {
        isOpened(val) {
            if(!val) {
                this.clear();
            }
        },
        editedItemProp(val) {
            this.editedItem = JSON.parse(JSON.stringify(val))
        }
    }
}
</script>