<template>
  <div
    v-if="
      col.type !== 'row-title' &&
      row.type !== 'graph' &&
      row.type !== 'communication' &&
      col.type !== 'card' &&
      !arraysHasCommon(row.options, ['stage', 'substage', 'persona'])
    "
    class="new-card"
  >
    <div
      class="placeholder"
      @dragover.prevent
      @dragenter="
        (event) => {
          event.preventDefault();
          cardDragEnter({ element: event.target });
        }
      "
      @dragleave="cardDragLeave"
      @drop="() => cardDrop({ rowId, columnId })"
    ></div>
    <div
      class="add-new"
      @click="openDialog({ rowId: rowId, columnId: columnId })"
    >
      <span>Dodaj [{{ title }}]</span>
      <PlusCircleIcon />
    </div>
  </div>
</template>

<script>
import utils from "@/plugins/utils.js";
import { mapState, mapActions } from "vuex";

import PlusCircleIcon from "@/components/icons/PlusCircleIcon.vue";

export default {
  components: {
    PlusCircleIcon,
  },
  props: {
    col: Object,
    row: Object,
    columnId: Number,
    rowId: Number,
  },
  computed: {
    ...mapState("cjstore", ["channels", "selectedPersonasObjs", "rows"]),
    title() {
      return this.rows[this.rowId].title;
    },
  },
  methods: {
    arraysHasCommon: utils.arraysHasCommon,
    ...mapActions("cjstore", [
      "openCardDialog",
      "cardDragStart",
      "cardDragEnter",
      "cardDragLeave",
      "cardDragEnd",
      "cardDrop",
      "setEditRowId",
      "setEditColId",
    ]),
    async openDialog(payload) {
      await this.setEditRowId(payload.rowId);
      await this.setEditColId(payload.columnId);
      this.openCardDialog(payload);
    },
  },
};
</script>

<style lang="sass" scoped>
.new-card
  height: 100%
  display: flex
  flex-direction: column
.add-new
  color: #8392AB
  opacity: 0
  display: flex
  align-items: center
  justify-content: space-between
  font-size: 12px
  margin: 7px 10px
  border-radius: 8px
  padding: 8px 20px
  transition: opacity .2s ease-out
  cursor: pointer
  box-shadow: 0px 2px 30px rgba(46, 27, 117, 0.05)
  height: 100%
  span
    line-height: 28px
.placeholder
  position: absolute
  width: calc(100% - 10px)
  height: calc(100% - 10px)
  z-index: -1
  top: 5px
  left: 5px
  &.card
    margin: 0
.dragenter
  background: #fff
  border-radius: 8px
  margin: 5px 10px
  padding: 7px 20px
  display: flex
  justify-content: space-between
  align-items: center
  color: $primary-color
.placeholder *
  pointer-events: none
</style>
