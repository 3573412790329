<template>
    <v-dialog
        v-model="dialog"
        max-width="421px"
    >
        <v-card id="billing-details-modal" class="mx-auto">
            <div class="close-btn" >
                <img @click="dialog = false" src="@/assets/img/cross-circle.svg" />
            </div>
            <div id="billing-details-content">
                <div class="billing-details-title">Dane rozliczeniowe</div>
                 <v-radio-group
                    v-model="company"
                    row
                >
                    <v-radio
                        label="Firma"
                        color="#2E1B75"
                        :value="true"
                    ></v-radio>
                    <v-radio
                        label="Osoba prywatna"
                        color="#2E1B75"
                        :value="false"
                    ></v-radio>
                 </v-radio-group>
                <label v-if="!company">Imię i nazwisko</label>
                <v-text-field
                    v-if="!company"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    placeholder="Imie i nazwisko"
                    class="billing-details-input"
                    >
                </v-text-field>

                <label v-if="company">NIP</label>
                <v-text-field
                    v-if="company"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    placeholder="677-10-10-100"
                    class="billing-details-input"
                    >
                </v-text-field>

                <label v-if="company">Nabywca</label>
                <v-text-field
                    v-if="company"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    placeholder="Nazwa podmiotu"
                    class="billing-details-input"
                    >
                </v-text-field>
    
                <label>Adres</label>
                <v-text-field
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    placeholder="Adres"
                    class="billing-details-input"
                >
                </v-text-field>
                
                <div class="d-flex justify-space-between">
                    <div>
                        <label>Kod pocztowy</label>
                        <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="31-002"
                            class="billing-details-input billing-details-input-sm"
                        >
                        </v-text-field>
                    </div>
                    <div id="address-input2">
                        <label>Miejscowość</label>
                        <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="Miejscowość"
                            class="billing-details-input"
                        >
                        </v-text-field>
                    </div>
                </div>
                <label>Kraj</label>
                <v-text-field
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    placeholder="Polska"
                    class="billing-details-input"
                >
                </v-text-field>
                <v-btn id="confirm-button">
                    ZAPISZ
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            company: false
        }
    },
    methods: {
        setDialog(value) {
            this.dialog = value;
        },
    },
}
</script>