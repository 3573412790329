<template>
  <div id="customerJourney">
    <div class="position-fixed d-flex mb-14 buttons-bar">
      <button
        class="d-flex align-center justify-space-between add-path-button"
        @click="showModal = true"
      >
        Stwórz nową ścieżkę
        <img src="@/assets/img/icon_plus-circle.svg" />
      </button>
      <div
        class="d-flex align-center justify-space-between current-view add-path-button ml-2"
        @click="isArchive = !isArchive"
      >
        <span class="mr-2">{{ view }}</span>
        <img :src="require(`@/assets/img/${icon}.svg`)" />
      </div>
    </div>
    <Modal
      :dialog="showModal"
      @close="showModal = false"
      @customerJourneyAdded="getCustomerJourneys"
    />

    <div
      class="d-flex align-center justify-center flex-column h-100"
      v-if="rows.length === 0"
    >
      <img src="@/assets/img/no_journey_illustration.svg" />
      <div class="no-cj-title text-color-primary">Jakoś tu pusto...</div>
      <div class="no-cj-subtitle text-color-primary">
        {{ noElements }}
      </div>
    </div>
    <v-row no-gutters>
      <v-col
        v-for="customerJourney in rows"
        :key="customerJourney.id"
        cols="12"
        class="col-sm-6 col-md-4 col-lg-3"
      >
        <CustomerJourneyCard
          @archive="archive"
          :data="customerJourney"
          :isArchive="isArchive"
          @deleteCustomerJourney="deleteCustomerJourney"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CustomerJourneyCard from "@/components/dashboard/CustomerJourneyCard";
import Modal from "@/components/dashboard/addCustomerJourneyModal/Modal";
import { http } from "@/plugins/http";
import store from "@/store/index";

export default {
  data() {
    return {
      isArchive: false,
      showModal: false,
      data: [],
      user: store.getters.getUser,
    };
  },
  computed: {
    rows() {
      return this.data.filter((item) =>
        this.isArchive ? item.is_archived == true : !item.is_archived
      );
    },
    view() {
      return this.isArchive ? "Aktywne ścieżki" : "Archiwum";
    },
    icon() {
      return this.isArchive ? "icon_grid" : "icon_archive";
    },
    noElements() {
      return this.isArchive
        ? "Brak elementów w archiwum"
        : "Rozpocznij tworzenie nowej ścieżki";
    },
  },
  components: { CustomerJourneyCard, Modal },
  mounted() {},
  methods: {
    archive(id, isArchive) {
      console.log(id, isArchive);
      http
        .patch(`/customer_journey/${id}`, {
          is_archived: isArchive,
        })
        .then(() => {
          this.getCustomerJourneys();
        });
    },
    async deleteCustomerJourney(cjId) {
      await http.delete(`/customer_journey/${cjId}`);
      const removeIndex = this.data
        .map(function (item) {
          return item.id;
        })
        .indexOf(cjId);
      this.data.splice(removeIndex, 1);
    },
    async getCustomerJourneys() {
      const response = await http.get(`/customer_journeys`);
      this.data = this.user.super_admin
        ? [...response.data.data]
        : [...response.data.data].filter((item) => item.is_template == false);
      console.log(this.user);
    },
  },
  created() {
    this.getCustomerJourneys();
  },
};
</script>

<style lang="sass" scoped>
#customerJourney .current-view
	margin-top: 10px
	background-color: white !important
	border: 2px solid transparent
	padding: 8px 16px
	color: $primary-color !important
	box-shadow: none !important
	position: relative !important
	cursor: pointer
	flex-wrap: wrap
	margin-left: 15px !important
	min-width: auto !important
	&:hover
		border: 2px solid $aquamarine-color
</style>
