<template>
    <v-form ref="form" @submit.prevent="sendForm" class="mt-sm-8 mt-md-5 mt-7 bg-transparent" id="choose-subscription">
        <div class="card-padding pb-0">
            <h2 class="mb-2 auth-header">
                Jeszcze jedno...
            </h2>
            <p class="mb-14 auth-subtitle">Wybierz subskrypcję do której chcesz się zalogować</p>
            <div class="pt-2 pb-2 subscription cursor-pointer" v-for="subscription in subscriptions" v-bind:key="subscription.subscription_id" @click="login(subscription.subscription_id)">
              <div class="card">
                <p class="mb-0 text-color-dark-gray font-weight-bold">{{ subscription.subscription.name }}</p>
                <p class="mb-0 text-color-gray font-weight-bolder text-size-1">ostatnie logowanie: {{ formatDate(subscription.last_logged_in) }}</p>
              </div>
            </div>
          </div>
    </v-form>
</template>

<script>
import { http } from "@/plugins/http";
export default {
  data() {
    return {
      emailValid: null,
      passwordValid: null,
      showPassword: false,
      remember: false,
      form: {
        email: null,
        password: null,
      },
      errorMessages: {},
      loading: false,
      subscriptions: this.$store.getters.getUserSubscriptions,
      months: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"]
    };
  },
  components: {  },
  methods: {
    formatDate(givenDate) {
      let fullDate = new Date(givenDate + "Z")

      const year = fullDate.getFullYear();
      const day = fullDate.getDate();
      const monthName = this.months[fullDate.getMonth()];
      const date = `${day} ${monthName} ${year}`

      const hours = fullDate.getHours()
      const minutes = fullDate.getMinutes()
      const time = `${hours}:${minutes}`
      
      return `${date}, o ${time}`;
    },
    async sendForm() {
      if (this.validate()) {
        this.loading = true;

        try {
          await this.login(this.form);
          
        } catch (e) {
          if (e.errors) {
            this.errorMessages = e.errors;
            return;
          }

          this.errorMessages = { email: [e.message] };
        } finally {
          this.loading = false;
        }
      }
    },
    resetValidation() {
        this.emailValid = null;
        this.passwordValid = null;
    },
    validate() {
        this.resetValidation();
        let valid = true;
        if(!this.form.email) {
            this.emailValid = false;
            valid = false;
        } else {
            this.emailValid = true;
        }
        if(!this.form.password || this.form.password.length <3) {
            this.passwordValid = false;
            valid = false;
        } else {
            this.passwordValid = true;
        }
        return valid;
    },
    toggleShow(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    login (subscription_id) {
        this.$root.$store.dispatch('login',[{'subscription_id':subscription_id},'/']);
    },
    showSpinner() {
        this.spinnerVisible = true;
    },
    hideSpinner() {
        setTimeout(() => this.spinnerVisible = false,500)
    },
    recoverPassword() {
        if (this.password_reset) {
            this.password_modal = false;
            return;
        }
        let payload = {
            "email": this.email,
            "username": this.username
        }
        http.post("/set_temporary_password", payload)
        .then(result => {
            this.$toasted.show(result.data.message, {type : 'info'})    
            if ('INFO' in result.data) {
                let type = 'info'
                if ('status' in result.data)
                    type = result.data.status
                this.$toasted.show(result.data.INFO, {     type : type })
            }   
            if ('status' in result.data && result.data.status == 'success') {
                this.reset_caption = this.$i18n.t('close');
                this.password_reset = true;
            }
        }, error => {
            this.$toasted.show(error, {     type : 'error' })
            console.error(error)
        });
    },
    beforeCreate () {
        //this.$store.dispatch('checkLoggedIn')
    },
  },
};
</script>

<style scoped>
#login-page {
  background-color: var(--v-primary-lighten5);
}
.cursor-hand {
  cursor: pointer;
}
</style>

