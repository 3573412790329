<template>
  <span>
    <template v-if="auth">
      <v-footer color="transparent" class="mt-0 pt-0">
        <div class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" lg="8" class="mx-auto text-center">
                <a
                  v-for="item in footer"
                  :key="item.linkName"
                  :href="item.link"
                  class="text-decoration-none text-secondary ls-0 mx-4 text-lg"
                  target="_blank"
                  >{{ item.linkName }}</a
                >
              </v-col>
            </v-row>
            <v-row>
               <v-col class="text-center">
                <span class="text-size-1 text-color-gray">Copyright © 2022 Soft by Numbers &amp; Intuition</span>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-footer>
    </template>

    <template v-else>
      <v-footer color="transparent" class="px-6">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="10" md="6" class="d-flex justify-end">
                <ul
                  v-for="item in footer"
                  :key="item.linkName"
                  class="d-flex list-style-none"
                >
                  <li>
                    <a
                      :href="item.link"
                      class="
                        text-decoration-none text-muted text-body-2
                        ls-0
                        btn-dark-hover
                        no-default-hover
                      "
                      target="_blank"
                      >{{ item.linkName }}</a
                    >
                  </li>
                </ul>
              </v-col>
            </v-row>
             <v-row>
               <v-col class="text-center">
                <span class="text-size-1 text-color-gray">Copyright © 2022 Soft by Numbers &amp; Intuition</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </span> 
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
  data() {
    return {
      footer: [
        {
          linkName: "Strona główna",
          link: "https://canvax.io",
        },
                {
          linkName: "O Canvax",
          link: "https://canvax.io",
        },
                {
          linkName: "Cennik",
          link: "https://canvax.io",
        },
      ],
    };
  },
};
</script>
