<template>
    <v-menu :z-index="13" transition="slide-y-transition" offset-y>
        <template v-slot:activator="{ on, attrs }">
            <button  
            v-bind="attrs"
            v-on="on"
            elevation="0"
            :ripple="false" class="d-flex align-center justify-end admin-button-dropdown">
              <img class="admin-profie-pic mr-4" :src="data.profilePic"  />
              <svg class="mr-5 admin-button-dropdown" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 6.75L9 12.75L15 6.75" stroke="currentColor" stroke-width="2" stroke-linecap="square"/>
              </svg>
            </button>
        </template>

        <v-list class="py-0 admin-dropdown-list">
            <v-list-item class="list-item-hover-active">
                <v-list-item-content class="pa-0">
                    <v-list-item-title
                    class="text-body-2 ls-0 text-typo font-weight-600 mb-0 admin-dropdown-item"
                    >
                    Zalogowany: <span class="font-weight-bold"> {{data.email}} </span>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <hr class="horizontal dark" />
            <v-list-item class="list-item-hover-active" @click="openAccountManagement">
                <v-list-item-content class="pa-0">
                    <v-list-item-title
                    class="text-body-2 ls-0 text-typo font-weight-600 mb-0 admin-dropdown-item"
                    >
                    Zarządzanie kontem
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item class="list-item-hover-active" @click="logout()">
                <v-list-item-content class="pa-0">
                    <v-list-item-title
                    class="text-body-2 ls-0 text-typo font-weight-600 mb-0 admin-dropdown-item"
                    >
                    Wyloguj
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import { http } from "@/plugins/http";

export default {
    data() {
        return {
            data: {
                profilePic: require('@/assets/img/avatar.png'),
                email: ""
            }
        }
    },
    computed: {
        userData() {
            return this.$store.getters.getUser;
        },
    },
    mounted() {
        this.setProfilePicture();
        this.data.email = this.userData.email;
    },
    methods: {
        logout() {
           this.$root.$store.dispatch('logout'); 
        },
        openAccountManagement() {
            this.$emit("showAccountManagement")
        },
        async profilePicture(profilePicture) {
            this.data.profilePic = require('@/assets/img/avatar.png');
            if(profilePicture) {
                let timestamp = new Date().getTime()
                http.get(`${process.env.VUE_APP_API_URL}/user_profile_picture?${timestamp}`, {responseType: 'blob'})
                .then(response => new Blob([response.data]))
                .then(blob => new Promise(() => {
                    this.data.profilePic = URL.createObjectURL(blob) 
                }))
            } 
        },
        async setProfilePicture() {
            if(this.userData) {
                await this.profilePicture(this.userData.profile_picture)
            }
        }
    },
    watch: {
        userData() {
           this.setProfilePicture();
        }
    }
}
</script>

<style lang="sass" scoped>
    ::v-deep .v-menu__content
        z-index: 11 !important
</style>
