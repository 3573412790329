<template>
  <v-app class="bg-white">
    <v-main class="auth-pages">
      <div
        class="
          position-relative
          pb-0
          pt-16
          border-radius-xl
          min-vh-100
          d-flex
          flex-column
          justify-space-between
        "
      >
        <v-container>
          <v-row>
            <v-col md="6" cols="12" class="d-flex flex-column mx-auto">
              <v-row no-gutters >
                <v-col cols="1"></v-col>
                <v-col cols="10">
                  <fade-transition
                    :duration="200"
                    origin="center top"
                    mode="out-in"
                  >
                    <!-- your content here -->
                    <router-view></router-view>
                  </fade-transition>
                </v-col>
                <v-col cols="1"></v-col>
              </v-row>
              
            </v-col>
            <v-col md="6">
              <div
                class="
                  oblique
                  position-absolute
                  top-0
                  h-75
                  d-md-block d-none
                  me-n8
                  min-vh-85
                "
              >
                <div
                  class="
                    oblique-image
                    position-absolute
                    fixed-top
                    ms-auto
                    h-100
                    w-100
                    z-index-0
                    ms-n16
                    d-flex
                    align-center
                    justify-center
                  "
                >
                  <img src="../assets/img/logo_canvax_login.svg" id="auth-logo" />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <Footer auth v-if="!$route.meta.hideFooter && false" />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import Footer from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    FadeTransition,
    Footer,
  },
  data() {
    return {};
  },
};
</script>