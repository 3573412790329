<template>
  <div
    :class="[
      'row',
      row.options.includes('stage') ? 'stage' : '',
      row.options.includes('substage') ? 'substage' : '',
      row.options.includes('persona') ? 'persona' : '',
    ]"
    :ref="
      row.options.includes('stage')
        ? 'stage'
        : row.options.includes('substage')
        ? 'substage'
        : null
    "
    :draggable="row.options && !row.options.includes('nondrag')"
    @dragstart="
      (event) => {
        event.stopPropagation();
        rowDragStart(rowId);
      }
    "
    @dragend="rowDragEnd"
    @dragover.prevent
    @dragenter="
      (event) => {
        event.preventDefault();
        rowDragEnter({ element: event.currentTarget });
      }
    "
    @dragleave="(event) => rowDragLeave({ element: event.currentTarget })"
    @drop="rowDrop(rowId)"
  >
    <div
      :key="`row_placeholder_${rowId}`"
      class="row-placeholder"
      @dragover.prevent
      @dragleave.prevent
      @dragenter.prevent
      @drop="rowDrop(rowId)"
    ></div>
    <!-- Graph row -->
    <template v-if="row.type === 'graph'">
      <template v-for="(col, columnId) in row.cols">
        <Graph
          :key="`row_${rowId}_col_${columnId}`"
          :col="col"
          :row="row"
          :rowId="rowId"
          :columnId="columnId"
        />
      </template>
    </template>
    <!-- Other rows -->
    <template v-else v-for="(col, columnId) in row.cols">
      <Column
        :key="`row_${rowId}_col_${columnId}`"
        :row="row"
        :col="col"
        :rowId="rowId"
        :columnId="columnId"
      />
    </template>
  </div>
</template>

<script>
import Graph from "@/components/customerJourney/Graph.vue";
import Column from "@/components/customerJourney/Column.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Graph,
    Column,
  },
  props: {
    rowId: Number,
    row: Object,
  },
  computed: {
    ...mapState("cjstore", ["scrollLeft", "fixedRowsWidth"]),
  },
  destroyed() {},
  watch: {
    fixedRowsWidth(newVal) {
      if (this.$refs.stage) {
        this.$refs.stage.style.width = newVal;
      }
      if (this.$refs.substage) {
        this.$refs.substage.style.width = newVal;
      }
    },
    scrollLeft(newVal) {
      if (this.$refs.stage) {
        this.$refs.stage.scrollLeft = `translateX(-${newVal}px)`;
      }
      if (this.$refs.substage) {
        this.$refs.substage.scrollLeft = `translateX(-${newVal}px)`;
      }
    },
  },
  methods: {
    ...mapActions("cjstore", [
      "rowDragStart",
      "rowDragLeave",
      "rowDragEnd",
      "rowDragEnter",
      "rowDrop",
    ]),
  },
};
</script>

<style lang="sass" scoped>
.row
  border-bottom: 2px dotted rgba(131, 146, 171, .5)
  flex-wrap: nowrap
  margin: 0
  position: relative
  background-color: #F3F3F3
  &.stage
    border-bottom: none
  &.stage .column.row-title,
  &.substage .column.row-title,
  &.persona .column.row-title
    padding: 8px 20px
.row-placeholder
  position: absolute
  width: 100%
  height: calc(100% - 5px)
  top: 5px
  left: 0
  z-index: -1
  pointer-events: none
  &.row-dragenter
    z-index: 10
    border: 6px solid #F3F3F3
    border-radius: 8px
    background-color: #fff
.row.stage,
.row.substage
  position: sticky
  z-index: 110
.row.stage::v-deep .row-title,
.row.substage::v-deep .row-title
  z-index: 100
.row.stage
  top: 0px
.row.substage
  top: 40px
</style>
