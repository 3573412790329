<template>
    <div id="analytics-top-bar">
        <span class="analytics-title">Analityka</span>
        <div class="date-pickers">
            <DatePicker 
                class="inline-block h-full date-picker" 
                v-model="dateStart" 
                :model-config="modelConfig" 
                :masks="masks" color="purple"
                :max-date='maxDayForDateStart'
            >
                <template v-slot="{ inputValue, togglePopover }">
                    <div class="position-relative">
                        <input
                            @click="togglePopover()"
                            :value="inputValue"
                            class="date-picker-input"
                            readonly
                        />
                        <svg class="input-icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 6.75L9 12.75L15 6.75" stroke="#2e1b75" stroke-width="2" stroke-linecap="square" />
                        </svg>
                    </div>
                </template>
            </DatePicker>
            <DatePicker 
                class="inline-block h-full date-picker ml-5" 
                v-model="dateEnd" 
                :model-config="modelConfig" 
                :masks="masks" 
                color="purple"
                :max-date='new Date()'
                :min-date="minDayForDateEnd"
             >
                <template v-slot="{ inputValue, togglePopover }">
                    <div class="position-relative">
                        <input
                            @click="togglePopover()"
                            :value="inputValue"
                            class="date-picker-input"
                            readonly
                        />
                        <svg class="input-icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 6.75L9 12.75L15 6.75" stroke="#2e1b75" stroke-width="2" stroke-linecap="square" />
                        </svg>
                    </div>
                </template>
            </DatePicker>
        </div>
    </div>
</template>

<script>
import { http } from "@/plugins/http";
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
    components: { DatePicker },
    data() {
        return {
            dateStart: null,
            dateEnd: null,
            data: null,
            masks: {
                input: 'DD-MM-YYYY',
            },
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD',
            },
        }
    },
    created() {
        this.setDefaultDates();
    },
    computed: {
        maxDayForDateStart() {
            let result = new Date(this.dateEnd);
            result.setDate(result.getDate() - 1);
            return result;
        },
        minDayForDateEnd() {
            let result = new Date(this.dateStart);
            result.setDate(result.getDate() + 1);
            return result;
        }
    },
    methods: {
        setDefaultDates() {
            const today = new Date();
            let dateEnd = today.toISOString().split("T")[0];
            let dateStart = new Date();
            dateStart.setDate(today.getDate() - 30);
            dateStart = dateStart.toISOString().split("T")[0];
            this.dateEnd = dateEnd;
            this.dateStart = dateStart;
        },
        async getData() {
            if(this.dateStart && this.dateEnd) {
                const response = await http.post(`/get_analytics_data`, {
                    start_date: this.dateStart,
                    end_date: this.dateEnd
                })
                const utmSourcesResponse = await http.post(`/ga_traffic_source`, {
                    start_date: this.dateStart,
                    end_date: this.dateEnd
                }) 
                const utmSources = utmSourcesResponse.data;

                const dataString = typeof response.data === 'string' 
                    ? response.data 
                    : JSON.stringify(response.data);
            
                const parsedData = JSON.parse(
                    dataString.replaceAll('NaN', '0').replaceAll('Infinity', '100')
                );
            
                this.data = {
                    ...parsedData.success,
                    utmSources: utmSources
                };
                // this.data = JSON.parse(response.data.replaceAll('NaN','0').replaceAll('Infinity', '100')).success
		// this.data = JSON.parse(response.data.replace("/NaN/g", "0").replace("/Infinity/g", "100")).success
                //this.data = {
                //    ...this.data,
                //    utmSources: utmSources
                //}
                this.$emit("dataUpdate", this.data)
            }
        },
    },
    watch: {
        dateStart() {
            this.getData();
        },
        dateEnd() {
            this.getData();
        }
    }
}
</script>
