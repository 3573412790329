var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:_vm.row.options.includes('stage')
      ? 'stage'
      : _vm.row.options.includes('substage')
      ? 'substage'
      : null,class:[
    'row',
    _vm.row.options.includes('stage') ? 'stage' : '',
    _vm.row.options.includes('substage') ? 'substage' : '',
    _vm.row.options.includes('persona') ? 'persona' : '' ],attrs:{"draggable":_vm.row.options && !_vm.row.options.includes('nondrag')},on:{"dragstart":function (event) {
      event.stopPropagation();
      _vm.rowDragStart(_vm.rowId);
    },"dragend":_vm.rowDragEnd,"dragover":function($event){$event.preventDefault();},"dragenter":function (event) {
      event.preventDefault();
      _vm.rowDragEnter({ element: event.currentTarget });
    },"dragleave":function (event) { return _vm.rowDragLeave({ element: event.currentTarget }); },"drop":function($event){return _vm.rowDrop(_vm.rowId)}}},[_c('div',{key:("row_placeholder_" + _vm.rowId),staticClass:"row-placeholder",on:{"dragover":function($event){$event.preventDefault();},"dragleave":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();},"drop":function($event){return _vm.rowDrop(_vm.rowId)}}}),(_vm.row.type === 'graph')?[_vm._l((_vm.row.cols),function(col,columnId){return [_c('Graph',{key:("row_" + _vm.rowId + "_col_" + columnId),attrs:{"col":col,"row":_vm.row,"rowId":_vm.rowId,"columnId":columnId}})]})]:_vm._l((_vm.row.cols),function(col,columnId){return [_c('Column',{key:("row_" + _vm.rowId + "_col_" + columnId),attrs:{"row":_vm.row,"col":col,"rowId":_vm.rowId,"columnId":columnId}})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }