<template>
    <v-card
        class="modal-card"
        :class="isSelected ? 'card-clicked' : ''"
        @click="clickCard"
    >
        <template slot="progress">
            <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
            ></v-progress-linear>
        </template>
        <template v-if="data.basic_info && data.detailed_info">
            <v-img
                height="112"
                :src="require(`@/assets/img/personas/person_${data.basic_info.image_id || 1}.jpg`)"
                class="card-image"
            ></v-img>
            
            <v-card-title class="card-title pt-0 mb-1 px-0">
                {{data.basic_info.name}}
            </v-card-title>
            <v-card-text class="px-0">
                <div class="card-subtitle mb-1">{{data.detailed_info.job_title}}</div>
            </v-card-text>
        </template>
    </v-card>
</template>

<script>
import global from '@/store/global';

    export default {
        props: {
            data: {
                basic_info: {},
                detailed_info: {},
                id: Number
            },
            preSelect: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isSelected: this.preSelect ? this.preSelect : false
            }
        },
        methods: {
            clickCard() {
                if(!this.isSelected){
                    global.dispatch('selectPersona', this.data);
                    this.$emit('selectCard', this.data.id);
                    this.isSelected = true;
                }else{
                    global.dispatch('unselectPersona', this.data);
                    this.$emit('unselectCard', this.data.id);
                    this.isSelected = false;
                }
            }
        }
    }
</script>