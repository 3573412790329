<template>
    <div id="source-files">
        <TopContainer 
            :personaId="personaId"
            :savedFiles="sourceFiles"
            @sourceFilesAdded="getSourceFiles"
        />
        <TableContainer 
            :items="sourceFiles"
            :personaId="personaId"
            @fileDeleted="getSourceFiles"
        />
    </div>
</template>

<script>
import TopContainer from "@/components/personas/sourceFiles/TopContainer";
import TableContainer from "@/components/personas/sourceFiles/TableContainer";
import { http } from "@/plugins/http";

export default {
    props: {
        personaId: {},
        isOpened: {}
    },
    components: { TableContainer, TopContainer },
    data() {
        return {
            sourceFiles: []
        }
    },
    created() {
        this.getSourceFiles();
    },
    methods: {
        async getSourceFiles() {
            const response = await http.get(`/persona_source_files/${this.personaId}`);
            this.sourceFiles = response.data.data.source_files;
        }
    },
    watch: {
        isOpened(val) {
            if(val) {
                this.getSourceFiles();
            } else {
                this.sourceFiles = []
            }
        }
    }
}
</script>
