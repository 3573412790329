<template>
  <v-app>
    <v-main class="auth-pages">
      <TopMenu />
      <div ref="cjwrapper" class="customer-journey-wrapper">
        <div class="content" ref="cjcontent">
          <template v-for="(row, rowId) in rows">
            <Row :key="`row_${rowId}`" :row="row" :rowId="rowId" />
          </template>
        </div>
        <CardDialog />
        <CardPickersDialog />
        <PersonPickerDialog />
        <RemoveCardDialog />
      </div>
    </v-main>
  </v-app>
</template>

<script>
import TopMenu from "@/components/customerJourney/TopMenu.vue";
import CardDialog from "@/components/customerJourney/dialogs/CardDialog.vue";
import CardPickersDialog from "@/components/customerJourney/dialogs/CardPickersDialog.vue";
import PersonPickerDialog from "@/components/customerJourney/dialogs/PersonPickerDialog.vue";
import RemoveCardDialog from "@/components/customerJourney/dialogs/RemoveCardDialog.vue";
import Row from "@/components/customerJourney/Row.vue";

import { mapState, mapActions } from "vuex";
import { http } from "@/plugins/http";
import bus from "@/components/customerJourney/eventBus.js";
import html2pdf from "html2pdf.js";

export default {
  components: {
    TopMenu,
    CardDialog,
    CardPickersDialog,
    PersonPickerDialog,
    RemoveCardDialog,
    Row,
  },
  computed: {
    ...mapState("cjstore", [
      "rows",
      "customerJourneyId",
      "isCardDragged",
      "isRowDragged",
      "selectedPersonasObjs",
      "zoom",
    ]),
  },
  created() {
    var customerJourneyId = this.$route.params.id;
    if (customerJourneyId) {
      this.setCustomerJourneyId(customerJourneyId);
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      const response = await this.getRowsData(this.customerJourneyId);

      if (response.status == 200 && !response.data.error) {
        this.setRows(JSON.parse(decodeURIComponent(response.data.data[0].content)));
        this.setRowElementId(response.data.data[0].id);
        this.setParentElementItemId(response.data.data[0].parent_element_id);
      } else {
        let savedTemplates = JSON.parse(
          localStorage.getItem("selected_templates")
        );
        if (savedTemplates) {
          let templateIndex = savedTemplates.findIndex((item) => {
            return item.customerJourneyId == this.customerJourneyId;
          });
          if (templateIndex !== -1) {
            const template = await this.getRowsData(
              savedTemplates[templateIndex].selectedTemplate
            );
            this.setRows(JSON.parse(decodeURIComponent(template.data.data[0].content)));
            this.setRowElementId(template.data.data[0].id);
            this.setParentElementItemId(
              template.data.data[0].parent_element_id
            );
          }
        }
      }

      await this.getCustomerJourney();
      await this.getPersonas();
      await this.renderChart(this.selectedPersonasObjs);

      bus.$on("exportPath", () => {
        var elements = document.querySelectorAll("svg.arrow, svg.baloon");
        var shapes = document.querySelectorAll(".card.arrow, .card.baloon");
        new Promise((resolve) => {
          this.setLoader(true);
          elements.forEach((item) => {
            item.style.height = `${item.clientHeight}px`;
            item.style.width = `${item.clientWidth}px`;
          });
          shapes.forEach((item) => item.classList.add("processed"));
          resolve();
        })
          .then(() => {
            var elementToPrint = document.querySelector(".content");
            return elementToPrint;
          })
          .then((elementToPrint) => {
            const width = elementToPrint.offsetWidth;
            const height = elementToPrint.offsetHeight;
            const opt = {
              margin: 1,
              filename: "myfile.pdf",
              image: { type: "jpeg" },
              html2canvas: {
                scale: 2,
                scrollX: 0,
                scrollY: 0,
              },
              jsPDF: {
                unit: "px",
                format: [width, height],
                orientation: "landscape",
              },
            };
            return html2pdf().set(opt).from(elementToPrint).toPdf().get("pdf");
          })
          .then((pdf) => {
            window.open(pdf.output("bloburl"), "_blank");
          })
          .then(() => {
            elements.forEach((item) => {
              item.style.height = "";
              item.style.width = "";
            });
            shapes.forEach((item) => item.classList.remove("processed"));
            this.setLoader(false);
          });
      });
    }
  },
  watch: {
    zoom(newVal) {
      this.$refs["cjwrapper"].style.transform = `scale(${newVal})`;
    },
    isCardDragged(newVal) {
      document.querySelectorAll(".placeholder").forEach((item) => {
        item.style.zIndex = newVal ? "10" : "-1";
      });
    },
    isRowDragged(newVal) {
      document.querySelectorAll(".row-placeholder").forEach((item) => {
        item.style.zIndex = newVal ? "10" : "-1";
        item.style.pointerEvents = newVal ? "none" : "auto";
      });
      document.querySelectorAll(".column").forEach((item) => {
        item.style.pointerEvents = newVal ? "none" : "auto";
      });
    },
  },
  async destroyed() {
    this.saveRowData().then(() => {
      this.resetState();
      this.clearHistory();
      bus.$off("exportPath");
    });
  },
  methods: {
    ...mapActions("cjstore", [
      "setRows",
      "setRowElementId",
      "setParentElementItemId",
      "setCustomerJourneyId",
      "getCustomerJourney",
      "renderChart",
      "getPersonas",
      "saveRowData",
      "resetState",
      "clearHistory",
      "setScrollLeft",
    ]),
    ...mapActions(["setLoader"]),
    async getRowsData(id) {
      return await http.get(`/customer_journey_elements/${id}`);
    },
  },
};
</script>

<style lang="sass" scoped>
.auth-pages
  background-color: #F3F3F3
.content
  width: fit-content
.customer-journey-wrapper
  height: calc(100vh - 35px)
  width: 100%
  overflow: scroll
  transform-origin: 0 0
  background-color: #F3F3F3
.customer-journey-wrapper::v-deep .topAuto
  top: auto !important
.customer-journey-wrapper::v-deep .relative
  position: relative
#app::v-deep .v-menu__content
  z-index: 9999 !important
</style>
