<template>
  <div
    v-if="
      col.type !== 'row-title' &&
      selectedPersonas.length === 0 &&
      arraysHasCommon(row.options, ['persona'])
    "
  >
    <div class="add-persona" @click="setPersonaDialog(true)">
      <div class="wrapper">
        <span>Dodaj personę</span>
        <PlusCircleIcon />
      </div>
    </div>
  </div>
</template>

<script>
import PlusCircleIcon from "@/components/icons/PlusCircleIcon.vue";
import { mapState, mapActions } from "vuex";
import utils from "@/plugins/utils.js";

export default {
  components: {
    PlusCircleIcon,
  },
  props: {
    col: Object,
    row: Object,
    columnId: Number,
    rowId: Number,
  },
  computed: {
    ...mapState("cjstore", ["selectedPersonas"]),
  },
  methods: {
    arraysHasCommon: utils.arraysHasCommon,
    ...mapActions("cjstore", ["setPersonaDialog"]),
  },
};
</script>

<style lang="sass" scoped>
.add-persona
  justify-content: center
  align-items: center
  display: flex
  opacity: 0
  transition: opacity .2s ease-out
.add-persona .wrapper
  display: flex
  align-items: center
  cursor: pointer
.add-persona .wrapper
  box-shadow: 0px 3px 8px rgba(70, 128, 255, 0.08)
  min-width: 150px
  padding: 7px 22px
  border-radius: 22px
  justify-content: space-between
  span
    font-size: 12px
    color: #8392AB
  svg
    width: 22px
    height: 22px
    margin-right: -15px
</style>
