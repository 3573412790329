<template>
    <v-card id="plan-emission-modal">
        <div class="plan-emission-close-btn" >
            <img @click="$emit('close')" src="@/assets/img/cross-circle.svg" />
        </div>
        <div class="plan-emission-content">
            <p class="plan-emission-title">Planowanie emisji ankiety</p>
            <p class="text-center text-color-primary font-weight-bold">Zaplanuj emisję ankiety</p>
            <p class="text-center text-color-gray text-size-1">Ankiety mogą być emitowane na wybranych przez Ciebie podstronach, lub wg Regex.</p>
            <label>Zakres czasu:</label>
            <div class="position-relative mb-3">
                <v-text-field
                    v-model="emissionDate"
                    :disabled="true"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    class="plan-emission-input mt-3"
                >
                </v-text-field>
                <img @click="showDatePicker=true" class="icon cursor-pointer" src="@/assets/img/icon_calendar.svg" />
                <div class="text-right text-color-red text-size-2" v-if="emissionDateErrorMessage">{{ emissionDateErrorMessage }}</div>
            </div>
            <label>emisja za pomocą:</label>
            <v-radio-group
                v-model="emissionLocationType"
            >
                <v-radio
                    class="trigger-radio-item"
                    label="Podstrona"
                    color="#2E1B75"
                    value="subpage"
                ></v-radio>
                    <v-radio
                    class="trigger-radio-item"
                    label="Regex"
                    color="#2E1B75"
                    value="regex"
                ></v-radio>
            </v-radio-group>
            <div class="mb-3 mt-0" v-if="emissionLocationType === 'subpage'" >
                <Dropdown 
                    :data="subpages"
                    @itemSelected="emissionLocationValue = $event"
                    :initialValue="`Wybierz podstronę`"
                    ref="dropdown"
                />
                <div class="text-right text-color-red text-size-2" v-if="emissionLocationValueErrorMessage">{{ emissionLocationValueErrorMessage }}</div>
            </div>
            <div class="mb-3" v-else>
                <v-text-field
                    hide-details
                    outlined
                    placeholder="Regex"
                    color="rgba(0,0,0,.6)"
                    light
                    class="plan-emission-input"
                    v-model="emissionLocationValue"
                >
                </v-text-field>
                <div class="text-right text-color-red text-size-2" v-if="emissionTypeErrorMessage">{{ emissionTypeErrorMessage }}</div>
            </div>
            <label>Trigger wg: </label>
            <div class="position-relative">
                <v-radio-group
                    v-model="triggerType"
                >
                    <v-radio
                        class="trigger-radio-item"
                        label="Czas na stronie"
                        color="#2E1B75"
                        value="time"
                    ></v-radio>
                    <v-radio
                        class="trigger-radio-item"
                        label="% Scrollowania"
                        color="#2E1B75"
                        value="scroll"
                    ></v-radio>
                    <v-radio
                        class="trigger-radio-item"
                        color="#2E1B75"
                        value="rage"
                    >
                        <template v-slot:label>
                            <div>Zdenerwowanie <br> (rage click)</div>
                        </template>
                    </v-radio>
                    <v-radio
                        class="trigger-radio-item"
                        color="#2E1B75"
                        value="rage_scroll"
                    >
                        <template v-slot:label>
                            <div>Nerwowy scroll</div>
                        </template>
                    </v-radio>
                    <v-radio
                        class="trigger-radio-item"
                        color="#2E1B75"
                        value="rage_browse"
                    >
                        <template v-slot:label>
                            <div>Nerwowe przekliki <br> (zmiany stron)</div>
                        </template>
                    </v-radio>
                    <v-radio
                        class="trigger-radio-item"
                        color="#2E1B75"
                        value="not_found"
                    >
                        <template v-slot:label>
                            <div>Nieznalezienie informacji</div>
                        </template>
                    </v-radio>
                    <v-radio
                        class="trigger-radio-item"
                        color="#2E1B75"
                        value="exit"
                    >
                        <template v-slot:label>
                            <div>Wyjście ze strony</div>
                        </template>
                    </v-radio>
                </v-radio-group>
            </div>
            <div class="position-relative mt-0" v-if="triggerType === 'time'">
                <v-text-field
                    v-model="triggerValue"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    placeholder="Czas na stronie"
                    class="plan-emission-input"
                >
                </v-text-field>
                <span class="unit">[s]</span>
                <img class="icon" src="@/assets/img/icon_stopwatch.svg" />
                <div class="text-right text-color-red text-size-2" v-if="triggerValueErrorMessage">{{ triggerValueErrorMessage }}</div>
            </div>
            <div class="position-relative mt-0" v-if="triggerType === 'scroll'">
                <v-text-field
                    v-model="triggerValue"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    placeholder="% Scrollowania"
                    class="plan-emission-input"
                >
                </v-text-field>
                <img class="icon" src="@/assets/img/icon_offer.svg" />
                <div class="text-right text-color-red text-size-2" v-if="triggerValueErrorMessage">{{ triggerValueErrorMessage }}</div>
            </div>
            <v-btn
                class="save-emission-btn"
                @click="save"
            >
                Zapisz emisję
            </v-btn>
        </div>
        <v-dialog
            v-model="showDatePicker"
            class="pr-0"
            max-width="fit-content"
        >
            <DatePickerModal
                max-width="fit-content"
                @close="showDatePicker=false"
                @datesSelected="setEmissionDate"
                :isOpened="showDatePicker"
                :initialDate="{ start: dateStart, end: dateEnd}"
                ref="datepicker"
                :futureEmissions="futureEmissions"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import DatePickerModal from "@/components/surveys/DatePickerModal.vue"
import Dropdown from "@/components/surveys/Dropdown.vue"
import { http } from "@/plugins/http";

export default {
    components: { DatePickerModal, Dropdown },
    props: {
        subpages: {},
        surveyId: {},
        isOpened: {},
        futureEmissions: {}
    },
    data() {
        return {
            showDatePicker: false,
            emissionLocationType: "subpage",
            triggerType: "time",
            emissionDate: null,
            dateStart: null,
            dateEnd: null,
            emissionDateErrorMessage: null,
            triggerValueErrorMessage: null,
            emissionTypeErrorMessage: null,
            emissionLocationValueErrorMessage: null,
            emissionLocationValue: "Wybierz podstronę",
            triggerValue: null
        }
    },
    methods: {
        formatDate(data) {
            const date = new Date(data);
            return [
                this.padTo2Digits(date.getDate()),
                this.padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('.');
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        setEmissionDate(dates) {
            this.emissionDate =  `${this.formatDate(dates[0])}-${this.formatDate(dates[1])}`
            this.checkTheOrder(dates);
            this.showDatePicker = false;
        },
        checkTheOrder(dates) {
            const date1 = new Date(dates[0]);
            const date2 = new Date(dates[1]);
            if(date1.getTime() === date2.getTime()) {
                this.dateStart = dates[1]
                this.dateEnd = dates[0]
            } else if (date1.getTime() > date2.getTime()) {
                this.dateStart = dates[1]
                this.dateEnd = dates[0]
            } else {
                this.dateStart = dates[0]
                this.dateEnd = dates[1]
            }
        },
        isValid() {
            this.resetValidation();
            let result = true;
            if(!this.emissionDate) {
                result = false;
                this.emissionDateErrorMessage = "Czas emisji ankiety jest wymagany"
            }
            if(this.emissionLocationType === "regex" && (this.emissionLocationValue==="" || !this.check_regex(this.emissionLocationValue))) {
                result = false;
                this.emissionTypeErrorMessage = "Nieprawidłowy regex"
            }
            if((this.triggerType === 'scroll' || this.triggerType === 'time') && !this.triggerValue) {
                result = false;
                this.triggerValueErrorMessage = "To pole nie może być puste"
            }
            if(this.emissionLocationValue === "Wybierz podstronę") {
                result = false;
                this.emissionLocationValueErrorMessage = "Podstrona jest wymagana"
            }
            return result;
        },
        check_regex(regex_to_test) {
            let success = false;
            if(regex_to_test !== "") {
                try {
                    new RegExp(`${regex_to_test}`)
                    success = true
                } catch (e) {
                    console.error(e)
                }
            }
            return success;
        },
        resetValidation() {
            this.emissionDateErrorMessage = null;
            this.emissionTypeErrorMessage = null;
            this.triggerValueErrorMessage = null;
            this.emissionLocationValueErrorMessage = null;
        },
        async save() {
            if(this.isValid()) {
                await http.post(`/survey_runs_binded_survey/${this.surveyId}`, {
                    date_start: this.dateStart,
                    date_end: this.dateEnd,
                    trigger: {
                        type: this.triggerType,
                        value: this.triggerValue
                    },
                    trigger_location: {
                        type: this.emissionLocationType,
                        value: this.emissionLocationValue
                    }
                })
                this.$emit('close');
            }   
        },
        clear() {
            this.emissionLocationValue = "Wybierz podstronę";
            this.triggerValue = null;
            this.emissionLocationType = "subpage";
            this.triggerType = "time";
            this.emissionDate = null;
            this.dateStart = null;
            this.dateEnd = null;
            this.$refs.dropdown.clear()
        }
    },
    watch: {
        isOpened(val) {
            if(!val) {
                this.clear();
            }
        },
        emissionLocationType(val, oldVal) {
            if(oldVal) {
                if(val === "subpage") {
                    this.emissionLocationValue = "Wybierz podstronę"
                } else {
                    this.emissionLocationValue = ""
                }
            }
        },
        triggerType(val, oldVal) {
            if(oldVal) {
                this.triggerValue = "";
            }
        }
    }
}
</script>