import Vue from 'vue'
import Router from 'vue-router'

// Layouts
import AuthLayout from "@/layouts/Auth";
import AdminLayout from "@/layouts/Admin";

//Components
import Dashboard from "@/views/Dashboard";
import Analytics from "@/views/Analytics";
import CustomerJourney from "@/views/CustomerJourney";
import Login from "@/views/auth/Login";
import LoginChooseSubscription from "@/views/auth/LoginChooseSubscription";
import Personas from "@/views/Personas";
import Surveys from "@/views/Surveys";
import Register from "@/views/auth/Register";
import ForgotPassword from "@/views/auth/ForgotPassword";
import ResetPassword from "@/views/auth/ResetPassword";
import GoogleCallback from "@/views/auth/callback/GoogleCallback";
import i18n from "@/i18n";
import Subscriptions from "@/components/Subscriptions";
import StartTrial from "@/components/StartTrial";

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/personas',
      name: 'home',
      component: AdminLayout,
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/analytics',
          name: 'Analytics',
          component: Analytics,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/surveys",
          name: "surveys",
          component: Surveys,
          meta: {
            requiresAuth: true,
            title: i18n.t("routes.surveys"),
          },
        },
        {
          path: "/personas",
          name: "personas",
          component: Personas,
          meta: {
            requiresAuth: true,
            title: i18n.t("routes.personas"),
          },
        },
      ],
    },
    {
      path: '/auth',
      redirect: '/auth/login',
      name: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/auth/login',
          name: 'Login',
          component: Login,
        },
        {
          path: '/auth/choose-subscription',
          name: 'Login',
          component: LoginChooseSubscription,
        },
        {
          path: '/auth/subscriptions',
          name: 'Subscriptions',
          component: Subscriptions,
        },
        {
          path: '/auth/start-trial',
          name: 'StartTrial',
          component: StartTrial,
        },
        {
          path: "/auth/register",
          name: "register",
          component: Register,
          meta: {
            title: i18n.t("routes.register"),
          },
        },
        {
          path: "/forgot-password",
          name: "forgot_password",
          component: ForgotPassword,
          meta: {
            title: i18n.t("routes.forgot_password"),
          },
        },
        {
          path: "/reset-password/:token",
          name: "reset_password",
          component: ResetPassword,
          props: true,
          meta: {
            title: i18n.t("routes.reset_password"),
          },
        },
        {
          path: "/auth/callback/google",
          name: "callback_google",
          component: GoogleCallback,
        },
      ]
    },
    {
      path: "/customer_journey/:id",
      name: "customer_journey",
      component: CustomerJourney,
      meta: {
        requiresAuth: true,
        title: i18n.t("routes.customer_journey"),
      },
    },
    {
    path: '*',
    redirect: '/'
    }
  ]
})


// authentication service
import store from "@/store";
//Auth.checkAuthenticated()

router.beforeEach((to, from, next) => {
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth && !store.getters.isLoggedIn) {
    console.log("NOT LOGGED IN")
    console.log(requiresAuth, store.getters.isLoggedIn)
    // if route requires auth and user isn't authenticated
    // console.log(this.a.app.$store)
    // console.log(to.matched[0])
    store.state.redirect = to.path;
    next('/auth/login')
  }
  next()
})

export default router