<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.75 9C4.75 8.58579 4.41421 8.25 4 8.25C3.58579 8.25 3.25 8.58579 3.25 9H4.75ZM4 15H3.25C3.25 15.4142 3.58579 15.75 4 15.75V15ZM10 15.75C10.4142 15.75 10.75 15.4142 10.75 15C10.75 14.5858 10.4142 14.25 10 14.25V15.75ZM20.5294 15.6283C20.6987 16.0063 21.1424 16.1756 21.5205 16.0063C21.8985 15.837 22.0677 15.3933 21.8984 15.0152L20.5294 15.6283ZM17.3539 11.1234L17.7167 10.467L17.3539 11.1234ZM11.7453 10.0878L11.8498 10.8305L11.7453 10.0878ZM3.25 9V15H4.75V9H3.25ZM4 15.75H10V14.25H4V15.75ZM21.8984 15.0152C21.0344 13.086 19.5668 11.4897 17.7167 10.467L16.991 11.7798C18.5565 12.6452 19.7984 13.9959 20.5294 15.6283L21.8984 15.0152ZM17.7167 10.467C15.8666 9.44435 13.7342 9.0506 11.6408 9.3451L11.8498 10.8305C13.6212 10.5813 15.4255 10.9145 16.991 11.7798L17.7167 10.467ZM11.6408 9.3451C8.0935 9.84416 5.73416 12.4361 3.49909 14.4418L4.50091 15.5582C6.92072 13.3867 8.87224 11.2494 11.8498 10.8305L11.6408 9.3451Z"
      :fill="disabled ? '#8392AB' : '#001A72'"
    />
  </svg>
</template>
<script>
export default {
  props: {
    disabled: Boolean,
  },
};
</script>
