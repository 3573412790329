<template>
    <v-card class="modal-step">
        <div class="justify-space-between modal-top d-flex"> 
            <v-btn
                :ripple="false"
                :elevation="0"
                class="goBack-button ml-1 mt-1"
                @click="prev"
                icon
            >
                <img src="@/assets/img/chevron-circle-left.svg" />
            </v-btn>
            <v-btn
                :ripple="false"
                :elevation="0"
                class="mr-1 mt-1 close-button"
                @click="close"
                icon
            >
                <img src="@/assets/img/cross-circle.svg" />
            </v-btn>
        </div>
        <div>
            <v-row class="text-center">
                <v-col cols="5" class="mx-auto">
                <h5 class="mb-2 modal-title">
                    Tworzenie nowej ścieżki klienta
                </h5>
                <p class="modal-subtitle">
                    krok 3/3: wybierz szablon ścieżki klienta
                </p>
                </v-col>
            </v-row>
            <v-row class="mt-2 mx-auto modal-cards">
                <template v-for="(template, id) in customerJourneyTemplates" >
                    <v-card
                        :key="`template_journey_${id}`"
                        class="modal-card"
                        :class="template.name === selectedCard.name ? 'card-clicked' : ''"
                        @click="selectCard(template)"
                    >
                        <template slot="progress">
                            <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                            ></v-progress-linear>
                        </template>
                        <v-img
                            height="112"
                            :src="template.image"
                            class="card-image"
                        ></v-img>
                        
                        <v-card-title class="card-title pt-0 mb-1 px-0">
                            {{template.name}}
                        </v-card-title>
                        <v-card-text class="px-0">
                            <div class="card-subtitle mb-1">{{template.description}}</div>
                        </v-card-text>
                    </v-card>
                </template>
            </v-row>
        </div>
        <v-row class="mt-2">
            <v-col cols="5" class="mx-auto">
                <v-btn
                    :ripple="false"
                    :elevation="0"
                    class="
                        py-5
                        px-6
                        me-2
                        next-button
                    "
                    @click="finish"
                    :disabled="!selectedCard.name"
                >
                    DO ŚCIEŻKI
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { http } from "@/plugins/http";

export default {
    data() {
        return {
            customerJourneyTemplates: [
            ],
            selectedCard: {
                name: null,
                description: null,
                image: null
            }
        };
    },
    mounted(){
        this.getCustomerJourneyTemplates();
    },
    methods: {
        async getCustomerJourneyTemplates(){
            const response = await http.get(`/customer_journeys`, {params:{template: 1}});
            this.customerJourneyTemplates = [...response.data.data];
            this.customerJourneyTemplates = this.customerJourneyTemplates.map(template => {
                let templateAvatars = JSON.parse(localStorage.getItem('journey_template_avatars'));
                if(templateAvatars){
                    let avatars = templateAvatars.filter(item => item.templateId == template.id);
                    template['image'] = avatars.length > 0
                    ? require(`@/assets/img/customer-journey/avatars/avatar_${avatars[0].avatar}.jpg`)
                    : require(`@/assets/img/customer-journey/avatars/avatar_1.jpg`)
                }else{
                    template['image'] = require(`@/assets/img/customer-journey/avatars/avatar_1.jpg`)
                }
                return template;
            })
        },
        prev() {
            this.$emit("changeStep", 2)
        },
        finish() {
            this.$emit("finish")
        },
        close() {
            this.$emit("close")
        },
        selectCard(card) {
            this.selectedCard = card;
            this.$emit("cardSelected", card)
        }
    },
}
</script>