<template>
  <div class="notifications-wrapper mr-5">
    <v-menu v-model="showPanel" offset-y attach right>
      <template v-slot:activator="{ on, attrs }">
        <div class="notification-bells">
          <img
            v-if="!notification"
            v-bind="attrs"
            v-on="on"
            :src="require('@/assets/img/icon_bell.svg')"
          />
          <img
            v-else
            v-bind="attrs"
            v-on="on"
            :src="require('@/assets/img/icon_bell_new_notification.svg')"
          />
        </div>
      </template>
      <div class="notifications-panel">
        <h6>Powiadomienia</h6>
        <div class="notifications">
          <template v-if="notifications.length > 0">
            <template v-for="item in notifications">
              <div
                :key="`notification_${item.id}`"
                class="notification"
                @click="updateNotification(item.id)"
                :class="item.view_status ? 'viewed' : 'new'"
              >
                <div
                  class="icon"
                  :class="item.content_type ? item.content_type : 'info'"
                >
                  <component :is="item.icon ? item.icon : item.content_type" />
                </div>
                <div class="content">
                  <div class="label">{{ item.content }}</div>
                  <div class="data">{{ parseDate(item.insert_ts) }}</div>
                </div>
                <div class="actions"></div>
              </div>
            </template>
            <div class="notification" @click="clearNotifications()">
              Oznacz jako przeczytane
            </div>
          </template>
          <template v-else>
            <div class="notification not-clickable">
              Brak nowych powiadomień
            </div>
          </template>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
import up from "@/components/icons/notifications/up.vue";
import down from "@/components/icons/notifications/down.vue";
import mail from "@/components/icons/notifications/mail.vue";
import { http } from "@/plugins/http.js";

export default {
  components: {
    up,
    down,
    mail,
  },
  data() {
    return {
      showPanel: false,
      interval: null,
      timeoutHints: null,
      notifications: [],
    };
  },
  computed: {
    ...mapState("notifications", ["notificationMenu"]),
    notification() {
      return (
        this.notifications.filter((item) => item.view_status == false).length >
        0
      );
    },
  },
  watch: {
    notification(newVar) {
      if (newVar) {
        this.timeoutHints = setTimeout(() => {
          this.$store.dispatch("hints/setHint", {
            visible: true,
            category: "notifications",
          });
        }, 5000);
      }
    },
    notificationMenu(newVal) {
      this.showPanel = newVal;
    },
    showPanel(newVal) {
      this.$store.dispatch("notifications/setNotificationMenu", newVal);
    },
  },
  mounted() {
    this.getNotifications();
    this.interval = setInterval(() => {
      this.getNotifications();
    }, 30000);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    getNotifications() {
      http.get("/notifications").then((response) => {
        this.notifications = response.data.data;
      });
    },
    updateNotification(id) {
      http
        .patch(`/notification/${id}`, {
          view_status: true,
        })
        .then(() => {
          this.getNotifications();
        });
    },
    clearNotifications() {
      http.get("clear_unreaded_notifications").then(() => {
        this.getNotifications();
        localStorage.removeItem("hint_notifications");
      });
    },
    parseDate(date) {
      var toParse = date + "Z";
      return new Date(toParse).toLocaleString();
    },
  },
};
</script>

<style lang="sass" scoped>
.notifications-wrapper
  position: relative
  display: flex
  justify-content: flex-end
.notification-bells
  height: 24px
  width: 24px
  display: flex
  align-items: center
  img
    cursor: pointer
.notifications-panel
  text-align: left
  display: flex
  width: 500px
  background: #FFFFFF
  box-shadow: 0px 2px 30px rgba(46, 27, 117, 0.05)
  border-radius: 8px
  padding: 22px 22px 0 22px
  flex-direction: column
  max-height: 80vh
  h6
    padding-bottom: 22px
    font-size: 14px
    font-weight: 400
.notification
  display: flex
  margin-bottom: 22px
  cursor: pointer
  .icon
    margin-right: 22px
    height: 35px
    width: 35px
    border-radius: 35px
    display: flex
    justify-content: center
    align-items: center
    background-color: transparent !important
    border-width: 1px
    border-style: solid
    &.success
      border-color: #49F074 !important
    &.error
      border-color: #F0004A !important
    svg
      width: 12px
      height: 12px
  .content .label
    font-size: 14px !important
    margin-bottom: 5px
    color: $primary-color
    line-height: 16px
    font-weight: 600
  .content .data
    font-size: 12px
    font-weight: 600
    color: #8392AB
.notification.viewed .content .label,
.notification.viewed .content .data
  font-weight: 400 !important
.icon.info::v-deep svg path
  fill: #2196f3
  stroke: #2196f3
.icon.success::v-deep svg path
  fill: #49F074
  stroke: #49F074
.icon.error::v-deep svg path
  fill: #F0004A
  stroke: #F0004A
.icon.warning::v-deep svg path
  fill: #FFB145
  stroke: #FFB145

.notifications-wrapper::v-deep .v-menu__content
  left: auto !important
  z-index: 8 !important
  top: 33px !important
  right: calc(100% - 24px) !important
.not-clickable
  cursor: auto
</style>
