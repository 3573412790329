import Vue from "vue";
import { http } from "@/plugins/http";

const ADD_ROW = "ADD_ROW";
const REMOVE_ROW = "REMOVE_ROW";
const SET_ROWS = "SET_ROWS";
const ADD_COLUMN = "ADD_COLUMN";
const REMOVE_COLUMN = "REMOVE_COLUMN";
const ADD_CARD = "ADD_CARD";
const REMOVE_CARD = "REMOVE_CARD";
const SET_EDITDATA = "SET_EDITDATA";
const UNSET_EDITDATA = "UNSET_EDITDATA";
const OPEN_CARD_DIALOG = "OPEN_CARD_DIALOG";
const CLOSE_CARD_DIALOG = "CLOSE_CARD_DIALOG";
const SET_ROW_TITLE = "SET_ROW_TITLE";
const SET_EDIT_ROW_ID = "SET_EDIT_ROW_ID";
const SET_EDIT_COL_ID = "SET_EDIT_COL_ID";
const SET_EDIT_CARD_TITLE = "SET_EDIT_CARD_TITLE";
const SET_EDIT_CARD_DESC = "SET_EDIT_CARD_DESC";
const SET_ICON_SELECTED = "SET_ICON_SELECTED";
const SET_SHAPE_SELECTED = "SET_SHAPE_SELECTED";
const SET_COLOR_SELECTED = "SET_COLOR_SELECTED";
const SET_PICTURE_UPLOADED = "SET_PICTURE_UPLOADED";
const SET_PICTURE_SAVED = "SET_PICTURE_SAVED";
const SET_PICKERS_DIALOG = "SET_PICKERS_DIALOG";
const SET_REMOVE_CARD_DIALOG = "SET_REMOVE_CARD_DIALOG";
const SET_SELECTED_PERSONAS = "SET_SELECTED_PERSONAS";
const SET_SELECTED_PERSONAS_OBJS = "SET_SELECTED_PERSONAS_OBJS";
const SET_PERSONAS = "SET_PERSONAS";
const SET_PERSONA_DIALOG = "SET_PERSONA_DIALOG";
const SELECT_CARD = "SELECT_CARD";
const UNSELECT_CARD = "UNSELECT_CARD";
const TOGGLE_CHANNEL = "TOGGLE_CHANNEL";
const SET_LABEL_INPUT = "SET_LABEL_INPUT";
const SET_CURRENT_PICKER = "SET_CURRENT_PICKER";
const SET_CHART_KEY = "SET_CHART_KEY";
const SET_CHART_DATA = "SET_CHART_DATA";
const SET_CHART_COLUMN_VALUES = "SET_CHART_COLUMN_VALUES";
const CHANGE_CHART_VALUES = "CHANGE_CHART_VALUES";
const SET_CUSTOMER_JOURNEY_ID = "SET_CUSTOMER_JOURNEY_ID";
const SET_CUSTOMER_JOURNEY_NAME = "SET_CUSTOMER_JOURNEY_NAME";
const SET_PARENTELEMENTITEM_ID = "SET_PARENTELEMENTITEM_ID";
const SET_ROWELEMENT_ID = "SET_ROWELEMENT_ID";
const SET_PREVDRAGPARENT = "SET_PREVDRAGPARENT";
const SET_DRAGENTERED = "SET_DRAGENTERED";
const SET_PREVDRAGPARENT_CHILDREN = "SET_PREVDRAGPARENT_CHILDREN";
const SET_ISCARDDRAGGED = "SET_ISCARDDRAGGED";
const SET_DRAGGEDROWID = "SET_DRAGGEDROWID";
const SET_DRAGROWID = "SET_DRAGROWID";
const SET_DRAGCOLID = "SET_DRAGCOLID";
const SET_CARDDROP = "SET_CARDDROP";
const SET_ISROWDRAGGED = "SET_ISROWDRAGGED";
const SET_ROWDROP = "SET_ROWDROP";
const RESET_STATE = "RESET_STATE";
const SET_ZOOM = "SET_ZOOM";
const SET_HISTORY_STEP = "SET_HISTORY_STEP";

const getDefaultState = () => {
  return {
    pickersLabels: {
      shape: "Wybierz kształt",
      icon: "Wybierz ikonę",
      color: "Wybierz kolor",
    },
    channels: [
      {
        label: "Email",
        icon: "at",
        visible: true,
      },
      {
        label: "Strona internetowa",
        icon: "laptop",
        visible: true,
      },
      {
        label: "Aplikacja",
        icon: "application",
        visible: true,
      },
      {
        label: "Telefon",
        icon: "phone",
        visible: true,
      },
      {
        label: "SMS",
        icon: "sms",
        visible: true,
      },
      {
        label: "Chat",
        icon: "chat",
        visible: true,
      },
    ],
    shapes: ["Prostokąt", "Dymek", "Grot"],
    colors: [
      {
        backgroundColor: "#fff",
        textColor: "#2E1B75",
      },
      {
        backgroundColor: "#FFB145",
        textColor: "#fff",
      },
      {
        backgroundColor: "#EB5757",
        textColor: "#fff",
      },
      {
        backgroundColor: "#333333",
        textColor: "#fff",
      },
      {
        backgroundColor: "#219653",
        textColor: "#fff",
      },
      {
        backgroundColor: "#6FCF97",
        textColor: "#2E1B75",
      },
      {
        backgroundColor: "#2F80ED",
        textColor: "#fff",
      },
      {
        backgroundColor: "#56CCF2",
        textColor: "#2E1B75",
      },
      {
        backgroundColor: "#EF5DA8",
        textColor: "#fff",
      },
      {
        backgroundColor: "#FCDDEC",
        textColor: "#2E1B75",
      },
      {
        backgroundColor: "#A5A6F6",
        textColor: "#2E1B75",
      },
      {
        backgroundColor: "#2E1B75",
        textColor: "#fff",
      },
    ],
    cardDialog: false,
    pickersDialog: false,
    personaDialog: false,
    removeCardDialog: false,
    customerJourneyId: 0,
    customerJourneyName: "",
    currentPicker: "",
    personas: [],
    selectedPersonas: [],
    selectedPersonasObjs: [],
    chartKey: 0,
    chartData: {
      labels: [],
      datasets: null,
    },
    rowElementId: null,
    parentElementId: null,
    removeRowId: null,
    removeColumnId: null,
    editRowId: null,
    editColumnId: null,
    editCardTitle: null,
    editCardDesc: null,
    shapeSelected: null,
    iconSelected: null,
    colorSelected: null,
    pictureUploaded: null,
    pictureSaved: null,
    prevDragParent: null,
    dragEntered: false,
    isCardDragged: false,
    draggedRowId: null,
    draggedItem: null,
    dragRowId: null,
    dragColId: null,
    isRowDragged: false,
    rows: [
      {
        type: "stage",
        title: "Etap",
        ref: "stage",
        options: ["nondrag", "title", "stage", "non-removal"],
        cols: [
          {
            type: "row-title",
            title: "Etap",
          },
          {
            type: "",
            title: "Etap 1",
          },
          {
            type: "",
            title: "Etap 2",
          },
          {
            type: "",
            title: "Etap 3",
          },
        ],
      },
      {
        type: "card",
        title: "Podetap",
        ref: "stage",
        options: ["substage", "nondrag", "non-removal"],
        cols: [
          {
            type: "row-title",
            title: "Podetap",
          },
          {
            type: "",
            title: "",
          },
          {
            type: "",
            title: "",
          },
          {
            type: "",
            title: "",
          },
        ],
      },
      {
        type: "persona",
        title: "Persony",
        options: ["persona", "nondrag", "non-removal"],
        cols: [
          {
            type: "row-title",
            title: "Persony",
          },
          {
            type: "",
            title: "",
          },
          {
            type: "",
            title: "",
          },
          {
            type: "",
            title: "",
          },
        ],
      },
      {
        type: "graph",
        title: "Sentyment",
        options: [],
        cols: [
          {
            type: "row-title",
            title: "Sentyment",
          },
          {
            values: [],
          },
          {
            values: [],
          },
          {
            values: [],
          },
        ],
      },
      {
        type: "communication",
        title: "Kanały komunikacji",
        options: ["non-removal"],
        cols: [
          {
            type: "row-title",
            title: "Kanały komunikacji",
          },
          {
            type: "",
            title: "",
            channels: [],
          },
          {
            type: "",
            title: "",
            channels: [],
          },
          {
            type: "",
            title: "",
            channels: [],
          },
        ],
      },
    ],
    zoom: 1,
    isHistory: false,
    historyStep: null,
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    setSelectedPersonas({commit}, payload) {
      commit(SET_SELECTED_PERSONAS, payload)
    },
    async addRow({ commit, dispatch }, payload) {
      await dispatch("saveHistory");
      commit(ADD_ROW, payload);
    },
    async removeRow({ commit, dispatch }, payload) {
      await dispatch("saveHistory");
      commit(REMOVE_ROW, payload);
    },
    setRows({ commit }, payload) {
      commit(SET_ROWS, payload);
    },
    async saveRowData({ dispatch, state }) {
      if (state.rowElementId) {
        return await dispatch("updateRows");
      } else {
        return await dispatch("postRows");
      }
    },
    async updateRows({ state }) {
      return await http.patch(
        `/customer_journey_element/${state.rowElementId}`,
        {
          parent_element_id: state.rowElementId,
          customer_journey_element_type: "row",
          title: "rows",
          content: encodeURIComponent(JSON.stringify(state.rows)),
          color: "none",
          image: "none",
          params: "none",
        }
      );
    },
    async postRows({ commit, state }, id) {
      var elementId = id ? id : state.customerJourneyId;
      var response = await http.post(
        `/customer_journey_elements/${elementId}`,
        {
          customer_journey_element_type: "row",
          title: "rows",
          content: JSON.stringify(state.rows),
          color: "none",
          image: "none",
          params: "none",
        }
      );
      if (response.status === 200) {
        //mutation
        commit(SET_ROWELEMENT_ID, response.data.id);
      }
      return response;
    },
    async addColumn({ commit, dispatch }, payload) {
      await dispatch("saveHistory");
      commit(ADD_COLUMN, payload);
    },
    async removeColumn({ commit, dispatch }, payload) {
      await dispatch("saveHistory");
      commit(REMOVE_COLUMN, payload);
    },
    async addCard({ commit, dispatch, state }) {
      await dispatch("saveHistory");
      if (
        !state.editCardTitle &&
        !state.editCardDesc &&
        !state.shapeSelected &&
        !state.colorSelected &&
        !state.iconSelected &&
        !state.pictureSaved
      ) {
        commit(REMOVE_CARD, {
          removeRowId: state.editRowId,
          removeColumnId: state.editColumnId,
        });
      } else {
        commit(ADD_CARD);
      }
    },
    async removeCard({ commit, dispatch }, payload) {
      await dispatch("saveHistory");
      commit(REMOVE_CARD, payload);
    },
    openCardDialog({ commit }, payload) {
      commit(OPEN_CARD_DIALOG, payload);
    },
    closeCardDialog({ commit }) {
      commit(CLOSE_CARD_DIALOG);
    },
    setRowTitle({ commit }, payload) {
      commit(SET_ROW_TITLE, payload);
    },
    setCardDialog({ dispatch }, payload) {
      if (payload) {
        dispatch("openCardDialog", payload);
      } else {
        dispatch("closeCardDialog");
      }
    },
    setEditRowId({ commit }, payload) {
      commit(SET_EDIT_ROW_ID, payload);
    },
    setEditColId({ commit }, payload) {
      commit(SET_EDIT_COL_ID, payload);
    },
    setEditCardTitle({ commit }, payload) {
      commit(SET_EDIT_CARD_TITLE, payload);
    },
    setEditCardDesc({ commit }, payload) {
      commit(SET_EDIT_CARD_DESC, payload);
    },
    setIconSelected({ commit, state }, payload) {
      let toCommit = payload;

      if (state.iconSelected == payload) {
        toCommit = null;
      }

      commit(SET_ICON_SELECTED, toCommit);
    },
    setShapeSelected({ commit, state }, payload) {
      let toCommit = payload;

      if (state.shapeSelected == payload) {
        toCommit = null;
      }
      commit(SET_SHAPE_SELECTED, toCommit);
    },
    setColorSelected({ commit, state }, payload) {
      let toCommit = payload;

      if (state.colorSelected == payload) {
        toCommit = null;
      }

      commit(SET_COLOR_SELECTED, toCommit);
    },
    setPictureUploaded({ commit }, payload) {
      commit(SET_PICTURE_UPLOADED, payload);
    },
    setPictureSaved({ commit }, payload) {
      commit(SET_PICTURE_SAVED, payload);
    },
    async saveCardPicture({ state, dispatch }, payload) {
      if (state.pictureUploaded) {
        let formData = new FormData();
        let name = state.pictureUploaded.name.split(".")[0];
        formData.append(name, state.pictureUploaded);
        const response = await http.post(
          `/customer_journey_source_files/${payload}`,
          formData
        );
        if (response.status == 200) {
          await dispatch("setPictureSaved", response.data.image_data);
        }
      } else {
        await dispatch("setPictureSaved", null);
      }
    },
    setPickersDialog({ commit }, payload) {
      commit(SET_PICKERS_DIALOG, payload);
    },
    setRemoveCardDialog({ commit }, payload) {
      commit(SET_REMOVE_CARD_DIALOG, payload);
    },
    setEditData({ commit }, payload) {
      commit(SET_EDITDATA, payload);
    },
    unsetEditData({ commit }) {
      commit(UNSET_EDITDATA);
    },
    setCurrentPicker({ commit }, picker) {
      commit(SET_CURRENT_PICKER, picker);
    },
    selectCard({ commit }, payload) {
      commit(SELECT_CARD, payload);
    },
    unselectCard({ commit }, payload) {
      commit(UNSELECT_CARD, payload);
    },
    async toggleChannel({ commit, dispatch }, payload) {
      await dispatch("saveHistory");
      commit(TOGGLE_CHANNEL, payload);
    },
    async setLabelInput({ commit, dispatch }, payload) {
      await dispatch("saveHistory");
      commit(SET_LABEL_INPUT, payload);
    },
    setParentElementItemId({ commit }, id) {
      commit(SET_PARENTELEMENTITEM_ID, id);
    },
    setChartKey({ commit }, payload) {
      commit(SET_CHART_KEY, payload);
    },
    setChartData({ commit }, payload) {
      commit(SET_CHART_DATA, payload);
    },
    setChartColumnValues({ commit }, payload) {
      commit(SET_CHART_COLUMN_VALUES, payload);
    },
    parseChartValues({ state, dispatch }, selectedPersonasObjs) {
      var rowId;
      let charts = state.rows.filter((row, id) => {
        if (row.type == "graph") {
          rowId = id;
          return row;
        }
      })[0];
      let chartCols = charts.cols.filter((col) => col.type !== "row-title");
      chartCols.forEach((col, id) => {
        // if(col.values.length == 0){
        // setup values for the chart rows
        let values = selectedPersonasObjs.map((persona, idx) => {
          return {
            personaId: persona.id,
            value: state.rows[rowId].cols[id + 1].values[idx]
              ? state.rows[rowId].cols[id + 1].values[idx].value
              : 2,
          };
        });
        dispatch("setChartColumnValues", {
          rowId: rowId,
          columnId: id + 1,
          values: values,
        });
        // }
      });
      dispatch("renderChart", selectedPersonasObjs);
    },
    async changeChartValues({ commit, state, dispatch }, payload) {
      commit(CHANGE_CHART_VALUES, payload);
      await dispatch("saveRowData");
      await dispatch("renderChart", state.selectedPersonasObjs);
    },
    renderChart({ dispatch, state }, selectedPersonas) {
      let datasets = selectedPersonas.map((persona) => {
        var personaValues = [];
        state.rows.map((row) => {
          if (row.type == "graph") {
            personaValues = row.cols
              .filter((col) => col.type !== "row-title")
              .map((item) =>
                item.values.filter((value) => value.personaId == persona.id)
              )
              .map((item) => {
                return {
                  personaId: persona.id,
                  value: item[0] ? item[0].value : 2,
                };
              });
          }
        });
        return {
          personaId: persona.id,
          imageId: persona.basic_info.image_id,
          backgroundColor: persona.basic_info.color,
          borderColor: persona.basic_info.color,
          pointHitRadius: 25,
          data: personaValues.map((item) => item.value),
          pointStyle: personaValues.map((item) => {
            var image = new Image(20, 20);
            image.src = require(`@/components/customerJourney/faces/face-${item.value}.svg`);
            return image;
          }),
        };
      });
      state.rows.forEach((row) => {
        if (row.type == "stage") {
          let labels = row.cols
            .filter((col) => col.type !== "row-title")
            .map((col) => col.title);
          dispatch("setChartData", { labels: labels });
        }
      });
      let chartKey = (Math.random() + 1).toString(36).substring(7);
      dispatch("setChartData", { datasets: datasets });
      dispatch("setChartKey", chartKey);
    },
    async getCustomerJourney({ commit, state }) {
      return await http
        .get(`/customer_journey/${state.customerJourneyId}`)
        .then((response) => {
          let data = response.data.data;
          commit(SET_CUSTOMER_JOURNEY_NAME, data.name);
          commit(SET_SELECTED_PERSONAS, data.personas);
          commit(SET_SELECTED_PERSONAS_OBJS, data.personas);
        });
    },
    setSelectedPersonasObjs({ commit }, payload) {
      commit("SET_SELECTED_PERSONAS_OBJS", payload);
    },
    async getPersonas({ commit }) {
      return await http.get("/personas").then((response) => {
        commit("SET_PERSONAS", response.data.data);
      });
    },
    setPersonaDialog({ commit }, payload) {
      commit(SET_PERSONA_DIALOG, payload);
    },
    setCustomerJourneyId({ commit }, id) {
      commit(SET_CUSTOMER_JOURNEY_ID, id);
    },
    setRowElementId({ commit }, id) {
      commit(SET_ROWELEMENT_ID, id);
    },
    parseCardElemClasses({ state, dispatch }) {
      new Promise((resolve) => {
        document.querySelectorAll(".placeholder").forEach((item) => {
          item.style.zIndex = "-1";
          item.classList.remove("dragenter", "card");
        });
        resolve();
      })
        .then(() => {
          document.querySelectorAll(".card").forEach((item) => {
            item.style.zIndex = "10";
          });
        })
        .then(() => {
          document.querySelectorAll(".channel-radios").forEach((item) => {
            item.style.zIndex = "10";
          });
        })
        .then(() => {
          if (
            state.prevDragParent &&
            !state.prevDragParent.children[0].classList.contains("card") &&
            !state.prevDragParent.children[0].classList.contains(
              "channel-radios"
            )
          ) {
            dispatch("setPrevDragParentChildren", "-1");
          }
        })
        .then(() => {
          dispatch("setPrevDragParent", null);
        });
    },
    async cardDragStart({ dispatch }, payload) {
      dispatch("setIsCardDragged", true);
      dispatch("setDragRowId", payload.rowId);
      dispatch("setDragColId", payload.columnId);
    },
    cardDragEnter({ state }, payload) {
      // event.preventDefault();
      if (
        payload.element.classList.contains("placeholder") &&
        state.isCardDragged
      ) {
        payload.element.classList.add("dragenter", "card");
      }
    },
    cardDragLeave({ state }, event) {
      if (
        event.currentTarget.classList.contains("placeholder") &&
        state.isCardDragged
      ) {
        event.currentTarget.classList.remove("dragenter", "card");
      }
    },
    cardDragEnd({ dispatch }) {
      dispatch("setIsCardDragged", false);
      dispatch("setDragRowId", null);
      dispatch("setDragColId", null);
    },
    async cardDrop({ state, commit, dispatch }, payload) {
      await dispatch("saveHistory");
      dispatch("setIsCardDragged", false);
      var dropTargetClone = Object.assign(
        {},
        state.rows[payload.rowId].cols[payload.columnId]
      );
      var dragClone = Object.assign(
        {},
        state.rows[state.dragRowId].cols[state.dragColId]
      );

      commit(SET_CARDDROP, {
        rowId: payload.rowId,
        columnId: payload.columnId,
        dropTargetClone: dropTargetClone,
        dragClone: dragClone,
      });

      dispatch("setDragRowId", null);
      dispatch("setDragColId", null);
      dispatch("parseCardElemClasses");
    },
    setPrevDragParent({ commit }, payload) {
      commit(SET_PREVDRAGPARENT, payload);
    },
    setDragEntered({ commit }, payload) {
      commit(SET_DRAGENTERED, payload);
    },
    setPrevDragParentChildren({ commit }, payload) {
      commit(SET_PREVDRAGPARENT_CHILDREN, payload);
    },
    setIsCardDragged({ commit }, payload) {
      commit(SET_ISCARDDRAGGED, payload);
    },
    setDragRowId({ commit }, payload) {
      commit(SET_DRAGROWID, payload);
    },
    setDragColId({ commit }, payload) {
      commit(SET_DRAGCOLID, payload);
    },
    rowDragStart({ commit }, payload) {
      commit(SET_ISROWDRAGGED, true);
      commit(SET_DRAGGEDROWID, payload);
    },
    rowDragLeave({ state }, payload) {
      if (state.isRowDragged) {
        let placeholder = payload.element
          .closest(".row")
          .querySelector(".row-placeholder");
        placeholder.classList.remove("row-dragenter");
      }
    },
    rowDragEnd({ commit }) {
      commit(SET_ISROWDRAGGED, false);
    },
    rowDragEnter({ state }, payload) {
      if (state.isRowDragged) {
        let placeholder = payload.element
          .closest(".row")
          .querySelector(".row-placeholder");
        placeholder.classList.add("row-dragenter");
      }
    },
    async rowDrop({ commit, state, dispatch }, rowId) {
      await dispatch("saveHistory");
      if (state.draggedRowId) {
        var dropTargetClone = Object.assign({}, state.rows[rowId]);
        var dragClone = Object.assign({}, state.rows[state.draggedRowId]);

        commit(SET_ROWDROP, {
          rowId: rowId,
          draggedRowId: state.draggedRowId,
          dropTargetClone: dropTargetClone,
          dragClone: dragClone,
        });

        commit(SET_ISROWDRAGGED, false);
        commit(SET_DRAGGEDROWID, null);
        dispatch("parseCardElemClasses");
      }
    },
    setIsRowDragged({ commit }, payload) {
      commit(SET_ISROWDRAGGED, payload);
    },
    resetState({ commit }) {
      commit(RESET_STATE);
    },
    setZoom({ commit }, payload) {
      commit(SET_ZOOM, payload);
    },
    saveHistory({ state, dispatch }) {
      var history = localStorage.getItem("cj_history");
      var steps = history ? JSON.parse(history) : [];
      if (
        state.historyStep !== null &&
        steps.length > 0 &&
        state.historyStep < steps.length - 1
      ) {
        let stepsToDelete = steps.length - state.historyStep;
        steps.splice(state.historyStep, stepsToDelete);
      }
      steps.push(state.rows);
      dispatch("setHistoryStep", steps.length - 1);
      localStorage.setItem("cj_history", JSON.stringify(steps));
    },
    clearHistory() {
      localStorage.removeItem("cj_history");
    },
    async undo({ dispatch, state }) {
      var steps = JSON.parse(localStorage.getItem("cj_history"));
      var index =
        state.historyStep !== null ? state.historyStep : steps.length - 1;
      var rows = steps[index];
      await dispatch("setRows", rows);
      await dispatch("setHistoryStep", index - 1 < 0 ? null : index - 1);
      if (index == 0) {
        localStorage.setItem("cj_history", JSON.stringify([]));
      }
    },
    setHistoryStep({ commit }, payload) {
      commit(SET_HISTORY_STEP, payload);
    },
  },
  mutations: {
    [ADD_ROW](state, rowId) {
      state.rows.forEach((row, id) => {
        if (rowId == id) {
          var placeholder = {
            type: "",
            title: "Tytuł",
            options: [],
            cols: [],
          };
          row.cols.forEach((col, idx) => {
            var newColumn = {
              type: "",
              title: "Tytuł",
            };
            if (idx == 0) {
              newColumn.type = "row-title";
            }
            placeholder.cols.push(newColumn);
          });
          state.rows.splice(rowId + 1, 0, placeholder);
        }
      });
    },
    [REMOVE_ROW](state, rowId) {
      state.rows.splice(rowId, 1);
    },
    [SET_ROWS](state, payload) {
      state.rows = payload;
    },
    [ADD_COLUMN](state, columnId) {
      state.rows.forEach((row, id) => {
        row.cols.forEach((col, idx) => {
          if (columnId == idx) {
            var newColumn = {};
            if (row.type == "graph") {
              newColumn = {
                values: state.selectedPersonas.map((personaId) => {
                  return {
                    personaId: personaId,
                    value: 2,
                  };
                }),
              };
            } else if (row.type == "graph") {
              newColumn = {
                type: "",
                title: "",
              };
            } else {
              newColumn = {
                type: "",
                title: "",
                channels: [],
              };
            }
            state.rows[id].cols.splice(columnId + 1, 0, newColumn);
          }
        });
      });
    },
    [REMOVE_COLUMN](state, columnId) {
      state.rows.forEach((row) => {
        row.cols.splice(columnId, 1);
      });
    },
    [ADD_CARD](state) {
      state.rows[state.editRowId].cols[state.editColumnId].title =
        state.editCardTitle;
      state.rows[state.editRowId].cols[state.editColumnId].type = "card";
      Vue.set(
        state.rows[state.editRowId].cols[state.editColumnId],
        "desc",
        state.editCardDesc
      );
      Vue.set(
        state.rows[state.editRowId].cols[state.editColumnId],
        "shape",
        state.shapeSelected
      );
      Vue.set(
        state.rows[state.editRowId].cols[state.editColumnId],
        "icon",
        state.iconSelected
      );
      Vue.set(
        state.rows[state.editRowId].cols[state.editColumnId],
        "color",
        state.colorSelected
      );
      Vue.set(
        state.rows[state.editRowId].cols[state.editColumnId],
        "picture",
        state.pictureSaved
      );
    },
    [REMOVE_CARD](state, payload) {
      Object.assign(
        state.rows[payload.removeRowId].cols[payload.removeColumnId],
        {
          title: "",
          type: "",
        }
      );
    },
    [OPEN_CARD_DIALOG](state, payload) {
      if (payload.isEdit) {
        state.editCardTitle =
          state.rows[payload.rowId].cols[payload.columnId].title;
        state.editCardDesc =
          state.rows[payload.rowId].cols[payload.columnId].desc;
        state.shapeSelected = state.rows[payload.rowId].cols[payload.columnId]
          .shape
          ? state.rows[payload.rowId].cols[payload.columnId].shape
          : null;
        state.iconSelected = state.rows[payload.rowId].cols[payload.columnId]
          .icon
          ? state.rows[payload.rowId].cols[payload.columnId].icon
          : null;
        state.colorSelected = state.rows[payload.rowId].cols[payload.columnId]
          .color
          ? state.rows[payload.rowId].cols[payload.columnId].color
          : null;
        if (state.rows[payload.rowId].cols[payload.columnId].picture) {
          let path =
            state.rows[payload.rowId].cols[payload.columnId].picture.path;
          http
            .get(`/files?file_path=${path}`, {
              responseType: "blob",
            })
            .then((response) => {
              if (response.status == 200) {
                const blob = new Blob([response.data]);
                const file = new File(
                  [blob],
                  state.rows[payload.rowId].cols[payload.columnId].picture.name
                );
                console.log(file);
                state.pictureUploaded = file;
              } else {
                state.pictureUploaded = null;
              }
            });
        } else {
          state.pictureUploaded = null;
        }
      }
      state.editRowId = payload.rowId;
      state.editColumnId = payload.columnId;
      state.cardDialog = true;
    },
    [CLOSE_CARD_DIALOG](state) {
      state.editRowId = null;
      state.editColumnId = null;
      state.pictureUploaded = null;
      state.cardDialog = false;
    },
    [SET_ROW_TITLE](state, payload) {
      state.rows[payload.rowId].title = payload.title;
    },
    [SET_EDIT_ROW_ID](state, payload) {
      state.editRowId = payload;
    },
    [SET_EDIT_COL_ID](state, payload) {
      state.editColumnId = payload;
    },
    [SET_EDIT_CARD_TITLE](state, payload) {
      state.editCardTitle = payload;
    },
    [SET_EDIT_CARD_DESC](state, payload) {
      state.editCardDesc = payload;
    },
    [SET_ICON_SELECTED](state, payload) {
      state.iconSelected = payload;
    },
    [SET_SHAPE_SELECTED](state, payload) {
      state.shapeSelected = payload;
    },
    [SET_COLOR_SELECTED](state, payload) {
      state.colorSelected = payload;
    },
    [SET_PICTURE_UPLOADED](state, payload) {
      state.pictureUploaded = payload;
    },
    [SET_PICTURE_SAVED](state, payload) {
      state.pictureSaved = payload;
    },
    [SET_PICKERS_DIALOG](state, payload) {
      state.pickersDialog = payload;
    },
    [SET_REMOVE_CARD_DIALOG](state, payload) {
      state.removeRowId = payload.removeRowId ? payload.removeRowId : null;
      state.removeColumnId = payload.removeColumnId
        ? payload.removeColumnId
        : null;
      state.removeCardDialog = payload.dialog;
    },
    [SET_PERSONA_DIALOG](state, payload) {
      state.personaDialog = payload;
    },
    [SET_PERSONAS](state, payload) {
      state.personas = payload;
    },
    [SET_SELECTED_PERSONAS](state, payload) {
      state.selectedPersonas = payload;
    },
    [SET_SELECTED_PERSONAS_OBJS](state, payload) {
      state.selectedPersonasObjs = payload;
    },
    [SET_CURRENT_PICKER](state, picker) {
      state.currentPicker = picker;
    },
    [SET_EDITDATA](state, editData) {
      state.editData = editData;
    },
    [UNSET_EDITDATA](state) {
      state.editData = {
        title: "",
        desc: "",
        icon: null,
        shape: null,
        color: null,
      };
    },
    [SELECT_CARD](state, id) {
      state.selectedPersonas = state.selectedPersonas
        .filter((f) => f !== id)
        .concat([id]);
    },
    [UNSELECT_CARD](state, id) {
      state.selectedPersonas = state.selectedPersonas.filter((f) => f !== id);
    },
    [TOGGLE_CHANNEL](state, payload) {
      let channel =
        state.rows[payload.rowId].cols[payload.colId].channels[
          payload.channelId
        ];
      if (typeof channel !== undefined) {
        state.rows[payload.rowId].cols[payload.colId].channels[
          payload.channelId
        ] = !channel;
      } else {
        state.rows[payload.rowId].cols[payload.colId].channels[
          payload.channelId
        ] = true;
      }
      let channelRefId = `channel_radio_${payload.rowId}_${payload.colId}_${payload.channelId}`;
      state.rows[payload.rowId].cols[payload.colId].channels[
        payload.channelId
      ] == true
        ? payload.refs[channelRefId].classList.add("selected")
        : payload.refs[channelRefId].classList.remove("selected");
    },
    [SET_LABEL_INPUT](state, payload) {
      state.rows[payload.rowId].cols[payload.columnId].title = payload.value;
    },
    [SET_CUSTOMER_JOURNEY_ID](state, id) {
      state.customerJourneyId = id;
    },
    [SET_CUSTOMER_JOURNEY_NAME](state, name) {
      state.customerJourneyName = name;
    },
    [SET_PARENTELEMENTITEM_ID](state, id) {
      state.parentElementId = id;
    },
    [SET_CHART_KEY](state, payload) {
      state.chartKey = payload;
    },
    [SET_CHART_DATA](state, payload) {
      if (payload.labels) {
        state.chartData.labels = payload.labels;
      }
      if (payload.datasets) {
        state.chartData.datasets = payload.datasets;
      }
    },
    [SET_CHART_COLUMN_VALUES](state, payload) {
      state.rows[payload.rowId].cols[payload.columnId].values = payload.values;
    },
    [CHANGE_CHART_VALUES](state, payload) {
      state.rows.forEach((row) => {
        if (row.type == "graph" && row.cols[payload.colId + 1]) {
          // Columns ids from graph are 0 indexed
          // In rows, 0 index item is row title, so we need to start from
          row.cols[payload.colId + 1].values.forEach((personaValue) => {
            if (
              state.selectedPersonas[payload.personaId] ==
              personaValue.personaId
            ) {
              personaValue.value = payload.value;
            }
          });
        }
      });
    },
    [SET_ROWELEMENT_ID](state, payload) {
      state.rowElementId = payload;
    },
    [SET_PREVDRAGPARENT](state, payload) {
      state.prevDragParent = payload;
    },
    [SET_DRAGENTERED](state, payload) {
      state.dragEntered = payload;
    },
    [SET_PREVDRAGPARENT_CHILDREN](state, payload) {
      state.prevDragParent.children[0].style.zIndex = payload;
    },
    [SET_ISCARDDRAGGED](state, payload) {
      state.isCardDragged = payload;
    },
    [SET_DRAGGEDROWID](state, payload) {
      state.draggedRowId = payload;
    },
    [SET_DRAGROWID](state, payload) {
      state.dragRowId = payload;
    },
    [SET_DRAGCOLID](state, payload) {
      state.dragColId = payload;
    },
    [SET_CARDDROP](state, payload) {
      state.rows[payload.rowId].cols.splice(
        payload.columnId,
        1,
        payload.dragClone
      );
      state.rows[state.dragRowId].cols.splice(
        state.dragColId,
        1,
        payload.dropTargetClone
      );
    },
    [SET_ISROWDRAGGED](state, payload) {
      state.isRowDragged = payload;
    },
    [SET_ROWDROP](state, payload) {
      state.rows.splice(payload.rowId, 1, payload.dragClone);
      state.rows.splice(payload.draggedRowId, 1, payload.dropTargetClone);
    },
    [RESET_STATE](state) {
      Object.assign(state, getDefaultState());
    },
    [SET_ZOOM](state, value) {
      state.zoom = state.zoom + value;
    },
    [SET_HISTORY_STEP](state, payload) {
      state.historyStep = payload;
    },
  },
  getters: {
    getPersonaColor: (state) => (id) => {
      let personaColor = state.personas
        .filter((persona) => persona.id == id)
        .map((persona) => {
          return persona.basic_info.color;
        });
      return personaColor[0];
    },
    getPersonaAvatar: (state) => (id) => {
      let personaId = id;
      if(personaId.id) {
        personaId = personaId.id;
      }
      let personaAvatarId = state.personas
        .filter((persona) => persona.id == personaId)
        .map((persona) => {
          return persona.basic_info.image_id;
        });

      return require(`@/assets/img/personas/person_${
        personaAvatarId[0] || 1
      }.jpg`);
    },
    isSelectedChannel(state) {
      return function (channelId, rowId, colId) {
        let channel = state.rows[rowId].cols[colId].channels[channelId];
        if (!channel) {
          return false;
        }
        return true;
      };
    },
  },
};
