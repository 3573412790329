<template>
    <div>
        <cropper
            ref="cropper"
            class="twitter-cropper mx-auto mt-5"
            background-class="cropper__background"
            foreground-class="cropper__foreground"
            :stencil-size="stencilSize"
            :transitions="false"
            :stencil-props="{
                handlers: {},
                movable: false,
                resizable: false,
                aspectRatio: 1,
                previewClass: 'cropper__stencil',
            }"
            stencil-component="circle-stencil"
            
            :debounce="false"
            :default-size="defaultSize"
            :width="177"
            :height="177"
            :src="imgSrc"
            @change="onChange"
            @ready="ready"
            :resizeImage="img ? true : false"
            :moveImage="img ? true : false"
        />
        <Slider v-if="img" :zoom="zoom" @change="onZoom" />
    </div>
</template>
<script>
import { Cropper } from 'vue-advanced-cropper';
// import 'blueimp-canvas-to-blob';
import 'vue-advanced-cropper/dist/style.css';
import Slider from '@/components/admin/accountManagement/profile/Slider';

!function(t){"use strict";var a=t.HTMLCanvasElement&&t.HTMLCanvasElement.prototype,l=t.Blob&&function(){try{return Boolean(new Blob)}catch(t){return!1}}(),u=l&&t.Uint8Array&&function(){try{return 100===new Blob([new Uint8Array(100)]).size}catch(t){return!1}}(),c=t.BlobBuilder||t.WebKitBlobBuilder||t.MozBlobBuilder||t.MSBlobBuilder,b=/^data:((.*?)(;charset=.*?)?)(;base64)?,/,r=(l||c)&&t.atob&&t.ArrayBuffer&&t.Uint8Array&&function(t){var e,o,n,a,r,i=t.match(b);if(!i)throw new Error("invalid data URI");for(e=i[2]?i[1]:"text/plain"+(i[3]||";charset=US-ASCII"),n=!!i[4],i=t.slice(i[0].length),o=(n?atob:decodeURIComponent)(i),n=new ArrayBuffer(o.length),a=new Uint8Array(n),r=0;r<o.length;r+=1)a[r]=o.charCodeAt(r);return l?new Blob([u?a:n],{type:e}):((i=new c).append(n),i.getBlob(e))};t.HTMLCanvasElement&&!a.toBlob&&(a.mozGetAsFile?a.toBlob=function(t,e,o){var n=this;setTimeout(function(){o&&a.toDataURL&&r?t(r(n.toDataURL(e,o))):t(n.mozGetAsFile("blob",e))})}:a.toDataURL&&r&&(a.msToBlob?a.toBlob=function(t,e,o){var n=this;setTimeout(function(){(e&&"image/png"!==e||o)&&a.toDataURL&&r?t(r(n.toDataURL(e,o))):t(n.msToBlob(e))})}:a.toBlob=function(t,e,o){var n=this;setTimeout(function(){t(r(n.toDataURL(e,o)))})})),"object"==typeof module&&module.exports?module.exports=r:t.dataURLtoBlob=r}(window);

export default {
	components: {
		Cropper,
        Slider
	},
    props: {
        img: {}
    },
    computed: {
        imgSrc() {
            let result = require('@/assets/img/avatar.png');
            if(this.img) {
                result = this.img;
            }
            return result;
        }
    },
	data() {
        return {
            zoom: 0,
            imageMoved: false
        }
	},
	methods: {
        ready() {
            this.zoom = 0;
            const cropper = this.$refs.cropper;
            cropper.zoom(0)
            this.imageMoved = false;
            this.$emit("imageMoved", false)
        },
        crop() {
            const { coordinates, image, visibleArea, canvas } = this.$refs.cropper.getResult();
            console.log(image)
            console.log(canvas)
            console.log(coordinates)
            console.log(visibleArea)
            canvas.toBlob(blob => {
                this.$emit("imageCropped", new File([blob], `${Math.random()}.png`));
            })
        },
		onChange() {
            this.imageMoved = true;
			const cropper = this.$refs.cropper;
			if (cropper) {
				if (cropper.imageSize.height < cropper.imageSize.width) {
					this.zoom =
						(cropper.imageSize.height - cropper.coordinates.height) /
						(cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
				} else {
					this.zoom =
						(cropper.imageSize.width - cropper.coordinates.width) /
						(cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
				}
			}
		},
        defaultSize() {
			return {
				width: "177",
				height: "177",
			};
		},
		stencilSize() {
			return {
				width: "177",
				height: "177",
			};
		},
        onZoom(value) {
            this.imageMoved = true;
			const cropper = this.$refs.cropper;
			if (cropper) {
				if (cropper.imageSize.height < cropper.imageSize.width) {
					const minHeight = cropper.sizeRestrictions.minHeight;
					cropper.zoom(
						((1 - this.zoom) * cropper.imageSize.height + minHeight) /
							((1 - value) * cropper.imageSize.height + minHeight),
					);
				} else {
					const minWidth = cropper.sizeRestrictions.minWidth;
					cropper.zoom(
						((1 - this.zoom) * cropper.imageSize.width + minWidth) /
							((1 - value) * cropper.imageSize.width + minWidth),
					);
				}
			}
		},
	},
     watch: {
        imageMoved(val) {
            this.$emit("imageMoved", val)
        },
        imgSrc() {
            this.imageMoved = false;
        }
    }
}
</script>
<style scoped>
.cropper {
	height: 177px;
	width: 177px;
	background: #DDD;
}
.vue-advanced-cropper {
    background-color: transparent;
    height: 177px !important;
    width: 177px !important;
}
.vue-advanced-cropper__image-wrapper{
    height: 177px !important;
    width: 177px !important;
}

</style>

<style lang="scss">
.cropper {
	height: 177px !important;
    width: 177px !important;
	&__background {
		background-color: transparent;
        height: 177px !important;
        width: 177px !important;
	}
	&__foreground {
		background-color: #C4C4C4;
        height: 177px !important;
        width: 177px !important;
	}
	&__stencil {
        border: solid 1px #C4C4C4;
	}
}
</style>