<template>
  <!-- Person picker dialog -->
  <v-dialog v-model="dialog" max-width="1145px">
    <v-card>
      <div class="new-item">
        <h3>Dodaj Personę</h3>
        <div class="modal-cards">
          <template v-for="(persona, id) in personas">
            <Card
              :key="`card_persona_${id}`"
              :data="persona"
              :preSelect="selectedPersonas.includes(persona.id)"
              @selectCard="selectCard($event)"
              @unselectCard="unselectCard($event)"
            />
          </template>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Card from "@/components/dashboard/addCustomerJourneyModal/Card.vue";
import { mapState, mapActions } from "vuex";
import { http } from "@/plugins/http";

export default {
  components: {
    Card,
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    ...mapState("cjstore", [
      "personas",
      "selectedPersonas",
      "personaDialog",
      "customerJourneyId",
    ]),
  },
  watch: {
    personaDialog(newVal) {
      this.dialog = newVal;
      if (newVal === false) {
        http.patch(
          `/personas_binded_customer_journey/${this.customerJourneyId}`,
          {
            personas_ids: this.selectedPersonas,
          }
        );
        let selected = this.personas.filter((item) => {
          return this.selectedPersonas.includes(item.id);
        });
        this.setSelectedPersonasObjs(selected);
      }
    },
    dialog(newVal) {
      this.setPersonaDialog(newVal);
    },
  },
  destroyed() {
    this.setPersonaDialog(false);
  },
  methods: {
    ...mapActions("cjstore", [
      "setSelectedPersonasObjs",
      "setPersonaDialog",
      "selectCard",
      "unselectCard",
    ]),
  },
};
</script>

<style lang="sass" scoped>
.new-item
  display: flex
  flex-direction: column
  align-items: center
  padding: 30px
  h3
    padding-bottom: 30px
    font-size: 18px
    color: $primary-color

.modal-cards
  background: $bg-color
  border-radius: 16px !important
  width: 100%
  padding: 20px
  max-height: 600px
  overflow-y: scroll
  display: grid
  grid-template-columns: repeat(3,minmax(250px, 1fr))
  gap: 20px

::v-deep .modal-cards .modal-card
  border-radius: 16px !important
  box-shadow: 0px 3.5px 5.5px rgba(0, 0, 0, 0.02) !important
  border: 2px solid transparent
  &:hover
    border: 2px solid $aquamarine-color
    cursor: pointer
  .card-title
    color: $primary-color !important
    font-size: 1.42857rem !important
    font-weight: 600
  .card-subtitle
    color: $gray-color !important
    font-size: 1rem !important
    text-align: center
  .card-image
    height: 147px
    border-radius: 16px
    margin-bottom: 46px
  .v-card--link:focus::before
    opacity: 1
::v-deep .card-clicked
  border: 2px solid $primary-color !important
  background-color: white !important

.personas,
.add-persona
  justify-content: center
  align-items: center
  display: flex

.add-persona .wrapper
  display: none
  opacity: 0
  transition: opacity .3s ease-out
.add-persona:hover .wrapper
    display: flex
    opacity: 1

.personas-avatars-wrapper,
.personas-avatars,
.add-persona .wrapper
  display: flex
  align-items: center
  cursor: pointer
.add-persona .wrapper
  box-shadow: 0px 3px 8px rgba(70, 128, 255, 0.08)
  min-width: 150px
  padding: 7px 22px
  border-radius: 22px
  justify-content: space-between
  span
    font-size: 12px
    color: #8392AB
  svg
    width: 22px
    height: 22px
    margin-right: -15px
</style>
