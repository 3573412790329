<template>
  <div
    v-if="
      col.type !== 'row-title' &&
      selectedPersonas.length !== 0 &&
      arraysHasCommon(row.options, ['persona'])
    "
    class="personas"
    @click="setPersonaDialog(true)"
  >
    <div class="personas-avatars-wrapper">
      <div class="personas-avatars">
        <template v-for="(personaId, idx) in selectedPersonas">
          <div
            :key="`selected_${idx}`"
            class="avatar-wrapper"
            :style="{
              borderWidth: '2px',
              borderStyle: 'solid',
              borderColor: getPersonaColor(personaId),
            }"
          >
            <img :src="getPersonaAvatar(personaId)" />
          </div>
        </template>
      </div>
      <span>{{ selectedPersonas.length }} Persona(s)</span>
    </div>
  </div>
</template>

<script>
import utils from "@/plugins/utils.js";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  props: {
    col: Object,
    row: Object,
    columnId: Number,
    rowId: Number,
  },
  computed: {
    ...mapState("cjstore", ["selectedPersonas"]),
    ...mapGetters("cjstore", ["getPersonaAvatar", "getPersonaColor"]),
  },
  methods: {
    arraysHasCommon: utils.arraysHasCommon,
    ...mapActions("cjstore", ["setPersonaDialog"]),
  },
};
</script>

<style lang="sass" scoped>
.personas-avatars-wrapper,
.personas-avatars
  display: flex
  align-items: center
  cursor: pointer
.personas-avatars-wrapper
  background-color: #fff
  padding: 4px 22px
  border-radius: 28px
  span
    font-size: 12px
    color: $primary-color
    font-family: 'Roboto'
.personas
  justify-content: center
  align-items: center
  display: flex
.personas-avatars
  margin-left: -18px
  margin-right: 7px
  ::v-deep .persona-wrapper,
  .avatar-wrapper
    overflow: hidden
    width: 22px
    height: 22px
    border-radius: 22px
    border: 1px solid #fff
    display: flex
    justify-content: center
    img
      height: 100%
</style>
