<template>
  <div class="slider">
    <div class="labels">
      <v-text-field 
        :class="'label-left'"
        flat 
        solo
        hide-details
        :value="data.labels.left"
        :placeholder="'Lewa'"
        @input="updateLabel($event,'left')"></v-text-field>
      <v-text-field
        :class="'label-right'"
        flat 
        solo
        hide-details
        :value="data.labels.right"
        :placeholder="'Prawa'"
        @input="updateLabel($event,'right')"></v-text-field>
    </div>
    <div class="slider-content">
      <v-slider 
        hide-details
        :value="value"
        @change="sliderChange"
        min="0"
        max="100">
      </v-slider>
      <div class="remove" @click="removeItem" data-html2canvas-ignore="true">
        <TrashIcon />
      </div>
    </div>
  </div>
</template>

<script>
import TrashIcon from '@/components/icons/TrashIcon.vue';
import bus from '@/components/personas/eventBus.js';

export default {
  components: {
    TrashIcon
  },
  props: {
    value: Number,
    id: [Number,String],
    itemId: Number,
    type: String,
    data: {
      type: Object,
      default(){
        return {
          labels: {
            left: {},
            right: {}
          },
          value: 50
        }
      }
    }
  },
  methods: {
    removeItem(){
      bus.$emit('removeCardItem', {
        type: this.type,
        id: this.id,
        itemId: this.itemId
      })
    },
    updateLabel(event, side){
      bus.$emit('updateLabel', {
        type: this.type,
        id: this.id,
        itemId: this.itemId,
        event: event,
        side: side
      })      
    },
    sliderChange(value){
      bus.$emit('sliderChange', {
        type: this.type,
        id: this.id,
        itemId: this.itemId,
        event: value
      })
    }
  }
}
</script>

<style lang="sass" scoped>
  .labels 
    display: flex
    justify-content: space-between
  .slider .v-messages 
    display: none
  .slider:hover .remove
    display: flex
  .slider-content
    display: flex
  .remove
    display: none
    cursor: pointer
  ::v-deep .labels .theme--light.v-input input
    color: $primary-color
    font-size: 14px
  ::v-deep .labels .label-left.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot
    padding-left: 8px
  ::v-deep .labels .label-right.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot
    padding-right: 8px
  ::v-deep .labels .label-right.theme--light.v-input input
    text-align: right
</style>