<template>
  <div class="basic-info">
    <v-card class="main">
      <div @click="photoList = true" class="picture-background">
        <template v-if="basic_info.image_id == ''">
          <img class="picture-empty" src="@/assets/img/icon_plus-circle.svg" />          
        </template>
        <template v-else>
          <img class="person-avatar" :src="require(`@/assets/img/personas/person_${basic_info.image_id}.jpg`)" />
        </template>
      </div>
      <div class="person-info">
        <TitleInput 
          :placeholder="'Imię'" 
          :title="basic_info.name"
          :id="'name'"
          :type="'basic_info'"
          :inputType="'basic_info'"></TitleInput>
        <div class="bottom-line"></div>
        <v-textarea 
          :placeholder="basic_info.bio || 'Uzupełnij text'"
          :value="basic_info.bio"
          @input="bioChange">
        </v-textarea>
        <div class="color-picker">
          <span>Wybierz kolor</span>
          <template v-for="(hex, id) in colors">
            <div 
              :key="`color_${id}`" 
              :class="['color', [(selectedColor == hex.color) ? 'selected':'']]"
              :style="{ background: hex.color }"
              @click="selectColor(id, hex.color)"></div>
          </template>
        </div>
      </div>
    </v-card>
    <div class="d-flex justify-space-between flex-column">
      <div class="buttons-wrapper" data-html2canvas-ignore="true">
        <button class="changes-handler" title="eksportuj PDF" @click="$emit('export')">
          <img src="@/assets/img/icon_download.svg" />
        </button>
        <button class="changes-handler" title="cofnij" @click="$emit('undo')">
          <img src="@/assets/img/icon_undo.svg" />
        </button>
        <button class="changes-handler" title="powtórz" @click="$emit('redo')">
          <img src="@/assets/img/icon_redo.svg" />
        </button>
        <button class="save-persona" @click="$emit('saveClicked')">
          ZAPISZ
        </button>
      </div>
      <div>
        <template class="details" v-for="(item, id) in inputs">
          <component 
            :is="'Input'" 
            :key="`detail_${id}`" 
            :background="'#2E1B75'"
            :color="'white'"
            :id="item.id"
            :value="item.value"
            :inputType="item.inputType ? item.inputType : 'text'"
            :type="'detailed_info'"
            :prepend="item.prepend"
            :outlined="true"></component>
        </template>
      </div>
    </div>
    <v-dialog v-model="photoList" max-width="950">
      <div class="photo-list-wrapper">
        <h3>Dodawanie zdjęcia</h3>
        <div class="photo-list">
          <template v-for="index in 9">
            <div 
              :key="`photo_${index}`" 
              :class="['photo', [(selectedPhoto == index) ? 'selected':'']]"
              @click="selectedPhoto = index">
              <img :src="require(`@/assets/img/personas/person_${index}.jpg`)"/>
            </div>
          </template>
        </div>
        <div @click="selectPhoto" class="add-photo">Ustaw zdjęcie</div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import Input from '@/components/personas/Input.vue';
import TitleInput from '@/components/personas/TitleInput.vue';
import bus from '@/components/personas/eventBus.js';

export default {
  components: {
    Input,
    TitleInput,
  },
  props: { 
    basic_info: Object,
    detailed_info: Object
  },
  data(){
    return {
      photoList: false,
      selectedColor: this.basic_info.color,
      selectedPhoto: this.basic_info.image_id,
      colors: [
        {
          color: '#FFB145'
        },
        {
          color: '#5E35F2'
        },
        {
          color: '#27AE60'
        },
        {
          color: '#EF5DA8'
        },
        {
          color: '#2F80ED'
        },
      ],
      inputs: [
        { 
          prepend: 'Wiek',
          id: 'age',
          value: '',
          inputType: 'number' 
        },
        { 
          prepend: 'Zawód',
          id: 'job_title',
          value: ''
        },
        { 
          prepend: 'Zarobki',
          id: 'salary',
          value: '',
          inputType: 'number' 
        },
        { 
          prepend: 'Lokalizacja',
          id: 'location',
          value: ''
        },
      ]
    }
  },
  watch: {
    detailed_info(newVal,oldVal){
      if(newVal !== oldVal){
        this.mapInputs()
      }
    },
    basic_info(newVal,oldVal){
      if(newVal.color !== oldVal.color){
        this.selectedColor = newVal.color
      }
    }
  },
  mounted(){
    this.mapInputs()
  },
  methods: {
    mapInputs(){
      Object.keys(this.detailed_info).map((item) => {
        this.inputs.forEach((input, index) => {
          if(input.id === item) {
            this.inputs[index].value = this.detailed_info[item]
          }
        });
      })
    },
    bioChange(event){
      bus.$emit('bioChange', event)
    },
    selectColor(id, color){
      this.selectedColor = color;
      bus.$emit('selectColor', this.colors[id].color);
    },
    selectPhoto(){
      bus.$emit('selectPhoto', this.selectedPhoto);
      this.photoList = false
    }
  }
}
</script>

<style lang="sass" scoped>
  .basic-info
    display: grid
    gap: 16px
    grid-template-columns: 2fr 1fr
    grid-column: span 2
    .main
      display: grid
      gap: 30px
      padding: 20px
      grid-template-columns: 1fr 2fr
      .v-card__title
        font-weight: 600
        font-size: 16px
        display: inline-block
        text-align: left
        width: 100%
        padding: 0 0 20px 0  
        color: $primary-color
      .bottom-line
        width: 100%
        height: 2px
        background: linear-gradient(to right, #fff, #E0E1E2, #fff)
  .details
    display: flex
    flex-direction: column
    justify-content: end
  .picture-background
    display: flex
    align-items: center
    justify-content: center
    background: $table-hover-color
    width: 180px
    height: 180px
    border-radius: 50% !important
    cursor: pointer
    overflow: hidden
  .person-avatar
    height: 100%
  .color-picker
    display: flex
    align-items: center
    justify-content: space-between
    span 
      font-size: 12px
      color: $primary-color
  .color
    width: 22px
    height: 22px
    border-radius: 22px
    cursor: pointer
    transition: transform .2s ease-out
    &:hover
      transform: scale(1.3)
    &.selected 
      border: 2px solid $primary-color
  ::v-deep .v-input.v-textarea>.v-input__control>.v-input__slot:before 
    border: none
  ::v-deep .v-textarea textarea
    font-size: 14px
  ::v-deep .v-textarea.v-text-field 
    padding: 12px
  .photo-list-wrapper 
    background-color: #fff
    max-width: 1000px
    padding: 30px
    display: flex
    flex-direction: column
    align-items: center
    h3
      text-align: center 
      font-size: 18px
      font-weight: 600
      color: $primary-color
      margin-bottom: 30px
  .photo-list
    justify-content: center
    display: grid
    gap: 13px 10px 
    grid-template-columns: repeat(3, minmax(min-content, 1fr))
  .photo
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    img
      width: 100%
    &.selected img
      border-radius: 8px
      border: 2px solid $primary-color
      margin: -2px      
  .photo:hover img
    border-radius: 8px
    border: 2px solid #52FBDE
    margin: -2px
  .add-photo
    margin: 30px auto 0 auto
    font-weight: bold
    font-size: 12px
    background: $primary-color
    color: #fff
    cursor: pointer
    border-radius: 8px
    padding: 12px 150px
  .buttons-wrapper
    display: flex
    justify-content: end
    button
      &.save-persona
        background: $secondary-color
        height: 35px
        width: 87px
        text-align: center
        border-radius: 8px
        border-top-left-radius: 8px !important
        border-top-right-radius: 8px !important
        color: white
        margin-left: 7px
    .changes-handler
      background: $table-hover-color
      width: 35px
      height: 35px
      border-radius: 8px
      margin-left: 7px
      display: flex
      align-items: center
      justify-content: center
</style>