<template>
    <div class="info-container">
        <div>
            <p class="info-container-title">Brak danych</p>
            <p class="mb-0">{{ message }}</p>
        </div>
        <div class="icon-container">
            <img src="@/assets/img/icon_info.svg">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: {}
    }
}
</script>