<template>
    <div class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
        <div class="card-padding pb-0">
            <h2 class="text-h2 mb-2 auth-header">
                Jeszcze tylko jeden krok...
            </h2>
            <p class="text-h5 mb-0 auth-subtitle">Wybierz subsykrypcję na której będziesz pracować </p>
            
            <div class="card-padding pb-0 px-0">
                <v-btn
                    icon
                    outlined
                    rounded
                    width="100%"
                    height="60px"
                    class="me-auto btn border-radius-md auth-google-button"
                >
                </v-btn>
            </div>
            <v-btn
                type="submit"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                text-uppercase
                w-100
                auth-button
                mt-8
                "
                color="#5e72e4"
                small
                >Zaloguj</v-btn
            >
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      emailValid: null,
      passwordValid: null,
      showPassword: false,
      remember: false,
      form: {
        username: null,
        password: null,
      },
      errorMessages: {},
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
          await this.login(this.form);
        } catch (e) {
          if (e.errors) {
            this.errorMessages = e.errors;
            return;
          }

          this.errorMessages = { email: [e.message] };
        } finally {
          this.loading = false;
        }
      }
    },
    toggleShow(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    }
  },
};
</script>

<style scoped>
#login-page {
  background-color: var(--v-primary-lighten5);
}
</style>
