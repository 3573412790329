<template>
  <component
     :is="component"
     v-bind="$attrs"
     @v-on="$listeners"
  >
    <slot>
    </slot>
  </component>
</template>

<script>

export default {
  name: 'BaseIcon',
  inheritAttrs: false,

  props: {
    path: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      component: null
    }
  },
  watch: {
    path(){
      this.loadComponent();
    }
  },
  methods: {
    async loadComponent(){
      this.component = () => ({
        component: import(`@/components/customerJourney/${this.path}`)
      })
    }
  },
  mounted(){
    this.loadComponent()
  }
}
</script>