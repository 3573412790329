<template>
  <v-card 
    id="payments-methods"
    :elevation="0" 
    class="mx-auto d-flex flex-column align-center">
    <div class="w-full justify-space-between align-center modal-top ma-0 d-flex">
        <v-btn
            :ripple="false"
            :elevation="0"
            height="24"
            width="24"
            text
            icon
            class="prev-button"
            @click="$emit('prev')">
            <img src="@/assets/img/chevron-circle-left.svg" />
        </v-btn>
          <v-btn
            :ripple="false"
            :elevation="0"
            height="24"
            width="24"
            text
            icon
            class="close-button"
            @click="$emit('close')">
            <img src="@/assets/img/cross-circle.svg" />
        </v-btn>
    </div>
    <template v-if="paymentType">
      <div class="payment-methods">
        <h3 class="modal-title mb-12 d-flex justify-center">
          {{labels[paymentType].title}}
        </h3>
        <div class="methods d-flex flex-column mb-5">
          <template v-for="(method, id) in paymentMethods.filter(item => item.type == paymentType)">
            <template v-if="paymentType == 'cyclical'">
              <div 
                :key="`payment_method_${id}`" 
                class="method cyclical mb-3 px-3 py-4 d-flex justify-space-between align-center">
                <input />
                <img :src="require(`@/assets/img/payments/${method.icon}.svg`)" />
              </div>
            </template>
            <template v-if="paymentType == 'onetime'">
              <div 
                @click="selectMethod(method)"
                :key="`payment_method_${id}`" 
                class="method onetime mb-3 px-3 py-4 d-flex justify-space-between align-center"
                :class="selectedMethod == method.value
                ? 'selected'
                : null">
                  <span>{{method.label}}</span>
                <img :src="require(`@/assets/img/payments/${method.icon}.svg`)" />
              </div>
            </template>
          </template>
        </div>
        <div class="coupon pa-3 mb-9">
          <span>Kod Rabatowy</span>
          <input type="text" />
        </div>
        <div class="checkout">
          <template v-if="paymentType == 'onetime'">
            <div class="btn py-2">Płacę {{price}} zł</div>
          </template>
          <template v-if="paymentType == 'cyclical'">
            <div class="btn py-2">Dodaj danę karty</div>
          </template>
        </div>
      </div>
    </template>
  </v-card>
</template>

<script>
export default {
  props: {
    price: Number,
    paymentType: String,
    interval: String,
  },
  data(){
    return {
      selectedMethod: null,
      labels: {
        cyclical: {
          title: "Wybierz metodę płatności cykliczną",
        },
        onetime: {
          title: "Wybierz metodę płatności jednorazową"
        }
      },
      paymentMethods: [
        {
          type: 'onetime',
          value: 'blik',
          label: 'Blik',
          icon: 'blik',
        },
        {
          type: 'onetime',
          value: 'payu',
          label: 'PayU',
          icon: 'payu',
        },
        {
          type: 'onetime',
          value: 'transfer',
          label: 'Przelew tradycyjny',
          icon: 'transfer',
        },
        {
          type: 'cyclical',
          value: 'card',
          label: 'karta',
          icon: 'card',
        },
      ]
    }
  },
  watch: {
    paymentType(newVal){
      console.log(newVal)
    }
  },
  methods: {
    selectMethod(method){
      this.selectedMethod = method.value
    }
  }
}
</script>

<style lang="sass" scoped>
  .modal-top
    width: 100%
  .modal-title
    font-size: 18px
    color: $primary-color
    text-align: center
    max-width: 240px
  .method
    border: 1px solid #8392AB
    border-radius: 8px
    cursor: pointer
    &.selected
      border: 2px solid $primary-color
    span
      color: $primary-color
    input
      outline: 0
      width: calc(100% - 40px)
  .coupon
    span 
      color: $secondary-color
      font-weight: 600
  .payment-methods
    min-width: 100%
    h3
      min-width: 100%
  .btn 
    font-weight: 700
    font-size: 14px
    box-shadow: 0px 2px 30px rgba(46, 27, 117, 0.05)
    border-radius: 8px
    cursor: pointer
    background-color: $primary-color
    color: #fff
    text-align: center
</style>