<template>
  <div class="person-item">
    <v-card>
      <img class="cursor-pointer" @click="clicked" :src="require(`@/assets/img/personas/person_${persona.basic_info.image_id || 1}.jpg`)"/>
      <div @click="clicked" class="person-name">
        <h2>{{persona.basic_info.name}}</h2>
        <div class="separator"></div>
        <span>{{persona.job_title}}</span>
      </div><br>
      <div class="card-date">
        Ostatnio otworzony: {{ formatDate(persona.last_modification_ts) }}
      </div>
      <div class="person-actions">
        <div class="edit" @click="clicked">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div 
                v-bind="attrs"
                v-on="on">
                <PencilIcon />
              </div>
            </template>
            <div>Edytuj personę</div>
          </v-tooltip>
        </div>
        <div class="duplicate" @click="duplicate">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div 
                v-bind="attrs"
                v-on="on">
                <DuplicateIcon />  
              </div>
            </template>
            <div>Zduplikuj personę</div>
          </v-tooltip>
        </div>
        <div class="remove" @click="removePersona">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div data-html2canvas-ignore="true"
                v-bind="attrs"
                v-on="on">
                  <TrashIcon />
              </div>
            </template>
            <div>Usuń personę</div>
          </v-tooltip>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import bus from '@/components/personas/eventBus.js';
import TrashIcon from '@/components/icons/TrashIcon.vue';
import DuplicateIcon from '@/components/icons/DuplicateIcon.vue'
import PencilIcon from '@/components/icons/PencilIcon.vue'

export default {
  components: {
    TrashIcon,
    DuplicateIcon,
    PencilIcon
  },
  props: {
    persona: Object
  },
  methods: {
    clicked(){
      bus.$emit('editPersona', this.persona.editId);
    },
    duplicate(){
      this.$nextTick(() => {
        bus.$emit('duplicatePersona', this.persona.editId);
      })
    },
    removePersona(){
      this.$nextTick(() => {
        bus.$emit('removePersona', this.persona.id);
      })
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    formatDate(data) {
      const date = new Date(data);
      return [
        this.padTo2Digits(date.getDate()),
        this.padTo2Digits(date.getMonth() + 1),
        date.getFullYear(),
      ].join(".");
    },
    
  },
}
</script>

<style lang="sass" scoped>
  .person-item
    margin: 0 10px 20px 10px
    min-width: 250px
    max-width: 360px
    width: 100%
    .v-card
      min-height: 323px
      max-height: 323px
      span
        white-space: nowrap !important
        overflow: hidden !important
        text-overflow: ellipsis !important
        max-width: 90% !important
      .person-name
        max-width: 90% !important
        h2
          white-space: nowrap !important
          overflow: hidden !important
          text-overflow: ellipsis !important
  ::v-deep .v-card > :first-child:not(.v-btn):not(.v-chip):not(.v-avatar) 
    border-radius: 20px
    padding: 12px 12px
    width: 100%
  .person-item .v-card
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-between
    padding-bottom: 15px
  .person-item h2
    justify-content: center
    font-size: 20px
    font-weight: bold
    margin: 20px 0 5px 0
    color: #2E1B75
  .person-name
    cursor: pointer
    text-align: center
  span
    padding-top: 5px
    font-size: 14px
    font-weight: 100
    color: #8392AB
  .separator
    position: relative
    width: 100%
    height: 2px
    background: linear-gradient(to right, #fff, #E0E1E2, #fff)
  .person-item:hover .person-actions
    opacity: 1 
  .person-actions
    opacity: 0
    display: flex
    padding: 10px 10px 0 0 
    width: 100%
    justify-content: flex-end
    &> div
      margin: 0 6px
      cursor: pointer
  .card-date
      font-weight: 700
      font-size: 0.85714rem
      text-align: end
      color: gray 
</style>