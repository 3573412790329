<template>
    <v-menu transition="slide-y-transition" offset-y>
        <template v-slot:activator="{ on, attrs }">
            <button  
                v-bind="attrs"
                v-on="on"
                elevation="0"
                :ripple="false"
                class="position-relative permission-dropdown text-size-1 text-color-primary"
            >
                <div class="d-flex align-center justify-space-between"> 
                    <span v-if="!selected">{{ items[0] }}</span>
                    <span v-else>{{ selected }}</span>
                    <svg class="ml-5" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 6.75L9 12.75L15 6.75" stroke="currentColor" stroke-width="2" stroke-linecap="square"/>
                    </svg>
                </div>
            </button>
        </template>

        <v-list class="py-0 permission-dropdown-list">
            <v-list-item v-for="item in items" :key="item.index" class="list-item-hover-active permission-dropdown-item" @click="selectItem(item)">
                <v-list-item-content class="pa-0">
                    <v-list-item-title
                        class="mb-0 text-size-1 text-color-primary"
                    >
                        {{item}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    props: {
        currentPermission: {}
    },
    mounted() {
        this.selected = this.currentPermission
    },
    data() {
        return {
            selected: null,
            items: [ "Admin", "Editor", "Viewer"]
        }
    },
    methods: {
        selectItem(item) {
            this.selected = item
            this.$emit("roleChanged", item);
        }
    },
    watch: {
        currentPermission(val) {
            this.selected = val;
        }
    }
}
</script>