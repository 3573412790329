<template>
    <v-dialog
        v-model="dialog"
        max-width="448px"
    >
        <v-card class="remove-card-modal mx-auto">
            <div class="justify-end modal-top d-flex">
                <v-btn
                    :ripple="false"
                    :elevation="0"
                    class="mr-1 mt-1 close-button mt-1"
                    @click="close"
                    icon
                >
                    <img src="@/assets/img/cross-circle.svg" />
                </v-btn>
            </div>
            <div class="remove-card-content">
                <v-card-title class="remove-card-modal-title px-0 py-0">
                    Czy na pewno chcesz usunąć ścieżkę klienta?
                </v-card-title>
                <v-card-text class="remove-card-modal-content mx-auto">Usunięcie ścieżki "{{data.name}}" spowoduje nieodwracalną jej utratę.</v-card-text>
                <v-card-actions class="buttons">
                    <v-btn
                        text
                        @click="close"
                        class="cancel-button"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        text
                        @click="deleteCustomerJourney"
                        class="confirm-button"
                    >
                        Usuń bezpowrotnie
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        dialog: {
            default: false
        },
        data: {
            default: () => {
                return {
                    title: "example"
                }
            }
        }
    },
    methods: {
        close() {
            this.$emit("close")
        },
        deleteCustomerJourney() {
            this.$emit("deleteCustomerJourney")
        }
    },
    watch: {
        dialog(val) {
            if(!val) {
                this.close();
            }
        }
    }
}
</script>

<style>

</style>