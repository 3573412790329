import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const PERSONA_SELECTED = "PERSONAS_SELECTED";
const PERSONA_UNSELECTED = "PERSONA_UNSELECTED";
const PERSONAS_CLEARED = "PERSONAS_CLEARED";
const CUSTOMER_PATH_SELECTED = "CUSTOMER_PATH_SELECTED";
const CUSTOMER_PATH_UNSELECTED = "CUSTOMER_PATH_UNSELECTED";

export default new Vuex.Store({
  state: {
    selectedPersonas: [],
    customerPathTemplate: null,
  },
  actions: {
    selectPersona({ commit }, payload) {
      commit(PERSONA_SELECTED, payload);
    },
    unselectPersona({ commit }, payload) {
      commit(PERSONA_UNSELECTED, payload);
    },
    clearPersonas({ commit }) {
      commit(PERSONAS_CLEARED);
    },
  },
  mutations: {
    [PERSONA_SELECTED](state, payload) {
      state.selectedPersonas.push(payload);
    },
    [PERSONA_UNSELECTED](state, payload) {
      let unselected = state.selectedPersonas.filter((persona) => {
        return persona.id !== payload.id;
      });
      state.selectedPersonas = unselected;
    },
    [PERSONAS_CLEARED](state) {
      state.selectedPersonas = [];
    },
    [CUSTOMER_PATH_SELECTED](state, payload) {
      state.customerPathTemplate = payload;
    },
    [CUSTOMER_PATH_UNSELECTED](state) {
      state.customerPathTemplate = null;
    },
  },
  getters: {
    getSelectedPersonas(state) {
      return state.selectedPersonas;
    },
  },
});
