<template>
  <v-dialog v-model="dialog" max-width="390px">
    <v-card>
      <div class="new-item">
        <h3>Czy chcesz usunąć kafelek?</h3>
        <div class="actions">
          <v-btn
            class="font-weight-bold mx-3"
            :elevation="0"
            outlined
            @click="setRemoveCardDialog({ dialog: false })"
          >
            Anuluj
          </v-btn>
          <v-btn
            class="font-weight-bold mx-3"
            :elevation="0"
            :color="'#F0004A'"
            @click="remove"
          >
            Usuń
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState("cjstore", [
      "removeCardDialog",
      "removeRowId",
      "removeColumnId",
    ]),
    dialog: {
      get() {
        return this.removeCardDialog;
      },
      set(value) {
        this.setRemoveCardDialog({
          dialog: value,
        });
      },
    },
  },
  methods: {
    ...mapActions("cjstore", ["setRemoveCardDialog", "removeCard"]),
    remove() {
      this.removeCard({
        removeRowId: this.removeRowId,
        removeColumnId: this.removeColumnId,
      });
      this.setRemoveCardDialog({
        dialog: false,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.new-item
  display: flex
  flex-direction: column
  align-items: center
  padding: 30px
  h3
    padding-bottom: 30px
    font-size: 18px
    color: $primary-color
</style>
