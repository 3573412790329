<template>
    <v-dialog
        v-model="dialog"
        max-width="448px"
    >
        <v-card id="save-changes-modal" class="mx-auto">
            <div class="close-btn" >
                <img @click="dialog = false" src="@/assets/img/cross-circle.svg" />
            </div>
            <div id="save-changes-modal-content">
                <v-card-title class="save-changes-modal-title px-0 py-0">
                    Czy chcesz zapisać zmiany?
                </v-card-title>
                <v-card-text class="save-changes-modal-content mx-auto">Cofnij lub zapisz wprowadzone przez siebie zmiany.</v-card-text>
                <v-card-actions class="buttons">
                    <v-btn
                        text
                        @click="cancel"
                        class="cancel-button"
                    >
                        Cofnij
                    </v-btn>
                    <v-tooltip 
                        right
                        v-if="disabled"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                text
                                v-bind="attrs"
                                v-on="on"
                                class="confirm-button disabled"
                            >
                                Zapisz
                            </v-btn>
                        </template>
                        <span>{{disabledMessage}}</span>
                    </v-tooltip>
                    <v-btn
                        v-else
                        text
                        @click="saveChanges"
                        class="confirm-button"
                    >
                        Zapisz
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        disabled: {},
        disabledMessage: {}
    },
    data() {
        return {
            dialog: false,
            actionPerformed: false
        }
    },
    methods: {
        cancel() {
            this.$emit("clearChanges");
            this.dialog = false;
            this.actionPerformed = true;
        },
        saveChanges() {
            this.$emit("saveChanges");
            this.dialog = false;
            this.actionPerformed = true;
        },
        setDialog(value) {
            this.dialog = value;
        },
    },
    watch: {
        dialog(val) {
            if(!val && !this.actionPerformed) {
                this.$emit("closedWithoutAction")
            }
            if(this.actionPerformed) {
                this.actionPerformed = false;
            }
        }
    }
}
</script>