import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import global from "./store/global";
import store from "./store/index";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";

import Toast from "vue-toastification";
import { POSITION, TYPE } from "vue-toastification";
import CloseIcon from './components/icons/CloseIcon.vue';
import AlertCircleIcon from './components/icons/AlertCircleIcon.vue';
import AlertTriangleIcon from './components/icons/AlertTriangleIcon.vue';
import InfoCircleIcon from './components/icons/InfoCircleIcon.vue';
import SuccessCircleIcon from './components/icons/SuccessCircleIcon.vue';
import VCalendar from "v-calendar";

import "vue-toastification/dist/index.css";
import "./plugins/i18n";
import "./plugins/base";
import "./plugins/chartist";
import "./sass/overrides.sass";
import "./sass/components.sass";

Vue.use(VCalendar, {
  componentPrefix: "vc",
  locales: {
    "en-EN": {
      firstDayOfWeek: 2,
      masks: {
        L: "YYYY-MM-DD"
      }
    }
  }
});
Vue.config.productionTip = false;
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
  timeout: 3000,
  maxToasts: 3,
  closeButton: CloseIcon,
  toastDefaults: {
    [TYPE.ERROR]: {
      icon: AlertCircleIcon
    },
    [TYPE.SUCCESS]: {
      icon: SuccessCircleIcon
    },
    [TYPE.INFO]: {
      icon: InfoCircleIcon
    },
    [TYPE.WARNING]: {
      icon: AlertTriangleIcon
    }
  }
});

new Vue({
  router,
  store,
  global,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
