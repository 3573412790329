<template>
  <div class="title-input-wrapper">
    <v-text-field
      class="title-input"
      flat
      solo
      :placeholder="placeholder"
      :value="title"
      @input="emitInput($event)"
      hide-details
    >
      <template v-if="events" v-slot:append>
        <div class="events">
          <div class="remove" @click="remove(id)">
            <TrashIcon />
          </div>
        </div>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import TrashIcon from "@/components/icons/TrashIcon.vue";
import bus from "@/components/personas/eventBus.js";

export default {
  components: {
    TrashIcon,
  },
  props: {
    title: String,
    type: String,
    id: [Number, String],
    placeholder: String,
    inputType: String,
    removeArrayName: String,
    events: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    emitInput($event) {
      bus.$emit("titleInputChange", {
        id: this.id,
        event: $event,
        type: this.inputType,
      });
    },
    remove(id) {
      bus.$emit("remove", { name: this.removeArrayName, id: id });
    },
  },
};
</script>

<style lang="sass">
.events
  display: none
.title-input:hover .events
  display: flex
  .edit, .remove
    cursor: pointer
  .edit
    margin-right: 13px
.title-input.theme--light.v-input input
  font-weight: 600
  font-size: 16px
  display: inline-block
  text-align: left
  width: 100%
  color: $primary-color
  opacity: 1
.title-input.v-text-field .v-input__control
  background-color: rgba(0,0,0,0)
.title-input.theme--light.v-text-field--solo > .v-input__control > .v-input__slot
  background: rgba(0,0,0,0)
</style>
