<template>
    <v-card class="px-6 py-10" id="subscription-modal">
        <v-row>
            <v-col cols="6">
                <div class="subscription-title">Twój team</div>
                <button
                    class="d-flex align-center justify-space-between add-person-button w-80"
                    @click="toggleAddUserModal"
                >
                    Dodaj do teamu
                    <img src="@/assets/img/icon_plus-circle.svg" />
                </button>
            </v-col>
            <v-col cols="6">
                <div class="subscription-title">Subskrypcja</div>
                <div class="mt-2 d-flex subscription-wrapper">
                    <img :src="subscriptionImg" class="subscription-img" />
                    <div class="ml-3 w-80 d-flex justify-space-between flex-column">
                        <label>Nazwa subskrypcji</label>
                        <v-text-field
                            v-model="subscriptionName"
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="Nazwij swoją subskrypcję"
                            class="subscription-name-input"
                        >
                        </v-text-field>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="users"
                    class="table-wrapper"
                >
                    <template v-slot:item.image="{ item }">
                        <div class="d-flex align-center">
                            <img :src="item.image" class="subscription-users-image" /> 
                        </div>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <img @click="$refs.editUserModal.setDialog(true, item)" class="subscriptions-icon cursor-pointer" src="@/assets/img/icon_edit-1.svg" />
                        <img @click="$refs.deleteUserModal.setDialog(true, item.id)" class="ml-7 subscriptions-icon cursor-pointer" src="@/assets/img/icon_trash.svg" />
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <AddUserModal ref="addUserModal" @saveUser="addUser" />
        <EditUserModal ref="editUserModal" @changeUserData="changeUserData" />
        <DeleteUserModal 
            ref="deleteUserModal" 
            @deleteUser="deleteUser" 
        />
        <SaveChangesModal
            ref="saveChangesModal"
            @saveChanges="saveChanges"
            @clearChanges="clearChanges"
            @closedWithoutAction="closedWithoutAction"
            :disabled="!subscriptionName"
            :disabledMessage="disabledMessage"
        /> 
        <div class="d-flex justify-center">
            <v-btn
                @click="saveChanges()"
                class="save-btn"
                :disabled="!userDataChanged"
            >
                Zapisz
            </v-btn>
        </div>    
    </v-card>
</template>

<script>
import EditUserModal from '@/components/admin/accountManagement/subscription/EditUserModal.vue'
import DeleteUserModal from '@/components/admin/accountManagement/subscription/DeleteUserModal.vue'
import AddUserModal from '@/components/admin/accountManagement/subscription/AddUserModal.vue'
import SaveChangesModal from "@/components/admin/accountManagement/profile/SaveChangesModal"
import { http } from "@/plugins/http";

export default {
    components: { EditUserModal, DeleteUserModal, AddUserModal, SaveChangesModal },
    props: {
        accessData: [],
        subscriptionData: {
            default: null
        },
        subscriptionNameProp: {}
    },
    data() {
        return {
            subscriptionImg: "https://cdn.vuetifyjs.com/images/cards/cooking.png",
            headers: [
            { text: '', value: 'image' },
            { text: '', value: 'role' },
            { text: '', value: 'email' },
            { text: '', value: 'time_added' },
            { text: '', value: 'actions', sortable: false },
            ],
            users: [],
            subscriptionName: null,
            closeAccountManagement: false,
            nextTab: null,
            disabledMessage: "Nazwa subskrypcji nie może być pusta"
        }
    },
    computed: {
        userDataChanged() {
            let newSubscriptionName = this.subscriptionName;
            if(newSubscriptionName === "") {
                newSubscriptionName = null;
            }
            const result = newSubscriptionName !== this.subscriptionNameProp;
            return result
        }
    },
    methods: {
        toggleAddUserModal() {
            if(this.subscriptionName !== this.subscriptionNameProp) {
                this.checkChanges("tab-2")
            } else {
                this.$refs.addUserModal.setDialog(true)
            }
        },
        checkChanges(nextTab, closeAccountManagement = false) {
            if(this.userDataChanged) {
                this.closeAccountManagement = closeAccountManagement;
                this.nextTab = nextTab;
                this.$refs.saveChangesModal.setDialog(true);
            } 
        },
        async saveChanges() {
            http.patch(`/subscription`, {name: this.subscriptionName})
            this.$emit(`updateSubscriptionName`, this.subscriptionName)
        },
        closedWithoutAction() {
            this.$emit('setDialog', true);
            this.$emit('setTab', 'tab-2');
        },
        clearChanges() {
            this.subscriptionName = this.subscriptionNameProp;
            this.$emit('setTab', this.nextTab);
            if(this.closeAccountManagement) {
                this.$emit("setDialog", false);
                this.closeAccountManagement = false;
            }
        },
        profilePicture(userId, profilePicture) {
            let result = require('@/assets/img/avatar.png');
            if(profilePicture) {
                let timestamp = new Date().getTime()
                result = process.env.VUE_APP_API_URL + `/super_user_profile_picture/${userId}?${timestamp}`;
            }
            return result
        },
        async deleteUser(id) {
            await http.delete(`/user_access/${id}`)
            this.$emit("getUserAccess");
        },
        async changeUserData(userData) {
            await http.patch(`/user_access/${userData.id}`, { role_name: userData.role })
            this.$emit("getUserAccess");
        },
        async addUser(user) {
            await http.post('/subscription_invite', {
                email: user.email,
                role: user.role
            })
            this.$emit("getUserAccess");
        },
        formatDate(date) {
            const formattedDate = new Date(date).toLocaleDateString().split(".").join("/")
            return formattedDate;
        },
        setUsersData() {
            if(this.accessData) {
                this.users = this.accessData;
                if(this.accessData.length > 0) {
                    this.users.map((user) => {
                        user.time_added = this.formatDate(user.time_added)
                        if(user.user_cnx) {
                            user.image = this.profilePicture(user.user_cnx.id, user.user_cnx.profile_picture)
                        } else {
                            user.image = this.profilePicture()
                        }
                    })
                }
            }   
        }
    },
    mounted() {
        this.setUsersData()
        this.subscriptionName = this.subscriptionNameProp;
    },
    watch: {
        accessData() {
           this.setUsersData();
        },
        subscriptionNameProp(val) {
            this.subscriptionName = val;
        }
    }
}
</script>