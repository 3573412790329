<template>
  <v-app id="admin-layout">
    <v-navigation-drawer app v-model="drawer" class="admin-menu">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            <img class="width-100" src="../assets/img/logo_canvax_black.svg" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list dense nav class="admin-nav">
        <router-link
          v-for="item in items"
          :key="item.title"
          :to="item.link"
          class="admin-link"
        >
          <v-list-item
            :class="routePath === item.link ? 'active' : ''"
            class="admin-menu-item"
          >
            <v-list-item-icon>
              <img class="admin-icon" :src="getImgUrl(item.icon)" />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="pl-2 pt-0 admin-menu-text">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app class="admin-top-bar">
      <v-btn
        class="ma-2 admin-menu-button"
        icon
        @click.stop="drawer = !drawer"
        v-if="!drawer"
        color="black"
      >
        <v-icon>mdi-reorder-horizontal</v-icon>
      </v-btn>

      <div class="admin-top-bar mt-5 d-flex align-center justify-end">
        <Notifications />
        <AdminDropdown
          @showAccountManagement="$refs.accountManagement.setDialog(true)"
        />
      </div>
    </v-app-bar>
    <v-main>
      <AccountManagement
        ref="accountManagement"
        @close="showAccountManagement = false"
      />
      <v-container fluid>
        <router-view></router-view>
        <Hints />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AdminDropdown from "@/components/AdminDropdown";
import AccountManagement from "@/components/admin/accountManagement/AccountManagement";
import Notifications from "@/components/Notifications.vue";
import Hints from "@/components/hints/Hints.vue";

export default {
  name: "App",
  data() {
    return {
      items: [
        { title: "Persony", icon: "icon_persona.svg", link: "/personas" },
        {
          title: "Ścieżki klienta",
          icon: "icon_customer_journey.svg",
          link: "/dashboard",
        },
        { title: "Ankiety", icon: "icon_survery.svg", link: "/surveys" },
        { title: "Analityka", icon: "icon_analytics.svg", link: "/analytics" },
      ],
      drawer: null,
      mini: false,
      notification: false,
      showAccountManagement: false,
    };
  },
  components: { AdminDropdown, AccountManagement, Notifications, Hints },
  computed: {
    routePath() {
      return this.$route.path;
    },
  },
  methods: {
    getImgUrl(pic) {
      return require(`../assets/img/${pic}`);
    },
  },
};
</script>
