<template>
  <div v-if="col.type && col.type == 'row-title'">
    <input
      @input="
        (e) => {
          labelInput(e, rowId, columnId);
          setRowTitle({ rowId: rowId, title: e.target.value });
        }
      "
      :value="col.title"
      :placeholder="col.title"
    />
    <!-- Communication labels -->
    <template v-if="row.type == 'communication'">
      <div class="channels">
        <template v-for="(channel, id) in channels">
          <template v-if="channel.visible">
            <div :key="`channel_${id}`" class="channel">
              <div class="icon-wrapper">
                <BaseIcon :path="`icons/${channel.icon}.vue`" />
              </div>
              <h4>{{ channel.label }}</h4>
            </div>
          </template>
        </template>
      </div>
    </template>
    <template v-if="!row.options.includes('stage')">
      <div class="add add-row" @click="addRow(rowId)">
        <PlusCircleFillIcon />
      </div>
      <div
        v-if="
          ['stage', 'card', 'persona', 'graph', 'communication'].indexOf(
            row.type
          ) == -1
        "
        class="remove-row"
        @click="removeRow(rowId)"
      >
        <MinusCircleFillIcon />
      </div>
    </template>
  </div>
</template>

<script>
import PlusCircleFillIcon from "@/components/icons/PlusCircleFillIcon.vue";
import MinusCircleFillIcon from "@/components/icons/MinusCircleFillIcon.vue";
import BaseIcon from "@/components/customerJourney/BaseIcon.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    PlusCircleFillIcon,
    MinusCircleFillIcon,
    BaseIcon,
  },
  props: {
    col: Object,
    row: Object,
    columnId: Number,
    rowId: Number,
  },
  computed: {
    ...mapState("cjstore", ["channels"]),
  },
  methods: {
    ...mapActions("cjstore", [
      "setLabelInput",
      "setRowTitle",
      "addRow",
      "removeRow",
    ]),
    labelInput(e, rowId, columnId) {
      this.setLabelInput({
        rowId: rowId,
        columnId: columnId,
        value: e.target.value,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.add-row,
.remove-row
  position: absolute
  width: 20px
  height: 20px
  z-index: 999
  cursor: pointer
  opacity: 0
  transition: opacity .2s ease-out
.add-row
  bottom: -10px
  right: 30px
.remove-row
  bottom: -10px
  right: 60px
.row-title
  padding: 20px
  background: #fff
  input
    font-family: 'Roboto'
    font-style: normal
    font-weight: 600
    font-size: 16px
    color: $primary-color
    outline: 0
.channels
  margin-top: 8px
.channel
  display: flex
  align-items: center
  min-height: 54px
  .icon-wrapper,
  svg
    width: 20px
    height: 20px
  .icon-wrapper
    margin: 17px 13px 17px 0
  h4
    font-weight: 400
    color: $primary-color
    font-family: 'Roboto'
</style>
