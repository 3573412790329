<template>
  <div id="Personas">
    <div class="position-fixed button-wrapper">
      <AddNewButton 
        @clicked="openPersona" 
        :label="'Dodaj nową personę'" />
    </div>
    <div class="personas-grid">
      <template v-for="(persona, id) in personas">
        <PersonItem 
          :key="`person_${id}`"
          :persona="{
            basic_info: persona.basic_info,
            id: persona.id,
            editId: id,
            job_title: persona.detailed_info.job_title,
            last_modification_ts: persona.last_modification_ts
          }"/>
      </template>
    </div>
    <v-dialog v-model="openDialog" width="1020" content-class="personas-dialog">
      <v-card
        class="person-card"
        color="#F6F6F6"
        :style="{ backgroundImage: `url(${require('@/assets/img/person_dialog_bg.png')})` } ">
          <button data-html2canvas-ignore="true"
            class="close-dialog"
            @click="close()">
            <CloseButton></CloseButton>
          </button>
          <div class="content grid">
            <!-- Person info -->
            <BasicInfo 
              :basic_info="basic_info"
              :detailed_info="detailed_info"
              @saveClicked="isEdit ? editPersona() : savePersona()"
              @undo="undo"
              @redo="redo"
              @export="exportPersona()" 
            ></BasicInfo>
            <div class="main grid">
              <!-- Characteristic cards -->
              <div class="sliders">
                <template v-for="(card, id) in sliders">
                  <CharacteristicCard 
                    :card="card" 
                    :type="'Slider'" 
                    :inputType="'sliders'"
                    :id="id" 
                    :key="`slider_card_${id}`" />
                </template>
                <div class="add-btn" @click="addSlider()" data-html2canvas-ignore="true">Dodaj nowe pole z suwakami</div>
              </div>
              <div class="inputs">
                <template v-for="(card, id) in inputs">
                  <CharacteristicCard 
                    :card="card"
                    :type="'Input'"
                    :inputType="'inputs'"
                    :id="id" 
                    :key="`input_card_${id}`" />
                </template>
                <div class="add-btn" @click="addInput()" data-html2canvas-ignore="true">Dodaj nowe pole z pozycjami</div>
              </div>
            </div>
            <div class="sidebar">
              <template v-for="(tag, id) in tags">
                <TagsArea 
                  :key="`tag_${id}`" 
                  :title="tag.title"
                  :items="tag.items"
                  :id="id" />
              </template>
              <div class="add-btn sidebar" @click="addTagsArea()" data-html2canvas-ignore="true">Dodaj nowe pole z tagami</div>
            </div>
          </div>
          <SourceFiles 
            v-if="isEdit"
            :personaId="editId"
            :isOpened="openDialog"
          />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddNewButton from '@/components/buttons/AddNewButton.vue';
import CloseButton from '@/components/buttons/CloseButton.vue';
import CharacteristicCard from '@/components/personas/CharacteristicCard.vue';
import BasicInfo from '@/components/personas/BasicInfo.vue';
import TagsArea from '@/components/personas/TagsArea.vue';
import PersonItem from '@/components/personas/PersonItem.vue';
import SourceFiles from '@/components/personas/sourceFiles/SourceFiles.vue';
import bus from '@/components/personas/eventBus.js';
import { http } from "../plugins/http";
import html2pdf from "html2pdf.js";
import { mapActions } from "vuex";

export default {
  components: {
    AddNewButton,
    CloseButton,
    CharacteristicCard,
    BasicInfo,
    TagsArea,
    PersonItem,
    SourceFiles
  },
  data(){
    return {
      indexOfChange: -1,
      isEdit: false,
      editId: null,
      openDialog: false,
      personas: [],
      basic_info: {
        name: "",
        bio: "",
        image_id: 0,
        color: "",
      },
      detailed_info: {
        age: 0,
        job_title: "",
        salary: 0,
        location: "",
      },
      tags: [],
      sliders:[],
      inputs:[],
      isDataChanged: false
    }
  },
  watch: {
    openDialog(newVal){
      if(newVal === false){  
        this.clearPersonas();
        this.getPersonas();
        this.indexOfChange = -1;
        localStorage.removeItem("persona_steps")
      } else {
        const array = []
        const jsonArr = JSON.stringify(array);
        localStorage.setItem("persona_steps", jsonArr);
        this.saveChanges();
      }
    }
  },
  mounted(){
    var self = this;
    this.getPersonas()
    console.log('mounted')
    bus.$on('editPersona', value => {
      if(this.personas[value]){
        this.basic_info = this.personas[value].basic_info;
        this.detailed_info = this.personas[value].detailed_info;
        this.sliders = this.personas[value].sliders;
        this.inputs = this.personas[value].inputs;
        this.tags = this.personas[value].tags;
        this.openDialog = true;
        this.isEdit = true;
        this.editId = this.personas[value].id
      }
    });

    bus.$on('duplicatePersona', value => {
      this.$nextTick(() => {
        if(this.personas[value]){
          this.basic_info = this.personas[value].basic_info;
          this.detailed_info = this.personas[value].detailed_info;
          this.sliders = this.personas[value].sliders;
          this.inputs = this.personas[value].inputs;
          this.tags = this.personas[value].tags;
          this.savePersona()
        }
      })
    })

    bus.$on('removePersona', value => {
        http.delete(`/persona/${value}`)
        .then(() => {
          self.getPersonas()
        })
    })

    bus.$on('titleInputChange', value => {
      if(value.type == 'basic_info'){
        this.basic_info.name = value.event
      }
      if(this[value.type].length) {
        this[value.type][value.id].title = value.event
      }
      this.saveChanges();
    });

    bus.$on('sliderChange', value => {
      if(value.type == 'Slider' && this.sliders[value.id]){
        this.sliders[value.id].items[value.itemId].value = value.event
      }
      this.saveChanges();
    })

    bus.$on('updateLabel', value => {
      if(value.type == 'Slider' && this.sliders[value.id]){
        this.sliders[value.id].items[value.itemId].labels[value.side] = value.event
      }
      this.saveChanges();
    })

    bus.$on('inputChange', value => {
      if(value.type == 'detailed_info'){
          this.detailed_info[value.id] = value.event
      }
      if(value.type == 'Input' && this.inputs[value.id]){
        this.inputs[value.id].items[value.itemId].value = value.event
      }
      this.saveChanges();
    })

    bus.$on('bioChange', value => {
      this.basic_info.bio = value;
      this.saveChanges();
    });
    bus.$on('selectColor', value => {
      this.basic_info.color = value;
      this.saveChanges();
    });
    bus.$on('selectPhoto', value => {
      this.basic_info.image_id = value;
      this.saveChanges();
    });
    
    bus.$on('addTag', val => {
      if(this.tags[val.id]){
        this.tags[val.id].items.push({label: val.label});
      }
      this.saveChanges();
    });
    bus.$on('removeTag',this.removeTag);
    bus.$on('remove', this.removeFromArray);
    bus.$on('removeCardItem', this.removeCardItem);
    bus.$on('addCardItem', this.addCardItem);
  },
  destroyed(){
    bus.$off('editPersona');
    bus.$off('duplicatePersona')
    bus.$off('removePersona')
    bus.$off('titleInputChange');
    bus.$off('sliderChange');
    bus.$off('updateLabel');
    bus.$off('inputChange');
    bus.$off('bioChange');
    bus.$off('selectColor');
    bus.$off('selectPhoto');
    bus.$off('addTag');
    bus.$off('removeTag');
    bus.$off('remove');
    bus.$off('removeCardItem');
    bus.$off('addCardItem');
  },
  methods:{
    ...mapActions(["setLoader"]),
    undo() {
      let personaStepsStr = localStorage.getItem("persona_steps");
      let personalStepsParsed = JSON.parse(personaStepsStr);
      if(this.indexOfChange > 0) {
        this.indexOfChange--;
        const changesToApply = personalStepsParsed[this.indexOfChange][`persona_step_${this.indexOfChange}`]
        this.setPersonaChanges(changesToApply)
      }
    },
    redo() {
      let personaStepsStr = localStorage.getItem("persona_steps");
      let personalStepsParsed = JSON.parse(personaStepsStr);
      if(this.indexOfChange+1 !== personalStepsParsed.length) {
        this.indexOfChange++;
        const changesToApply = personalStepsParsed[this.indexOfChange][`persona_step_${this.indexOfChange}`]
        this.setPersonaChanges(changesToApply)
      }
    },
    saveChanges() {
      let step = {}
      step[`persona_step_${this.indexOfChange+1}`] = {
          basic_info: {...this.basic_info},
          detailed_info: {...this.detailed_info},
          sliders: [...this.sliders],
          inputs: [...this.inputs],
          tags: [...this.tags]
        }
      let personaStepsStr = localStorage.getItem("persona_steps");
      let personalStepsParsed = JSON.parse(personaStepsStr);

      if(personalStepsParsed.length - 1 !== this.indexOfChange) {
        personalStepsParsed.length = parseInt(this.indexOfChange) + 1;
      }
      personalStepsParsed.push(step)
      this.indexOfChange++;
      const changesToBeSaved = JSON.stringify(personalStepsParsed)
      localStorage.setItem("persona_steps", changesToBeSaved);
    },
    setPersonaChanges(changes) {
      console.log(changes)
      this.basic_info = {
        name: changes.basic_info.name,
        bio: changes.basic_info.bio,
        image_id: changes.basic_info.image_id,
        color: changes.basic_info.color,
      },
      this.detailed_info = {
        age: changes.detailed_info.age,
        job_title: changes.detailed_info.job_title,
        salary: changes.detailed_info.salary,
        location: changes.detailed_info.location,
      },
      this.tags = [...changes.tags];
      this.sliders = [...changes.sliders];
      this.inputs = [...changes.inputs];
    },
    openPersona(val){
      this.isEdit = false
      this.openDialog = val
    },
    clearPersonas(){
      this.basic_info = {
        name: "",
        bio: "",
        image_id: 0,
        color: "",
      },
      this.detailed_info = {
        age: 0,
        job_title: "",
        salary: 0,
        location: "",
      },
      this.tags = [];
      this.sliders = [];
      this.inputs = [];
      this.editId = null
    },
    mapPersonaRequestBody(){
      return {
        is_template: true,
        basic_info: this.basic_info,
        detailed_info: this.detailed_info,
        sliders: this.sliders.map(item => {
          return {
            title: item.title,
            items: item.items
          }
        }),
        inputs: this.inputs.map(item => {
          return {
            title: item.title,
            items: item.items
          }
        }),
        tags: this.tags.map(item => {
          return {
            title: item.title,
            items: item.items
          }
        })
      }
    },
    savePersona(){
      var self = this;
      var body = this.mapPersonaRequestBody();
      http.post('/personas', body)
        .then(() => { self.clearPersonas() })
        .then(() => { self.getPersonas() })
        .then(() => { self.close() })
    },
    editPersona(){
      var self = this;
      var body = this.mapPersonaRequestBody();
      http.patch(`/persona/${this.editId}`, body)
        .then(() => { self.clearPersonas() })
        .then(() => { self.getPersonas() })
        .then(() => { self.close() })
    },
    getPersonas(){
      http
        .get('/personas')
        .then((response) => {
            this.personas = response.data.data
        });
    },
    exportPersona() {
      console.log("Trying to export to pdf")
      var elements = document.querySelectorAll("svg.arrow, svg.baloon");
      var shapes = document.querySelectorAll(".card.arrow, .card.baloon");
      new Promise((resolve) => {
        this.setLoader(true);
        elements.forEach((item) => {
          item.style.height = `${item.clientHeight}px`;
          item.style.width = `${item.clientWidth}px`;
        });
        shapes.forEach((item) => item.classList.add("processed"));
        resolve();
      })
        .then(() => {
          var elementToPrint = document.querySelector(".person-card");
          return elementToPrint;
        })
        .then((elementToPrint) => {
          const width = elementToPrint.offsetWidth;
          const height = elementToPrint.offsetHeight;
          const opt = {
            margin: 1,
            filename: "myfile.pdf",
            image: { type: "jpeg" },
            html2canvas: {
              scale: 2,
              scrollX: 0,
              scrollY: 0,
            },
            jsPDF: {
              unit: "px",
              format: [width, height],
              orientation: "portrait",
            },
          };
          return html2pdf().set(opt).from(elementToPrint).toPdf().get("pdf");
        })
        .then((pdf) => {
          window.open(pdf.output("bloburl"), "_blank");
        })
        .then(() => {
          elements.forEach((item) => {
            item.style.height = "";
            item.style.width = "";
          });
          shapes.forEach((item) => item.classList.remove("processed"));
          this.setLoader(false);
        });
    },
    close(){
      this.editId = null;
      this.openDialog = false;
      this.clearPersonas();
    },
    addSlider(){
      this.sliders.push({
        title: '',
        type: 'Slider',
        items: []
      })
      this.saveChanges();
    },
    addInput(){
      this.inputs.push({
        title: '',
        type: 'Input',
        items: []
      })
      this.saveChanges();
    },
    removeCardItem(data){
      if(data.type == 'Input'){
        this.inputs[data.id].items.splice(data.itemId,1);
      }
      if(data.type == 'Slider'){
        this.sliders[data.id].items.splice(data.itemId,1);
      }
      this.saveChanges();
    },
    addCardItem(data){
      if(data.type == 'Slider' && this.sliders[data.id]){
        this.sliders[data.id].items.push({
          labels: {
            left: "Lewa",
            right: "Prawa"
          },
          value: 50
        })
        this.saveChanges();
      }
      if(data.type == 'Input' && this.inputs[data.id]){
        this.inputs[data.id].items.push({
          value: ""
        })
        this.saveChanges();
      }
    },
    removeFromArray(data){
      if(this[data.name][data.id]){
        this[data.name].splice(data.id,1);
      }
      this.saveChanges();
    },
    addTagsArea(){
      this.tags.push({
        title: '',
        items: []
      })
      this.saveChanges();
    },
    removeTag(ids){
      if(this.tags[ids.areaId]){
        this.tags[ids.areaId].items.splice(ids.tagId,1);        
      }
      this.saveChanges();
    }
  }
}
</script>

<style lang="sass">
  .personas-dialog
    &.v-dialog
      overflow-y: scroll !important
      -ms-overflow-style: none !important
      scrollbar-width: none !important
      &::-webkit-scrollbar 
        display: none !important
</style>

<style lang="sass" scoped>
  .personas-grid
    display: flex
    margin: 45px 0 45px 35px 
    flex-wrap: wrap
    margin-top: 110px
  .button-wrapper
    top: 0px 
    z-index: 1
    height: 162px
    width: 100%
    background: #f3f3f3
    button
      margin-top: 86px

  .grid 
    display: grid
    gap: 16px
    &.content
      grid-template-columns: 2fr 1fr
    &.main 
      grid-template-columns: 1fr 1fr
  .person-card
    padding: 14px 44px 20px 25px
    position: relative
    background-position-y: top
    background-size: 100% auto
  ::v-deep .v-sheet.v-card:not(.v-sheet--outlined)
    border-radius: 16px
    box-shadow: 0px 2px 30px rgba(46, 27, 117, 0.05)
  .close-dialog
    position: absolute
    right: 12px
    top: 12px
  .filler 
    width: 30px
    height: 30px
    background: #cadadf
  .sliders, .inputs
    display: grid
    gap: 16px
  .add-btn
    background-color: #fff
    cursor: pointer
    border-radius: 8px
    padding: 8px
    border: 1px solid #8392AB
    color: #8392AB
    text-align: center
    align-self: baseline
    transition: border-color .3s ease-out, color .3s ease-out
    &:hover
      border-color: #2E1B75
      color: #2E1B75
    &.sidebar
      margin-top: 45px
    
</style>