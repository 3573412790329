<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import ChartJSdragDataPlugin from "chartjs-plugin-dragdata";
import ChartDataLabels from "chartjs-plugin-datalabels";
import store from "@/store/index";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  ChartJSdragDataPlugin,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);
ChartJS.unregister(ChartDataLabels);

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  data() {
    var self = this;
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.4,
        animation: false,
        cubicInterpolationMode: "monotone",
        plugins: {
          legend: {
            display: false,
            title: {
              display: false,
            },
          },
          dragData: {
            round: 0,
            magnet: {
              to: Math.round,
            },
            onDragEnd: function (e, personaId, colId, value) {
              store.dispatch("cjstore/changeChartValues", {
                personaId: personaId,
                colId: colId,
                value: value,
              });
            },
          },
          tooltip: {
            enabled: false,
            external: function (context) {
              let tooltipEl = document.getElementById("chartjs-tooltip");

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement("div");
                tooltipEl.id = "chartjs-tooltip";
                tooltipEl.innerHTML = "<table></table>";
                document.body.appendChild(tooltipEl);
              }

              // Hide if no tooltip
              const tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set caret Position
              tooltipEl.classList.remove("above", "below", "no-transform");
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add("no-transform");
              }

              function getBody(bodyItem) {
                return bodyItem.lines;
              }

              // Set Text
              if (tooltipModel.body) {
                // const titleLines = tooltipModel.title || [];
                const bodyLines = tooltipModel.body.map(getBody);

                let innerHtml = "<tbody>";

                bodyLines.forEach(function (body, i) {
                  const colors = tooltipModel.labelColors[i];
                  const bodyPersonaId =
                    tooltipModel.dataPoints[i].dataset.imageId;
                  let style = "background:" + colors.backgroundColor;
                  style += "; border-color:" + colors.borderColor;
                  style += "; border-width: 2px";
                  const span = '<span style="' + style + '"></span>';
                  let images = body.map(() => {
                    let toolTipStyle =
                      "height: 35px;" +
                      "width:35px;" +
                      "overflow:hidden;" +
                      "border:2px solid " +
                      colors.borderColor +
                      ";" +
                      "border-radius:35px;" +
                      "display:flex;" +
                      "justify-content:center";
                    const personImg =
                      '<div class="persona-wrapper" style="' +
                      toolTipStyle +
                      '">' +
                      '<img style="height:100%" src="' +
                      self.getPersonaAvatar(bodyPersonaId) +
                      '" />' +
                      "</div>";
                    return (
                      '<div class="persona-tooltip" style="display:flex; align-items:flex-end">' +
                      personImg +
                      "</div>"
                    );
                  });
                  innerHtml +=
                    "<tr><td>" + span + images.join("") + "</td></tr>";
                });
                innerHtml += "</tbody>";

                let tableRoot = tooltipEl.querySelector("table");
                tableRoot.innerHTML = innerHtml;
              }

              const position = context.chart.canvas.getBoundingClientRect();
              // const bodyFont = ChartJS.helpers.toFont(tooltipModel.options.bodyFont);

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.position = "absolute";
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + "px";
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + "px";
              tooltipEl.style.padding =
                tooltipModel.padding + "px " + tooltipModel.padding + "px";
              tooltipEl.style.pointerEvents = "none";
            },
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
          },
        },
        scales: {
          x: {
            display: false,
            offset: true,
          },
          y: {
            min: 0,
            max: 4,
            ticks: {
              stepSize: 1,
              display: false,
            },
            grid: {
              drawBorder: false,
              lineWidth: 2,
              color: function (context) {
                let tickColors = [
                  "#EB5757",
                  "#F2994A",
                  "#F2C94C",
                  "#6FCF97",
                  "#27AE60",
                ];
                return tickColors[context.tick.value];
              },
            },
            offset: true,
          },
        },
      },
    };
  },
  methods: {
    getPersonaAvatar(id) {
      return require(`@/assets/img/personas/person_${id || 1}.jpg`);
    },
  },
};
</script>

<style lang="sass">
::v-deep .persona-wrapper
  overflow: hidden
  width: 22px
  height: 22px
  border-radius: 22px
  border: 1px solid #fff
  display: flex
  justify-content: center
  img
    height: 100%
</style>
