<template>
  <div
    class="channel-radio"
    :class="{ selected: selected }"
    :ref="`channel_radio_${rowId}_${columnId}_${id}`"
    @click="
      toggleChannel({
        channelId: id,
        rowId: rowId,
        colId: columnId,
        refs: $refs,
      })
    "
  >
    <hr />
    <div :class="['radio']"></div>
    <hr />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    rowId: Number,
    columnId: Number,
    id: Number,
  },
  data() {
    return {
      selected: false,
    };
  },
  mounted() {
    this.selected = this.rows[this.rowId].cols[this.columnId].channels[this.id];
  },
  computed: {
    ...mapState("cjstore", ["channels", "rows"]),
  },
  watch: {
    rows(newVal) {
      // console.log(
      //   newVal[this.rowId].cols[this.columnId].channels[this.id] == true
      // );
      let ref = `channel_radio_${this.rowId}_${this.columnId}_${this.id}`;
      this.selected =
        newVal[this.rowId].cols[this.columnId].channels[this.id] == true
          ? true
          : false;
      this.selected
        ? this.$refs[ref].classList.add("selected")
        : this.$refs[ref].classList.remove("selected");
    },
  },
  methods: {
    ...mapActions("cjstore", ["toggleChannel"]),
  },
};
</script>

<style lang="sass" scoped>
.channel-radio
  align-items: center
  padding: 0 18px
  cursor: pointer
  hr
    width: calc(50% - 48px)
    height: 1px
    background-color: #C4C4C4
    border: none
  .radio
    margin: 0 30px
    width: 14px
    height: 14px
    background-color: #BDBDBD
    border-radius: 14px
    transition: background-color .2s ease-out
.channel-radio:hover .radio
  background-color: #52FBDE
.channel-radio.selected .radio
  background-color: $primary-color
</style>
