<template>
    <div id="utm-sources-list" v-if="data">
        <span class="analytics-title">Analityka: Strony wejścia</span>
        <div id="utm-sources-list-content">
            <div class="content-wrapper">
                <v-data-table
                    :headers="headers"
                    :items="dataToDisplay"
                    v-scroll:#virtual-scroll-table="onScroll"
                    :items-per-page="dataToDisplay.length"
                >
                </v-data-table>
            </div>
            <div class="content-wrapper px-4">
                <p class="text-size-2 text-color-primary font-weight-bold chartTitle">Udział procentowy poszczególnych źródeł</p>
                <PieChart
                    :width="350"
                    :height="350"
                    :chartData="{
                        labels: chartData.labels,
                        datasets: [
                            { 
                                data: chartData.data,
                                backgroundColor: ['#2e1b75', '#452d85', '#5b3f96', '#7053a7', '#8666b9', '#9b7ac9', '#af8edb', '#c5a3ed', '#dab9fe', '#ecdcfe', '#dddbe8'],
                            }, 
                        ]
                    }"
                />
            </div>
        </div>
    </div>
</template>

<script>
import PieChart from '@/components/analytics/PieChart'

export default {
    components: { PieChart },
    data() {
        return {
            headers: [
                {
                    text: 'źródło',
                    align: 'start',
                    sortable: true,
                    value: 'name',
                },
                {
                    text: 'ilość wejść',
                    align: 'end',
                    sortable: true,
                    value: 'value',
                },
            ],
            directSourceName: "wejścia bezpośrednie (direct)",
            remainingSourcesName: "pozostałe",
        }
    },
    props: {
        data: {}
    },
    computed: {
        allData() {
            let result = [];
            if(this.data) {
                const data = this.data;
                data[this.directSourceName] = data[`(direct)`];
                delete data.start_date;
                delete data.end_date;
                delete data[`(direct)`];

                result = Object.entries(data);
                result = result.map((element) => {
                    return {
                        name: element[0],
                        value: element[1]
                    }
                })
                result = this.sortData([...result])
            }
            
            return result;
        },
        dataToDisplay() {
            let result = [];
            if(this.allData) {
                result = [...this.allData];
                if(result.length > 10) {
                    let sumOfTheRemaining = [...this.allData];
                    sumOfTheRemaining.splice(0, 10)
                    sumOfTheRemaining = sumOfTheRemaining.reduce((a, b) => a + (b.value || 0), 0);
                    result.splice(10, result.length)
                    result.push({
                        value: sumOfTheRemaining,
                        name: this.remainingSourcesName
                    })
                }    
                result = this.sortData([...result]);
            }    
            return result;
        },
        chartData() {
            let result = {
                data: [],
                labels: []
            }
            if(this.allData) {
                let sortedData =  this.sortData([...this.allData]);
                let sumOfTheRemaining = 0;
                sortedData.forEach((element, index) => {
                    if(index < 10) {
                        result.data.push(element.value);
                        result.labels.push(element.name);
                    } else {
                        sumOfTheRemaining+=element.value;
                    }
                });
                if(sortedData.length > 10) {
                    result.data.push(sumOfTheRemaining);
                    result.data = this.sortData(result.data);
                    result.labels.splice(result.data.indexOf(sumOfTheRemaining),0,this.remainingSourcesName);
                }
            }
            const sumOfAllData = result.data.reduce((a, b) => a + b, 0);
            result.data = result.data.map((element) => {
                return this.turnIntoPercentage(element, sumOfAllData);
            })
            return result;
        },    
    },
    methods: {
        turnIntoPercentage(num, sum) {
            const result = ((num*100)/sum).toFixed(2)
            return +result;
        },
        sortData(data) {
            const compareSources = (sourceA, sourceB) => {
                let valueA = sourceA;
                let valueB = sourceB;
                if(sourceA.value && sourceB.value) {
                    valueA = sourceA.value;
                    valueB = sourceB.value;
                } 
                let comparison = 0;
                if (valueA > valueB) {
                    comparison = 1;
                } else if (valueA < valueB) {
                    comparison = -1;
                }
                return comparison * -1;
            }
            let result = [];
            result = data.sort(compareSources)
            return result
        },
    }
}
</script>