<template>
    <v-card class="survey-config-modal">
        <div class="survey-config-wrapper">
            <div class="survey-config-close-btn" >
                <img class="cursor-pointer" @click="$emit('close')" src="@/assets/img/cross-circle.svg" />
            </div>
            <div class="survey-config-header">
                <img src="@/assets/img/icon_success.svg" />
                <span class="font-weight-bold ml-3">Instrukcja integracji systemu canvax z Twoją stroną</span>
            </div>
            <ol class="content">
                <li>Przygotuj konfigurację GA4 we współpracy z GTM na swojej stronie.</li>
                <li>Aktywuj eksport danych z GA4 do BigQuery.</li>
                <li>Dodaj potrzebną konfigurację GTM:
                    <ul>
                        <li>Triggers:
                            <ul>
                                <li>"Canvax - survey display"
                                    <ul>
                                        <li>Trigger type: "Custom Event"</li>
                                        <li>Event name: 'canvax_poll_display'</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <li>"Canvax - survey submit"
                                    <ul>
                                        <li>Trigger type: "Custom Event"</li>
                                        <li>Event name: 'canvax_poll_submit'</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>Variables:
                            <ul>
                                <li>"DLV - SurveyID"
                                    <ul>
                                        <li>Type: DataLayer Variable</li>
                                        <li>Data Layer Variable Name: 'canvax.survey.id'</li>
                                    </ul>
                                </li>
                                <li>"DLV - SurveyAnswer"
                                    <ul>
                                        <li>Type: DataLayer Variable</li>
                                        <li>Data Layer Variable Name: 'canvax.survey.answer'</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>Tags:
                            <ul>
                                <li>Standardowy tag z konfiguracją GA4</li>
                                <li>"Canvax - poll driver"
                                    <ul>
                                        <li>Tag type: Custom HTML</li>
                                        <li>HTML:
                                            <div class="code-container">
                                                &#60;script async src="{{url}}/fetch_survey/{{subscriptionId}}"&#62;
                                                &#60;/script&#62;
                                            </div>
                                        </li>
                                        <li>Triggering: "All Pages"</li>
                                    </ul>
                                </li>
                                <li>"GA4 - Canvax - poll display"
                                    <ul>
                                        <li>Tag type: "GA: GA4 Event"</li>
                                        <li>Configuration tag: Twoja konfiguracja GA4, pierwszy tag na tej liście</li>
                                        <li>Event name: "canvax_poll_display"</li>
                                        <li>Triggering: "Canvax - survey display"</li>
                                        <li>Event parameters
                                            <ul>
                                                <li>poll_id: {{leftCurlyBracket}}{{leftCurlyBracket}}DLV - SurveyID{{rightCurlyBracket}}{{rightCurlyBracket}}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>"GA4 - Canvax - poll submit"
                                    <ul>
                                        <li>Tag type: "GA: GA4 Event"</li>
                                        <li>Configuration tag: Twoja konfiguracja GA4, pierwszy tag na tej liście</li>
                                        <li>Event name: "canvax_poll_submit"</li>
                                        <li>Triggering: "Canvax - survey submit"</li>
                                        <li>Event parameters
                                            <ul>
                                                <li>poll_id: {{leftCurlyBracket}}{{leftCurlyBracket}}DLV - SurveyID{{rightCurlyBracket}}{{rightCurlyBracket}}</li>
                                                <li>answers: {{leftCurlyBracket}}{{leftCurlyBracket}}DLV - SurveyAnswer{{rightCurlyBracket}}{{rightCurlyBracket}}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>Ankiety już się ładują, a dane są gromadzone w BigQuery (z zależnym od Twojej subskrypcji opóźnieniem).</li>
                <li>Aby uzyskać dostęp do Twoich danych tutaj, skonfiguruj łączenie konta z GCP w menu profil / Zarządzanie kontem / Analityka.</li>
            </ol> 
        </div>
    </v-card>
</template>

<script>
 export default {
    data() {
        return {
            leftCurlyBracket: "{",
            rightCurlyBracket: "}"
        }
    },
    computed: {
        subscriptionId() {
            let result = 0;
            if(this.$store.getters.getSubscriptionId) {
                result = this.$store.getters.getSubscriptionId;
            } else {
                const user = this.$store.getters.getUser;
                result = user.user_access[0].subscription_id;
            }
            return result;
        },
        url() {
            return process.env.VUE_APP_API_URL;
        }
    }
 }
 </script>