<template>
  <button
    @click="$emit('clicked', true)"
    class="d-flex align-center justify-space-between add-button"
  >
    {{label}}
    <img src="@/assets/img/icon_plus-circle.svg" />
  </button>
</template>

<script>
export default {
  props: {
    label: {}
  }
}
</script>

<style lang="sass" scoped>
.add-button
  background-color: white
  color: $primary-color !important
  text-align: left !important
  padding: 10px
  border: 2px solid transparent
  margin-top: 10px
  border-radius: 16px
  height: 54px
  min-width: 250px
  margin-left: 45px !important
  &:hover
    border: 2px solid $aquamarine-color
</style>