function arraysHasCommon(array1, array2) {
  let hasCommon = false;
  if (array1.length == 0 || array2.length == 0) {
    return hasCommon;
  }
  first: for (var i = 0; i <= array1.length; i++) {
    for (var j = 0; j <= array2.length; j++) {
      if (array1[i] && array2[j] && array1[i] == array2[j]) {
        hasCommon = true;
        break first;
      }
    }
  }
  return hasCommon;
}

export default {
  arraysHasCommon,
};
