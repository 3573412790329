<template>
  <div
    v-if="col.type !== 'row-title' && row.type == 'communication'"
    class="channel-radios"
  >
    <template
      v-for="(channel, id) in channels.filter(
        (channel) => channel.visible == true
      )"
    >
      <ChannelRadio
        :key="`channel_radio_${rowId}_${columnId}_${id}`"
        :rowId="rowId"
        :columnId="columnId"
        :id="id"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ChannelRadio from "@/components/customerJourney/cells/ChannelRadio.vue";

export default {
  components: {
    ChannelRadio,
  },
  props: {
    col: Object,
    row: Object,
    columnId: Number,
    rowId: Number,
  },
  computed: {
    ...mapState("cjstore", ["channels"]),
  },
};
</script>

<style lang="sass" scoped>
.channel-radios,
.channel-radio
  display: flex
  justify-content: center
.channel-radios
  height: 100%
  display: flex
  margin: 70px 0 45px
  flex-direction: column
  justify-content: space-between
</style>
