<template>
  <v-card 
    id="payments-details" 
    elevation="0" 
    class="mx-auto d-flex flex-column align-center">
    <div class="ml-auto justify-end align-center modal-top ma-0 d-flex">
        <v-btn
          :ripple="false"
          :elevation="0"
          height="24"
          width="24"
          text
          icon
          class="close-button"
          @click="$emit('close')"
        >
          <v-img src="@/assets/img/cross-circle.svg" />
      </v-btn>
    </div>
    <h3 class="modal-title mb-6 d-flex justify-center">
      Wybierz interwał czasowy oraz metodę płatności w jakich chcesz się rozliczać
    </h3>
    <div class="payment-interval-type d-flex justify-center mb-6">
      <template v-for="(interval,id) in paymentIntervalTypes">
        <div           :key="`interval_${id}`"
          @click="selectInterval(interval.value)"
          class="btn"
          :class="
            selectedInterval == interval.value
            ? 'selected'
            : null">
          {{interval.label}}
        </div>
      </template>
    </div>
    <div class="text-content mb-10">
      Płacąc z góry za rok, zyskujesz 20% zniżki.<br>
      Jeżeli postadasz kod rabatowy to super,<br>
      dodamy go podczas dokonywania płatności.
    </div>
    <div class="payment-types d-flex justify-center">
      <template v-for="(type, id) in paymentsTypes[selectedInterval]">
        <div 
          :key="`payment_type_${id}`"
          class="payment-type mx-3 d-flex flex-column align-center"
          :class="selectedPaymentType == type.type
            ? 'selected'
            : null"
          @click="selectType(type.type)">
          <h2 class="mb-5">{{type.title}}</h2>
          <div class="price">
            <div class="value mb-5">
              <h3>{{type.price}} zł</h3>
              <p >brutto / miesiąc</p>
            </div>
            <p class="mb-5">{{type.price * 12}} brutto / rok</p>
          </div>
          <div class="content">
            <b v-if="type.content">{{type.content}}</b>
            <div class="ps mt-16" v-if="type.ps">{{type.ps}}</div>
          </div>
          <div class="checkout">
            <div class="btn selected px-2 py-3" @click="$emit('next',type.type,selectedInterval == 'yearly'
              ? type.price * 12 
              : type.price )">
              Płacę {{ getPrice(type.type, type.price) }} zł
            </div>
          </div>
        </div>
      </template>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      selectedInterval: 'yearly',
      selectedPaymentType: null,
      paymentIntervalTypes:[
        {
          value: 'monthly',
          label: 'Płatność Miesięczna'
        },
        {
          value: 'yearly',
          label: 'Płatność Roczna'
        }
      ],
      paymentsTypes:{
        yearly: [
          {
            type: 'onetime',
            price: 100,
            title: 'Płatność jednorazowa',
            content: 'płacąc jednorazowo zyskujesz 300 zł',
            ps: ''
          },
          {
            type: 'cyclical',
            price: 125,
            title: 'Płatność cyckliczna',
            content: '',
            ps: '*w przypadku nie uiszczenia opłaty, dostęp do planu zostanie zablokowany.'
          }
        ],
        monthly:[
          {
            type: 'onetime',
            price: 100,
            title: 'Płatność jednorazowa',
            content: 'płacąc jednorazowo zyskujesz 300 zł',
            ps: ''
          }
        ]
      }
    }
  },
  methods: {
    selectInterval(interval){
      this.selectedInterval = interval;
      this.$emit('selectedInterval', interval);
    },
    selectType(type){
      this.selectedPaymentType = type;
      this.$emit('selectedType', type);
    },
    getPrice(type, price){
      if(this.selectedInterval == 'yearly'
      && type == 'cyclical' 
      || this.selectedInterval == 'monthly'){
        return price
      }
      if(this.selectedInterval == 'yearly'
      && type == 'onetime'){
        return price * 12
      }

    }
  }
}
</script>

<style lang="sass" scoped>
  .modal-title
    font-size: 18px
    color: $primary-color
    text-align: center
    max-width: 385px
  .btn 
    font-weight: 700
    font-size: 14px
    box-shadow: 0px 2px 30px rgba(46, 27, 117, 0.05)
    border-radius: 8px
    cursor: pointer
    &.selected
      background-color: $primary-color
      color: #fff
  .text-content
    text-align: center
    color: $primary-color
    line-height: 16px
    letter-spacing: -0.387234px
  .payment-interval-type .btn
    padding: 8px 23px
    margin: 0 7px
  .payment-type 
    border: 1px solid #8392AB
    padding: 20px
    box-shadow: 0px 2px 30px rgba(46, 27, 117, 0.05)
    border-radius: 16px
    width: 230px
    text-align: center
    position: relative
    h2
      font-size: 20px
      font-weight: 600
      color: $primary-color
      letter-spacing: -0.553191px
  .content
    margin-bottom: 76px
    b
      color: $primary-color
    .ps 
      font-size: 12px
      line-height: 14px
      letter-spacing: -0.331915px
      color: #8392AB
  .price
    > p
      font-size: 12px
      color: $primary-color
    .value
      h3,
      p
        font-weight: 600
        color: $secondary-color
      h3
        font-size: 30px
        line-height: 35.16px
      p
        font-size: 16px
        line-height: 18.75px
  .checkout
    position: absolute
    bottom: 20px 
</style>