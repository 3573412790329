var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.col.type !== 'row-title' &&
    _vm.row.type !== 'graph' &&
    _vm.row.type !== 'communication' &&
    _vm.col.type !== 'card' &&
    !_vm.arraysHasCommon(_vm.row.options, ['stage', 'substage', 'persona'])
  )?_c('div',{staticClass:"new-card"},[_c('div',{staticClass:"placeholder",on:{"dragover":function($event){$event.preventDefault();},"dragenter":function (event) {
        event.preventDefault();
        _vm.cardDragEnter({ element: event.target });
      },"dragleave":_vm.cardDragLeave,"drop":function () { return _vm.cardDrop({ rowId: _vm.rowId, columnId: _vm.columnId }); }}}),_c('div',{staticClass:"add-new",on:{"click":function($event){return _vm.openDialog({ rowId: _vm.rowId, columnId: _vm.columnId })}}},[_c('span',[_vm._v("Dodaj ["+_vm._s(_vm.title)+"]")]),_c('PlusCircleIcon')],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }