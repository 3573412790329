import axios from "axios";
import store from "@/store";
import Vue from "vue";
import router from "../router";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const baseURL = process.env.VUE_APP_API_URL || "http://localhost:3000";

export const http = axios.create({
  baseURL: baseURL,
});

const refreshAuthLogic = (failedRequest) =>
  new Promise((resolve) => {
    store.dispatch("refreshToken", [this, "/"]);
    resolve();
  }).then(() => {
    // localStorage.setItem('token', tokenRefreshResponse.data.access_token);
    failedRequest.response.config.headers["Authorization"] =
      "Bearer " + localStorage.getItem("token");
    return Promise.resolve();
  });

createAuthRefreshInterceptor(http, refreshAuthLogic);

//axiosRetry(HTTP, { retries: 3 })

http.interceptors.request.use(
  (conf) => {
    store.dispatch("setRequestSent", true);
    return conf;
  },
  (error) => {
    store.dispatch("setRequestSent", true);
    // Vue.toasted.show( "Unknown error", {     type : 'info' })
    return Promise.reject(error);
  }
);
http.interceptors.response.use(
  async (response) => {
    store.dispatch("setRequestSent", false);
    let token_time = localStorage.getItem("token_time");
    let token_time_diff = new Date() - token_time;
    if (token_time_diff > 9e6) {
      store.dispatch("refreshToken", [this, false]);
    }
    if (response?.data?.message) {
      Vue.$toast(response.data.message, {
        type: "info",
      });
    }
    if (response?.data?.success) {
      Vue.$toast(response.data.success, {
        type: "success",
      });
    }
    return response;
  },
  (error) => {
    store.dispatch("setRequestSent", false);
    if (error?.response?.status) {
      var message = error.response.data.message
        ? error.response.data.message
        : error.response.data.error
        ? error.response.data.error
        : `Ups, żądanie do serwera zwróciło błąd:${error.response.status}`;
      Vue.$toast(message, {
        type: "error",
      });
      switch (error.response.status) {
        case 401: {
          router.push("/auth/login");
          // store.dispatch("refreshToken", [this, "/"]);
          break;
        }
      }
    } else {
      Vue.$toast(`Niespodziewany błąd`, {
        type: "error",
      });
    }
    return Promise.reject(error);
  }
);
