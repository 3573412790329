<template>
    <div id="analytics-page">
        <TopBar @dataUpdate="data=$event" />
        <FirstSegment :domainName="domainName" :data="data" />
        <Demography :data="data" />
        <UtmSourcesList :data="data.utmSources" />
        <SurveysDataGlobal :data="data.survey_data_global" />
        <SurveysDataStages :data="data.survey_data_stages" />
    </div>
</template>

<script>
import TopBar from "@/components/analytics/TopBar"
import FirstSegment from "@/components/analytics/FirstSegment"
import Demography from "@/components/analytics/Demography"
import SurveysDataGlobal from "@/components/analytics/SurveysDataGlobal"
import SurveysDataStages from "@/components/analytics/SurveysDataStages"
import { http } from "@/plugins/http";
import UtmSourcesList from "@/components/analytics/UtmSourcesList"

export default {
    components: { TopBar, FirstSegment, Demography, SurveysDataGlobal, SurveysDataStages, UtmSourcesList },
    data() {
        return {
            data: {},
            domainName: null
        }
    },
    methods: {
        async getDomainName() {
            const result = await http.get(`/domain`)
	    window.console.log(result);
            this.domainName = result.data.data[0].fqdn;
        }
    },
    created() {
        this.getDomainName();
    }
}
</script>
