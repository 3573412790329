<template>
  <div class="top-menu">
    <div class="nav labels">
      <div class="back" @click="$router.go(-1)">
        <ArrowLeft />
      </div>
      <div class="name">
        {{ customerJourneyName }}
      </div>
    </div>
    <div class="nav nav-actions">
      <div class="save" @click="saveRowData">Zapisz</div>
      <div title="Cofnij"
        class="undo"
        :class="historyStep == null ? 'disabled' : null"
        @click="
          () => {
            if (historyStep !== null) undo();
          }
        "
      >
        <Undo :disabled="historyStep == null" />
      </div>
      <div class="zoom">
        <div @click="setZoom(0.25)" title="Przybliż">
          <ZoomIn />
        </div>
        <span>{{ zoom * 100 }} %</span>
        <div @click="setZoom(-0.25)" title="Oddal">
          <ZoomOut />
        </div>
      </div>
      <div title="Eksportuj do PDF"
        class="export"
        @click="
          () => {
            exportPath();
          }
        "
      >
        <Document />
      </div>
      <div class="divider"></div>
      <Notifications />
      <AdminDropdown
        @showAccountManagement="$refs.accountManagement.setDialog(true)"
      />
    </div>
    <AccountManagement
      ref="accountManagement"
      @close="showAccountManagement = false"
    />
  </div>
</template>

<script>
import AdminDropdown from "@/components/AdminDropdown.vue";
import AccountManagement from "@/components/admin/accountManagement/AccountManagement";
import ArrowLeft from "@/components/customerJourney/icons/arrow-left.vue";
import Document from "@/components/customerJourney/icons/document.vue";
import Undo from "@/components/customerJourney/icons/undo.vue";
import ZoomIn from "@/components/customerJourney/icons/zoom-in.vue";
import ZoomOut from "@/components/customerJourney/icons/zoom-out.vue";
import Notifications from "@/components/Notifications.vue";

import { mapState, mapActions } from "vuex";
import bus from "@/components/customerJourney/eventBus.js";

export default {
  components: {
    AdminDropdown,
    AccountManagement,
    ArrowLeft,
    Document,
    Undo,
    ZoomIn,
    ZoomOut,
    Notifications,
  },
  computed: {
    ...mapState("cjstore", ["customerJourneyName", "zoom", "historyStep"]),
  },
  methods: {
    ...mapActions("cjstore", ["setZoom", "saveRowData", "undo"]),
    exportPath() {
      bus.$emit("exportPath");
    },
  },
};
</script>

<style lang="sass" scoped>
.top-menu,
.nav,
.nav-actions,
.back,
.name,
.share,
.export,
.undo,
.zoom,
.zoom div
  display: flex
  align-items: center
.share,
.export,
.divider,
.zoom,
.undo,
.save
  margin-right: 20px
.back
.zoom div,
.undo,
.export
  cursor: pointer
.top-menu
  position: sticky
  top: 0
  left: 0
  width: 100vw
  height: 35px
  box-shadow: 0px 2px 5.5px rgba(0, 0, 0, 0.02)
  background-color: #fff
  z-index: 999
.nav
  width: 50%
  height: 35px
.back
  height: 22px
  width: 22px
  margin-left: 19px
  display: flex
  align-items: center
  cursor: pointer
.name
  margin-left: 145px
  font-size: 16px
  color: $primary-color
.divider
  height: 24px
  width: 2px
  border-radius: 2px
  background-color: #8392AB
.zoom span
  margin: 0 25px
.save
  border-radius: 8px
  background-color: $primary-color
  font-size: 14px
  color: #fff
  padding: 7px 10px
  cursor: pointer
.undo.disabled
  cursor: auto
.nav-actions
  justify-content: flex-end
.nav-actions::v-deep button
.nav-actions::v-deep button img
  height: 27px
  width: 27px
::v-deep .notifications-wrapper
  margin-right: 15px !important
::v-deep .admin-profie-pic
  border-radius: 50%
</style>
