<template>
  <svg width="20" height="20" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 17.041L9.46012 17.5616C9.60147 17.7082 9.79636 17.791 10 17.791C10.2036 17.791 10.3985 17.7082 10.5399 17.5616L10 17.041ZM2.3314 9.08839L1.79152 9.60899H1.79152L2.3314 9.08839ZM17.6686 9.08839L17.1287 8.56779L17.6686 9.08839ZM8.75997 2.42173L8.22009 2.94233V2.94233L8.75997 2.42173ZM10 3.70768L9.46012 4.22828C9.60147 4.37487 9.79636 4.45768 10 4.45768C10.2036 4.45768 10.3985 4.37487 10.5399 4.22828L10 3.70768ZM11.24 2.42173L10.7001 1.90113L10.7001 1.90113L11.24 2.42173ZM10.5399 16.5204L2.87128 8.56779L1.79152 9.60899L9.46012 17.5616L10.5399 16.5204ZM17.1287 8.56779L9.46012 16.5204L10.5399 17.5616L18.2085 9.609L17.1287 8.56779ZM8.22009 2.94233L9.46012 4.22828L10.5399 3.18708L9.29986 1.90113L8.22009 2.94233ZM10.5399 4.22828L11.7799 2.94233L10.7001 1.90113L9.46012 3.18708L10.5399 4.22828ZM14.4543 0.291016C13.0408 0.291016 11.691 0.873571 10.7001 1.90113L11.7799 2.94233C12.494 2.20177 13.4566 1.79102 14.4543 1.79102V0.291016ZM18.25 5.75506C18.25 6.81501 17.8437 7.82631 17.1287 8.56779L18.2085 9.609C19.1984 8.58237 19.75 7.19559 19.75 5.75506H18.25ZM19.75 5.75506C19.75 2.76329 17.4045 0.291016 14.4543 0.291016V1.79102C16.5251 1.79102 18.25 3.53984 18.25 5.75506H19.75ZM5.54569 1.79102C6.54338 1.79102 7.50598 2.20177 8.22009 2.94233L9.29986 1.90113C8.309 0.873572 6.95917 0.291016 5.54569 0.291016V1.79102ZM1.75 5.75506C1.75 3.53984 3.47486 1.79102 5.54569 1.79102V0.291016C2.59548 0.291016 0.25 2.76329 0.25 5.75506H1.75ZM2.87128 8.56779C2.15628 7.82631 1.75 6.81501 1.75 5.75506H0.25C0.25 7.19559 0.801554 8.58237 1.79152 9.60899L2.87128 8.56779Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#2E1B75'
    }
  }
}
</script>