<template>
  <div class="min-vh-55 d-flex align-center justify-center forgot-password">
    <div class="card-padding pt-4 pb-4">
      <p class="text-color-primary second-stage-header mb-4">Odzyskiwanie hasła</p>
      <p class="text-color-primary second-stage-text mb-4 mt-2">Wpisz adres email <br> na który wyślemy Ci nowe hasło</p>
      <div class="position-relative">
        <input
          v-model="form.email"
          type="email"
          placeholder="Email"
          class="
            border border-radius-md
            mt-2
            mb-4
            auth-input
          "
          :class="emailValid === false ? 'invalid' : ''"
        />
        <svg class="check-icon" v-if="emailValid" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17.5303 9.53033C17.8232 9.23744 17.8232 8.76256 17.5303 8.46967C17.2374 8.17678 16.7626 8.17678 16.4697 8.46967L17.5303 9.53033ZM9.99998 16L9.46965 16.5304C9.76255 16.8232 10.2374 16.8232 10.5303 16.5303L9.99998 16ZM7.53027 12.4697C7.23737 12.1768 6.7625 12.1768 6.46961 12.4697C6.17671 12.7626 6.17672 13.2374 6.46961 13.5303L7.53027 12.4697ZM16.4697 8.46967L9.46965 15.4697L10.5303 16.5303L17.5303 9.53033L16.4697 8.46967ZM6.46961 13.5303L9.46965 16.5304L10.5303 15.4697L7.53027 12.4697L6.46961 13.5303Z" fill="#2E1B75"/>
        </svg>
        <span v-if="emailValid === false" class="invalid-text">Nieprawidłowy adres email</span>
      </div>
      <v-btn
          @click="sendEmail"
          elevation="0"
          :ripple="false"
          height="43"
          class="
          text-uppercase
          w-100
          auth-button
          mt-2
          "
          color="#5e72e4"
          small
          >WYŚLIJ</v-btn
        >
      </div>
  </div>
</template>
<script>
import { http } from "@/plugins/http";
export default {
  data() {
    return {
      form: {
        email: null,
      },
      emailValid: null,
      errorMessages: {},
      loading: false,
      message: null,
      messageType: null,
    };
  },
  methods: {
    success(message) {
      this.messageType = "success";
      this.message = message;
    },
    error(message) {
      this.messageType = "error";
      this.message = message;
    },
    validate() {
      let valid = true;
      if(!this.form.email) {
        valid = false;
        this.emailValid = false
      } else {
        this.emailValid = true
      }
      return valid;
    },
    async sendEmail() {
      this.errorMessages = {};

      if (this.validate()) {
        this.loading = true;

        try {
          let { data } = await http.post(
            "/create_reset_password_link",
            this.form
          );

          this.success(data.message);
        } catch (e) {
          if (e.errors) {
            this.errorMessages = e.errors;
            return;
          }

          this.error(e.message);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
