<template>
    <div class="mainContainer">
        <div>
            <p>{{title}}</p>
            <span v-if="title !== 'Mediana sentymentu'" class="first-value">{{results[0].toFixed(placesAfterComma)}}</span>
            <span v-else class="first-value">{{results[0].toFixed(1)}}</span>
            <span v-if="results[1]" class="second-value" :class="results[1]>=0 ? 'positive' : 'negative'">
                <span v-if="results[1]>0">+</span>{{results[1].toFixed(0)}}%
            </span>
        </div>
        <div class="icon-container">
            <div>
                <img :src="icon" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {},
        results: {},
        icon: {},
        placesAfterComma: {
            default: 2
        }
    },
}
</script>