<template>
     <div class="rating-stars-parent">
        <svg v-for="n in numberOfStars" @click="clickedStarNumber = n" :key="n" width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg" :class="n <= clickedStarNumber ? 'yellow-star' : 'gray-star'">
            <path d="M30.5979 5.85409C31.1966 4.01147 33.8034 4.01148 34.4021 5.8541L39.2354 20.7295C39.5032 21.5535 40.2711 22.1115 41.1375 22.1115H56.7784C58.7159 22.1115 59.5214 24.5907 57.954 25.7295L45.3002 34.923C44.5993 35.4323 44.306 36.335 44.5737 37.1591L49.407 52.0344C50.0057 53.8771 47.8968 55.4093 46.3293 54.2705L33.6756 45.077C32.9746 44.5677 32.0254 44.5677 31.3244 45.077L18.6707 54.2705C17.1032 55.4093 14.9943 53.8771 15.593 52.0344L20.4263 37.1591C20.694 36.335 20.4007 35.4323 19.6997 34.923L7.04598 25.7295C5.47855 24.5907 6.28411 22.1115 8.22156 22.1115H23.8625C24.7289 22.1115 25.4968 21.5535 25.7646 20.7295L30.5979 5.85409Z" fill="#8392AB"/>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        numberOfStars: {}
    },
    data() {
        return {
            clickedStarNumber: 0
        }
    }
}
</script>