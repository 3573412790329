var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.col.type == 'card')?_c('div',{staticClass:"card",class:[_vm.col.shape ? _vm.shapes[_vm.col.shape] : null],style:({
    backgroundColor:
      _vm.col.color && _vm.col.color !== null
        ? _vm.colors[_vm.col.color].backgroundColor
        : null,
    color:
      _vm.col.color && _vm.col.color !== null ? _vm.colors[_vm.col.color].textColor : null,
  }),attrs:{"draggable":""},on:{"dragstart":function (event) {
      event.stopPropagation();
      _vm.cardDragStart({
        element: event.target.parentElement,
        rowId: _vm.rowId,
        columnId: _vm.columnId,
      });
    },"dragend":_vm.cardDragEnd,"dragover":function($event){$event.preventDefault();},"dragenter":function (event) {
      event.preventDefault();
      _vm.cardDragEnter({ element: event.target });
    },"drop":function (event) { return _vm.cardDrop({ event: event, rowId: _vm.rowId, columnId: _vm.columnId }); }}},[(_vm.col.shape == 1)?[_c('Baloon',{attrs:{"fill":_vm.col.color ? _vm.colors[_vm.col.color].backgroundColor : '#fff'}})]:_vm._e(),_c('div',{staticClass:"card-content",on:{"click":function($event){return _vm.openDialog({ rowId: _vm.rowId, columnId: _vm.columnId, isEdit: true })}}},[_c('h5',[_vm._v(_vm._s(_vm.col.title))]),_c('div',[_vm._v(_vm._s(_vm.col.desc))]),(_vm.col.picture)?_c('div',{staticClass:"card-picture"},[(_vm.pictureSrc != null)?_c('img',{attrs:{"src":_vm.pictureSrc,"alt":_vm.col.picture.name}}):_vm._e()]):_vm._e()]),_c('div',{staticClass:"card-actions"},[(_vm.col.icon && _vm.col.icon !== null)?[_c('div',{staticClass:"card-icon"},[_c('BaseIcon',{key:'selected_icon',staticClass:"selected",attrs:{"path":("icons/shape-" + (_vm.col.icon) + ".vue"),"fill":_vm.col.color !== null ? _vm.colors[_vm.col.color].textColor : null}})],1)]:_vm._e(),_c('div',{staticClass:"remove-card",class:!_vm.col.icon ? 'mt-0' : null,on:{"click":function($event){return _vm.setRemoveCardDialog({
          removeRowId: _vm.rowId,
          removeColumnId: _vm.columnId,
          dialog: true,
        })}}},[_c('TrashIcon',{attrs:{"fill":_vm.col.color ? _vm.colors[_vm.col.color].textColor : '#2E1B75'}})],1)],2),(_vm.col.shape == 2)?[_c('Arrow',{attrs:{"fill":_vm.col.color ? _vm.colors[_vm.col.color].backgroundColor : '#fff'}})]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }