<template>
  <!-- Card pickers dialog -->
  <v-dialog v-model="dialog" max-width="390px">
    <v-card>
      <div class="new-item">
        <h3 class="pb-3">{{ pickersLabels[currentPicker] }}</h3>
        <template v-if="currentPicker == 'shape'">
          <div class="items">
            <div class="shapes">
              <template v-for="(title, id) in shapes">
                <div :key="`shape_${id}`" class="shape" @click="shape = id">
                  <h5 class="label">{{ title }}</h5>
                  <BaseIcon
                    :class="shape == id ? 'selected' : null"
                    :path="`shapes/shape-${id}.vue`"
                  />
                </div>
              </template>
            </div>
          </div>
        </template>
        <template v-if="currentPicker == 'icon'">
          <div class="items">
            <div class="icons">
              <template v-for="id in 43">
                <div
                  :key="`icon_${id}`"
                  :class="['icon-wrapper', icon == id ? 'selected' : null]"
                  @click="icon = id"
                >
                  <BaseIcon :path="`icons/shape-${id}.vue`" :fill="'#2E1B75'" />
                </div>
              </template>
            </div>
          </div>
        </template>
        <template v-if="currentPicker == 'color'">
          <div
            class="preview"
            :style="{
              backgroundColor:
                color !== null ? colors[color].backgroundColor : null,
            }"
          >
            <div
              class="text"
              :style="{
                color: color !== null ? colors[color].textColor : null,
              }"
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Turpis
              vestibulum donec dignissim amet purus pharetra facilisi.
            </div>
            <div class="card-icon">
              <HeartIcon
                :fill="color !== null ? colors[color].textColor : '#2E1B75'"
              ></HeartIcon>
            </div>
          </div>
          <div class="items">
            <div class="colors">
              <template v-for="(item, id) in colors">
                <div
                  :key="`color_${id}`"
                  :style="{
                    backgroundColor: item.backgroundColor,
                    border:
                      item.backgroundColor == '#fff'
                        ? '2px solid  #cacaca'
                        : null,
                  }"
                  :class="['color', color == id ? 'selected' : null]"
                  @click="color = id"
                ></div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseIcon from "@/components/customerJourney/BaseIcon.vue";
import HeartIcon from "@/components/icons/HeartIcon.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseIcon,
    HeartIcon,
  },
  computed: {
    ...mapState("cjstore", [
      "pickersLabels",
      "pickersDialog",
      "currentPicker",
      "shapeSelected",
      "iconSelected",
      "colorSelected",
      "colors",
      "shapes",
    ]),
    dialog: {
      get() {
        return this.pickersDialog;
      },
      set(value) {
        this.setPickersDialog(value);
      },
    },
    icon: {
      get() {
        return this.iconSelected;
      },
      set(value) {
        this.setIconSelected(value);
      },
    },
    shape: {
      get() {
        return this.shapeSelected;
      },
      set(value) {
        this.setShapeSelected(value);
      },
    },
    color: {
      get() {
        return this.colorSelected;
      },
      set(value) {
        this.setColorSelected(value);
      },
    },
  },
  methods: {
    ...mapActions("cjstore", [
      "setPickersDialog",
      "setIconSelected",
      "setShapeSelected",
      "setColorSelected",
    ]),
  },
};
</script>

<style lang="sass" scoped>
.new-item
  display: flex
  flex-direction: column
  align-items: center
  padding: 30px
  h3
    padding-bottom: 30px
    font-size: 18px
    color: $primary-color
.items
  max-height: 140px
  overflow-y: scroll
.items .shapes,
.items .icons,
.items .colors
  display: flex
  flex-wrap: wrap

.items .shapes .shape
  margin: 0 7px
  .label
    font-size: 12px
    font-weight: 400
    color: $primary-color
    margin-bottom: 7px

::v-deep .shape svg rect,
::v-deep .shape svg path
  stroke: #8392AB
  transition: stroke .2s ease-out
::v-deep .shape svg.selected rect,
::v-deep .shape svg.selected path
  stroke: $primary-color

.items .icons .icon-wrapper
  width: 38px
  height: 38px
  display: flex
  justify-content: center
  align-items: center

.items .icons .icon-wrapper.selected
  width: 38px
  height: 38px
  border: 2px solid $primary-color
  border-radius: 8px

.preview
  box-shadow: 0px 2px 30px rgba(46, 27, 117, 0.05)
  border-radius: 8px
  padding: 8px 17px
  display: flex
  max-width: 290px
  margin-bottom: 20px
  .text
    font-size: 12px
  .card-icon
    margin-left: 20px
.items .colors
  justify-content: center
.items .colors .color
  width: 34px
  height: 34px
  border-radius: 8px
  margin: 6px
.items .colors .color.selected
  width: 34px
  height: 34px
  border: 2px solid $primary-color !important
</style>
