<template>
    <v-card id="delete-emission-modal" class="mx-auto">
        <div class="close-btn" >
            <img class="cursor-pointer" @click="close" src="@/assets/img/cross-circle.svg" />
        </div>
        <div id="delete-emission-modal-content">
            <v-card-title class="delete-emission-modal-title px-0 py-0">
                Czy na pewno chcesz usunąć emisję?
            </v-card-title>
            <v-card-actions class="buttons">
                <v-btn
                    text
                    @click="close"
                    class="cancel-button"
                >
                    Anuluj
                </v-btn>
                <v-btn
                    text
                    @click="deleteEmission"
                    class="confirm-button"
                >
                    Usuń
                </v-btn>
            </v-card-actions>
        </div>
        </v-card>
</template>

<script>
import { http } from "@/plugins/http";

export default {
    props: {
        emissionId: {}
    },
    methods: {
        async deleteEmission() {
            await http.delete(`/survey_run/${this.emissionId}`)
            this.close();
            this.$emit("emissionDeleted")
        },
        close() {
            this.$emit("close")
        },
    },
}
</script>