<template>
    <v-menu transition="slide-y-transition" offset-y>
        <template v-slot:activator="{ on, attrs }">
            <button  
                v-bind="attrs"
                v-on="on"
                elevation="0"
                :ripple="false"
                class="position-relative w-100" 
            >
                <div class="d-flex align-center justify-space-between reusable-dropdown"> 
                    <span v-if="!selected">{{data[0]}}</span>
                    <span v-else>{{selected}}</span>
                    <svg class="ml-5" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 6.75L9 12.75L15 6.75" stroke="currentColor" stroke-width="2" stroke-linecap="square"/>
                    </svg>
                </div>
            </button>
        </template>

        <v-list class="py-0 reusable-dropdown-list">
            <v-list-item v-for="item in data" :key="item.index" class="list-item-hover-active reusable-dropdown-item" @click="selectItem(item)">
                <v-list-item-content class="pa-0">
                    <v-list-item-title class="mb-0">
                        <span>{{item}}</span>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    props: {
        data: {},
        initialValue: {}
    },
    data() {
        return {
            selected: null,
        }
    },
    methods: {
        selectItem(item) {
            this.selected = item;
            this.$emit("itemSelected", item);
        },
        clear() {
            this.selected = this.initialValue;
        }
    },
    mounted() {
        if(!this.selected) {
            this.selected = this.initialValue;
        }
    },
    watch: {
        initialValue(val) {
            this.selected = val;
        }
    }
}
</script>