<template>
    <div class="chart-wrapper">
        <Bar
            :chart-options="chartOptions"
            :chart-data="chartData"
            :chart-id="chartId.toString()"
            :dataset-id-key="datasetIdKey"
            :plugins="plugins"
            :css-classes="cssClasses"
            :styles="styles"
            :width="width"
            :height="height"
        />
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChart',
    components: { Bar },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 369
        },
        height: {
            type: Number,
            default: 265
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Array,
            default: () => []
        },
        chartData: {},
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    x: {
                        display: true,
                        grid: {
                            lineWidth: 0,
                        }
                    },
                    y: {
                        grid: {
                            drawBorder: false,
                            lineWidth: 1,
                            color: "#E2E8F0",
                            borderDash: [8, 4],
                        }
                    }
                }
            }
        }
    },
}
</script>