const SET_HINT = "SET_HINT";
const SET_HINT_CATEGORY = "SET_HINT_CATEGORY";

const baseState = function () {
  return {
    isHintVisible: false,
    hintCategory: null,
  };
};

export default {
  namespaced: true,
  state: baseState(),
  actions: {
    async setHint({ commit, dispatch }, payload) {
      if (payload.category) {
        await dispatch("setHintCategory", payload.category);
      }
      commit(SET_HINT, payload);
    },
    setHintCategory({ commit }, payload) {
      commit(SET_HINT_CATEGORY, payload);
    },
  },
  mutations: {
    [SET_HINT](state, payload) {
      state.isHintVisible = payload.visible;
      if (!payload.visible) {
        state.hintCategory = null;
      }
    },
    [SET_HINT_CATEGORY](state, payload) {
      state.hintCategory = payload;
    },
  },
};
