<template>
    <v-dialog
        v-model="dialog"
        max-width="421px"
    >
        <v-card id="card-details-modal" class="mx-auto">
            <div class="close-btn" >
                <img @click="dialog = false" src="@/assets/img/cross-circle.svg" />
            </div>
            <div id="card-details-content">
                <div class="card-details-title">Dane karty</div>
                <div class="card-details-subtitle">Podaj dane karty</div>
                <div class="card-details-text">Dodanie Karty umożliwi odnawianie abonamentu i płatności jednym kliknięciem.</div>
                <label>Imię i nazwisko właściciela karty</label>
                <v-text-field
                        hide-details
                        outlined
                        color="rgba(0,0,0,.6)"
                        light
                        placeholder="Imię i nazwisko"
                        class="card-details-input"
                    >
                </v-text-field>
    
                <label>Numer karty</label>
                <v-text-field
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    light
                    placeholder="Numer karty"
                    class="card-details-input"
                >
                </v-text-field>
                
                <div class="d-flex justify-space-between">
                    <div>
                        <label>Data ważności</label>
                        <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="MM/RRRR"
                            class="card-details-input card-details-input-sm"
                        >
                        </v-text-field>
                    </div>
                    <div>
                        <label>Kod zabezpieczający</label>
                        <v-text-field
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="CVV"
                            class="card-details-input card-details-input-sm"
                        >
                        </v-text-field>
                    </div>
                </div>
                <v-btn id="confirm-button">
                    ZAPISZ DANE KARTY
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        setDialog(value) {
            this.dialog = value;
        },
    },
}
</script>