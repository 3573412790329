<template>
    <div id="surveys-data-global" v-if="data">
        <div class="data-container">
            <span class="analytics-title">Analityka: Dane z ankiet (Global)</span>
            <v-row class="small-data-container-wrapper">
                <v-col class="col-sm-12 col-md-12">
                    <v-row>
                        <v-col>
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`Mediana sentymentu`"
                                    :results="data.mediana_sentymentu"
                                    :icon="`${require('@/assets/img/icon_smile.svg')}`"
                                />
                            </div>
                        </v-col>
                        <v-col>
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`Ilość wyświetleń`"
                                    :results="data.ilosc_wyswietlen"
                                    :icon="`${require('@/assets/img/icon_globe.svg')}`"
                                    :placesAfterComma="0"
                                />
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`Ilość wypełnień`"
                                    :results="data.ilosc_wypelnien"
                                    :icon="`${require('@/assets/img/icon_pinpaper_check.svg')}`"
                                    :placesAfterComma="0"
                                />
                            </div>
                        </v-col>
                        <v-col>
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`% Konwersji`"
                                    :results="data.procent_konwersji"
                                    :icon="`${require('@/assets/img/icon_refresh_circle.svg')}`"
                                    :placesAfterComma="0"
                                />
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
        <div class="globe-img-container">
            <img class="globe-img" src="@/assets/img/globe.png" /> 
        </div>
    </div>
</template>

<script>
import SmallDataContainer from '@/components/analytics/SmallDataContainer'

export default {
    props: {
        data: {}
    }, 
    components: { SmallDataContainer }
}
</script>