<template>
    <v-card class="px-6 py-10" >
        <div id="profile-modal">
            <img v-if="userData.profilePicture" class="profile-picture" :src="imgSrc" @click="$refs.pictureCropper.setDialog(true)" />
            <div v-if="userData.profilePicture" class="profile-picture-overlay" @mouseenter="profilePictureHover = true" @mouseleave="profilePictureHover = false" @click="$refs.pictureCropper.setDialog(true)">
                <svg width="24" height="25" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 20.75H3.25C3.25 21.1642 3.58579 21.5 4 21.5V20.75ZM4 16.75L3.46967 16.2197C3.32902 16.3603 3.25 16.5511 3.25 16.75H4ZM17 3.75L17.5303 3.21967C17.2374 2.92678 16.7626 2.92678 16.4697 3.21967L17 3.75ZM21 7.75L21.5303 8.28033C21.8232 7.98744 21.8232 7.51256 21.5303 7.21967L21 7.75ZM8 20.75V21.5C8.19891 21.5 8.38968 21.421 8.53033 21.2803L8 20.75ZM4.75 20.75V16.75H3.25V20.75H4.75ZM16.4697 4.28033L20.4697 8.28033L21.5303 7.21967L17.5303 3.21967L16.4697 4.28033ZM8 20H4V21.5H8V20ZM20.4697 7.21967L17.4697 10.2197L18.5303 11.2803L21.5303 8.28033L20.4697 7.21967ZM17.4697 10.2197L7.46967 20.2197L8.53033 21.2803L18.5303 11.2803L17.4697 10.2197ZM4.53033 17.2803L14.5303 7.28033L13.4697 6.21967L3.46967 16.2197L4.53033 17.2803ZM14.5303 7.28033L17.5303 4.28033L16.4697 3.21967L13.4697 6.21967L14.5303 7.28033ZM18.5303 10.2197L14.5303 6.21967L13.4697 7.28033L17.4697 11.2803L18.5303 10.2197Z" :fill="profilePictureHover ? 'white' : 'transparent'"/>
                </svg>
            </div>
            <div v-if="!userData.profilePicture" class="profile-picture-background" @click="$refs.pictureCropper.setDialog(true)">
                <img  class="profile-picture-empty" src="@/assets/img/icon_camera.svg" />
            </div>
            <div class="profile-content">
                <v-row> 
                    <v-col cols="8" class="mx-0">
                        <label 
                            >Imię</label
                        >
                        <v-text-field
                            v-model="userData.name"
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="eg. Jackson"
                            class="
                            font-size-input
                            placeholder-lighter
                            border-radius-md
                            text-light-input
                            mt-2
                            profile-input
                            "
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="4" class="mx-0">
                        <label 
                            >Uprawnienia</label
                        >
                        <v-text-field
                            v-model="formattedRole"
                            disabled
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="eg. Michael"
                            class="
                            font-size-input
                            placeholder-lighter
                            border-radius-md
                            text-light-input
                            mt-2
                            profile-input
                            "
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="8" class="mx-0">
                        <label 
                            >Nazwisko</label
                        >
                        <v-text-field
                            v-model="userData.surname"
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="eg. Michael"
                            class="
                            font-size-input
                            placeholder-lighter
                            border-radius-md
                            text-light-input
                            mt-2
                            profile-input
                            "
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="mx-0">
                        <label 
                            >Email</label
                        >
                        <v-text-field
                            v-model="userData.email"
                            disabled
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="example@address.com"
                            class="
                            font-size-input
                            placeholder-lighter
                            border-radius-md
                            text-light-input
                            mt-2
                            profile-input
                            "
                        >
                        </v-text-field>
                    </v-col>
                    <v-col cols="4" class="mx-0">
                        <label 
                            >Numer telefonu</label
                        >
                        <v-text-field
                            v-model="userData.phone"
                            hide-details
                            outlined
                            color="rgba(0,0,0,.6)"
                            light
                            placeholder="+40 941 538 024"
                            class="
                            font-size-input
                            placeholder-lighter
                            border-radius-md
                            text-light-input
                            mt-2
                            profile-input
                            "
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row class="d-flex align-end">
                    <v-col cols="4" class="mx-0">
                        <span @click="$refs.changePasswordModal.setDialog(true)" class="change-password">Zmień hasło</span>
                    </v-col>
                </v-row>
                <hr class="horizontal dark mt-7" />
                <v-row class="mt-4">
                    <v-col cols="12">
                        <div class="delete-account-title">Usuwanie konta</div>
                        <div class="delete-account-text">Po usunięciu konta nie ma możliwości powrotu do niego.</div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" class="d-flex align-center ">
                        <v-switch
                            :ripple="false"
                            class="mt-0 pt-0"
                            v-model="deleteConfirmation"
                            hide-details
                            color="#2E1B75"
                            inset
                        >
                        </v-switch>
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold text-color-primary">Potwierdzam</span>
                            <span class="text-color-gray text-size-1">Chcę usunąć moje konto</span>
                        </div>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-end delete-account-btn">
                        <v-btn
                            :disabled="!deleteConfirmation"
                            @click="$refs.deleteAccount.setDialog(true)"
                        >
                            DELETE ACCOUNT
                        </v-btn>
                    </v-col>
                </v-row>
            </div>
            <DeleteAccountModal 
                ref="deleteAccount"
                @deleteAccount="deleteAccount"
            />
            <ChangePasswordModal 
                ref="changePasswordModal"
                :userId="userData.id"
            />
            <CroppPictureModal
                ref="pictureCropper"
                :savedProfilePicture="imgSrc"
                :savedProfilePicturePath="userData.profilePicture"
                :userId="userData.id"
                @profilePictureChanged="updateProfilePicture"
            />
            <SaveChangesModal
                ref="saveChangesModal"
                @saveChanges="saveChanges"
                @clearChanges="clearChanges"
                @closedWithoutAction="closedWithoutAction"
            />
        </div>
        <div class="d-flex justify-center">
            <v-btn
                @click="saveChangesOnBtnClick()"
                class="save-btn"
                :disabled="!userDataChanged"
            >
                Zapisz
            </v-btn>
        </div>                                                                                                                                                                                
    </v-card>
</template>

<script>
import ChangePasswordModal from "@/components/admin/accountManagement/profile/ChangePasswordModal"
import DeleteAccountModal from "@/components/admin/accountManagement/profile/DeleteAccountModal"
import CroppPictureModal from "@/components/admin/accountManagement/profile/CroppPictureModal"
import SaveChangesModal from "@/components/admin/accountManagement/profile/SaveChangesModal"
import { http } from "@/plugins/http";

export default {
    props: {
        apiData: {},
        userRole: {}
    },
    data() {
        return {
            deleteConfirmation: false,
            userData: {
                name: '',
                surname: '',
                email: '',
                phone: '',
                profilePicture: '',
                id: null
            },
            newRole: null,
            nextTab: null,
            closeAccountManagement: false,
            profilePictureHover: false,
            imgSrc: null
        }
    },
    mounted() {
        if(this.apiData) {
            this.setApiData();
        }
    },
    computed: {
        formattedRole() {
            let result = "";
            if(this.userRole) {
                result = this.userRole.charAt(0) + this.userRole.substring(1).toLowerCase();
            }
            return result
        },
        userDataChanged() {
            const result = (
                this.isChanged(this.userData.name, this.apiData.first_name) ||
                this.isChanged(this.userData.surname, this.apiData.last_name) ||
                this.isChanged(this.userData.phone, this.apiData.phone_number)
            )
            return result;
        }
    },
    methods: {
        async profilePictureUrl() {
            let timestamp = new Date().getTime()
            http.get(`${process.env.VUE_APP_API_URL}/user_profile_picture?${timestamp}`, {responseType: 'blob'})
            .then(response => new Blob([response.data]))
            .then(blob => new Promise(() => {
               this.imgSrc = URL.createObjectURL(blob) 
            }))
        },
        closedWithoutAction() {
            this.$emit('setDialog', true);
            this.$emit('setTab', 'tab-1');
        },
        async setApiData() {
            this.userData.email = this.apiData.email;
            this.userData.name = this.apiData.first_name;
            this.userData.phone = this.apiData.phone_number;
            this.userData.surname = this.apiData.last_name;
            this.userData.profilePicture = this.apiData.profile_picture;
            this.userData.id = this.apiData.id;
            await this.profilePictureUrl()
        },
        async updateProfilePicture(fileName) {
            const oldData = this.$store.getters.getUser;
            let newData = {}
            if(fileName) {
                const newImagePath = `/var/www/html/apache-flask/app/data_storage/profile_pictures/${fileName}`
                newData = {...oldData, profile_picture: newImagePath}
            } else {
                newData = {...oldData, profile_picture: null}
            }
            this.$store.commit("SET_USER", newData)
            this.$emit("updateUser");
        },
        isChanged(firstValue, secondValue) {
            if(firstValue === "") {
                firstValue = null;
            }
            return firstValue !== secondValue;
        },
        checkChanges(nextTab, closeAccountManagement = false) {
            if(this.userDataChanged) {
                this.closeAccountManagement = closeAccountManagement;
                this.nextTab = nextTab;
                this.$refs.saveChangesModal.setDialog(true);
            } 
        },
        async deleteAccount() {
            await http.delete(`/user`)
            this.$root.$store.dispatch('logout');
        },
        async saveChanges() {
            const changedData = this.getChangedData();
            if(changedData) {
                await http.patch(`/user`, changedData);
                this.$emit('setTab', this.nextTab);
                this.perfActionAfterDecidingOnChanges();
                this.updateUserDataInVuex(changedData);
                this.$emit('updateUser');
            }
        },
        getChangedData() {
            const changedData = {}
            if(this.userData.name !== this.apiData.first_name) {
                changedData.first_name = this.userData.name;
            }
            if(this.userData.surname !== this.apiData.last_name) {
                changedData.last_name = this.userData.surname;
            }
            if(this.userData.phone !== this.apiData.phone_number) {
                changedData.phone_number  = this.userData.phone;
            }
            return changedData;
        },
        async saveChangesOnBtnClick() {
            const changedData = this.getChangedData();
            if(changedData) {
                await http.patch(`/user`, changedData);
                this.updateUserDataInVuex(changedData);
                this.$emit('updateUser');
            }
        },
        updateUserDataInVuex(newData) {
            const oldData = this.$store.getters.getUser;
            const changedFields = {
                first_name: oldData.first_name,
                last_name: oldData.last_name,
                phone_number: oldData.phone_number
            }
            if(newData.first_name) {
                changedFields.first_name = newData.first_name;
            }
            if(newData.last_name) {
                changedFields.last_name = newData.last_name;
            }
            if(newData.phone_number) {
                changedFields.phone_number = newData.phone_number;
            }
            const newUserData = {
                ...oldData,
                first_name: changedFields.first_name,
                last_name: changedFields.last_name,
                phone_number: changedFields.phone_number
            }
            this.$store.commit("SET_USER", newUserData)
        },
        clearChanges() {
            this.setApiData();
            this.perfActionAfterDecidingOnChanges();
        },
        perfActionAfterDecidingOnChanges() {
            this.$emit('setTab', this.nextTab);
            if(this.closeAccountManagement) {
                this.$emit("setDialog", false);
                this.closeAccountManagement = false;
            }
        }
    },
    watch: {
        apiData() {
            this.setApiData();
        }
    },
    components: { ChangePasswordModal, DeleteAccountModal, CroppPictureModal, SaveChangesModal },
};
</script>
