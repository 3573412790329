<template>
    <div id="survey-data-stages" v-if="data">
        <span class="analytics-title">Analityka: Dane z ankiet (Etapy)</span>
        <div id="survey-data-stages-content">
            <v-row>
                <v-col>
                    <v-row>
                        <v-col cols="12" class="title-container-wrapper">
                            <div class="title-container first">
                                <div>
                                    <p class="mb-0">Strona wejścia</p>
                                    <p class="mb-0">(Świadomość)</p>
                                </div>
                                <div>
                                    <img src="@/assets/img/icon_users_more.svg" />
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                     <v-row>
                        <v-col cols="12" class="wrapper">
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`Mediana sentymentu`"
                                    :results="data.strona_wejscia.mediana_sentymentu"
                                    :icon="`${require('@/assets/img/icon_smile.svg')}`"
                                />
                            </div>
                    </v-col>
                    </v-row>
                     <v-row>
                        <v-col cols="12" class="wrapper">
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`Ilość wyświetleń`"
                                    :results="data.strona_wejscia.ilosc_wyswietlen"
                                    :icon="`${require('@/assets/img/icon_globe.svg')}`"
                                    :placesAfterComma="0"
                                />
                            </div>
                        </v-col>
                    </v-row>
                     <v-row>
                        <v-col cols="12" class="wrapper">
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`Ilość wypełnień`"
                                    :results="data.strona_wejscia.ilosc_wypelnien"
                                    :icon="`${require('@/assets/img/icon_pinpaper_check.svg')}`"
                                    :placesAfterComma="0"
                                />
                            </div>
                        </v-col>
                    </v-row>
                     <v-row>
                        <v-col cols="12" class="wrapper">
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`% Konwersji`"
                                    :results="data.strona_wejscia.procent_konwersji"
                                    :icon="`${require('@/assets/img/icon_refresh_circle.svg')}`"
                                    :placesAfterComma="0"
                                />
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row>
                       <v-col cols="12" class="title-container-wrapper">
                            <div class="title-container second">
                                <div>
                                    <p class="mb-0">Strona produktu</p>
                                    <p class="mb-0">(Rozważanie)</p>
                                </div>
                                <div>
                                    <img src="@/assets/img/icon_user_question.svg" />
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                     <v-row>
                        <v-col cols="12" class="wrapper">
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`Mediana sentymentu`"
                                    :results="data.strona_produktu.mediana_sentymentu"
                                    :icon="`${require('@/assets/img/icon_smile.svg')}`"
                                />
                            </div>
                    </v-col>
                    </v-row>
                     <v-row>
                        <v-col cols="12" class="wrapper">
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`Ilość wyświetleń`"
                                    :results="data.strona_produktu.ilosc_wyswietlen"
                                    :icon="`${require('@/assets/img/icon_globe.svg')}`"
                                    :placesAfterComma="0"
                                />
                            </div>
                        </v-col>
                    </v-row>
                     <v-row>
                        <v-col cols="12" class="wrapper">
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`Ilość wypełnień`"
                                    :results="data.strona_produktu.ilosc_wypelnien"
                                    :icon="`${require('@/assets/img/icon_pinpaper_check.svg')}`"
                                    :placesAfterComma="0"
                                />
                            </div>
                        </v-col>
                    </v-row>
                     <v-row>
                        <v-col cols="12" class="wrapper">
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`% Konwersji`"
                                    :results="data.strona_produktu.procent_konwersji"
                                    :icon="`${require('@/assets/img/icon_refresh_circle.svg')}`"
                                    :placesAfterComma="0"
                                />
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row>
                        <v-col cols="12" class="title-container-wrapper">
                            <div class="title-container third">
                                <div>
                                    <p class="mb-0">Koszyk</p>
                                    <p class="mb-0">(Decyzja)</p>
                                </div>
                                <div>
                                    <img src="@/assets/img/icon_user_check.svg" />
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                     <v-row>
                        <v-col cols="12" class="wrapper">
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`Mediana sentymentu`"
                                    :results="data.koszyk.mediana_sentymentu"
                                    :icon="`${require('@/assets/img/icon_smile.svg')}`"
                                />
                            </div>
                    </v-col>
                    </v-row>
                     <v-row>
                        <v-col cols="12" class="wrapper">
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`Ilość wyświetleń`"
                                    :results="data.koszyk.ilosc_wyswietlen"
                                    :icon="`${require('@/assets/img/icon_globe.svg')}`"
                                    :placesAfterComma="0"
                                />
                            </div>
                        </v-col>
                    </v-row>
                     <v-row>
                        <v-col cols="12" class="wrapper">
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`Ilość wypełnień`"
                                    :results="data.koszyk.ilosc_wypelnien"
                                    :icon="`${require('@/assets/img/icon_pinpaper_check.svg')}`"
                                    :placesAfterComma="0"
                                />
                            </div>
                        </v-col>
                    </v-row>
                     <v-row>
                        <v-col cols="12" class="wrapper">
                            <div class="small-data-container">
                                <SmallDataContainer
                                    :title="`% Konwersji`"
                                    :results="data.koszyk.procent_konwersji"
                                    :icon="`${require('@/assets/img/icon_refresh_circle.svg')}`"
                                    :placesAfterComma="0"
                                />
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
    
</template>

<script>
import SmallDataContainer from '@/components/analytics/SmallDataContainer'

export default {
    components: { SmallDataContainer },
    props: {
        data: {}
    }
}
</script>