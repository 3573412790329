<template>
  <div
    v-if="
      col.type !== 'row-title' && arraysHasCommon(row.options, ['substage'])
    "
  >
    <div class="substage">
      <input
        @input="(e) => labelInput(e, rowId, columnId)"
        :value="col.title"
        @blur="renderChart(selectedPersonasObjs)"
        placeholder="Nazwa podetapu"
      />
    </div>
  </div>
</template>

<script>
import utils from "@/plugins/utils.js";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    col: Object,
    row: Object,
    columnId: Number,
    rowId: Number,
  },
  computed: {
    ...mapState("cjstore", ["selectedPersonasObjs"]),
  },
  methods: {
    ...mapActions("cjstore", ["setLabelInput", "renderChart"]),
    arraysHasCommon: utils.arraysHasCommon,
    labelInput(e, rowId, columnId) {
      this.setLabelInput({
        rowId: rowId,
        columnId: columnId,
        value: e.target.value,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.substage
  display: flex
  justify-content: center
  input
    outline: none
    text-align: center
    font-family: 'Roboto'
  input::placeholder
    font-family: 'Roboto'
</style>
