<template>
    <v-dialog
        v-model="dialog"
        max-width="324px"
    >
        <v-card id="delete-user-modal" class="mx-auto">
            <div class="close-btn" >
                <img class="cursor-pointer" @click="dialog = false" src="@/assets/img/cross-circle.svg" />
            </div>
            <div id="delete-user-modal-content">
                <v-card-title class="delete-user-modal-title px-0 py-0">
                    Czy na pewno chcesz usunąć?
                </v-card-title>
                <v-card-text class="delete-user-modal-content mx-auto">Usunięcie osoby z teamu spowoduje brak dostępu do Canvax.</v-card-text>
                <v-card-actions class="buttons">
                    <v-btn
                        text
                        @click="dialog = false"
                        class="cancel-button"
                    >
                        Anuluj
                    </v-btn>
                    <v-btn
                        text
                        @click="deleteUser"
                        class="confirm-button"
                    >
                        Usuń
                    </v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            userId: null
        }
    },
    methods: {
        deleteUser() {
            this.$emit("deleteUser", this.userId);
            this.dialog = false;
        },
        setDialog(dialog, id) {
            this.dialog = dialog;
            this.userId = id;
        },
    },
}
</script>