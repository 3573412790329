var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.col.type && _vm.col.type == 'row-title')?_c('div',[_c('input',{attrs:{"placeholder":_vm.col.title},domProps:{"value":_vm.col.title},on:{"input":function (e) {
        _vm.labelInput(e, _vm.rowId, _vm.columnId);
        _vm.setRowTitle({ rowId: _vm.rowId, title: e.target.value });
      }}}),(_vm.row.type == 'communication')?[_c('div',{staticClass:"channels"},[_vm._l((_vm.channels),function(channel,id){return [(channel.visible)?[_c('div',{key:("channel_" + id),staticClass:"channel"},[_c('div',{staticClass:"icon-wrapper"},[_c('BaseIcon',{attrs:{"path":("icons/" + (channel.icon) + ".vue")}})],1),_c('h4',[_vm._v(_vm._s(channel.label))])])]:_vm._e()]})],2)]:_vm._e(),(!_vm.row.options.includes('stage'))?[_c('div',{staticClass:"add add-row",on:{"click":function($event){return _vm.addRow(_vm.rowId)}}},[_c('PlusCircleFillIcon')],1),(
        ['stage', 'card', 'persona', 'graph', 'communication'].indexOf(
          _vm.row.type
        ) == -1
      )?_c('div',{staticClass:"remove-row",on:{"click":function($event){return _vm.removeRow(_vm.rowId)}}},[_c('MinusCircleFillIcon')],1):_vm._e()]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }