<template>
  <v-dialog v-model="open" :max-width="modalWidth">
    <v-stepper
      alt-labels
      elevation="0"
      v-model="step"
      class="w-100 mx-auto"
    >
      <v-stepper-items class="border-radius-xl overflow-hidden">
        <v-stepper-content
          step="1"
          class="bg-white border-radius-xl pb-9 pr-9 pl-9"
        >
          <Step1 
            @close="open = false"
            @selectedInterval="interval = $event"
            @next="next"/>
        </v-stepper-content>
        <v-stepper-content 
          step="2"
          class="bg-white border-radius-xl pb-9 pr-9 pl-9">
          <Step2
            :price="price"
            :paymentType="paymentType"
            :interval="interval"
            @close="open = false"
            @prev="step = 1; modalWidth = 558" /> 
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper> 
  </v-dialog>
</template>

<script>
import Step1 from '@/components/admin/accountManagement/payments/payment-methods/Step1.vue';
import Step2 from '@/components/admin/accountManagement/payments/payment-methods/Step2.vue';

export default {
  components: {
    Step1,
    Step2,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      open: false,
      step: 1,
      modalWidth: 558,
      paymentType: null,
      interval: null,
      price: null,
    }
  },
  watch:{
    isOpen(newVal){
      this.open = newVal
    },
    open(newVal){
      if(!newVal){
        this.$emit('close');
      }
    }
  },
  mounted(){
    this.open = this.isOpen
  },
  methods:{
    next(type,price){
      this.paymentType = type;
      this.price = price;
      this.step = 2;
      this.modalWidth = 375;
    }
  }
}
</script>

<style lang="sass" scoped>

</style>