var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.uzytkownicy)?_c('div',{attrs:{"id":"demography"}},[_c('span',{staticClass:"analytics-title"},[_vm._v("Analityka: Demografia")]),_c('div',{attrs:{"id":"demography-content"}},[_c('v-row',[_c('v-col',{staticClass:"col-sm-12 col-md-4 pie-chart-col"},[_c('div',[_c('p',{staticClass:"title"},[_vm._v("Użytkownicy")]),_c('div',{staticClass:"d-flex mb-4"},[_c('div',{staticClass:"pie-chart-legend-circle women"}),_c('span',[_vm._v("kobiety")])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"pie-chart-legend-circle men"}),_c('span',[_vm._v("mężczyźni")])])]),_c('PieChart',{attrs:{"chartData":{
                        labels: ['kobiety', 'mężczyźni'],
                        datasets: [
                            { 
                                data: _vm.data.uzytkownicy,
                                backgroundColor: ['#5e35f2', '#2e1b75'],
                            } ]
                    }}})],1),_c('v-col',{staticClass:"col-sm-12 col-md-4"},[_c('div',{staticClass:"chart-title-container"},[_c('span',{staticClass:"chart-title-first"},[_vm._v("Użytkownicy")])]),_c('BarChart',{attrs:{"chartData":{
                        labels: _vm.data.uzytkownicy_m.labels,
                        datasets: [
                            { 
                                data: _vm.data.uzytkownicy_m.data,
                                backgroundColor: ['#E5E1F1','#e0ddea', '#cac6dc', '#aba4c8', '#8276ac', '#584991', '#2e1b75'],
                                borderRadius: 6,
                                barPercentage: 1,
                                categoryPercentage: 0.5
                            } ]
                    }}})],1),_c('v-col',{staticClass:"col-sm-12 col-md-4"},[_c('div',{staticClass:"chart-title-container"},[_c('span',{staticClass:"chart-title-first"},[_vm._v("Użytkowniczki")])]),_c('BarChart',{attrs:{"chartData":{
                        labels: _vm.data.uzytkownicy_w.labels,
                        datasets: [
                            { 
                                data: _vm.data.uzytkownicy_w.data,
                                backgroundColor: ['#F2EFFF','#e7e1fd', '#d6ccfb', '#bfaefa', '#9e86f7', '#7d5df5', '#5e35f2'],
                                borderRadius: 6,
                                barPercentage: 1,
                                categoryPercentage: 0.5
                            } ]
                    }}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-sm-12 col-md-4"},[_c('div',{staticClass:"chart-title-container"},[_c('span',{staticClass:"chart-title-first"},[_vm._v("Użytkownicy")]),_c('span',{staticClass:"chart-title-second"},[_vm._v("śr.wartość zamówienia")])]),_c('BarChart',{attrs:{"displayDataLabels":true,"chartData":{
                        labels: ['mężczyźni','kobiety'],
                        datasets: [
                            { 
                                data: _vm.data.sr_wartosc_zamowienia,
                                backgroundColor: ['#1AC5A8', '#52FBDE'],
                                borderRadius: 6,
                                barPercentage: 1,
                                categoryPercentage: 0.5
                            } ]
                    }}})],1),_c('v-col',{staticClass:"col-sm-12 col-md-4"},[_c('div',{staticClass:"chart-title-container"},[_c('span',{staticClass:"chart-title-first"},[_vm._v("Mężczyźni")]),_c('span',{staticClass:"chart-title-second"},[_vm._v("śr.wartość zamówienia")])]),_c('BarChart',{attrs:{"chartData":{
                        labels: _vm.data.sr_wartosc_zamowienia_m.labels,
                        datasets: [
                            { 
                                data: _vm.data.sr_wartosc_zamowienia_m.data,
                                backgroundColor: ['#D5F5F0','#c5f0e9', '#afebe1', '#81dfcf', '#5fd7c2', '#3ccdb4', '#1ac5a8'],
                                borderRadius: 6,
                                barPercentage: 1,
                                categoryPercentage: 0.5
                            } ]
                    }}})],1),_c('v-col',{staticClass:"col-sm-12 col-md-4"},[_c('div',{staticClass:"chart-title-container"},[_c('span',{staticClass:"chart-title-first"},[_vm._v("Kobiety")]),_c('span',{staticClass:"chart-title-second"},[_vm._v("śr.wartość zamówienia")])]),_c('BarChart',{attrs:{"chartData":{
                        labels: _vm.data.sr_wartosc_zamowienia_w.labels,
                        datasets: [
                            { 
                                data: _vm.data.sr_wartosc_zamowienia_w.data,
                                backgroundColor: ['#E5FFFA','#d3fef6', '#c3fef4', '#a0fded', '#86fde8', '#6cfbe3', '#52fbde'],
                                borderRadius: 6,
                                barPercentage: 1,
                                categoryPercentage: 0.5
                            } ]
                    }}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"col-sm-12 col-md-4"},[_c('div',{staticClass:"chart-title-container"},[_c('span',{staticClass:"chart-title-first"},[_vm._v("Użytkownicy")]),_c('span',{staticClass:"chart-title-second"},[_vm._v("transakcje")])]),_c('BarChart',{attrs:{"displayDataLabels":true,"chartData":{
                        labels: ['mężczyźni','kobiety'],
                        datasets: [
                            { 
                                data: _vm.data.transakcje,
                                backgroundColor: ['#E48400', '#FFB145'],
                                borderRadius: 6,
                                barPercentage: 1,
                                categoryPercentage: 0.5
                            } ]
                    }}})],1),_c('v-col',{staticClass:"col-sm-12 col-md-4"},[_c('div',{staticClass:"chart-title-container"},[_c('span',{staticClass:"chart-title-first"},[_vm._v("Mężczyźni")]),_c('span',{staticClass:"chart-title-second"},[_vm._v("transakcje")])]),_c('BarChart',{attrs:{"chartData":{
                        labels: _vm.data.transakcje_m.labels,
                        datasets: [
                            { 
                                data: _vm.data.transakcje_m.data,
                                backgroundColor: ['#F9E7CE','#f8e0bf', '#f6d4a6', '#f0bc73', '#ecaa4d', '#e89626', '#e48400'],
                                borderRadius: 6,
                                barPercentage: 1,
                                categoryPercentage: 0.5
                            } ]
                    }}})],1),_c('v-col',{staticClass:"col-sm-12 col-md-4"},[_c('div',{staticClass:"chart-title-container"},[_c('span',{staticClass:"chart-title-first"},[_vm._v("Kobiety")]),_c('span',{staticClass:"chart-title-second"},[_vm._v("transakcje")])]),_c('BarChart',{attrs:{"chartData":{
                        labels: _vm.data.transakcje_w.labels,
                        datasets: [
                            { 
                                data: _vm.data.transakcje_w.data,
                                backgroundColor: ['#FFF1DE','#ffebd0', '#ffe4be', '#ffd499', '#ffc97d', '#ffbc61', '#ffb145'],
                                borderRadius: 6,
                                barPercentage: 1,
                                categoryPercentage: 0.5
                            } ]
                    }}})],1)],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }