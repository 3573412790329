<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="361px"
    content-class="loader"
  >
    <svg
      width="361"
      height="267"
      viewBox="0 0 361 267"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g clip-path="url(#clip0_1112_5148)">
        <path
          d="M360.856 0H133.693C115.883 0.0148479 98.2561 3.57992 81.8443 10.4862C65.4324 17.3925 50.5664 27.5011 38.1177 40.2192C25.6691 52.9374 15.8883 68.0093 9.34875 84.5513C2.80921 101.093 -0.357476 118.773 0.0343406 136.553C1.66056 208.498 62.6335 267 135.957 267L361.021 266.863L360.856 0Z"
          fill="#5E35F2"
        />
        <path
          d="M177.278 198.412C164.608 206.521 149.875 210.832 134.825 210.835C113.767 210.809 93.5777 202.452 78.6773 187.594C63.7769 172.735 55.3801 152.585 55.3257 131.557H84.5633C84.5842 139.968 86.7199 148.238 90.7744 155.609C94.8289 162.98 100.672 169.217 107.769 173.746C114.865 178.275 122.987 180.953 131.389 181.532C139.791 182.111 148.204 180.574 155.856 177.061L177.278 198.412Z"
          fill="#52FBDE"
        />
        <path
          d="M99.3228 95.9685C94.6246 100.607 90.8995 106.135 88.3653 112.228C85.8311 118.321 84.5386 124.857 84.5633 131.455V131.558H55.3257V131.434C55.2988 121.001 57.3472 110.665 61.352 101.029C65.3569 91.3917 71.2385 82.6451 78.6554 75.2961L99.3228 95.9685Z"
          fill="#FFB145"
        />
        <path
          d="M177.278 64.4974L155.856 85.848C149.525 82.9372 142.656 81.3732 135.686 81.2552C128.717 81.1373 121.799 82.468 115.372 85.1628C113.881 85.7726 112.422 86.4587 111.001 87.2184C106.759 89.5195 102.87 92.4178 99.4532 95.8245C99.4532 95.8245 99.3571 95.9204 99.3571 95.9547L78.676 75.3029L78.7721 75.207L80.0003 74.0216C80.8031 73.2473 81.5717 72.5073 82.347 71.8358C83.9165 70.4206 85.5562 69.0851 87.26 67.8343L88.1314 67.1902C88.7764 66.7105 89.5038 66.2309 90.1556 65.7444L90.8006 65.3333C91.7338 64.6892 92.667 64.1068 93.6276 63.5312L93.7237 63.4696C94.6569 62.9214 95.6244 62.3459 96.5919 61.7977H96.8458L97.2026 61.4757C98.2593 60.9275 99.3228 60.3862 100.386 59.8723C101.608 59.2899 102.603 58.8445 103.598 58.3923C105.594 57.5563 107.584 56.8232 109.423 56.2134C110.61 55.7954 111.736 55.4391 112.895 55.117L113.472 54.9252C115.242 54.4456 117.012 54.0276 118.81 53.6439L119.551 53.5137C120.484 53.3492 121.417 53.1574 122.351 53.034C141.449 49.9553 161.013 54.0382 177.278 64.4974Z"
          fill="white"
        />
        <path
          id="cross"
          d="M271.757 157.211L246.046 131.544L271.757 105.876L251.083 85.2314L225.379 110.899L199.668 85.2314L179.001 105.876L204.705 131.544L179.001 157.211L199.668 177.856L225.379 152.182L251.083 177.856L271.757 157.211Z"
          fill="white"
        />
        <path
          id="square_1"
          d="M308.948 194.359L288.279 173.719L267.61 194.359L288.279 214.999L308.948 194.359Z"
          fill="white"
        />
        <path
          id="square_2"
          d="M346.157 231.504L325.488 210.864L304.819 231.504L325.488 252.144L346.157 231.504Z"
          fill="white"
        />
      </g>
      <defs>
        <animateTransform
          xlink:href="#cross"
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          values="0 225.379 131.544; 270 225.379 131.544; 90 225.379 131.544; 90 225.379 131.544; 90 225.379 131.544"
          keyTimes="0;0.2; 0.5; 0.7; 1"
          dur="3s"
          begin="0s"
          repeatCount="indefinite"
          fill="freeze"
        />
        <animate
          xlink:href="#square_1"
          attributeName="opacity"
          keyTimes="0;0.2; 0.4; 0.6; 0.8; 1"
          values="0;0;0;1;1;0"
          dur="3s"
          begin="0s"
          repeatCount="indefinite"
        />
        <animate
          xlink:href="#square_2"
          attributeName="opacity"
          keyTimes="0;0.2; 0.4; 0.6; 0.8; 1"
          values="0;0;0;0;1;0"
          dur="3s"
          begin="0s"
          repeatCount="indefinite"
        />
        <clipPath id="clip0_1112_5148">
          <rect width="361" height="267" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store/index";

const myTimeout = () =>
  setInterval(() => {
    console.log("set loader");
    store.dispatch("setLoader", true);
  }, 1000);

export default {
  data() {
    return {
      timeout: null,
    };
  },
  computed: {
    ...mapState(["loader", "requestSent"]),
    dialog: {
      get() {
        return this.loader;
      },
      set(value) {
        this.setLoader(value);
      },
    },
  },
  watch: {
    requestSent(newVal) {
      if (newVal) {
        this.timeout = myTimeout();
      } else {
        clearInterval(this.timeout);
        this.setLoader(false);
      }
    },
  },
  methods: {
    ...mapActions(["setLoader"]),
  },
};
</script>

<style lang="sass" scoped>
::v-deep .v-dialog.loader
  box-shadow: none !important
  border-radius: none !important
</style>
