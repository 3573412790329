<template>
    <v-menu transition="slide-y-transition" offset-y>
        <template v-slot:activator="{ on, attrs }">
            <button  
            v-bind="attrs"
            v-on="on"
            elevation="0"
            :ripple="false"
            class="position-relative" 
            >
                <div class="d-flex align-center justify-space-between subpage-dropdown"> 
                    <span v-if="!selected">{{items[0].text}}</span>
                    <span v-else>{{selected.text}}</span>
                    <svg class="ml-5" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 6.75L9 12.75L15 6.75" stroke="currentColor" stroke-width="2" stroke-linecap="square"/>
                    </svg>
                </div>
            </button>
        </template>

        <v-list class="py-0 subpage-dropdown-list">
            <v-list-item v-for="item in items" :key="item.index" class="list-item-hover-active subpage-dropdown-item" @click="selectItem(item)">
                <v-list-item-content class="pa-0">
                    <v-list-item-title class="mb-0">
                        {{item.text}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    props: {
        editedItemQuestion: {}
    },
    data() {
        return {
            selected: null,
            items: [ 
                {
                    text: "sentyment 3 gwiazdki",
                    type: "sentiment_3_stars",
                    answers: [1,2,3]
                }, {
                    text: "Customizowane odpowiedzi",
                    type: "custom",
                    answers: ["odpowiedź pierwsza", "odpowiedź druga"]
                },  {
                    text: "sentyment 5 gwiazdek",
                    type: "sentiment_5_stars",
                    answers:  [1,2,3,4,5]
                }, 
                // {
                //     text: "sentyment Likert",
                //     type: "likert_5_stars",
                //     answers:  [-2,-1,0,1,2]
                // },
            ]
        }
    },
    mounted() {
        if(this.editedItemQuestion) {
            this.setInitialData(this.editedItemQuestion);
        }
    },
    methods: {
        selectItem(item) {
            this.selected = item;
            this.$emit("typeSelected", item);
        },
        setInitialData(data) {
            const initialAnswers = {}

            this.items.forEach(element => {
                if(element.type === data.type) {
                    initialAnswers.text = element.text;
                }
            });
        
            initialAnswers.type = data.type;
            initialAnswers.answers = data.answers;
            this.selected = initialAnswers;
        }
    },
    watch: {
        editedItemQuestion(val) {
            this.setInitialData(val);
        }
    }
}
</script>