
<template>
    <v-dialog  
        v-model="dialog"
        max-width="448px">
        <v-card class="mx-auto" id="cropp-picture-modal">
            <div class="close-btn" >
                <img @click="dialog = false" src="@/assets/img/cross-circle.svg" />
            </div>
            <div class="cropp-picture-modal-content">
                <div id="cropp-picture-title">Edycja zdjęcia</div>
                <input
                    type="file"
                    name="img"
                    ref="fileInput"
                    id="upload-area-input"
                    accept="image/png, image/jpeg"
                    @change="loadImage"
                />
                <div v-if="image.src" class="text-center text-color-primary">Dostosuj swoje zdjęcie profilowe</div>
                <Cropper 
                    :img="image.src"
                    ref="cropper"
                    @imageCropped="save"
                    @imageMoved="imageModified = $event"
                />
                <div 
                    class="font-weight-bold text-color-secondary cursor-pointer text-center mb-2" 
                    :class="!image.src ? 'mt-8' : ''"
                    @click="$refs.fileInput.click()"
                >
                    zmień zdjęcie
                </div>
                <div
                    v-if="image.src"
                    class="font-weight-bold text-color-secondary cursor-pointer text-center" 
                    @click="removeImage"
                >
                    usuń zdjęcie
                </div>
                <v-btn class="save-btn" @click="crop" :disabled="disableSaveButton">
                    Zapisz
                </v-btn>
            </div>
            <ImageInfoModal
                :type="messageType"
                :message="message"
                ref="infoModal"
             />
        </v-card>
    </v-dialog>
</template>

<script>
import Cropper from "@/components/admin/accountManagement/profile/Cropper"
import { http } from "@/plugins/http";
import ImageInfoModal from "@/components/admin/accountManagement/profile/ImageInfoModal"

function getMimeType(file, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
       header += byteArray[i].toString(16);
    }
	switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

export default {
    components: { Cropper, ImageInfoModal },
    name: "Upload",
    props: {
        savedProfilePicture: {},
        savedProfilePicturePath: {},
        userId: {}
    },
    data() {
        return {
            dragover: false,
            image: {
				src: null,
				type: null
			},
            dialog: false,
            message: null,
            messageType: null,
            imageModified: false
        };
    },
    computed: {
        disableSaveButton() {
            let result = false
            if(this.savedProfilePicture === this.image.src && !this.imageModified) {
                result = true
            }
            if(!this.savedProfilePicturePath && !this.image.src) {
                result = true
            }
            return result;
        }
    },
    methods: {
        setDialog(value) {
            this.dialog = value;
        },
        crop() {
            if(this.image.src) {
                this.$refs.cropper.crop();
            } else {
                this.save();
            }
        },
        async save(newImage) {
            let formData = new FormData();
            if(newImage) {
                formData.append("profile_picture", newImage);
                this.sendImage(formData, newImage.name);
            } else {
                formData.append("profile_picture", null);
                this.deleteImage();
            }
            
        },
        deleteImage() {
            http.delete(`/user_profile_picture`)
            .then(() => {
                this.dialog = false;
                this.$emit("profilePictureChanged");
                this.message = 'Successfully deleted an image!';
                this.messageType = 'success';
                this.$refs.infoModal.setDialog(true);
            })
            .catch((error) => {
                console.log(error)
            })
        },
        sendImage(formData, fileName) {
            http.patch(`/user_profile_picture`, formData)
            .then(() => {
                this.dialog = false;
                this.$emit("profilePictureChanged", fileName);
                this.message = 'Successfully uploaded an image!';
                this.messageType = 'success';
                this.$refs.infoModal.setDialog(true);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 403) {
                        this.message = "You have reached your upload limit! In order to upload next images contact the administrator";
                        this.messageType = 'danger';
                        this.$refs.infoModal.setDialog(true);
                    } else if (error.response.status == 400) {
                        this.message = "Image has wrong fromat or is too big (max allowed size is 5MB). Supported formats are png, jpg, heic.";
                        this.messageType = 'danger';
                        this.$refs.infoModal.setDialog(true);
                    } else {
                        this.message = "Could not process your image. Please try again with different one or eventually try different file extension.";
                        this.messageType = 'danger';
                        this.$refs.infoModal.setDialog(true);
                    }
                    // eslint-disable-next-line
                    console.log(error);
                }
                else {
                    this.message = "Could not process your image. Please try again with different one, smaller one if size >5MB or eventually try different file extension.";
                    this.messageType = 'danger';
                    this.$refs.infoModal.setDialog(true);
                }
            });
        },
        clear() {
            this.dragover = false;
            this.image = {
				src: null,
				type: null
			}
        },
        loadImage(event) {
			const { files } = event.target;
			if (files && files[0]) {
				if (this.image.src) {
					URL.revokeObjectURL(this.image.src)
				}
                let self = this
				const blob = URL.createObjectURL(files[0]);
				const reader = new FileReader();
				reader.onload = (e) => {
                    let img = new Image();
                    img.onload = function () {
                        let minSize = self.$refs.cropper.defaultSize();
                        console.log(this.width + " " + this.height);
                        if (this.width < minSize.width || this.height < minSize.height) {
                            alert("Image is too small - zoom will brake :)")
                            self.image = {src:null,type:null}
                            return
                        }
                    };
                    img.src = blob;
					this.image = {
						src: blob,
						type: getMimeType(e.target.result, files[0].type),
					};
                    event.target.value = ""
				};
				reader.readAsArrayBuffer(files[0]);
			}
		},
        removeImage() {
            this.image.src = null;
            this.image.type = null;
        }
    },
    watch: {
        dialog(val) {
            if(!val) {
                this.clear();
            } else {
                if(this.savedProfilePicturePath) {
                    this.image.src = this.savedProfilePicture;
                }
            }
        }
    }
};
</script>