var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('div',{attrs:{"id":"utm-sources-list"}},[_c('span',{staticClass:"analytics-title"},[_vm._v("Analityka: Strony wejścia")]),_c('div',{attrs:{"id":"utm-sources-list-content"}},[_c('div',{staticClass:"content-wrapper"},[_c('v-data-table',{directives:[{name:"scroll",rawName:"v-scroll:#virtual-scroll-table",value:(_vm.onScroll),expression:"onScroll",arg:"#virtual-scroll-table"}],attrs:{"headers":_vm.headers,"items":_vm.dataToDisplay,"items-per-page":_vm.dataToDisplay.length}})],1),_c('div',{staticClass:"content-wrapper px-4"},[_c('p',{staticClass:"text-size-2 text-color-primary font-weight-bold chartTitle"},[_vm._v("Udział procentowy poszczególnych źródeł")]),_c('PieChart',{attrs:{"width":350,"height":350,"chartData":{
                    labels: _vm.chartData.labels,
                    datasets: [
                        { 
                            data: _vm.chartData.data,
                            backgroundColor: ['#2e1b75', '#452d85', '#5b3f96', '#7053a7', '#8666b9', '#9b7ac9', '#af8edb', '#c5a3ed', '#dab9fe', '#ecdcfe', '#dddbe8'],
                        } ]
                }}})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }