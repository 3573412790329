<template>
    <v-card class="modal-step">
        <div class="justify-end modal-top d-flex">
             <v-btn
                :ripple="false"
                :elevation="0"
                class="mr-1 mt-1 close-button mt-1"
                @click="close"
                icon
            >
                <img src="@/assets/img/cross-circle.svg" />
            </v-btn>
        </div>
        <div>
            <v-row class="text-center">
                <v-col cols="5" class="mx-auto px-0 py-0">
                    <h5 class="mb-2 modal-title">
                        Tworzenie nowej ścieżki klienta
                    </h5>
                    <p class="modal-subtitle">
                        <template v-if="user && user.super_admin">Krok 1/2:</template>
                        <template v-else>Krok 1/3:</template>  
                        nazwij ścieżkę i ustal zarządzenie projektem
                    </p>
                </v-col>
            </v-row>
            <v-row class="mt-2">
                <v-col cols="5" class="mx-auto">
                    <input
                        type="text"
                        hide-details
                        v-model="projectTitle"
                        placeholder="Wpisz nazwę ścieżki klienta"
                        class="mt-2"
                    /> 
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="5" class="mx-auto">
                    <textarea
                        type="text"
                        hide-details
                        v-model="projectDescription"
                        placeholder="Wpisz opis ścieżki klienta (opcjonalne)"
                        class="mb-4 description-input"
                    /> 
                </v-col>
            </v-row>
        </div>
        <div class="text-end">
            <v-row class="mt-2">
                <v-col cols="5" class="mx-auto">
                    <v-btn
                        :disabled="!projectTitle"
                        :ripple="false"
                        :elevation="0"
                        class="
                            py-5
                            px-6
                            me-2
                            next-button
                        "
                        @click="next"
                    >
                        Dalej
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
import store from '@/store/index';
export default {
    data() {
        return {
            projectTitle: null,
            projectDescription: null,
            user: null
        }
    },
    mounted(){
		this.user = store.getters.getUser;
    },
    methods: {
        next() {
           this.$emit("changeStep", 2)
           this.$emit("setProjectTitle", this.projectTitle)
           this.$emit("setProjectDescription", this.projectDescription)
        },
        close() {
            this.$emit("close")
        }
    }
}
</script>
