<template>
  <v-dialog v-model="dialog" max-width="1034">
    <div id="account-management">
      <div class="d-flex align-top">
        <v-tabs v-model="tab" left>
          <v-tabs-slider color="#2E1B75"></v-tabs-slider>
          <v-tab href="#tab-1"> Profil </v-tab>

          <v-tab v-if="role === 'ADMIN'" href="#tab-2">
            Sybskrypcja i jej udostępnianie
          </v-tab>

          <v-tab v-if="role === 'ADMIN'" href="#tab-3"> Płatności </v-tab>

          <v-tab v-if="role === 'ADMIN'" href="#tab-4"> Analityka </v-tab>

          <v-tab v-if="false" href="#tab-5"> Notyfikacje </v-tab>
        </v-tabs>
        <div class="close-btn">
          <img @click="dialog = false" src="@/assets/img/cross-circle.svg" />
        </div>
      </div>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="i in 5" :key="i" :value="'tab-' + i">
          <v-card flat>
            <component
              :ref="`tab${i}`"
              :is="components[i - 1]"
              :apiData="userData"
              :accessData="accessData"
              :userRole="role"
              :subscriptionNameProp="subscriptionName"
              @setTab="tab = $event"
              @setDialog="setDialog"
              @getUserAccess="getUserAccess"
              @updateUser="updateUser"
              @updateSubscriptionName="updateSubscriptionName"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import Profile from "@/components/admin/accountManagement/profile/Profile";
import Payments from "@/components/admin/accountManagement/payments/Payments";
import Subscription from "@/components/admin/accountManagement/subscription/Subscription";
import Notifications from "@/components/admin/accountManagement/notifications/Notifications";
import Analytics from "@/components/admin/accountManagement/analytics/Analytics";
import { http } from "@/plugins/http";
import store from "@/store";

export default {
  name: "paginated-tables",
  components: {
    Profile,
    Payments,
    Subscription,
    Notifications,
    Analytics,
  },
  data() {
    return {
      tab: null,
      components: [
        "Profile",
        "Subscription",
        "Payments",
        "Analytics",
        "Notifications",
      ],
      userData: null,
      dialog: false,
      accessData: null,
      role: null,
      subscriptionName: null,
    };
  },
  methods: {
    updateSubscriptionName(value) {
      this.subscriptionName = value;
    },
    setDialog(value) {
      this.dialog = value;
    },
    async getData() {
      this.userData = store.getters.getUser;
      await this.getUserRole();
      if (this.role === "ADMIN") {
        this.getSubscriptionName();
        await this.getUserAccess();
      }
    },
    async getUserAccess() {
      const response = await http.get("/user_accesses");
      this.accessData = response.data.data;
    },
    async updateUser() {
      this.userData = store.getters.getUser;
    },
    async getSubscriptionName() {
      const response = await http.get("/subscription");
      this.subscriptionName = response.data.data[0].name;
    },
    getUserRole() {
      if (this.userData) {
        const subscriptionId = store.getters.getSubscriptionId;
        if (subscriptionId) {
          this.userData.user_access.forEach((element) => {
            if (element.subscription_id === subscriptionId) {
              this.role = element.role;
            }
          });
        } else {
          this.role = this.userData.user_access[0].role;
        }
      }
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        if (this.tab === "tab-1") {
          this.$refs.tab1[0].checkChanges("tab-1", true);
        }
        if (this.tab === "tab-2") {
          this.$refs.tab2[0].checkChanges("tab-2", true);
        }
        if (this.tab === "tab-4") {
          this.$refs.tab4[0].checkChanges("tab-4", true);
        }
      } else {
        this.getData();
      }
    },
    tab(newVal, oldVal) {
      if (oldVal === "tab-1") {
        this.$refs.tab1[0].checkChanges(newVal);
      }
      if (oldVal === "tab-2") {
        this.$refs.tab2[0].checkChanges(newVal);
      }
      if (oldVal === "tab-4") {
        this.$refs.tab4[0].checkChanges(newVal);
      }
    },
    role(val) {
      if (val === "ADMIN") {
        this.getSubscriptionName();
      }
    },
  },
};
</script>
