<template>
  <div
    v-if="col.type == 'card'"
    class="card"
    :class="[col.shape ? shapes[col.shape] : null]"
    draggable
    @dragstart="
      (event) => {
        event.stopPropagation();
        cardDragStart({
          element: event.target.parentElement,
          rowId: rowId,
          columnId: columnId,
        });
      }
    "
    @dragend="cardDragEnd"
    @dragover.prevent
    @dragenter="
      (event) => {
        event.preventDefault();
        cardDragEnter({ element: event.target });
      }
    "
    @drop="
      (event) => cardDrop({ event: event, rowId: rowId, columnId: columnId })
    "
    :style="{
      backgroundColor:
        col.color && col.color !== null
          ? colors[col.color].backgroundColor
          : null,
      color:
        col.color && col.color !== null ? colors[col.color].textColor : null,
    }"
  >
    <template v-if="col.shape == 1">
      <Baloon :fill="col.color ? colors[col.color].backgroundColor : '#fff'" />
    </template>
    <div
      @click="openDialog({ rowId: rowId, columnId: columnId, isEdit: true })"
      class="card-content"
    >
      <h5>{{ col.title }}</h5>
      <div>{{ col.desc }}</div>
      <div v-if="col.picture" class="card-picture">
        <img v-if="pictureSrc != null" :src="pictureSrc" :alt="col.picture.name" />
      </div>
    </div>
    <div class="card-actions">
      <template v-if="col.icon && col.icon !== null">
        <div class="card-icon">
          <BaseIcon
            :key="'selected_icon'"
            class="selected"
            :path="`icons/shape-${col.icon}.vue`"
            :fill="col.color !== null ? colors[col.color].textColor : null"
          />
        </div>
      </template>
      <div
        class="remove-card"
        :class="!col.icon ? 'mt-0' : null"
        @click="
          setRemoveCardDialog({
            removeRowId: rowId,
            removeColumnId: columnId,
            dialog: true,
          })
        "
      >
        <TrashIcon
          :fill="col.color ? colors[col.color].textColor : '#2E1B75'"
        />
      </div>
    </div>
    <template v-if="col.shape == 2">
      <Arrow :fill="col.color ? colors[col.color].backgroundColor : '#fff'" />
    </template>
  </div>
</template>

<script>
import Baloon from "@/components/customerJourney/shapes/baloon.vue";
import BaseIcon from "@/components/customerJourney/BaseIcon.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import Arrow from "@/components/customerJourney/shapes/arrow.vue";
import { mapState, mapActions } from "vuex";
import { http } from "@/plugins/http.js";

export default {
  components: {
    Baloon,
    BaseIcon,
    TrashIcon,
    Arrow,
  },
  props: {
    col: Object,
    row: Object,
    columnId: Number,
    rowId: Number,
  },
  data() {
    return {
      shapes: ["card", "baloon", "arrow"],
      pictureSrc: null,
    };
  },
  computed: {
    ...mapState("cjstore", ["colors", "pictureUploaded", "rows"]),
  },
  watch: {
    rows: {
      deep: true,
      handler(newVal) {
        if (newVal[this.rowId].cols[this.columnId].picture) {
          let picture = newVal[this.rowId].cols[this.columnId].picture;
          this.getFile(picture.path);
        }
      },
    },
  },
  methods: {
    ...mapActions("cjstore", [
      "setRemoveCardDialog",
      "openCardDialog",
      "cardDragStart",
      "cardDragEnter",
      "cardDragLeave",
      "cardDragEnd",
      "cardDrop",
      "setEditRowId",
      "setEditColId",
    ]),
    async openDialog(payload) {
      await this.setEditRowId(payload.rowId);
      await this.setEditColId(payload.columnId);
      this.openCardDialog(payload);
    },
    async getFile(path) {
      if (path == undefined)
        return;
      const response = await http.get(`/files?file_path=${path}`, {
        responseType: "blob",
      });
      if (response.status == 200) {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        this.pictureSrc = url;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.card,
.dragenter
  background: #fff
  border-radius: 8px
  margin: 5px 10px
  padding: 7px 20px
  display: flex
  justify-content: space-between
  align-items: center
  color: $primary-color
  &.arrow
    width: calc(100% - 30px)
    margin-right: 0
    padding-right: 0
    &.processed
      padding-bottom: 0
      padding-top: 0
  &.baloon
    margin-left: 0
    transform: translateX(10px)
    width: calc(100% - 20px)
    padding-left: 0
    align-items: flex-end
    &.processed .baloon
      margin-left: -2px
  .baloon
    height: 34px
    width: 10px
    margin-left: -3px
    margin-bottom: -7px
  .arrow
    position: relative
    height: calc(100% + 14px)
    margin-right: -10px
    z-index: 99
.card
  position: relative
.card-content
  cursor: pointer
  max-width: 175px
  width: 100%
  min-height: 20px
  word-wrap: break-word
  h5, div
    font-family: 'Roboto'
.card-content > *
  font-size: 12px
.card-icon,
.remove-card
  width: 20px
  height: 20px
.card::v-deep .remove-card
  cursor: pointer
  margin-top: 5px
  opacity: 0
  display: flex
  svg
    width: 100%
    height: 100%
.card::v-deep:hover .remove-card
  opacity: 1
.card-picture
  display: flex
  justify-content: center
  padding: 20px 0
  img
    border-radius: 8px
    max-width: 175px
    max-height: 100px
</style>
