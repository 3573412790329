
<template>
    <v-dialog  
        v-model="dialog"
        max-width="448px">
        <v-card class="mx-auto" id="info-modal" :class="type">
             <div class="close-btn" >
                <img class="cursor-pointer" @click="dialog = false" src="@/assets/img/cross-circle.svg" />
            </div>
            <div class="info-modal-content px-7 pb-11 text-center">
                {{message}}
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
        }
    },
    props: {
        message: {},
        type: {},
    },
    methods: {
        setDialog(value) {
            this.dialog = value
        }
    }
}
</script>