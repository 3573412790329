<template>
  <div class="tag-area">
    <TitleInput 
      :id="id"
      :placeholder="'Tytuł'"
      :title="title"
      :events="true"
      :type="'tags'"
      :inputType="'tags'"
      :removeArrayName="'tags'"></TitleInput>
    <v-text-field
      class="tag-input noprint"
      hide-details
      flat
      :placeholder="'Zacznij wpisywać i wciśnij ENTER'"
      v-on:keyup.enter="addTag($event)"></v-text-field>
    <div class="tags-content">
      <template v-for="(tag,tagId) in items">
        <div :key="`tag_${tagId}`" class="tag">
          <span>{{tag.label}}</span>
          <div class="remove noprint" @click="removeTag(id,tagId)" data-html2canvas-ignore="true">
            <TrashIcon />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import TitleInput from '@/components/personas/TitleInput.vue';
import TrashIcon from '@/components/icons/TrashIcon.vue';
import bus from '@/components/personas/eventBus.js';

export default {
  components: {
    TitleInput,
    TrashIcon
  },
  props: {
    id: Number,
    title: String,
    items: Array
  },
  methods:{
    addTag($event){
      if($event.target.value.trim().length !== 0) {
        bus.$emit('addTag',{
          label: $event.target.value,
          id: this.id
        });
        $event.target.value = "";
      }
    },
    removeTag(id,tagId){
      bus.$emit('removeTag',{areaId: id, tagId: tagId})
    },
  }
}
</script>

<style lang="sass" scoped>
  .tag-area:last-of-type
    margin-bottom: 20px
  .tag-area 
    .tag-input
      min-height: 0px
      margin-top: 0px
      padding-top: 0px
      margin-bottom: 15px
      transition: height .2s ease-out, margin-top .2s ease-out, padding-top .2s ease-out, margin-bottom .2s ease-out
  .tags-content
    display: flex
    flex-wrap: wrap
  .tag
    min-height: 0px
    font-size: 12px
    text-transform: uppercase
    padding: 8px
    border: 1px solid #2E1B75
    color: #2E1B75
    border-radius: 8px
    margin-right: 12px
    margin-bottom: 6px
    display: flex
    cursor: default
  .remove
    margin-left: 10px
    display: flex
    cursor: pointer
  ::v-deep .v-text-field .v-input__control 
    background-color: rgba(0,0,0,0)
  ::v-deep .tag-input.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot
    padding: 0 12px
  ::v-deep .tag-input.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot input,
  ::v-deep .tag-input.v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot input::placeholder
    font-size: 12px
  @media print
    .noprint 
      display: none
</style>