<template>
    <v-card class="px-6 py-10" id="notifications-tab">
        <v-row>
            <v-col cols="12">
                <v-list three-line>
                    <v-subheader id="notifications-title">Notyfikacje</v-subheader>
                    <template v-for="(item, index) in items">
                        <v-list-item
                        :key="item.index"
                        >
                            <v-list-item-content>
                                <v-row>
                                    <v-col class="text-start">
                                        <v-list-item-title class="text-color-primary mb-1"> {{ item.title }}</v-list-item-title>
                                        <v-list-item-subtitle class="text-color-gray text-size-1"> {{ item.subtitle }} </v-list-item-subtitle>
                                    </v-col>
                                    <v-col class="d-flex align-center justify-end text-color-gray text-size-1 font-weight-bolder">
                                        <div class="switch-wrapper">
                                            <v-switch
                                                :ripple="false"
                                                class="notifications-switch"
                                                :class="item.isActive ? '' : 'off'"
                                                v-model="item.isActive"
                                                hide-details
                                                color="#344767"
                                                inset
                                            >
                                            </v-switch>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider
                            :key="index"
                            :inset="item.inset"
                        ></v-divider>
                    </template>
                </v-list>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>

export default {
    data() {
        return {
            items: [
                {
                    title: 'Wskaźniki',
                    subtitle: `Wzrosty i spadki o 80%`,
                    isActive: false
                },
                {
                    title: 'Płatności',
                    subtitle: `Zakończone sukcesem, odrzucone`,
                    isActive: false
                },
                {
                    title: 'GA / Pixel',
                    subtitle: `Niezintegrowany pixel, wysypany GA`,
                    isActive: false
                },
                {
                    title: 'Ankiety',
                    subtitle: `Wyniki ankiet, rozbieżności w wynikach`,
                    isActive: true
                },
            ],
        }
    },
}
</script>