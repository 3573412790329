<template>
    <v-card class="modal-step">
        <div class="justify-space-between modal-top d-flex">
            <v-btn
                :ripple="false"
                :elevation="0"
                class="goBack-button ml-1 mt-1"
                @click="prev"
                icon
            >
                <img src="@/assets/img/chevron-circle-left.svg" />
            </v-btn>
             <v-btn
                :ripple="false"
                :elevation="0"
                class="mr-1 mt-1 close-button"
                @click="close"
                icon
            >
                <img src="@/assets/img/cross-circle.svg" />
            </v-btn>
        </div>
        <div>
            <v-row class="text-center">
                <v-col cols="5" class="mx-auto">
                    <h5 class="mb-2 modal-title">
                        Tworzenie nowej ścieżki klienta
                    </h5>
                    <p class="modal-subtitle">
                        krok 2/2: Wybierz awatar
                    </p>
                </v-col>
            </v-row>
            <v-row class="mt-2 mx-auto modal-cards">
                <template v-for="id in 3" >
                    <v-card
                        :key="`template_journey_${id}`"
                        class="modal-card"
                        :class="id === selectedAvatar ? 'card-clicked' : ''"
                        @click="selectedAvatar = id"
                    >
                        <template slot="progress">
                            <v-progress-linear
                                color="deep-purple"
                                height="10"
                                indeterminate
                            ></v-progress-linear>
                        </template>
                        <v-img
                            :src="require(`@/assets/img/customer-journey/avatars/avatar_${id}.jpg`)"
                            class="card-image"
                        ></v-img>
                    </v-card>
                </template>
            </v-row>
        </div>
        <v-row class="mt-2">
            <v-col cols="5" class="mx-auto">
                <v-btn
                    :ripple="false"
                    :elevation="0"
                    class="
                        py-5
                        px-6
                        me-2
                        next-button
                    "
                    @click="finish"
                    :disabled="!selectedAvatar"
                >
                    DO ŚCIEŻKI
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>

export default {
  data() {
    return {
      selectedAvatar: null
    }
  },
  methods: {
    prev() {
      this.$emit("changeStep", 1)
    },
    close() {
      this.$emit("close")
    },
    finish() {
      this.$emit("finish", this.selectedAvatar)
    },
  }
}

</script>
<style lang="sass" scoped>
  .modal-step .modal-card .card-image 
    margin-bottom: 0
</style>