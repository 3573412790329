import Vue from "vue";
import Vuex from "vuex";
import { http } from "../plugins/http";
import router from "../router";
import createPersistedState from "vuex-persistedstate";
import cjstore from "@/store/cjstore";
import hints from "@/store/hints";
import notifications from "@/store/notifications";

Vue.use(Vuex);

const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT = "LOGOUT";
const SET_PERMISSIONS = "SET_PERMISSIONS";
const UNSET_PERMISSIONS = "UNSET_PERMISSIONS";
const CHANGE_PASSWD = "CHANGE_PASSWD";
const PASSWD_CHANGED = "PASSWD_CHANGED";
const SET_LOADER = "SET_LOADER";
const SET_REQUEST_SENT = "SET_REQUEST_SENT";

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    permissions: null,
    redirect: "",
    table_inline_edit: null,
    token: null,
    groupId: null,
    cache: {},
    subscriptions: [],
    currenSubscriptionId: null,
    credentials: null,
    user: null,
    change_passwd: false,
    loader: false,
    requestSent: false,
  },
  plugins: [createPersistedState()],
  actions: {
    login({ commit }, [context, redirect, google = false]) {
      commit(LOGIN); // show spinner
      // auth login
      let creds, request, login_endpoint;
      if (this.state.credentials !== null) {
        creds = this.state.credentials;
        creds.subscription_id = context.subscription_id;
        this.state.currenSubscriptionId = context.subscription_id;
      } else {
        creds = context.form;
      }
      if (google) {
        login_endpoint = "/google/login";
        request = http.get(login_endpoint);
        request.then((result) => {
          if (result.data) {
            window.location.replace(result.data);
          }
        });
      } else {
        login_endpoint = "/api/auth/login";
        request = http.post(login_endpoint, creds, { timeout: 5000 });
        request.then(
          (result) => {
            this.dispatch("parseLogin", {
              result,
              redirect,
              context,
              creds,
            });
          },
          (error) => {
            // Vue.notify( "Internet connection failed", {     type : "error" })
            console.log(error);
            commit(LOGOUT);
          }
        );
      }
    },
    refreshToken({ commit, dispatch }, [context, redirect]) {
      if (this.state.pending) return;
      commit(LOGIN); // show spinner
      console.log("commiting token refresh");
      let token = localStorage.getItem("refresh_token");
      if (token == null) return;
      http.defaults.headers.common = {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      };
      http.post("api/auth/refresh").then(
        (result) => {
          if (result.status == 200) {
            commit(LOGIN_SUCCESS);
            let token = (this.state.token = result.data.access_token);
            localStorage.setItem("token", token);
            localStorage.setItem("token_time", Date.now());
            if (result.data.user) this.state.user = result.data.user;
            http.defaults.headers.common = {
              Authorization: "Bearer " + token,
              Accept: "application/json",
            };
            if (redirect) {
              router.push(redirect).catch((err) => {console.log(err)});
            }
            if (this.state.redirect) {
              let redirect = this.state.redirect;
              this.state.redirect = "";
              router.push(redirect).catch((err) => {console.log(err)});
            }
          } else {
            context.errorMessage = result.data.msg;
            dispatch("logout");
          }
        },
        (error) => {
          console.log(error);
          dispatch("logout");
        }
      );
    },
    logout({ commit }) {
      http.post("/api/auth/logout", {}, { timeout: 1000 }).then(
        (result) => {
          console.log(result);
        },
        (error) => {
          console.log(error);
        }
      );
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      commit(LOGOUT);
      // Vue.notify( "Logged out", {     type : "info" })
      this.state.subscriptions = [];
      this.state.currenSubscriptionId = null;
      router.push("/auth/login");
    },
    passwordChanged({ commit }) {
      commit(PASSWD_CHANGED);
    },
    checkLoggedIn({ commit }) {
      console.log("CHECK LOGGED IN");
      let token = localStorage.getItem("token");
      if (token) {
        let role = localStorage.getItem("role");
        if (role) commit(SET_PERMISSIONS, role);
        else commit(UNSET_PERMISSIONS);
        http.defaults.headers.common = {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        };
        commit(LOGIN_SUCCESS);
        if (this.state.redirect) {
          let redirect = this.state.redirect;
          console.log("redirecting to=");
          console.log(redirect);
          this.state.redirect = "";
          router.push(redirect).catch((err) => {console.log(err)});
        } 
      } else {
        console.log("User not logged in?");
      }
    },
    parseLogin({ commit }, payload) {
      if (
        payload.result.status == 200 &&
        payload.result.data.access_token != null
      ) {
        commit("SET_USER", payload.result.data.user);
        commit(LOGIN_SUCCESS);
        let token = (this.state.token = payload.result.data.access_token);
        localStorage.setItem("token", token);
        localStorage.setItem("token_time", Date.now());
        localStorage.setItem(
          "refresh_token",
          payload.result.data.refresh_token
        );
        if (payload.result.data.force_change_password) {
          commit(CHANGE_PASSWD);
        }
        if (
          payload.result.data.user.user_access.length !== 0 &&
          payload.result.data.user.user_access[0].role != "unverified" &&
          payload.result.data.user.user_access[0].role != null
        ) {
          commit(SET_PERMISSIONS, payload.result.data.user.user_access[0].role);
        } else {
          commit(UNSET_PERMISSIONS);
        }
        http.defaults.headers.common = {
          Authorization: "Bearer " + token,
          Accept: "application/json",
        };
        // Vue.notify("Successfully connected!", {     type : "success" })
        if (
          !payload.result.data.user.user_access ||
          payload.result.data.user.user_access.length === 0
        ) {
          router.push("/auth/start-trial");
        } else {
          if (payload.redirect) {
            this.state.credentials = null;
            router.push(payload.redirect);
          }
          if (this.state.redirect) {
            this.state.credentials = null;
            let redirect = this.state.redirect;
            this.state.redirect = "";
            router.push(redirect);
          }
        }

        // router.push("/")
      } else if (
        payload.result.status == 200 &&
        payload.result.data.data.length > 1
      ) {
        router.push("/auth/choose-subscription");
        this.state.subscriptions = payload.result.data.data;
        this.state.credentials = payload.creds ? payload.creds : null;
      } else {
        // Vue.notify({"title":"Error","text":"Login or password incorrect"})
        payload.context
          ? (payload.context.errorMessage = payload.result.data.msg)
          : null;
        commit(LOGOUT);
      }
    },
    setLoader({ commit }, payload) {
      commit(SET_LOADER, payload);
    },
    setRequestSent({ commit }, payload) {
      commit(SET_REQUEST_SENT, payload);
    },
  },
  mutations: {
    [LOGIN](state) {
      state.pending = true;
    },
    [LOGIN_SUCCESS](state) {
      state.isLoggedIn = true;
      state.pending = false;
    },
    [SET_PERMISSIONS](state, permissions) {
      state.permissions = permissions;
      localStorage.setItem("role", permissions);
    },
    [UNSET_PERMISSIONS](state) {
      state.permissions = null;
      localStorage.setItem("role", null);
    },
    [LOGOUT](state) {
      state.isLoggedIn = false;
    },
    ["CHANGE_PASSWD"](state) {
      state.change_passwd = true;
    },
    ["PASSWD_CHANGED"](state) {
      state.change_passwd = false;
    },
    ["UPDATE_TOKEN"](state, payload) {
      state.token = payload;
    },
    ["SET_USER"](state, payload) {
      state.user = payload;
    },
    ["SET_CREDENTIALS"](state, payload) {
      state.credentials = payload;
    },
    ["SET_SUBSCRIPTION"](state, payload) {
      state.subscriptions = payload;
    },
    [SET_LOADER](state, payload) {
      state.loader = payload;
    },
    [SET_REQUEST_SENT](state, payload) {
      state.requestSent = payload;
    },
  },
  getters: {
    isLoggedIn: (state) => {
      // let self = this
      if (!state.isLoggedIn) {
        state.checkLoggedIn;
      } // FIXME doesn't work anymore ;)
      return state.isLoggedIn;
    },
    permissions: (state) => {
      return state.permissions;
    },
    getToken: (state) => {
      return state.token;
    },
    forceChangePassword: (state) => {
      return state.change_passwd;
    },
    getUserSubscriptions: (state) => {
      return state.subscriptions;
    },
    getUser: (state) => {
      return state.user;
    },
    getSubscriptionId: (state) => {
      return state.currenSubscriptionId;
    },
  },
  modules: {
    cjstore,
    hints,
    notifications,
  },
  created() {},
  methods: {},
});
