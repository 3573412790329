<template>
  <v-row justify="center">
	<v-dialog
	  v-model="dialog"
	  max-width="1145px"
	>
		<v-container fluid id="customer-journey-modal">
			<v-row>
				<v-stepper
					alt-labels
					elevation="0"
					v-model="e1"
					class="w-100 mx-auto"
				>
					<v-stepper-items
						class="border-radius-xl overflow-hidden"
					>
						<v-stepper-content
							step="1"
							class="bg-white border-radius-xl"
						>
							<Step1
								v-if="dialog"
								ref="step1"
								@changeStep="e1=$event" 
								@setProjectTitle="projectTitle = $event"
								@setProjectDescription="projectDescription = $event"
								@close="$emit('close')"
							/>
						</v-stepper-content>

						<template v-if="!user.super_admin">
							<v-stepper-content
								step="2"
								class="bg-white border-radius-xl px-4"
							>
								<Step2
									v-if="dialog"
									ref="step2"
									@changeStep="e1=$event"
									@close="$emit('close')"
									@cardSelected="addPersona($event)"
								/>
							</v-stepper-content>
							<v-stepper-content
								step="3"
								class="bg-white border-radius-xl px-4"
							>
								<Step3
									v-if="dialog"
									ref="step3"
									@changeStep="e1=$event" @finish="sendCustomerJourney"
									@close="$emit('close')"
									@cardSelected="customerJourneyTemplate = $event"
								/>
							</v-stepper-content>

						</template>

						<template v-else>
							<v-stepper-content
								step="2"
								class="bg-white border-radius-xl px-4">
								<StepAdmin 
									v-if="dialog"
									@changeStep="e1=$event" 
									@finish="sendCustomerJourney"
									@close="$emit('close')"/>
							</v-stepper-content>
						</template>

					</v-stepper-items>
				</v-stepper>
			</v-row>
		</v-container>
	</v-dialog>
  </v-row>
</template>

<script>
import Step1 from "@/components/dashboard/addCustomerJourneyModal/Step1";
import Step2 from "@/components/dashboard/addCustomerJourneyModal/Step2";
import Step3 from "@/components/dashboard/addCustomerJourneyModal/Step3";
import StepAdmin from "@/components/dashboard/addCustomerJourneyModal/StepAdmin";
import global from "@/store/global";
import store from '@/store/index';
import { http } from "@/plugins/http";

export default {
	components: { Step1, Step2, Step3, StepAdmin },
	props: {
		dialog: {
			default: false
		}
	},
	data() {
		return {
			e1: 1,
			projectTitle: null,
			projectDescription: null,
			customerJourneyTemplate: null,
			personasArray: [],
			user: store.getters.getUser
		};
	},
	methods: {
		addPersona(persona) {
			if(!this.personasArray.includes(persona)) {
				this.personasArray.push(persona);
			}
		},
		async sendCustomerJourney(selectedAvatar = null) {
			const description = this.projectDescription ? this.projectDescription : "";
			const result = await http.post(`/customer_journeys`, {name: this.projectTitle, description: description})
			if(result.status === 200) {
				if(this.personasArray){
					let customerJourneyId = result.data.id;
					http.patch(`/personas_binded_customer_journey/${customerJourneyId}`, {personas_ids: this.personasArray})
					.then(() => this.$store.dispatch("cjstore/setSelectedPersonas", this.personasArray))
				}
				if(selectedAvatar){
					var avatars = JSON.parse(localStorage.getItem('journey_template_avatars'));
					var templateAvatar = {
						templateId: result.data.id,
						avatar: selectedAvatar
					}
					if(avatars){
						avatars.push(templateAvatar)
						localStorage.setItem('journey_template_avatars', JSON.stringify(avatars))
					}else{
						localStorage.setItem('journey_template_avatars', JSON.stringify([templateAvatar]))
					}
				}
				if(this.customerJourneyTemplate){
					var selectedTemplates = JSON.parse(localStorage.getItem('selected_templates'));
					var selectedTemplate = {
						customerJourneyId: result.data.id,
						selectedTemplate: this.customerJourneyTemplate.id
					};
					if(selectedTemplates){
						selectedTemplates.push(selectedTemplate)
						localStorage.setItem('selected_templates', JSON.stringify(selectedTemplates))
					}else{
						localStorage.setItem('selected_templates', JSON.stringify([selectedTemplate]))
					}
				}
				this.$emit("customerJourneyAdded");
				this.$emit('close');
				this.$router.push(`/customer_journey/${result.data.id}`)
			}
		},
		clear() {
			this.e1 = 1;
			this.projectTitle = null;
			global.dispatch('clearPersonas');
		}
	},
	watch: {
		dialog(val) {
			if(!val) {
				this.clear();
				this.$emit('close');
			}
		}
	}
}
</script>
