<template>
    <v-card class="modal-step">
        <div class="justify-space-between modal-top d-flex">
            <v-btn
                :ripple="false"
                :elevation="0"
                class="goBack-button ml-1 mt-1"
                @click="prev"
                icon
            >
                <img src="@/assets/img/chevron-circle-left.svg" />
            </v-btn>
             <v-btn
                :ripple="false"
                :elevation="0"
                class="mr-1 mt-1 close-button"
                @click="close"
                icon
            >
                <img src="@/assets/img/cross-circle.svg" />
            </v-btn>
        </div>
        <div>
            <v-row class="text-center">
                <v-col cols="5" class="mx-auto">
                    <h5 class="mb-2 modal-title">
                        Tworzenie nowej ścieżki klienta
                    </h5>
                    <p class="modal-subtitle">
                        krok 2/3: wybierz personę lub pomiń ten krok
                    </p>
                </v-col>
            </v-row>
            <div class="modal-cards" v-if="personas.length > 0">
                <template 
                    v-for="(persona, id) in personas">
                    <Card 
                        :key="`card_persona_${id}`"
                        :data="persona"
                        @selectCard="selectCard($event)" 
                    />
                </template>
            </div>
            <div v-else>
                <p class="text-center text-color-gray text-size-2 mb-0">Brak person. Aby utworzyć personę przejdź do <a class="text-decoration-none text-color-secondary" href="/personas">widoku person.</a></p>
            </div>
        </div>
        <div class="text-end">
            <v-row class="mt-2">
                <v-col cols="5" class="mx-auto">
                    <v-btn
                        :ripple="false"
                        :elevation="0"
                        class="
                            py-5
                            px-6
                            me-2
                            next-button
                        "
                        @click="next"
                    >
                        Dalej
                    </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
import Card from "@/components/dashboard/addCustomerJourneyModal/Card";
import { http } from "@/plugins/http";

export default {
    components: { Card },
    data() {
        return {
            toggle_exclusive: 2,
            personas: [],
            selectedCard: {
                title: null,
                subtitle: null,
                image: null
            }
        };
    },
    computed: {
        personasToBeShowed() {
            return this.personas.slice(0, 3);
        }
    },
    created(){
        this.getPersonas();
    },
    methods: {
        next() {
           this.$emit("changeStep", 3)
        },
        prev() {
            this.$emit("changeStep", 1)
        },
        close() {
            this.$emit("close")
        },
        selectCard(card) {
            this.selectedCard = card;
            this.$emit("cardSelected", card)
        },
        getPersonas(){
            http.get('/personas')
            .then((response) => {
                this.personas = response.data.data
            });
        },
    }
}
</script>