<template>
    <v-row id="first-segment" v-if="data.przychod">
        <v-col class="firstCol col-sm-12 col-md-4 wrapper">
            <div class="position-relative income">
                <div class="income-bg">
                    <img src="@/assets/img/analytics-blured-bg.png" />
                </div>
                <div class="income-content">
                    <p>Przychód</p>
                    <p>${{data.przychod[0]}}</p>
                    <p><span v-if="data.przychod[1]>0">+</span>{{data.przychod[1].toFixed(0)}}%</p>
                </div>
            </div>
        </v-col>
        <v-col class="col-sm-12 col-md-4 wrapper">
            <div class="purple-container">
                <p>Współczynnik konwersji</p>
                <p>
                    <span>{{ data.wspolczynnik_konwersji[0].toFixed(2) }}%</span>
                    <span :class="data.wspolczynnik_konwersji[1]>=0 ? 'positive' : 'negative'">
                        <span v-if="data.wspolczynnik_konwersji[1]>0">+</span>{{ data.wspolczynnik_konwersji[1].toFixed(0) }}%
                    </span>
                </p>
            </div>
            <v-row class="small-data-container-wrapper">
                <div class="small-data-container">
                   <SmallDataContainer
                        :title="`Liczba zamówień`"
                        :results="data.analytical_data.liczba_zamowien"
                        :icon="`${require('@/assets/img/icon_shopping_basket.svg')}`"
                        :placesAfterComma="0"
                    />
                </div>
                <div class="small-data-container">
                    <SmallDataContainer
                        :title="`Bounce rate`"
                        :results="data.analytical_data.bounce_rate"
                        :icon="`${require('@/assets/img/icon_chart.svg')}`"
                    />
                </div>
                <div class="small-data-container">
                    <SmallDataContainer
                        :title="`Średnia wartość koszyka`"
                        :results="data.analytical_data.srednia_wartosc_koszyka"
                        :icon="`${require('@/assets/img/icon_wallet.svg')}`"
                    />
                </div>
            </v-row>
        </v-col>
        <v-col class="col-sm-12 col-md-4 wrapper">
            <div class="title">
                <p>Dane analityczne w porównaniu do ubiegłego miesiąca dla domeny <span class="font-weight-bolder">{{domainName}}</span></p>
            </div>
            <v-row class="small-data-container-wrapper">
                <div class="small-data-container">
                    <SmallDataContainer
                        :title="`Liczba odsłon`"
                        :results="data.analytical_data.liczba_odslon"
                        :icon="`${require('@/assets/img/icon_windows.svg')}`"
                        :placesAfterComma="0"
                    />
                </div>
                <div class="small-data-container">
                    <SmallDataContainer
                        :title="`Porzucone koszyki`"
                        :results="data.analytical_data.porzucone_koszyki"
                        :icon="`${require('@/assets/img/icon_shopping_cart.svg')}`"
                        :placesAfterComma="0"
                    />
                </div>
                <div class="small-data-container">
                    <SmallDataContainer
                        :title="`Średni czas na stronie`"
                        :results="data.analytical_data.sredni_czas_na_stronie"
                        :icon="`${require('@/assets/img/icon_stopwatch_white.svg')}`"
                        :placesAfterComma="0"
                    />
                </div>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import SmallDataContainer from '@/components/analytics/SmallDataContainer'

export default {
    props: {
        data: {},
        domainName: {}
    },
    components: { SmallDataContainer },
    data() {
        return {
            wspolczynnik_konwersji: [],
            przychod: []
        }
    },
}
</script>