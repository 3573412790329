<template>
    <v-card>
       <div id="add-source-file-modal">
            <div class="close-btn" >
                <img class="cursor-pointer" @click="$emit('close')" src="@/assets/img/cross-circle.svg" />
            </div>
            <p class="add-source-file-title">Dodawanie pliku źródłowego</p>

            <div @dragover.prevent @drop.prevent>
                <input type="file" multiple @change="uploadFile" hidden ref="fileInput"/>
                <div @drop="dragFile" class="upload-area" @click="$refs.fileInput.click()">
                    <img src="@/assets/img/share-2.svg" />
                </div>
            </div>

            <p class="add-source-file-description">Przeciągnij plik lub <span class="font-weight-bold text-color-secondary cursor-pointer" @click="$refs.fileInput.click()">wybierz z komputera</span></p>
            <div v-if="files && files.length" class="file-names">
                <ul v-for="(file, index) in files" :key="index">
                    <li >
                        <div class="file-name">
                            {{file.name}}
                            <svg @click="removeFile(index)" class="trash-icon" width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.08325 7.33333C7.08325 6.91912 6.74747 6.58333 6.33325 6.58333C5.91904 6.58333 5.58325 6.91912 5.58325 7.33333H7.08325ZM5.58325 12.3333C5.58325 12.7475 5.91904 13.0833 6.33325 13.0833C6.74747 13.0833 7.08325 12.7475 7.08325 12.3333H5.58325ZM10.4166 7.33333C10.4166 6.91912 10.0808 6.58333 9.66658 6.58333C9.25237 6.58333 8.91658 6.91912 8.91658 7.33333H10.4166ZM8.91658 12.3333C8.91658 12.7475 9.25237 13.0833 9.66658 13.0833C10.0808 13.0833 10.4166 12.7475 10.4166 12.3333H8.91658ZM13.7499 4C13.7499 3.58579 13.4141 3.25 12.9999 3.25C12.5857 3.25 12.2499 3.58579 12.2499 4H13.7499ZM3.74992 4C3.74992 3.58579 3.41413 3.25 2.99992 3.25C2.5857 3.25 2.24992 3.58579 2.24992 4H3.74992ZM1.33325 3.25C0.919038 3.25 0.583252 3.58579 0.583252 4C0.583252 4.41421 0.919038 4.75 1.33325 4.75V3.25ZM14.6666 4.75C15.0808 4.75 15.4166 4.41421 15.4166 4C15.4166 3.58579 15.0808 3.25 14.6666 3.25V4.75ZM9.74992 4C9.74992 4.41421 10.0857 4.75 10.4999 4.75C10.9141 4.75 11.2499 4.41421 11.2499 4H9.74992ZM4.74992 4C4.74992 4.41421 5.0857 4.75 5.49992 4.75C5.91413 4.75 6.24992 4.41421 6.24992 4H4.74992ZM5.58325 7.33333V12.3333H7.08325V7.33333H5.58325ZM8.91658 7.33333V12.3333H10.4166V7.33333H8.91658ZM12.2499 4V14H13.7499V4H12.2499ZM11.3333 14.9167H4.66658V16.4167H11.3333V14.9167ZM3.74992 14V4H2.24992V14H3.74992ZM4.66658 14.9167C4.16032 14.9167 3.74992 14.5063 3.74992 14H2.24992C2.24992 15.3347 3.3319 16.4167 4.66658 16.4167V14.9167ZM12.2499 14C12.2499 14.5063 11.8395 14.9167 11.3333 14.9167V16.4167C12.6679 16.4167 13.7499 15.3347 13.7499 14H12.2499ZM1.33325 4.75H14.6666V3.25H1.33325V4.75ZM11.2499 4V3.16667H9.74992V4H11.2499ZM8.83325 0.75H7.16658V2.25H8.83325V0.75ZM4.74992 3.16667V4H6.24992V3.16667H4.74992ZM7.16658 0.75C5.8319 0.75 4.74992 1.83198 4.74992 3.16667H6.24992C6.24992 2.66041 6.66032 2.25 7.16658 2.25V0.75ZM11.2499 3.16667C11.2499 1.83198 10.1679 0.75 8.83325 0.75V2.25C9.33951 2.25 9.74992 2.66041 9.74992 3.16667H11.2499Z" fill="#4d5f9d"/>
                            </svg>
                        </div>
                    </li>
                </ul>
            </div>
            <v-btn class="confirm-btn" :disabled="files && files.length === 0" @click="addFiles">DODAJ PLIKI</v-btn>
            <v-dialog
                v-model="showDuplicatedNamesInfo"
                max-width="fit-content"
            >
              <v-card>
                    <div id="duplicated-names-info-modal">
                        <div class="close-btn" >
                            <img class="cursor-pointer" @click="showDuplicatedNamesInfo = false" src="@/assets/img/cross-circle.svg" />
                        </div>
                        <p class="text-color-red mb-0">Plik o nazwie <span class="font-weight-bold">{{duplicatedName}}</span> został już dodany.</p>
                        <p class="text-color-red mb-0">Zmień nazwę pliku, który chcesz dodać.</p>
                    </div>
              </v-card>
            </v-dialog>
       </div>
    </v-card>
</template>

<script>
import { http } from "@/plugins/http";

export default {
    props: {
        personaId: {},
        savedFiles: {},
    },
    data() {
        return {
            files: [],
            showDuplicatedNamesInfo: false,
            duplicatedName: null
        }
    },
    methods: {
        uploadFile(e) {
            const newFiles = [...e.target.files]
            if(!this.areNamesDuplicated(newFiles)) {
                this.files.push(...e.target.files);
            } else {
                this.showDuplicatedNamesInfo = true;
            }
            this.$refs.fileInput.value = null;
        },
        areNamesDuplicated(newFiles) {
            let result = false;
            newFiles.forEach(newFile => {
                this.savedFiles.forEach(file => {
                    if(file.name === newFile.name) {
                        result = true;
                        this.duplicatedName = newFile.name
                    }
                });
            });
            return result;
        },
        dragFile(e) {
            const newFiles = [...e.dataTransfer.files];
            if(!this.areNamesDuplicated(newFiles)) {
                this.files.push(...e.dataTransfer.files);
            } else {
                this.showDuplicatedNamesInfo = true;
            }
            this.$refs.fileInput.value = null;
        },
        removeFile(fileIndex) {
            this.files.splice(fileIndex, 1);
        },
        clear() {
            this.files = [];
        },
        async addFiles() {
            let formData = new FormData()
            this.files.forEach(element => {
                formData.append(element.name, element);
            });
            await http.post(`/persona_source_files/${this.personaId}`, formData)
            this.$emit('sourceFilesAdded')
            this.$emit('close');
        }
    },
}
</script>