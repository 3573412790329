<template>
    <v-data-table
        :headers="headers"
        :items="items"
        class="all-emissions-table"
        :items-per-page="items.length"
    >
        <template v-slot:body="{ items }">
            <tr 
                v-for="item in items" :key="item.id" 
                :active="selectedItem == item" 
                @click="selectItem(item)"
                :class="rowClass(item)"
            >
                <td class="emission-date-column">{{ formatDate(item.date_start) }} - {{ formatDate(item.date_end) }}</td>
                <td>
                    <div class="status-wrapper">
                        <v-switch
                            v-if="shouldEmissionBeRunning(item.date_start, item.date_end) === emissionStatus.active"
                            :ripple="false"
                            class="survey-switch custom-red"
                            :class="item.status === emissionStatus.active ? '' : 'off'"
                            v-model="item.is_active"
                            @change="isActiveToggle(item)"
                            hide-details
                            color="#49f074"
                            inset
                        >
                        </v-switch>
                        <span :class="statusColor(item.status)">
                            {{ item.status }}
                        </span>
                    </div>
                </td>
                <td class="trigger-location-column">{{ formatTriggerLocationType(item.trigger_location.type) }}: {{ item.trigger_location.value }}</td>
                <td>{{ item.triggerTypeToDisplay }}</td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
import { http } from "@/plugins/http";

export default {
    props: {
        allSurveyRuns: {
            default: [],
        },
    },
    data() {
        return {
            items: [],
            headers: [
                {
                    text: 'Data emisji',
                    align: 'start',
                    sortable: true,
                    value: 'date_start',
                },
                { text: 'status', value: 'status' },
                { text: 'Emisja ankiety wg:', value: 'triggerLocation', sortable: false },
                { text: 'Trigger wg:', value: 'triggerTypeToDisplay' },
            ], 
            selectedItem: null,
            emissionStatus: {
                active: 'aktywna',
                inactive: 'nieaktywna',
                planned: 'zaplanowana'
            },
            triggerTypes: {
                rage: 'zdenerwowanie',
                time: 'czas na stronie',
                scroll: '% scrollowania',
                rage_scroll: "nerwowy scroll",
                rage_browse: "nerwowe zmiany stron",
                not_found: "nieznalezienie informacji",
                exit: "próba wyjścia",
            },
            triggerLocationTypes: {
                regex: 'regex',
                subpage: 'podstrona'
            }
        }
    },
    methods: {
        async isActiveToggle(item) {
            await http.patch(`/survey_run/${item.id}`, {
                is_active: item.is_active,
            })
            
            this.$emit('getSurveyRunData')
        },
        shouldEmissionBeRunning(dateStart, dateEnd) {
            const today = new Date();
            const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
            const emissionStartDate = new Date(dateStart);
            const emissionEndDate = new Date(dateEnd);
            if(todayWithoutTime.getTime() < emissionStartDate.getTime()) {
                return this.emissionStatus.planned
            } else if(todayWithoutTime.getTime() <= emissionEndDate.getTime() && todayWithoutTime.getTime() >= emissionStartDate.getTime()) {
                return this.emissionStatus.active
            } else if((todayWithoutTime.getTime() > emissionEndDate.getTime())) {
                return this.emissionStatus.inactive
            }
        },
        rowClass(item) {
            let result = ""
            if(item.status !== this.emissionStatus.planned) {
                result = "cursor-pointer";
            }  else {
                return "cursor-auto";
            }
            if(item == this.selectedItem) {
                result += " active"
            }
            return result;
        },
        selectItem(item) {
            if(item.status !== this.emissionStatus.planned) {
                this.selectedItem = item;
            } else {
                this.selectedItem = null;
                this.$emit("emissionSelected", null)
            }
        },
        getEmissionStatus(dateStart, dateEnd, isActive) {
            const today = new Date();
            const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
            const emissionStartDate = new Date(dateStart);
            const emissionEndDate = new Date(dateEnd);
            if(todayWithoutTime.getTime() < emissionStartDate.getTime()) {
                return this.emissionStatus.planned
            } else if((todayWithoutTime.getTime() <= emissionEndDate.getTime() && todayWithoutTime.getTime() >= emissionStartDate.getTime()) && isActive) {
                return this.emissionStatus.active
            } else if((todayWithoutTime.getTime() > emissionEndDate.getTime()) || !isActive) {
                return this.emissionStatus.inactive
            }
        },
        triggerTypeToDisplay(triggerType) {
            if(triggerType === "rage") {
                return this.triggerTypes.rage;
            } else if (triggerType === "time") {
                return  this.triggerTypes.time;
            } else if (triggerType === "scroll") {
                return this.triggerTypes.scroll;
            } else if (triggerType === "rage_scroll") {
                return this.triggerTypes.rage_scroll;
            } else if (triggerType === "rage_browse") {
                return this.triggerTypes.rage_browse;
            } else if (triggerType === "not_found") {
                return this.triggerTypes.not_found;
            } else if (triggerType === "exit") {
                return this.triggerTypes.exit;
            }

            return triggerType
        },
        formatDate(data) {
            const date = new Date(data);
            return [
                this.padTo2Digits(date.getDate()),
                this.padTo2Digits(date.getMonth() + 1),
                date.getFullYear(),
            ].join('.');
        },
        padTo2Digits(num) {
            return num.toString().padStart(2, '0');
        },
        formatTriggerLocationType(triggerLocationType) {
            if(triggerLocationType === "subpage") {
                return this.triggerLocationTypes.subpage;
            } else {
                return this.triggerLocationTypes.regex;
            }
        },
        statusColor(emissionStatus) {
            if(emissionStatus === this.emissionStatus.inactive) {
                return "text-color-red"
            } else if (emissionStatus === this.emissionStatus.active) {
                return "text-color-green"
            } else if (emissionStatus === this.emissionStatus.planned) {
                return "text-color-gray"
            }
        }
    },
    watch: {
        allSurveyRuns(val) {
            this.items = val;
            this.items.map((element) => {
                element.status = this.getEmissionStatus(element.date_start, element.date_end, element.is_active);
                element.triggerTypeToDisplay = this.triggerTypeToDisplay(element.trigger.type);
            })
        },
        selectedItem(val) {
            this.$emit("emissionSelected", val)
        }
    }
}
</script>