<template>
   <svg
      width="10.020095"
      height="34"
      viewBox="0 0 10.020095 34"
      fill="none"
      version="1.1"
      class="baloon"
      id="svg4"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg">
   <defs
      id="defs8" />
   <path
      d="m 3.5055248,6.5000005 c 0,-3.58985 2.91015,-6.5 6.4999702,-6.5 C 9.9680848,3.0309919 10.020095,34 10.020095,34 8.7051478,33.971021 2.5016938,34 2.5016938,34 0.81998379,34 -0.38206621,32.373 0.11204379,30.765501 l 2.97419001,-9.6761 c 0.27797,-0.9043 0.41929,-1.8451 0.41929,-2.7912 z"
      stroke="#8392ab"
      stroke-width="3"
      id="path2"
      :style="{fill: fill}"
      style="fill-opacity:1;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none;image-rendering:auto"/>
   </svg>
</template>
<script>
export default {
   props: {
      fill: {
         type: String,
         default: '#fff'
      }
   }
}
</script>