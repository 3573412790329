<template>
  <component 
    v-bind:is="componentName"
    @changeComponent="changeComponent"
  >
  </component>
</template>

<script>
import RegisterFirstStage from "@/components/RegisterFirstStage";
import RegisterSecondStage from "@/components/RegisterSecondStage";

export default {
  components: { RegisterFirstStage, RegisterSecondStage },
  data() {
    return {
      componentName: "RegisterFirstStage"
    };
  },

  methods: {
    changeComponent() {
      this.componentName = 'RegisterSecondStage'
    }
  },
};
</script>
