<template>
    <div id="demography" v-if="data.uzytkownicy">
        <span class="analytics-title">Analityka: Demografia</span>
        <div id="demography-content">
            <v-row>
                <v-col class="col-sm-12 col-md-4 pie-chart-col">
                    <div>
                        <p class="title">Użytkownicy</p>
                        <div class="d-flex mb-4">
                            <div class="pie-chart-legend-circle women"></div><span>kobiety</span>
                        </div>
                        <div class="d-flex">
                            <div class="pie-chart-legend-circle men"></div><span>mężczyźni</span>
                        </div>
                    </div>
                    <PieChart
                        :chartData="{
                            labels: ['kobiety', 'mężczyźni'],
                            datasets: [
                                { 
                                    data: data.uzytkownicy,
                                    backgroundColor: ['#5e35f2', '#2e1b75'],
                                }, 
                            ]
                        }"
                    />
                </v-col>
                <v-col class="col-sm-12 col-md-4">
                    <div class="chart-title-container">
                        <span class="chart-title-first">Użytkownicy</span>
                    </div>
                    <BarChart
                        :chartData="{
                            labels: data.uzytkownicy_m.labels,
                            datasets: [
                                { 
                                    data: data.uzytkownicy_m.data,
                                    backgroundColor: ['#E5E1F1','#e0ddea', '#cac6dc', '#aba4c8', '#8276ac', '#584991', '#2e1b75'],
                                    borderRadius: 6,
                                    barPercentage: 1,
                                    categoryPercentage: 0.5
                                }, 
                            ]
                        }"
                    />
                </v-col>
                <v-col class="col-sm-12 col-md-4">
                     <div class="chart-title-container">
                        <span class="chart-title-first">Użytkowniczki</span>
                    </div>
                    <BarChart
                        :chartData="{
                            labels: data.uzytkownicy_w.labels,
                            datasets: [
                                { 
                                    data: data.uzytkownicy_w.data,
                                    backgroundColor: ['#F2EFFF','#e7e1fd', '#d6ccfb', '#bfaefa', '#9e86f7', '#7d5df5', '#5e35f2'],
                                    borderRadius: 6,
                                    barPercentage: 1,
                                    categoryPercentage: 0.5
                                }, 
                            ]
                        }"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="col-sm-12 col-md-4">
                    <div class="chart-title-container">
                        <span class="chart-title-first">Użytkownicy</span>
                        <span class="chart-title-second">śr.wartość zamówienia</span>
                    </div>
                    <BarChart
                        :displayDataLabels="true"
                        :chartData="{
                            labels: ['mężczyźni','kobiety'],
                            datasets: [
                                { 
                                    data: data.sr_wartosc_zamowienia,
                                    backgroundColor: ['#1AC5A8', '#52FBDE'],
                                    borderRadius: 6,
                                    barPercentage: 1,
                                    categoryPercentage: 0.5
                                }, 
                            ]
                        }"
                    />
                </v-col>
                <v-col class="col-sm-12 col-md-4">
                    <div class="chart-title-container">
                        <span class="chart-title-first">Mężczyźni</span>
                        <span class="chart-title-second">śr.wartość zamówienia</span>
                    </div>
                    <BarChart
                        :chartData="{
                            labels: data.sr_wartosc_zamowienia_m.labels,
                            datasets: [
                                { 
                                    data: data.sr_wartosc_zamowienia_m.data,
                                    backgroundColor: ['#D5F5F0','#c5f0e9', '#afebe1', '#81dfcf', '#5fd7c2', '#3ccdb4', '#1ac5a8'],
                                    borderRadius: 6,
                                    barPercentage: 1,
                                    categoryPercentage: 0.5
                                }, 
                            ]
                        }"
                    />
                </v-col>
                <v-col class="col-sm-12 col-md-4">
                    <div class="chart-title-container">
                        <span class="chart-title-first">Kobiety</span>
                        <span class="chart-title-second">śr.wartość zamówienia</span>
                    </div>
                    <BarChart
                        :chartData="{
                            labels: data.sr_wartosc_zamowienia_w.labels,
                            datasets: [
                                { 
                                    data: data.sr_wartosc_zamowienia_w.data,
                                    backgroundColor: ['#E5FFFA','#d3fef6', '#c3fef4', '#a0fded', '#86fde8', '#6cfbe3', '#52fbde'],
                                    borderRadius: 6,
                                    barPercentage: 1,
                                    categoryPercentage: 0.5
                                }, 
                            ]
                        }"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col class="col-sm-12 col-md-4">
                    <div class="chart-title-container">
                        <span class="chart-title-first">Użytkownicy</span>
                        <span class="chart-title-second">transakcje</span>
                    </div>
                    <BarChart
                        :displayDataLabels="true"
                        :chartData="{
                            labels: ['mężczyźni','kobiety'],
                            datasets: [
                                { 
                                    data: data.transakcje,
                                    backgroundColor: ['#E48400', '#FFB145'],
                                    borderRadius: 6,
                                    barPercentage: 1,
                                    categoryPercentage: 0.5
                                }, 
                            ]
                        }"
                    />
                </v-col>
                <v-col class="col-sm-12 col-md-4">
                    <div class="chart-title-container">
                        <span class="chart-title-first">Mężczyźni</span>
                        <span class="chart-title-second">transakcje</span>
                    </div>
                    <BarChart
                        :chartData="{
                            labels: data.transakcje_m.labels,
                            datasets: [
                                { 
                                    data: data.transakcje_m.data,
                                    backgroundColor: ['#F9E7CE','#f8e0bf', '#f6d4a6', '#f0bc73', '#ecaa4d', '#e89626', '#e48400'],
                                    borderRadius: 6,
                                    barPercentage: 1,
                                    categoryPercentage: 0.5
                                }, 
                            ]
                        }"
                    />
                </v-col>
                <v-col class="col-sm-12 col-md-4">
                    <div class="chart-title-container">
                        <span class="chart-title-first">Kobiety</span>
                        <span class="chart-title-second">transakcje</span>
                    </div>
                    <BarChart
                        :chartData="{
                            labels: data.transakcje_w.labels,
                            datasets: [
                                { 
                                    data: data.transakcje_w.data,
                                    backgroundColor: ['#FFF1DE','#ffebd0', '#ffe4be', '#ffd499', '#ffc97d', '#ffbc61', '#ffb145'],
                                    borderRadius: 6,
                                    barPercentage: 1,
                                    categoryPercentage: 0.5
                                }, 
                            ]
                        }"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import BarChart from '@/components/analytics/BarChart'
import PieChart from '@/components/analytics/PieChart'

export default {
    components: { BarChart, PieChart },
    props: {
        data: {}
    },
}
</script>