<template>
  <div v-if="isHintVisible && !category" id="hints">
    <div class="mascot">
      <mascot />
    </div>
    <div class="balloon">
      <triangle class="polygon" />
      <div class="content">
        <h3>{{ title }}</h3>
        <div class="text">{{ text }}</div>
        <div
          class="btn"
          @click="
            () => {
              btnAction();
              setHint({ visible: false });
            }
          "
        >
          {{ button }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import triangle from "@/components/hints/triangle.vue";
import mascot from "@/components/hints/mascot.vue";

export default {
  components: {
    triangle,
    mascot,
  },
  data() {
    return {
      hints: {
        analytics: {
          title: "Title",
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          button: "Okej!",
        },
        notifications: {
          title: "Hej!",
          text: "Masz nowe, nieodczytane notyfikacje",
          button: "Zobacz teraz",
          btnAction: function () {
            this.$store.dispatch("notifications/setNotificationMenu", true);
          },
        },
      },
      category: false,
    };
  },
  computed: {
    ...mapState("hints", ["isHintVisible", "hintCategory"]),
    title() {
      return this.hints[this.hintCategory].title;
    },
    text() {
      return this.hints[this.hintCategory].text;
    },
    button() {
      return this.hints[this.hintCategory].button;
    },
    btnAction() {
      return this.hints[this.hintCategory].btnAction || null;
    },
  },
  watch: {
    hintCategory(newVal) {
      if (newVal) {
        localStorage.setItem(`hint_${this.hintCategory}`, true);
      }
    },
    isHintVisible(newVal) {
      if (newVal) {
        localStorage.setItem(`hint_${this.hintCategory}`, true);
      }
    },
  },
  mounted() {
    this.$store.dispatch("notifications/setNotificationMenu", false);
    this.category = localStorage.getItem(`hint_${this.hintCategory}`)
      ? true
      : false;
  },
  destroyed() {
    this.setHint({ visible: false });
  },
  methods: {
    ...mapActions("hints", ["setHint"]),
  },
};
</script>

<style lang="sass" scoped>
#hints
  position: fixed
  bottom: 20px
  right: 20px
  z-index: 999
  .mascot
    position: absolute
    bottom: 0
    right: 25px
    svg
      max-width: 180px
  .balloon
    position: relative
    background-color: #fff
    padding: 15px
    border-radius: 8px
    max-width: 260px
    max-height: 480px
    display: flex
    flex-direction: column
    margin-bottom: 180px
    h3
      font-weight: 400
      font-size: 30px
      color: $primary-color
      margin-bottom: 14px
    .text
      font-size: 14px
      max-height: 300px
      overflow-y: scroll
      line-height: 18.75px
    .btn
      cursor: pointer
      margin-top: 20px
      border-radius: 8px
      padding: 12px 0
      color: #fff
      text-align: center
      text-transform: uppercase
      font-size: 14px
      font-weight: 600
      background-color: $primary-color
    .polygon
      position: absolute
      bottom: -36px
      left: 64px
      height: 136px
      width: 136px
      z-index: -1
      transform: rotate(180deg)
</style>
