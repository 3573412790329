<template>
  <div
    v-if="col.type !== 'row-title' && arraysHasCommon(row.options, ['stage'])"
    class="stage"
  >
    <input
      @input="(e) => labelInput(e, rowId, columnId)"
      :value="col.title"
      @blur="renderChart(selectedPersonasObjs)"
      placeholder="Nazwa etapu"
    />
  </div>
</template>

<script>
import utils from "@/plugins/utils.js";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    col: Object,
    row: Object,
    columnId: Number,
    rowId: Number,
  },
  computed: {
    ...mapState("cjstore", ["channels", "selectedPersonasObjs"]),
  },
  methods: {
    ...mapActions("cjstore", ["setLabelInput", "renderChart"]),
    arraysHasCommon: utils.arraysHasCommon,
    labelInput(e, rowId, columnId) {
      this.setLabelInput({
        rowId: rowId,
        columnId: columnId,
        value: e.target.value,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.stage
  position: relative
  &::after
    content: ""
    height: 21px
    width: 15px
    position: absolute
    right: 0
    top: 0
    background-image: url('~@/assets/img/customer-journey/triangle-right-filled.svg')
    background-repeat: no-repeat
    background-size: 100% 100%
    background-position-x: -2px
  & input
    background-color: $primary-color
    font-family: 'Roboto'
    color: #fff
    height: 21px
    font-weight: 600
    text-align: center
    width: calc(100% - 15px)
    outline: none
    &::placeholder
      color: #fff
      opacity: 1
</style>
