<template>
    <v-card class="survey-link-modal">
        <div class="survey-link-wrapper">
            <div class="survey-link-close-btn" >
                <img class="cursor-pointer" @click="$emit('close')" src="@/assets/img/cross-circle.svg" />
            </div>
            <h2>Poniżej znajduje się wygenerowany link do ankiety</h2>
            <div class="link-container">
                <div>{{ linkToSurvey }}</div>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        linkToSurvey: {},
    },
}
</script>