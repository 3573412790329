<template>
  <div v-if="row.options && row.options.includes('stage')" class="actions">
    <div class="add add-column" @click="add(columnId)">
      <PlusCircleFillIcon />
    </div>
    <div v-if="columnId !== 0" class="remove-column" @click="remove(columnId)">
      <MinusCircleFillIcon />
    </div>
  </div>
</template>

<script>
import PlusCircleFillIcon from "@/components/icons/PlusCircleFillIcon.vue";
import MinusCircleFillIcon from "@/components/icons/MinusCircleFillIcon.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    PlusCircleFillIcon,
    MinusCircleFillIcon,
  },
  props: {
    row: Object,
    columnId: Number,
  },
  computed: {
    ...mapState("cjstore", ["selectedPersonasObjs"]),
  },
  methods: {
    ...mapActions("cjstore", ["addColumn", "removeColumn", "parseChartValues"]),
    async add(columnId) {
      await this.addColumn(columnId);
      await this.parseChartValues(this.selectedPersonasObjs);
    },
    async remove(columnId) {
      await this.removeColumn(columnId);
      await this.parseChartValues(this.selectedPersonasObjs);
    },
  },
};
</script>

<style lang="sass" scoped>
.add-column,
.remove-column
  position: absolute
  width: 20px
  height: 20px
  z-index: 999
  cursor: pointer
  opacity: 0
  transition: opacity .2s ease-out
.add-column
  right: -10px
  top: 0
.remove-column
  right: -10px
  bottom: 0
</style>
