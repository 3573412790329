<template>
    <div class="mt-sm-8 mt-md-5 mt-7 bg-transparent" id="start-trial">
        <div class="card-padding pb-0">
          <h2 class="text-h2 mb-2 auth-header">
              Wygląda na to, że nie masz jeszcze żadnych subskrypcji
          </h2>
          <p class="text-h5 mb-0 auth-subtitle">Stwórz subskrypcję aby zacząć pracować </p>
          <v-text-field
            hide-details
            outlined
            light
            placeholder="Nazwa subskrypcji"
            class="start-trial-input mt-8"
            v-model="subscriptionName"
          >
          </v-text-field>
          <span v-if="errorMessage" class="font-weight-bold text-color-red text-size-2 d-flex justify-end">{{errorMessage}}</span>
          <v-btn
            type="submit"
            elevation="0"
            :ripple="false"
            height="43"
            class="
            text-uppercase
            w-100
            auth-button
            mt-8
            "
            @click="startTrial"
            color="#5e72e4"
            small
            >Stwórz subskrypcję</v-btn
          >
        </div>
    </div>
</template>

<script>
import { http } from "../plugins/http";

export default {
  data() {
    return {
      subscriptionName: null,
      errorMessage: null
    };
  },
  methods: {
    async startTrial() {
      if(this.isValid()) {
        const response = await http.post('/subscription', {name: this.subscriptionName})
        let token = response.data.access_token
        this.$store.commit('UPDATE_TOKEN', token)
        this.$store.commit("SET_USER", response.data.user)
        localStorage.setItem("token", token)
        localStorage.setItem("token_time",Date.now())
        localStorage.setItem("refresh_token", response.data.refresh_token)
        http.defaults.headers.common = {"Authorization" : "Bearer " + token,"Accept" : "application/json" }
        if(response.data.user.user_access) {
          if (response.data.user.user_access[0].role != "unverified" && response.data.user.user_access[0].role != null) {
            this.$store.commit("SET_PERMISSIONS", response.data.user.user_access[0].role);
          } else {
            this.$store.commit("UNSET_PERMISSIONS");
          }
        }
        this.$router.push("/dashboard");
      }
    },
    isValid() {
      let result = true;
      this.errorMessage = null;
      if(!this.subscriptionName) {
        result = false;
        this.errorMessage = "Nazwa subskrypcji jest wymagana"
      }
      return result;
    }
  },
};
</script>

<style scoped>
#login-page {
  background-color: var(--v-primary-lighten5);
}
</style>

